import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Input,
  Label,
  Row,
  Form,
  FormFeedback,
} from "reactstrap";
import { Checkbox, FormControlLabel, Typography } from "@material-ui/core";
//redux
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";

import { withRouter, Link, useHistory } from "react-router-dom";
import { config } from "../../config";
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { getUserData } from "../../store/auth/profile/actions";

import { loginUser, resetLoginFlag } from "../../store/actions";
import logo from "../../assets/images/logo.png";
const { TOAST_AUTOCLOSE_TIMER } = config;



const Login = (props) => {
  const History = useHistory();

  const dispatch = useDispatch();
  const { user } = useSelector((state) => ({
    user: state.Account.user,
  }));
  const [userLogin, setUserLogin] = useState([]);
  const [rememberMe, setRememberMe] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);

  const [rememberedUserName, setRememberedUserName] = useState("");
  const [rememberedPassword, setRememberedPassword] = useState("");
  
  
  const handleRememberMeChange = (event) => {
    setRememberMe(event.target.checked);
    if (event.target.checked) {
      localStorage.setItem('rememberedUserName', validation.values.username);
      localStorage.setItem('rememberedPassword', validation.values.password);
    } else {
      localStorage.removeItem('rememberedUserName');
      localStorage.removeItem('rememberedPassword');
      setRememberedUserName("")
      setRememberedPassword("")
    }
  };

  useEffect(() => {
    const rememberedUserName = localStorage.getItem('rememberedUserName');
    const rememberedPassword = localStorage.getItem('rememberedPassword');
    setRememberedUserName(rememberedUserName)
    setRememberedPassword(rememberedPassword)
    if (rememberedUserName && rememberedPassword) {
      setRememberMe(true);
    }
  }, [user]);

  useEffect(() => {
    if (user && user) {
      setUserLogin({
        username: user.user.username,
        password: user.user.confirm_password,
      });
    }
  }, [user]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      username: userLogin.username || rememberedUserName ||  "",
      password: userLogin.password || rememberedPassword|| "",
    },
    validationSchema: Yup.object({
      username: Yup.string()
        .min(4, "Too Short!")
        .max(30, "Too Long!")
        .required("Please Enter Your username"),
      password: Yup.string()
        .min(4, "Too Short!")
        .max(30, "Too Long!")
        .required("Please Enter Your Password"),
    }),
    onSubmit: (values) => {
      dispatch(loginUser(values, props.history));
    },
  });

  const { loginError, loginSuccess } = useSelector((state) => ({
    loginError: state.Login.loginError,
    loginSuccess: state.Login.loginSuccess,
  }));

  useEffect(() => {
    if (loginError) {
      toast(loginError, {
        position: "top-left",
        hideProgressBar: true,
        className: "bg-danger text-white",
        progress: undefined,
        toastId: "",
      });
    }
    if (loginSuccess) {
      if (localStorage.getItem("authUser")) {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        dispatch(getUserData(obj.data.id));
          History.push("/dashboard");
      }
      dispatch(resetLoginFlag());
    }
  }, [dispatch, loginError, loginSuccess, History]);
  document.title = "Sign In | Salis";
  return (
    <React.Fragment>
      <ToastContainer autoClose={TOAST_AUTOCLOSE_TIMER} />
      <Row className="row-absolute">
        <Col md={6} lg={6} xl={6}>
          <div className="mx-auto login-card-width">
            <div className="mx-4 px-4 mt-5 text-white-50">
              <Link to="/" className="d-inline-block auth-logo">
                <img src={logo} alt="" height="50" />
              </Link>
            </div>
            <Card className="ms-4 mt-4 border-0 shadow-none">
              <CardBody className="p-4">
                <div className="mt-2">
                  <h5 className="text-dark font-weight-bold">Welcome again,</h5>
                </div>

                <div className="p-2 mt-4">
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                      return false;
                    }}
                    action="#"
                  >
                    <div className="mb-3">
                      <Label htmlFor="username" className="form-label">
                        Username
                      </Label>
                      <div className="position-relative auth-pass-inputgroup mb-3">
                        <Input
                          name="username"
                          className="form-control padding_input"
                          placeholder="Enter Username"
                          type="username"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.username || ""}
                          invalid={
                            validation.touched.username &&
                              validation.errors.username
                              ? true
                              : false
                          }
                        />
                        {validation.touched.username &&
                          validation.errors.username ? (
                          <FormFeedback type="invalid">
                            {validation.errors.username}
                          </FormFeedback>
                        ) : null}
                        <button
                          className="btn btn-link position-absolute start-0 top-0 text-decoration-none text-muted"
                          type="button"
                          id="password-addon"
                          disabled={true}
                        >
                          <i className="ri-mail-line"></i>
                        </button>
                      </div>
                    </div>

                    <div className="mb-3">
                      <Label className="form-label " htmlFor="password-input">
                        Password
                      </Label>
                      <div className="position-relative auth-pass-inputgroup mb-3">
                        <Input
                          name="password"
                          value={validation.values.password || ""}
                          type={passwordShown ? "text" : "password"}
                          className="form-control padding_input pe-5 auth-error-hide"
                          placeholder="Enter Password"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          invalid={
                            validation.touched.password &&
                              validation.errors.password
                              ? true
                              : false
                          }
                        />
                        {validation.touched.password &&
                          validation.errors.password ? (
                          <FormFeedback type="invalid">
                            {validation.errors.password}
                          </FormFeedback>
                        ) : null}
                        <button
                          className="btn btn-link position-absolute start-0 top-0 text-decoration-none text-muted"
                          type="button"
                          id="password-addon"
                          disabled={true}
                        >
                          <i className="ri-lock-line"></i>
                        </button>
                        <button
                          onClick={() => {
                            setPasswordShown(!passwordShown);
                          }}
                          className="btn border-0 position-absolute end-0 top-0 text-decoration-none text-muted"
                          type="button"
                          id="password-addon"
                        >
                          {passwordShown ? (
                            <i className="ri-eye-off-fill align-middle"></i>
                          ) : (
                            <i className="ri-eye-fill align-middle"></i>
                          )}
                        </button>
                      </div>
                      <div className=" float-sm-end">
                        <Link to="/forgot-password" className="text-muted">
                          Forgot password?
                        </Link>
                      </div>
                    </div>

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={rememberMe}
                          onChange={(e) => handleRememberMeChange(e)}
                          className="text-light-red"
                          value="work"
                        />
                      }
                      label={
                        <Typography className="text-light-red">
                          Remember me
                        </Typography>
                      }
                    />

                    <div className="mt-4">
                      <button
                        className="border-0 w-100 rounded bg-light-red text-white button-height-login"
                        type="submit"
                      >
                        Sign In
                      </button>
                    </div>
                  </Form>
                </div>
              </CardBody>
            </Card>

            <div className="mt-4 text-center">
              <p className="mb-0">
                Don't have an account ?{" "}
                <Link
                  to="/register"
                  className="fw-semibold text-primary text-decoration-none link-color"
                >
                  {" "}
                  <p className="text-light-red d-inline">Signup</p>
                </Link>{" "}
              </p>
            </div>
          </div>
        </Col>
        <Col lg={6} md={6} className="d-md-block d-none">
          <div className="auth-right-image"></div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default withRouter(Login);
