import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import DataTable from "react-data-table-component";
import DeleteBankModal from "../../../Components/Common/DeleteModal";
import ChangeBankModal from "./changeBankPopup";

//Import actions
import {
  getBankAccounts,
  resetEcomFlag,
  deleteBankAccount,
} from "../../../store/ecommerce/action";
//redux
import { useSelector, useDispatch } from "react-redux";
const BankAccounts = ({ banks }) => {
  const [deleteBankModal, setDeleteBankModal] = useState(false);
  const [changeBankModal, setChangeBankModal] = useState(false);
  const [DeleteID, setDeleteID] = useState("");
  const [BankID, setBankID] = useState("");
  const [bankAccount, setBankAccount] = useState({});
  const dispatch = useDispatch();
  const { bankAccountsData, isBankDelete, isBankAdded, isBankChanged } =
    useSelector((state) => ({
      bankAccountsData: state.Ecommerce.bankAccountsData,
      deleteBankAccountData: state.Ecommerce.deleteBankAccount,
      isBankDelete: state.Ecommerce.isBankDelete,
      isBankAdded: state.Ecommerce.isBankAdded,
      isBankChanged: state.Ecommerce.isBankChanged,
    }));
  // eslint-disable-next-line no-unused-vars
  const [pageNumber, setPageNumber] = useState(1);
  const handleGetBankAccounts = () => {
    const filterData = {};

    filterData["page"] = pageNumber;
  };

  useEffect(() => {
    dispatch(getBankAccounts());
    handleGetBankAccounts();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isBankDelete, isBankAdded, isBankChanged]);

  useEffect(() => {
    setTimeout(() => {
      dispatch(resetEcomFlag());
    }, 3000);
  }, [dispatch, isBankDelete, isBankAdded, isBankChanged]);

  const handelDelete = (ID) => {
    setDeleteID(ID);
    setDeleteBankModal(true);
  };
  const handelChange = (bank, id) => {
    setBankAccount(bank);
    setBankID(id);
    setChangeBankModal(true);
  };
  const onDeleteClick = () => {
    setDeleteBankModal(false);
    dispatch(deleteBankAccount({ id: DeleteID }));
  };
  useEffect(() => {
    if (isBankChanged) {
      setChangeBankModal(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBankChanged]);
  const bankscolumns = [
    {
      id: "holderCol",
      name: <span className="fw-normal text-gray-650 fs-6">Holder name</span>,
      selector: (cell) => (
        <p className="text-wrap fw-normal fs-6 p-0 m-0">{cell.holder_name}</p>
      ),
    },
    {
      name: <span className="fw-normal text-gray-650 fs-6">Bank name</span>,
      selector: (cell) => (
        <p className="text-wrap fw-semibold p-0 m-0 fs-6">
          {cell.bank.en_name}
        </p>
      ),
    },
    {
      name: <span className="fw-normal text-gray-650 fs-6">IBAN</span>,
      selector: (cell) => {
        return (
          <p className="fw-normal p-0 m-0 fs-6 d-flex gap-2 flex-wrap">
            {cell.iban}
          </p>
        );
      },
    },
    {
      id: "changeCol",
      name: <span className="fw-normal fs-6 text-gray-650">Edit</span>,
      selector: (cell) => (
        <button
          type="button"
          className="btn border-none fs-5 fw-normal p-0"
          onClick={() => handelChange(cell, cell.bank.id)}
          data-testid="update-bank"
        >
           <i className="ri-edit-line"></i>
        </button>
      ),
    },
    {
      id: "deleteCol",
      name: <span className="fw-normal text-gray-650 fs-6">Delete</span>,
      selector: (cell) => (
        <Button
          className="btn btn-soft-danger"
          onClick={() => handelDelete(cell.id)}
          data-testid="delete-bank"
        >
          <i className="ri-delete-bin-2-line"></i>
        </Button>
      ),
    },
  ];
  
  return (
    <div className="common-table-rdt w-100 w-lg-50 mt-4">
      <DeleteBankModal
        show={deleteBankModal}
        onCloseClick={() => setDeleteBankModal(false)}
        onDeleteClick={onDeleteClick}
      />
      <ChangeBankModal
        banks={banks}
        show={changeBankModal}
        onCloseClick={() => setChangeBankModal(false)}
        bankAccount={bankAccount}
        bankID={BankID}
      />
      <DataTable
        columns={bankscolumns}
        data={bankAccountsData.results}
        pagination
        responsive
        paginationRowsPerPageOptions={[5, 10, 25, 50]}
      />
    </div>
  );
};

export default BankAccounts;
