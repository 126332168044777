import { combineReducers } from "redux";

// Front
import Layout from "./layouts/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Otp from "./auth/otp/reducer";
import Charts from "./charts/reducer";

import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";

//Ecommerce
import Ecommerce from "./ecommerce/reducer";

//Project
import Projects from "./projects/reducer";

//Form advanced
import changeNumber from "./formAdvanced/reducer";
//Invoice
import Invoice from "./invoice/reducer";

import FeaturesFlags from "./featuresFlags/reducer";


const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Otp,
  Charts,
  Account,
  ForgetPassword,
  Profile,
  Projects,
  Ecommerce,
  changeNumber,
  Invoice,
  FeaturesFlags
});

export default rootReducer;
