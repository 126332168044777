import React from "react";

import "antd/dist/antd.css";
import {
  Card,
  CardBody,
  Col,
  Row,
  Collapse,
} from "reactstrap";
import { Link } from "react-router-dom";
import Checkout from "../../../Components/Common/Checkout";

import { config } from "../../../config";

const CollapseFour = ({title, collapseFour, productDetails, myBid, dealCategory, dealDuration, endDate, startDate, handleAddSpecialDeal }) => {

  const { currency } = config;
const callback_url =`special-deals/?product=${productDetails.id}&start_date=${startDate}&end_date=${endDate}&category=${dealCategory}&bid=${myBid}&title=${title.replace(/ /g, "-")}`
  return (
    <Collapse in isOpen={collapseFour}>
      <Card className=" h-100%">
        <Row className="d-flex m-3 justify-content-center">
        <h4 className="card-body mb-0">Billing</h4>

          <Col
            xl={8}
            className="BillingSpecialDeals table-responsive"
          >
            <Row className="d-flex m-1 mt-4 justify-content-center">
              <CardBody className=" h-100%">
              <div className="mb-3">
                  <span className="badge badge-soft-danger align-middle badge-span rounded-pill  mr-2">
                  <p className="badge-icon">{1}</p>
                  </span>  Special deals details:
                </div>
              
                <Row>
                  <Col
                    xl={12}
                  >
                    <Card className="m10">
                      <CardBody>
                        <div className="table-responsive table-card">
                          <table className="table table-hover table-centered align-middle m-0  ">
                            <thead
                              className={"data-sticky-header-offset-y bg-white"}
                            >
                              <tr>
                                <th> Product Details</th>
                                <th>Deal Category</th>
                                <th>From</th>
                                <th>To</th>
                                <th>Bid</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <div className="d-flex align-items-center">
                                    <div className="flex-shrink-0 me-3">
                                      <div className="avatar-sm bg-light rounded p-1">
                                        <img
                                          src={productDetails.image}
                                          className="img-fluid d-block"
                                          alt=""
                                        />
                                      </div>
                                    </div>
                                    <div className="flex-grow-1">
                                      <h5 className="fs-14 mb-1">
                                        <Link
                                          to="/product-details"
                                          className="text-dark"
                                        >
                                          
                                        </Link>
                                      </h5>

                                      <p className="text-muted mb-0">
                                       Special deal title :
                                        <span className="fw-medium">
                                          {title}
                                        </span>
                                      </p>
                                      <p className="text-muted mb-0">
                                        Product name :
                                        <span className="fw-medium">
                                        {productDetails.name}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </td>
                                <td>{dealCategory}</td>
                                <td>{startDate}</td>

                                <td>{endDate}</td>

                                <td className="text-light-red">{myBid} {currency}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>

                <Row className="">
                  <Col xl={12} className="mt-5">
                  <div className="mb-3">
                  <span className="badge badge-soft-danger align-middle badge-span rounded-pill  mr-2">
                  <p className="badge-icon">{2}</p>
                  </span>  Special deal budget:
                </div>
                    <table className="table table-hover text-lg table-centered align-middle table-nowrap w-75 mx-4">
                      <tbody>
                        <tr>
                          <td>Bid Price</td>
                          <td className="fw-bold text-right ">{parseFloat(myBid).toFixed(2)} {currency}</td>
                        </tr>
                        <tr >
                          <td>Total</td>
                          <td className="text-light-red fw-bold text-right " >
                          {parseFloat(myBid).toFixed(2)} {currency}
                         
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Col>

                </Row>
              </CardBody>
            </Row>
          </Col>
          <Col xl={4} className="mt-5">
            <Row className="d-flex justify-content-center">
              <Col className="p-0">
              <div className="mb-3">
                  <span className="badge badge-soft-danger align-middle badge-span rounded-pill  mr-2">
                  <p className="badge-icon">{3}</p>
                  </span> Select your credit card:
                </div>
              <Checkout 
              trigger={collapseFour} 
              amount={parseFloat(myBid).toFixed(2)} 
              callback_url={callback_url} 
              payment_type={"DB"}
              source={"special_deal"}
              />
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </Collapse >
  )
};
export default CollapseFour;
