import React, { useState } from "react";

import { Collapse, Form, Label, Input, FormFeedback } from "reactstrap";
import { useTranslation } from "react-i18next";

const CollapseThree = ({ currentPage, validation }) => {
  const { t } = useTranslation();
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);

  return (
    <Collapse in isOpen={currentPage === 2}>
      <div className="p-2 mt-4 mx-4">
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
          className="needs-validation"
          action="#"
        >
          <h3> {t("createYourPassword")}</h3>
          <div className="mb-3">
            <Label htmlFor="userpassword" className="form-label">
              {t("password")} <span className="text-danger">*</span>
            </Label>
            <div className="position-relative auth-pass-inputgroup mb-3">
              <Input
                name="password"
                className="form-control padding_input pe-5 auth-error-hide"
                placeholder={t("enterPassword")}
                type={passwordShown ? "text" : "password"}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.password || ""}
                invalid={
                  validation.touched.password && validation.errors.password
                    ? true
                    : false
                }
              />
              {validation.touched.password && validation.errors.password ? (
                <FormFeedback type="invalid">
                  <div>{t(validation.errors.password)}</div>
                </FormFeedback>
              ) : null}
              <button
                className="btn btn-link position-absolute start-0 top-0 text-decoration-none text-muted"
                type="button"
                disabled={true}
                id="password-addon"
              >
                <i className="ri-lock-password-line"></i>
              </button>
              <button
                onClick={() => {
                  setPasswordShown(!passwordShown);
                }}
                className="btn border-0 position-absolute end-0 top-0 text-decoration-none text-muted"
                type="button"
                id="password-addon"
              >
                {passwordShown ? (
                  <i className="ri-eye-off-fill align-middle"></i>
                ) : (
                  <i className="ri-eye-fill align-middle"></i>
                )}
              </button>
            </div>
          </div>

          <div className="mb-3">
            <Label htmlFor="confirmPassword" className="form-label">
              {t("confirmPassword")} <span className="text-danger">*</span>
            </Label>
            <div className="position-relative auth-pass-inputgroup mb-3">
              <Input
                name="confirm_password"
                type={confirmPasswordShown ? "text" : "password"}
                className="form-control padding_input pe-5 auth-error-hide"
                placeholder={t("confirmPassword")}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.confirm_password || ""}
                invalid={
                  validation.touched.confirm_password &&
                    validation.errors.confirm_password
                    ? true
                    : false
                }
              />
              {validation.touched.confirm_password &&
                validation.errors.confirm_password ? (
                <FormFeedback type="invalid">
                  <div>{t(validation.errors.confirm_password)}</div>
                </FormFeedback>
              ) : null}
              <button
                className="btn btn-link position-absolute start-0 top-0 text-decoration-none text-muted"
                type="button"
                disabled={true}
                id="password-addon"
              >
                <i className="ri-lock-password-line"></i>
              </button>
              <button
                onClick={() => {
                  setConfirmPasswordShown(!confirmPasswordShown);
                }}
                className="btn border-0 position-absolute end-0 top-0 text-decoration-none text-muted"
                type="button"
                id="password-addon"
              >
                {confirmPasswordShown ? (
                  <i className="ri-eye-off-fill align-middle"></i>
                ) : (
                  <i className="ri-eye-fill align-middle"></i>
                )}
              </button>
            </div>
          </div>

          <div className="mb-2 mt-4 d-flex justify-content-center  ">
            <button
              style={{
                backgroundColor: "#EE856F",
                color: "white",
                height: "45px",
              }}
              className="border-0 w-100 rounded"
              type="submit"
            >
              {t("saveContinue")}
            </button>
          </div>
        </Form>
      </div>
    </Collapse>
  );
};
export default CollapseThree;
