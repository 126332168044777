import React, { useState, useEffect } from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import { BarChart } from "../../../Components/Common/ChartsJs";
import DateFilter from "../DateFilter";
import { useDispatch, useSelector } from "react-redux";
import { timeFilter, MonthsFinder } from "../../../helpers/charts_helper";
import { getPPCDataChart } from "../../../store/charts/action";
import { useTranslation } from "react-i18next";
import NoDataFound from "../NoDataFound";
import moment from "moment";
import { config } from "../../../config";

const { currency } = config;
const PayPerClick3DChart = ({ disabledDate }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const now = new Date();
    const firstDay = moment(now).startOf('year').format("YYYY-MM-DD");
    const lastDay = moment(now).endOf('year').format('YYYY-MM-DD');
    const [action, setAction] = useState("month");
    const [startDate, setStartDate] = useState(firstDay);
    const [endDate, setEndDate] = useState(lastDay);
    const [xLabel, setxLabel] = useState(MonthsFinder(firstDay, lastDay));
    const [callApi, setCallApi] = useState(false);
    const [ppcSpending3D, setPpcSpending3D] = useState([]);
    const { allPPCData } = useSelector((state) => ({
        allPPCData: state.Charts.allPPCData,
    }));
    useEffect(() => {
        const params = {
            start_date: startDate,
            end_date: endDate,
            action: action,
        };
        dispatch(getPPCDataChart(params));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, callApi]);

    useEffect(() => {
        if (allPPCData) {
            let customers = [];
      
            xLabel.forEach((date) => {
              const item = allPPCData?.results?.find((item) => {
                return item.date === date;
              });
      
              if (item) {
                customers.push(item.total);
              } else {
                customers.push(0);
              }
            });
            setPpcSpending3D(customers);
          }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allPPCData, xLabel]);


    return (
        <>
            <Card className="">
                <CardHeader className="border-0 align-items-center d-flex pb-2">
                    {/* eslint-disable-next-line */}
                    <h4 className="card-title mb-0 flex-grow-1">{t("payPerClick") + " " + "/" + " " + t(timeFilter[action])}</h4>
                    <DateFilter
                        action={action}
                        setAction={setAction}
                        setxLabel={setxLabel}
                        setStartDate={setStartDate}
                        setEndDate={setEndDate}
                        setCallApi={setCallApi}
                        startDate={startDate}
                        endDate={endDate}
                        callApi={callApi}
                        disabledDate={disabledDate}
                    />
                </CardHeader>
                <CardBody className="position-relative">
                    {allPPCData?.results?.length === 0 && (
                        <NoDataFound />
                    )}
                    <BarChart
                        xLabel={timeFilter[action]}
                        yLabel={t("ppcSpending")}
                        Month={xLabel}
                        Sales={ppcSpending3D}
                        dataFlag={allPPCData?.results?.length !== 0}
                        currency={currency}
                        label={t("ppcSpending")}
                        dataColors='["--vz-primary-rgb, 0.8", "--vz-primary-rgb, 0.9"]'
                        colorFrom={"#DD7868"}
                        colorTo={"#EDA46F"}
                    />
                </CardBody>
            </Card>
        </>
    );
};
export default PayPerClick3DChart;
