import React from "react";
import * as moment from "moment";
import theme from "../theme"
const {defaultStatusStyle,openStatusStyle,successStatusStyle} = theme

  const handleLetter=(value) => {
    if (value){
      let captilzied = value.toLowerCase()
        .split("_")
        .filter(x => x.length > 0)
        .map((x) => (x.charAt(0).toUpperCase() + x.slice(1)))
        .join(" ");
        captilzied = captilzied.toLowerCase()
        captilzied = captilzied.charAt(0).toUpperCase() + captilzied.slice(1);
        return captilzied
    }
      }

const OrderDetailsStatus = ({value}) => {
  let statusBgColor = "white";
  let statusColor = "black";
  if (value === "new") {
    statusBgColor = "rgba(3, 161, 212, 0.1)";
    statusColor = "#03A1D4";
  } else if (value === "declined") {
    statusBgColor = "rgba(246, 163, 0, 0.1)";
    statusColor = "##F6A300";
  } else if (value === "expired") {
    statusBgColor = "#E8E8E8";
    statusColor = "#B3B3B3";
  }
  else if (value === "approved") {
    statusBgColor = "#E5FFE2";
    statusColor = "#22A717  ";
  }
  else if (value === "authorized" ) {
    statusBgColor = "rgba(54, 120, 104, 0.1)";
    statusColor = "#367868";
  }
  else if (value === "canceled" || value === "partially_canceled") {
    statusBgColor = "#FFDCDC";
    statusColor = "#BF1D1D";
  }
  else if (value === "updated" || value === "partially_updated") {
    statusBgColor = "rgba(141, 0, 164, 0.1)";
    statusColor = "#8D00A4";
  }
  else if (value === "captured" || value === "partially_captured") {
    statusBgColor = "rgba(238, 133, 111, 0.1)";
    statusColor = "#EE856F";
  }
  else if (value === "refunded"|| value === "partially_refunded") {
    statusBgColor = "rgba(78, 90, 168, 0.1)";
    statusColor = "#4E5AA8";
  }

  return (
    <React.Fragment>
      <span>
        <span
          className="badge  fs-12 fw-medium "
          style={{ backgroundColor: statusBgColor, color: statusColor }}
        >
          {handleLetter(value)}
        </span>
      </span>
    </React.Fragment>
  );
};
const SettlementsDetailsStatus = ({ value }) => {
  const appliedStyle = (value === "open") ? openStatusStyle : (value === "completed") ? successStatusStyle :  defaultStatusStyle;

return (
  <React.Fragment>
    <span>
      <span style={appliedStyle}>
      {handleLetter(value)}
      </span>
    </span>
  </React.Fragment>
);
};
const handleDate = (date) => {
  const date1 = moment(new Date(date)).format('MM/DD/YYYY h:mm a');
  return date1
};


export {OrderDetailsStatus,handleDate,handleLetter,SettlementsDetailsStatus };
