import React, { useMemo } from "react";
import DataTable from "react-data-table-component";
import Loader from "../../Ecommerce/Loader";
import { config } from "../../../config";
import moment from "moment";
import { useTranslation } from "react-i18next";
const { currency } = config;
const TopTenItems = ({ topTenItems }) => {
  const { t } = useTranslation();
  const columns = useMemo(
    () => [
      {
        name: <span className="fw-normal text-gray-650">{"Rank"}</span>,
        selector: (row, index) => (
          <p className="m-0 p-0 fw-bold">{`#${index + 1}`}</p>
        ),
        sortable: false,
      },
      {
        name: <span className="fw-normal text-gray-650">{t("item")}</span>,
        selector: (row) => (
          <>
            <p className="m-0 p-0 fw-bold">{row.name}</p>
            <p className="m-0 p-0 text-gray-650">#{row.reference_id}</p>
          </>
        ),
      },

      // {
      //   name: <span className="fw-normal text-gray-650">{t("price")}</span>,
      //   selector: (row) => (
      //     <p className="m-0 p-0 fw-bold">{row.price + " " + currency}</p>
      //   ),
      //   sortable: false,
      // },
      {
        name: <span className="fw-normal text-gray-650">{t("unitSold")}</span>,
        selector: (row) => (
          <p className="m-0 p-0 fw-normal">{row.quantity_sold}</p>
        ),
        sortable: false,
      },
      {
        name: <span className="fw-normal text-gray-650">{t("revenue")}</span>,
        selector: (row) => (
          <p className="m-0 p-0 fw-normal">{row.revenue + " " + currency}</p>
        ),
        sortable: false,
      },
      {
        name: <span className="fw-normal text-gray-650">{t("reportDate")}</span>,
        selector: (row) => (
          <p className="m-0 p-0 fw-normal">
            {moment(new Date()).format("DD/MM/YYYY")}
          </p>
        ),
        sortable: false,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const columnNames = columns.map((col) => col.name.props.children);
  return (
    <div className="py-4 mt-2 ">
      <div className="bg-white rounded d-flex justify-content-between align-items-center py-3 p-2">
        <h4 className="card-title mb-0">{t("topTenSellingItems")}</h4>
      </div>

      <div className="product-visitors-table-rdt">
        {topTenItems && topTenItems.length > 0 ? (
          <DataTable columns={columns} data={topTenItems} responsive />
        ) : (
          <Loader columnNames={columnNames} />
        )}
      </div>
    </div>
  );
};
export default TopTenItems;
