import React, { useEffect, useState, useMemo } from "react";

import {
  Container,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  TabContent,
  TabPane,
  Row,
} from "reactstrap";

import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import TableContainer from "../../../Components/Common/TableContainer";
import { ToastContainer, toast } from "react-toastify";

import {
  deleteProducts,
  resetEcomFlag,
  getProductsData,
  getOneProductData,
} from "../../../store/ecommerce/action";
import Loader from "../Loader";
import { Price } from "./EcommerceProductCol";
import DeleteModal from "../../../Components/Common/DeleteModal";
import BreadCrumb from "../../../Components/Common/BreadCrumb";

import { config } from "../../../config";
const { TOAST_AUTOCLOSE_TIMER } = config;
const { TOAST_AUTOOPEN_TIMER } = config;
const { LOADER_RESET_TIMER } = config;

const EcommerceProducts = (props) => {
  const dispatch = useDispatch();

  const {
    allProductsData,
    isProductDeleteSuccessFlag,
    isProductDeleteFailFlag,
    isProductAddSuccessFlag,
    isProductAddFailFlag,
    isProductUpdateSuccessFlag,
    isProductUpdateFailFlag,
  } = useSelector((state) => ({
    products: state.Ecommerce.products,
    allProductsData: state.Ecommerce.allProductsData,
    oneProduct: state.Ecommerce.oneProduct,
    isProductDeleteSuccessFlag: state.Ecommerce.isProductDeleteSuccessFlag,
    isProductDeleteFailFlag: state.Ecommerce.isProductDeleteFailFlag,
    isProductAddSuccessFlag: state.Ecommerce.isProductAddSuccessFlag,
    isProductAddFailFlag: state.Ecommerce.isProductAddFailFlag,
    isProductUpdateSuccessFlag: state.Ecommerce.isProductUpdateSuccessFlag,
    isProductUpdateFailFlag: state.Ecommerce.isProductUpdateFailFlag,
  }));
  // your API's response data will be in demoData variable.
  const showToast = (message, options) => {
    toast.dismiss(); // dismiss any previous toast message
    setTimeout(() => {
      toast(message, options);
    }, TOAST_AUTOOPEN_TIMER); // wait 1 second before showing the next message
  };

  useEffect(() => {
    if (isProductDeleteSuccessFlag) {


      showToast("Product Deleted Successfully", {
        position: "top-right",
        hideProgressBar: true,
        className: "bg-success text-white",
        progress: undefined,
        toastId: "",
      })
    }
    if (isProductDeleteFailFlag) {

      showToast("There is already a special deal or Pay Per Click campaign for this product", {
        position: "top-right",
        hideProgressBar: true,
        className: "bg-danger text-white",
        progress: undefined,
        toastId: "",
      })
    }
    if (isProductAddSuccessFlag) {

      showToast("Product Added Successfully", {
        position: "top-right",
        hideProgressBar: true,
        className: "bg-success text-white",
        progress: undefined,
        toastId: "",
      })
    }
    if (isProductAddFailFlag) {
      showToast("Product Adding Failed", {
        position: "top-right",
        hideProgressBar: true,
        className: "bg-danger text-white",
        progress: undefined,
        toastId: "",
      })
    }


    if (isProductUpdateSuccessFlag) {
      showToast("Product Updated Successfully", {
        position: "top-right",
        hideProgressBar: true,
        className: "bg-success text-white",
        progress: undefined,
        toastId: "",
      });


    }

    if (isProductUpdateFailFlag) {
      showToast("Product Updating Failed", {
        position: "top-right",
        hideProgressBar: true,
        className: "bg-danger text-white",
        progress: undefined,
        toastId: "",
      });

    }

  }, [
    isProductDeleteSuccessFlag,
    isProductDeleteFailFlag,
    isProductAddSuccessFlag,
    isProductAddFailFlag,
    isProductUpdateSuccessFlag,
    isProductUpdateFailFlag,
  ]);

  useEffect(() => {
    setTimeout(() => {
      dispatch(resetEcomFlag());
    }, LOADER_RESET_TIMER);
  }, [
    dispatch,
    isProductDeleteSuccessFlag,
    isProductDeleteFailFlag,
    isProductAddSuccessFlag,
  ]);
  const dataProducts = allProductsData.results;
  const productsCount = allProductsData.count;

  const [productsId, setProductsId] = useState([]);
  const fetchData = (params = {}) => {
    const filterData = { ...params };

    dispatch(getProductsData(filterData));
  };

  useEffect(() => {
    fetchData({ page: 1 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isProductDeleteSuccessFlag,
    isProductDeleteFailFlag,
    isProductAddSuccessFlag,
  ]);

  const handleChangeOneProduct = (productId) => {
    dispatch(getOneProductData(productId));
  };

  //delete order
  const [deleteModal, setDeleteModal] = useState(false);

  const handleDeleteProduct = () => {
    toast.dismiss();
    dispatch(deleteProducts(productsId));
    setDeleteModal(false);
  };

  const handleShowDeleteModal = (productId) => {
    setDeleteModal(true);
    setProductsId(productId);
  };

  const columns = useMemo(
    () => [
      {
        Header: "Product Image",
        accessor: "product_image",
        Cell: (dataProducts) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0 me-3">
                <div className="avatar-sm bg-light rounded p-1">
                  <img
                    src={`${dataProducts.row.original.product_image}?${dataProducts.row.original.updated_at}`}
                    alt=""
                    className="img-fluid d-block"
                  />
                </div>
              </div>
            </div>
          </>
        ),
      },
      {
        Header: "Product Name",
        accessor: "product_name",
        filterable: false,
      },
      {
        Header: "Description",
        accessor: "short_description",
        filterable: false,
      },

      {
        Header: "Product Price",
        accessor: "sale_price",
        filterable: false,
        Cell: (cellProps) => {
          return <Price {...cellProps} />;
        },
      },

      {
        Header: "Action",
        Cell: (cellProps) => {
          return (
            <UncontrolledDropdown>
              <DropdownToggle
                href="#"
                className="btn-soft-secondary btn-sm"
                tag="button"
              >
                <i className="ri-more-fill" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                <Link
                  to={`product-details/${cellProps.row.original.id}`}
                  className="text-decoration dropdown-item"
                  onClick={() =>
                    handleChangeOneProduct(cellProps.row.original.id)
                  }
                >
                  <i className="ri-eye-fill align-bottom me-2 text-muted"></i>
                  View
                </Link>
                <Link
                  to={`products-update/${cellProps.row.original.id}`}
                  className="text-decoration dropdown-item"
                >
                  <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>
                  Edit
                </Link>
                <DropdownItem
                  onClick={() =>
                    handleShowDeleteModal(cellProps.row.original.id)
                  }
                >
                  <i className="ri-delete-bin-fill align-bottom me-2 text-muted"></i>
                  Delete
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  document.title = "Products | Salis ";

  return (
    <div className="page-content">
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteProduct}
        onCloseClick={() => setDeleteModal(false)}
      />
      <Container fluid>
        <BreadCrumb title="Products" pageTitle="Marketing" />
        <ToastContainer
          autoClose={TOAST_AUTOCLOSE_TIMER}
          style={{ width: "auto" }}
        />
        <Row>
          <div className="row">
            <div className="col">
              <div>
                <div className="card">
                  <div className="card-header  border-0">
                    <div className="row g-4 table-header-margin">
                      <div className="col-sm-auto">
                        <div>
                          <Link
                            to="/products-add"
                            className="btn bg-light-red text-white"
                          >
                            <i className="ri-add-line align-bottom me-1"></i>{" "}
                            Add Product
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card-header">
                    <div className="row align-items-center">
                      <div className="col-auto">
                        <div id="selection-element">
                          <div className="my-n1 d-flex align-items-center text-muted">
                            Select{" "}
                            <div
                              id="select-content"
                              className="text-body fw-semibold px-1"
                            ></div>{" "}
                            Result{" "}
                            <button
                              type="button"
                              className="btn btn-link link-danger p-0 ms-3"
                            >
                              Remove
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className=" card-body table-responsive border h-100">
                    <TabContent className="text-muted">
                      <TabPane>
                        <div
                          id="table-product-list-all"
                          className="table-card gridjs-border-none pb-2"
                        >
                          {dataProducts && dataProducts.length > 0 ? (
                            <TableContainer
                              columns={columns}
                              data={dataProducts || []}
                              customPageSize={10}
                              numOfPages={productsCount}
                              fetchData={fetchData}
                              divClass="overflow-visible table table-responsive mb-1"
                              tableClass="mb-0 align-middle table-borderless pb-5"
                              theadClass="table-light text-muted"

                            />
                          ) : (
                            <Loader />
                          )}
                        </div>
                      </TabPane>
                    </TabContent>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default EcommerceProducts;
