import React, { useState, useEffect } from "react";
import { Card, CardBody, Col, Container, Row, CardHeader } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";

import { Link, useParams } from "react-router-dom";
import {
  OrderDetailsStatus,
  handleDate,
} from "../../../helpers/orderColHelper";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import Select from "react-select";
import ConfirmationModal from "../../../Components/Common/ConfirmationModal";
import OrderRefundModal from "./OrderRefundModal";
import CaptureModal from "./CaptureModal";
import Loader from "../Loader";

import { config } from "../../../config";

import {
  getOneOrdersData,
  getOrderItem,
  getOrderCaptures,
  cancelOrder,
  addCapturePayment,
  resetEcomFlag,
} from "../../../store/ecommerce/action";
const { currency } = config;


const orderActionOptions = [
  { value: "", label: "Viewed only" },
  { value: "capture", label: "Captured" },
  { value: "cancel", label: "Cancelled" },
];
const { TOAST_AUTOCLOSE_TIMER } = config;

const EcommerceOrderDetail = () => {
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const [itemQuantity, setItemQuantity] = useState(false);
  const [items, setItems] = useState([]);
  const [itemsToAction, setItemsToAction] = useState([]);
  const [orderAction, setOrderAction] = useState("");
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [captureModal, setCaptureModal] = useState(false);
  const [refundModal, setRefundModal] = useState(false);
  const [itemTotalAmount, setItemsTotalAmount] = useState(0);
  const [shippingCompany, setShippingCompany] = useState("");
  const [orderIdSize, setOrderIdSize] = useState(true);
  const [returnPeriodRefundFlag, setReturnPeriodRefundFlag] = useState(false);

  const dispatch = useDispatch();

  const {
    oneOrder,
    orderItems,
    orderCaptures,
    cancelOrderFlag,
    captureFlag,
    refundFlag,
    userData,
  } = useSelector((state) => ({
    oneOrder: state.Ecommerce.oneOrder,
    orderItems: state.Ecommerce.orderItems,
    orderCaptures: state.Ecommerce.orderCaptures,
    cancelOrderFlag: state.Ecommerce.cancelOrderFlag,
    captureFlag: state.Ecommerce.captureFlag,
    refundFlag: state.Ecommerce.refundFlag,
    userData: state.Profile.userData,
  }));


  let { id } = useParams();
  useEffect(() => {
    const filterData = {};

    filterData["action"] = orderAction;
    dispatch(getOneOrdersData(id));
    dispatch(getOrderCaptures(id));

    const data = {
      id: id,
      action: { action: orderAction },
    };
    dispatch(getOrderItem(data));
  }, [dispatch, id, orderAction, captureFlag, cancelOrderFlag, refundFlag]);

  useEffect(() => {
    if (cancelOrderFlag) {
      toast(cancelOrderFlag, {
        position: "top-right",
        hideProgressBar: true,
        className: cancelOrderFlag.includes("failed")
          ? "bg-danger text-white"
          : "bg-success text-white",
        progress: undefined,
        toastId: "",
      });
    }

    if (captureFlag) {
      toast(captureFlag, {
        position: "top-right",
        hideProgressBar: true,
        className: captureFlag.includes("failed")
          ? "bg-danger text-white"
          : "bg-success text-white",
        progress: undefined,
        toastId: "",
      });
    }
    dispatch(resetEcomFlag());
  }, [dispatch, cancelOrderFlag, captureFlag]);
  
  useEffect(() => {
    setItems(oneOrder.items);

    const currentDate = new Date(oneOrder.created_at)
    const newDate = new Date(currentDate.setDate(currentDate.getDate() + userData.return_period));

    if (newDate > new Date()) {
      setReturnPeriodRefundFlag(true)

    }

  }, [oneOrder, itemsToAction, userData]);

  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setItemsToAction([...orderItems]);
    setIsCheck(orderItems?.map((li) => li.id));
    if (isCheckAll) {
      setIsCheck([]);
      setItemsToAction([]);
      setItemsTotalAmount(0);
    }
  };

  const handleOrderAction = () => {
    setConfirmationModal(false);
    setShippingCompany("")
    setItemsToAction([])
    setIsCheckAll(false)
    setIsCheck([])
    setItemQuantity(false)
    setItems([])
    setItemsTotalAmount(0)
    setOrderIdSize(true)

    if (orderAction === "cancel") {
      const orderCancelData = {
        order_id: oneOrder.order_id,
        total_amount: itemTotalAmount,
        canceled_items: itemsToAction,
      };
      dispatch(cancelOrder(orderCancelData));
    } else {
      setCaptureModal(false);

      const orderCaptureData = {
        order_id: oneOrder.order_id,
        total_amount: itemTotalAmount,
        captured_items: itemsToAction,
        shipping_info: {
          shipped_at: new Date(),
          shipping_company: shippingCompany,
        },
      };
      dispatch(addCapturePayment(orderCaptureData));
    }
  };

  const handleOrderRefund = () => {
    setRefundModal(true);
  };
  const handleClick = (e) => {
    setIsCheckAll(false);
    const { id, checked } = e.target;

    setIsCheck([...isCheck, Number(id)]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== Number(id)));
      setItemsToAction(itemsToAction.filter((item) => item.id !== Number(id)));
    } else {
      setItemsToAction([
        ...itemsToAction,
        ...items.filter((item) => item.id === Number(id)),
      ]);
    }
  };

  const handleQuantityMinus = (value) => {
    const objIndex = orderItems.findIndex((obj) => obj.id === Number(value));

    orderItems[objIndex].quantity -= 1;
    setItemQuantity(!itemQuantity);
  };

  const handleQuantityPlus = (value) => {
    const objIndex = orderItems.findIndex((obj) => obj.id === Number(value));

    orderItems[objIndex].quantity += 1;
    setItems(items);
    setItemQuantity(!itemQuantity);
  };
  useEffect(() => {
    const total = itemsToAction.reduce((prev, current) => {
      return prev + current.total_amount * current.quantity;
    }, 0);
    setItemsTotalAmount(total);
  }, [itemsToAction, itemTotalAmount]);

  document.title = "Order Details";
  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Order Details" pageTitle="Ecommerce" />
        {(cancelOrderFlag || captureFlag) && (
          <ToastContainer
            autoClose={TOAST_AUTOCLOSE_TIMER}
            style={{ width: "auto" }}
          />
        )}
        <ConfirmationModal
          show={confirmationModal}
          onCloseClick={() => setConfirmationModal(false)}
          message={`You are going to ${orderAction} the selected items!`}
          action={handleOrderAction}
        />

        <OrderRefundModal
          show={refundModal}
          onCloseClick={() => setRefundModal(false)}
          message={`You are going to ${orderAction} the selected items!`}
          action={handleOrderAction}
          orderCaptures={orderCaptures}
          oneOrder={oneOrder}
        />
        <CaptureModal
          show={captureModal}
          onCloseClick={() => setCaptureModal(false)}
          message={`You are going to ${orderAction} the selected items!`}
          action={handleOrderAction}
          setShippingCompany={setShippingCompany}
          shippingCompany={shippingCompany}
        />
        <Row>
          <Col xl={9}>
            <Card>
              <CardHeader>
                <Col className="col-sm">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex gap-4  ">
                      <h5 className="card-title flex-grow-1 mb-0 ">Order</h5>
                    </div>
                    <div className="d-flex justify-content-sm-end text-light-red">
                      <button className="btn ">
                        <Link
                          to={`/invoice/${oneOrder.order_id}`}
                          className="text-white px-2 text-light-red"
                        >
                          View Invoice
                        </Link>
                      </button>
                    </div>
                  </div>
                </Col>
              </CardHeader>
              <CardBody>
                <div className="table-responsive table-card mb-1">
                  <table
                    className="table align-middle table-nowrap"
                    id="customerTable"
                  >
                    <thead className="table-light">
                      <tr>
                        <th scope="col">Order number</th>
                        <th scope="col">Canceled amount</th>
                        <th scope="col">Captured amount</th>
                        <th scope="col">Refunded amount</th>
                        <th scope="col">Payment method</th>
                        <th scope="col">Status</th>
                      </tr>
                    </thead>
                    <tbody className="list form-check-all">
                      <tr style={{ height: "48px" }}>
                        <td>
                          <button
                            type="button"
                            onClick={() => setOrderIdSize(!orderIdSize)}
                            className={
                              orderIdSize ? "order-id" : "order-id-full"
                            }
                          >
                            {oneOrder.order_id}
                          </button>
                        </td>
                        <td>
                          <p
                            className={
                              oneOrder.cancelled_amount
                                ? "amount-font-weight align-center m-0"
                                : " align-center m-0"
                            }
                          >
                            {oneOrder.cancelled_amount || "N/A"} {currency}
                          </p>
                        </td>
                        <td>
                          <p
                            className={
                              oneOrder.captured_amount
                                ? "amount-font-weight align-center m-0"
                                : " align-center m-0"
                            }
                          >
                            {oneOrder.captured_amount || "N/A"} {currency}
                          </p>
                        </td>
                        <td>
                          <p
                            className={
                              oneOrder.refunded_amount
                                ? "amount-font-weight align-center m-0"
                                : " align-center m-0"
                            }
                          >
                            {oneOrder.refunded_amount || "N/A"} {currency}
                          </p>
                        </td>
                        <td>
                          <OrderDetailsStatus value={oneOrder.payment_type} />
                        </td>
                        <td>
                          <OrderDetailsStatus value={oneOrder.status} />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="d-flex justify-content-start">
                  <h5 className="card-title flex-grow-1 mb-0 ">
                    {handleDate(oneOrder.created_at)}
                  </h5>
                </div>
              </CardBody>
            </Card>
            <div className="d-flex justify-content-sm-end text-light-red mb-3">
              {oneOrder.status === "captured" && returnPeriodRefundFlag && (
                <td>
                  <button
                    type="button"
                    onClick={handleOrderRefund}
                    className="btn bg-light-red text-white"
                  >
                    Refund
                  </button>
                </td>
              )}
            </div>
            <>
              <Card className="mb-2">
                <CardHeader>
                  <Col className="col-sm">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="d-flex gap-4  ">
                        <h5 className="card-title flex-grow-1 mb-0 ">
                          Items details
                        </h5>
                      </div>
                      <div className="d-flex justify-content-sm-end">
                        <div className="col-sm">
                          <div className="d-flex justify-content-sm-end">
                            <div className="d-flex">
                              <h6 className="pt-2 px-2">
                                Show Items that can be:
                              </h6>

                              <Select
                                onChange={(e) => {
                                  setOrderAction(e.value);
                                  setIsCheck([]);
                                  setIsCheckAll(false);
                                  setItemsToAction([]);
                                }}
                                defaultValue={{
                                  value: "",
                                  label: "Viewed only",
                                }}
                                options={orderActionOptions}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </CardHeader>
                {orderItems.length !== 0 ? (
                  <CardBody>
                    <div className="table-responsive table-card mb-1">
                      <table
                        className="table align-middle table-nowrap table-borderless"
                        id="customerTable"
                      >
                        <thead className="table-light">
                          <tr>
                            {orderAction !== "" && (
                              <th scope="col">
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="selectAll"
                                    id="selectAll"
                                    value="option"
                                    onChange={handleSelectAll}
                                    checked={isCheckAll}
                                  />
                                </div>
                              </th>
                            )}
                            <th scope="col">Items</th>
                            <th scope="col" style={{ width: "250px" }}>
                              Quantity
                            </th>
                            <th scope="col">Price</th>
                            <th scope="col">Discount</th>
                            <th scope="col">Status</th>
                          </tr>
                        </thead>
                        <tbody className="list form-check-all">
                          {orderItems?.map((item, index) => (
                            <tr
                              className="border-top"
                              key={item.id}
                              style={{ height: "60px" }}
                            >
                              {orderAction !== "" && (
                                <th scope="col">
                                  <div className="form-check">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      id={item.id}
                                      name={item.id}
                                      value={item.id}
                                      onChange={handleClick}
                                      checked={isCheck.includes(item.id)}
                                    />
                                  </div>
                                </th>
                              )}
                              <td> {item.name}</td>
                              <td>
                                {orderAction === "cancel" && (
                                  <div className="input-step border-0">
                                    <button
                                      type="button"
                                      className={
                                        item?.quantity <= 1
                                          ? "bg-white opacity-50"
                                          : "bg-white"
                                      }
                                      onClick={(e) => {
                                        handleQuantityMinus(item.id);
                                        handleClick(e);
                                      }}
                                      disabled={item?.quantity <= 1}
                                    >
                                      <i className="ri-indeterminate-circle-line text-light-red "></i>
                                    </button>
                                    <span className="quantity-span-width">
                                      {item?.quantity}
                                    </span>
                                    <button
                                      className={
                                        item.quantity >= items[index]?.quantity
                                          ? "bg-white opacity-50"
                                          : "bg-white"
                                      }
                                      onClick={(e) => {
                                        handleQuantityPlus(item.id);
                                        handleClick(e);
                                      }}
                                      disabled={
                                        item.quantity >= items[index]?.quantity
                                      }
                                    >
                                      <i className="ri-add-circle-line text-light-red"></i>
                                    </button>
                                  </div>
                                )}
                                {orderAction !== "cancel" && (
                                  <div className="input-step border-0">
                                    <span className="quantity-span-width">
                                      {orderItems[index]?.quantity}
                                    </span>
                                  </div>
                                )}
                              </td>
                              <td>
                                <p className="amount-font-weight align-center m-0">
                                  {item.total_amount} {currency}
                                </p>
                              </td>
                              <td>
                                <p className="amount-font-weight align-center m-0">
                                  {item.discount_amount || 0} {currency}
                                </p>
                              </td>
                              <td>
                                <OrderDetailsStatus value={item.status} />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </CardBody>
                ) : (
                  <Loader />
                )}
              </Card>
              <div className="d-flex justify-content-sm-end text-light-red mb-3">
                {oneOrder.status !== "Expired" && (
                  <>
                    {orderAction === "cancel" && (
                      <button
                        className="btn bg-light-red text-white"
                        disabled={itemsToAction.length === 0}
                        onClick={() => setConfirmationModal(true)}
                      >
                        Cancel
                      </button>
                    )}
                    {orderAction === "capture" && (
                      <button
                        className="btn bg-light-red text-white"
                        disabled={itemsToAction.length === 0}
                        onClick={() => setCaptureModal(true)}
                      >
                        Capture
                      </button>
                    )}
                  </>
                )}
              </div>
            </>
            <Card>
              <CardHeader>
                <div className="d-flex align-items-center">
                  <h5 className="card-title flex-grow-1 mb-0">
                    Billing amount
                  </h5>
                </div>
              </CardHeader>
              <CardBody>
                <div className="table-responsive table-card">
                  <table className="table table-nowrap align-middle table-borderless mb-0">
                    <tbody>
                      <tr className="border-top border-top-dashed">
                        <td colSpan="4" className="fw-medium p-0">
                          <table className="table table-borderless mb-0">
                            <tbody>
                              <tr>
                                <td>Sub Total</td>
                                <td className="text-end">
                                  <p className="amount-font-weight align-center m-0">
                                    {oneOrder.total_amount} {currency}
                                  </p>
                                </td>
                              </tr>
                              <tr>
                                <td>Shipping amount</td>
                                <td className="text-end">
                                  <p className="amount-font-weight align-center m-0">
                                    {oneOrder.shipping_amount || 0} {currency}
                                  </p>
                                </td>
                              </tr>
                              <tr className="border-top border-top-dashed">
                                <th scope="row">Total </th>
                                <th className="text-end">
                                  <p className="amount-font-weight align-center m-0">
                                    {Number(oneOrder.total_amount) +
                                      Number(oneOrder.shipping_amount)}{" "}
                                    {currency}
                                  </p>
                                </th>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xl={3}>
            <Card>
              <CardHeader>
                <div className="d-flex">
                  <h5 className="card-title flex-grow-1 mb-0">
                    Customer Details
                  </h5>
                </div>
              </CardHeader>
              <CardBody>
                <ul className="list-unstyled mb-0 vstack gap-3">
                  <li>
                    <i className="ri-user-3-line me-2 align-middle text-light-red text-muted fs-16"></i>
                    {oneOrder.shipping_info?.first_name || "N/A"}{" "}
                    {oneOrder.shipping_info?.last_name}
                  </li>
                  <li>
                    <i className="ri-mail-line me-2 align-middle text-light-red text-muted fs-16"></i>
                    {oneOrder.consumer?.email || "N/A"}
                  </li>
                  <li>
                    <i className="ri-smartphone-line me-2 align-middle text-light-red text-muted fs-16"></i>
                    {oneOrder.consumer?.phone_number || "N/A"}
                  </li>
                </ul>
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <h5 className="card-title mb-0">Shipping info</h5>
              </CardHeader>
              <CardBody>
                <ul className="list-unstyled mb-0 vstack gap-3">
                  <li>
                    <i className="ri-user-3-line me-2 align-middle text-light-red text-muted fs-16"></i>
                    {oneOrder.shipping_info?.first_name || "N/A"}{" "}
                    {oneOrder.shipping_info?.last_name}
                  </li>

                  <li>
                    <i className="ri-smartphone-line me-2 align-middle text-light-red text-muted fs-16"></i>
                    {oneOrder.shipping_info?.phone_number || "N/A"}
                  </li>
                  <li>
                    <i className="ri-global-line me-2 align-middle text-light-red text-muted fs-16"></i>
                    {oneOrder.shipping_info?.region || "N/A"}
                  </li>
                  <li>
                    <i className="ri-map-pin-line me-2 align-middle text-light-red text-muted fs-16"></i>
                    {oneOrder.shipping_info?.city || "N/A"}
                  </li>
                  <li>
                    <i className="ri-building-2-line me-2 align-middle text-light-red text-muted fs-16"></i>
                    {oneOrder.shipping_info?.line1 || "N/A"}
                    {oneOrder.shipping_info?.line2}
                  </li>
                </ul>
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <h5 className="card-title mb-0">Billing info</h5>
              </CardHeader>
              <CardBody>
                <ul className="list-unstyled mb-0 vstack gap-3">
                  <li>
                    <i className="ri-global-line me-2 align-middle text-light-red text-muted fs-16"></i>
                    {oneOrder.billing_info?.region || "N/A"}
                  </li>
                  <li>
                    <i className="ri-map-pin-line me-2 align-middle text-light-red text-muted fs-16"></i>
                    {oneOrder.billing_info?.city || "N/A"}
                  </li>
                </ul>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default EcommerceOrderDetail;
