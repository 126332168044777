import * as url from "./url_helper";

import { APIClient } from "./api_helper";

import { config } from "../config";
const api = new APIClient();

// get Cards
export const getCards = () => api.get(url.GET_CARDS);
export const preAddCard = () =>
  api.create(url.PRE_ADD_CARD, { callback_url: config.PUBLIC_URL + "cards/" });
export const AddCard = (data) => api.create(url.ADD_CARD, data);
export const preChangeCard = (data) => api.create(url.PRE_CHANGE_CARD, data);
export const changeCard = (data) =>
  api.update(url.CHANGE_CARD + data.token + "/");
export const deleteCard = (data) =>
  api.delete(url.DELETE_CARD + data.token + "/");

//checkout
export const preCheckout = (data) => api.create(url.PRE_CHECKOUT, data);
export const checkout = (data) =>
  api.update(url.CHECKOUT + data.checkout_id + "/", data);

// Gets the logged in user data from local session
export const getLoggedInUser = () => {
  const user = localStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

// //is user is logged in
export const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

// Register Method
export const postFakeRegister = (data) =>
  api.create(url.POST_FAKE_REGISTER, data);

// Login Method
export const postFakeLogin = (data) => api.create(url.POST_FAKE_LOGIN, data);

// postForgetPwd
export const postFakeForgetPwd = (data) =>
  api.create(url.POST_FAKE_PASSWORD_FORGET, data);

// Edit profile
export const postJwtProfile = (data) =>
  api.create(url.POST_EDIT_JWT_PROFILE, data);

export const postFakeProfile = (data) =>
  api.update(url.POST_EDIT_PROFILE + "/" + data.idx, data);

// Register Method
export const postJwtRegister = (url, data) => {
  return api.create(url, data).catch((err) => {
    var message;
    if (err.response && err.response.status) {
      switch (err.response.status) {
        case 404:
          message = "Sorry! the page you are looking for could not be found";
          break;
        case 500:
          message =
            "Sorry! something went wrong, please contact our support team";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        default:
          message = err[1];
          break;
      }
    }
    throw message;
  });
};

// Login Method
export const postJwtLogin = (data) => api.create(url.POST_FAKE_JWT_LOGIN, data);

// postForgetPwd
export const postJwtForgetPwd = (data) =>
  api.create(url.POST_FAKE_JWT_PASSWORD_FORGET, data);

// postSocialLogin
export const postSocialLogin = (data) => api.create(url.SOCIAL_LOGIN, data);

// Calendar
// get Events
export const getEvents = () => api.get(url.GET_EVENTS);

// get Events
export const getCategories = () => api.get(url.GET_CATEGORIES);

// get Upcomming Events
export const getUpCommingEvent = () => api.get(url.GET_UPCOMMINGEVENT);

// add Events
export const addNewEvent = (event) => api.create(url.ADD_NEW_EVENT, event);

// update Event
export const updateEvent = (event) => api.update(url.UPDATE_EVENT, event);

// delete Event
export const deleteEvent = (event) =>
  api.delete(url.DELETE_EVENT, { headers: { event } });

// Chat
// get Contact
export const getDirectContact = () => api.get(url.GET_DIRECT_CONTACT);

// get Messages
export const getMessages = (roomId) =>
  api.get(`${url.GET_MESSAGES}/${roomId}`, { params: { roomId } });

// add Message
export const addMessage = (message) => api.create(url.ADD_MESSAGE, message);

// get Channels
export const getChannels = () => api.get(url.GET_CHANNELS);

// MailBox
//get Mail
export const getMailDetails = () => api.get(url.GET_MAIL_DETAILS);

// delete Mail
export const deleteMail = (forId) =>
  api.delete(url.DELETE_MAIL, { headers: { forId } });

// Ecommerce
// get Products
export const getProducts = () => api.get(url.GET_PRODUCTS); //old code
// add Products

// OTP
export const sendOtp = (otp) => api.create(url.SEND_OTP, otp);
export const verifyOtp = (otp) => api.create(url.VERIFY_OTP, otp);

//merchant register

export const addMerchant = (merchant) =>
  api.create(url.ADD_NEW_MERCHANT, merchant);

// products salis

export const getProductsData = (params) =>
  api.get(`${url.GET_PRODUCTS_DATA}`, params);

export const getOneProductData = (productId) =>
  api.get(`${url.GET_ONE_PRODUCTS_DATA}${productId}/`);
export const addProductData = (product) =>
  api.create(url.ADD_NEW_PRODUCT, product);
export const updateProduct = (product) =>
  api.update(url.UPDATE_ONE_PRODUCTS_DATA + product.get("id") + "/", product);
export const deleteProducts = (productId) =>
  api.delete(url.DELETE_PRODUCT + productId + "/");
export const logIn = (user) => api.create(url.LOGIN_USER, user);

export const getUserData = ( id ) => api.get(`${url.USER_DATA}${id}/`);
export const updateUserData = ( userDate ) => api.patch(`${url.USER_DATA}${userDate.id}/`,userDate);

// Special Deals
export const getSpecialDeals = (params) =>
  api.get(`${url.GET_SPECIAL_DEALS_DATA}`, params);
export const addSpecialDeals = (specialDeal) =>
  api.create(url.ADD_SPECIAL_DEALS, specialDeal);
export const getSpecialDealsSettings = () =>
  api.get(`${url.SPECIAL_DEALS_SETTINGS}`);
export const getSpecialDealsAvgMaxSettings = (params) =>
  api.get(`${url.SPECIAL_AVG_MAX_SETTINGS}`, params);


// PPC
export const getPPC = (params) =>
  api.get(`${url.GET_PPC_DATA_SPENDING}`, params);
export const getPPCTable = (params) =>
  api.get(`${url.GET_PPC_DATA_TABLE}`, params);

// Marketing spending
export const getTotalMarketingSpending = (params) =>
  api.get(`${url.GET_TOTAL_MARKETING_SPENDING}`, params);

// Total receivables
export const getTotalReceivablesChart = (params) =>
  api.get(`${url.GET_TOTAL_RECEIVABLES_CHART}`, params);


// orders salis

export const getOrdersData = (params) =>
  api.get(`${url.GET_ORDERS_DATA}`, params);
export const getOneOrdersData = (orderId) =>
  api.get(`${url.GET_ONE_ORDERS_DATA}${orderId}/`);
export const updateOrdersData = (order) =>
  api.update(url.UPDATE_ORDER_DATA + order.id + "/", order);

export const cancelOrder = (order) => api.create(url.CANCEL_ORDER, order);

export const getCustomers = (pageNumber) =>
  api.get(`${url.GET_CUSTOMERS}?page=${pageNumber}`);

// delete Product

// get Orders
export const getOrders = () => api.get(url.GET_ORDERS);

// add Order
export const addNewOrder = (order) => api.create(url.ADD_NEW_ORDER, order);

// update Order
export const updateOrder = (order) =>
  api.update(url.UPDATE_ORDER + "/" + order._id, order);

// delete Order
export const deleteOrder = (order) =>
  api.delete(url.DELETE_ORDER + "/" + order);

// get Customers

// add Customers
export const addNewCustomer = (customer) =>
  api.create(url.ADD_NEW_CUSTOMER, customer);

// update Customers
export const updateCustomer = (customer) =>
  api.update(url.UPDATE_CUSTOMER + "/" + customer._id, customer);

// delete Customers
export const deleteCustomer = (customer) =>
  api.delete(url.DELETE_CUSTOMER + "/" + customer);

// get Sellers
export const getSellers = () => api.get(url.GET_SELLERS);

// Project
// get Project list
export const getProjectList = () => api.get(url.GET_PROJECT_LIST);

// Tasks
// get Task
export const getTaskList = () => api.get(url.GET_TASK_LIST);

// add Task
export const addNewTask = (task) => api.create(url.ADD_NEW_TASK, task);

// update Task
export const updateTask = (task) =>
  api.update(url.UPDATE_TASK + "/" + task._id, task);

// delete Task
export const deleteTask = (task) => api.delete(url.DELETE_TASK + "/" + task);

// CRM
// get Contacts
export const getContacts = () => api.get(url.GET_CONTACTS);

// add Contact
export const addNewContact = (contact) =>
  api.create(url.ADD_NEW_CONTACT, contact);

// update Contact
export const updateContact = (contact) =>
  api.update(url.UPDATE_CONTACT + "/" + contact._id, contact);

// delete Contact
export const deleteContact = (contact) =>
  api.delete(url.DELETE_CONTACT + "/" + contact);

// get Companies
export const getCompanies = () => api.get(url.GET_COMPANIES);

// add Companies
export const addNewCompanies = (company) =>
  api.create(url.ADD_NEW_COMPANIES, company);

// update Companies
export const updateCompanies = (company) =>
  api.update(url.UPDATE_COMPANIES + "/" + company._id, company);

// delete Companies
export const deleteCompanies = (company) =>
  api.delete(url.DELETE_COMPANIES + "/" + company);

// get Deals
export const getDeals = () => api.get(url.GET_DEALS);

// get Leads
export const getLeads = () => api.get(url.GET_LEADS);

// add Lead
export const addNewLead = (lead) => api.create(url.ADD_NEW_LEAD, lead);

// update Lead
export const updateLead = (lead) =>
  api.update(url.UPDATE_LEAD + "/" + lead._id, lead);

// delete Lead
export const deleteLead = (lead) => api.delete(url.DELETE_LEAD + "/" + lead);

// Crypto
// Transation
export const getTransationList = () => api.get(url.GET_TRANSACTION_LIST);

// Order List
export const getOrderList = () => api.get(url.GET_ORDRER_LIST);

// Invoice
//get Invoice
export const getInvoices = () => api.get(url.GET_INVOICES);

// add Invoice
export const addNewInvoice = (invoice) =>
  api.create(url.ADD_NEW_INVOICE, invoice);

// update Invoice
export const updateInvoice = (invoice) =>
  api.update(url.UPDATE_INVOICE + "/" + invoice._id, invoice);

// delete Invoice
export const deleteInvoice = (invoice) =>
  api.delete(url.DELETE_INVOICE + "/" + invoice);

// Support Tickets
// Tickets
export const getTicketsList = () => api.get(url.GET_TICKETS_LIST);

// add Tickets
export const addNewTicket = (ticket) => api.create(url.ADD_NEW_TICKET, ticket);

// update Tickets
export const updateTicket = (ticket) =>
  api.update(url.UPDATE_TICKET + "/" + ticket._id, ticket);
// delete Tickets
export const deleteTicket = (ticket) =>
  api.delete(url.DELETE_TICKET + "/" + ticket);

// charts

export const getActualCustomers = (params) =>
  api.get(url.GET_ACTUAL_CUSTOMERS, params);


  
export const getCommission = (params) =>
api.get(url.GET_COMMISSIONS, params);

  export const getTotalConversionRate = (params) =>
  api.get(url.GET_TOTAL_CONVERSION_RATE, params);
  
export const getTopItems = () => api.get(url.GET_TOP_ITEMS);
export const getTotalSales = (params) => api.get(url.GET_TOTAL_SALES,params);
export const getItemsSales = (params) => api.get(url.GET_ITEMS_SALES,params);
export const getTotalSpent = (params) => api.get(url.GET_TOTAL_SPENT, params);
export const getVisitors = (params) =>api.get(url.GET_VISITORS,params);
export const getDailyVisitors = (params) =>api.get(url.GET_VISITORS,params);
export const getMonthlyVisitors = (params) =>api.get(url.GET_VISITORS,params);
export const getTotalRecievables = (params) =>api.get(url.GET_TOTAL_RECIEVABLES,params);



// In store order

export const addInStoreOrder = (order) => api.create(url.IN_STORE_ORDER, order);

//rating
export const addOrderRate = (rate) => api.create(url.ORDER_RATING, rate);
// get Banks
export const getBankAccounts = () => api.get(url.GET_BANK_ACCOUNTS);
export const getBanks = () => api.get(url.GET_BANKS);
export const deleteBankAccount = (data) =>
  api.delete(url.GET_BANK_ACCOUNTS + data.id + "/");
export const addBankAccount = (data) => api.create(url.GET_BANK_ACCOUNTS, data);
export const changeBankAccount = (data) =>
  api.update(url.GET_BANK_ACCOUNTS + data.id + "/", data);

//wallet

export const getWallet = () => api.get(url.GET_WALLET);
// items
export const getOrderItem = (order) =>
  api.get(url.GET_ITEMS + order.id + "/items/", order.action);

// items
export const getOrderCaptures = (order) =>
  api.get(url.GET_CAPTURES + order + "/captures/");

// items
export const addRefundPayment = (refund) =>
  api.create(url.REFUND_PAYMENT, refund);

// captures
export const addCapturePayment = (capture) =>
  api.create(url.CAPTURE_PAYMENT, capture);

// password
export const forgetPassword = (data) => {
  if (data.token) {
    return api.update(url.FORGET_PASSWORD, data);
  } else {
    return api.create(url.FORGET_PASSWORD, data);
  }
};

// webhook
export const addWebhook = (webhook) => api.create(url.REGISTER_WEBHOOK, webhook);

export const getWebhook = () => api.get(url.GET_WEBHOOK);
export const deleteWebhook = (webhookId) => api.delete(url.DELETE_WEBHOOK + webhookId + "/");

// settlements

export const getSettlements = () =>  api.get(url.GET_SETTLEMENTS);

export const getOneSettlements = (id) =>  api.get(`${url.GET_SETTLEMENTS}${id}/`);

export const withdraw = (data) => api.create(url.WITHDRAW, data);



export const getTransactions = (params) => api.get(url.GET_TRANSACTION,params);


// images upload
  export const getUploaderLink = (params) => api.get(url.PRE_UPLOAD_IMAGE, params);
  export const uploadImage = (data) => api.upload(data.url, data.image);


  export const getFeaturesFlags = () => api.get(url.GET_FEATURES_FLAGS);
export const getProductVisitors = (params) =>api.get(url.GET_VISITORS,params);
export const getWalletStatement = (params) =>api.get(url.GET_WALLET_STATEMENT,params);

export const getItemConversionRate = (params) =>api.get(url.GET_ITEMS_CONVERSION_RATE,params);


export const postPayPerClick = (data) => api.create(url.POST_PayPer_Click,data);
export const getPPCTableData = (params) => api.get(`${url.GET_PPC_DATA}`, params);
export const CancelPPC = (data) => api.update(url.CANCEL_PPC + data+ "/");

//ROI
export const getROI = (params) => api.get(`${url.GET_ROI}`, params);
export const getROIChart = (params) => api.get(`${url.GET_ROI_CHART}`, params);

//Marketing Spending Cards
export const getMarketingSpendingCards=(params) => api.get(`${url.GET_MARKETING_SPENDING_CARDS}`, params);
export const getReceivablesDue = (params) => api.get(`${url.GET_RECEIVABLES_DUE}`, params);

export const getpaymenttypes = (order) => api.get(url.GET_PAYMENT_TYPES,order);
