import {
  GET_FEATURES_FLAGS,
  GET_FEATURES_FLAGS_SUCCESS,
  GET_FEATURES_FLAGS_FAILED,
} from "./actionType";

const INIT_STATE = {
  featuresFlags: [],
  loading:false,
  error:false,
};

const FeaturesFlags = (state = INIT_STATE, action) => {
  switch (action.type) {
    
    case GET_FEATURES_FLAGS:
      state = {
        ...state,
        loading:true,
      };
      break;
    case GET_FEATURES_FLAGS_SUCCESS:
      state = {
        ...state,
        loading: false,
        featuresFlags: action.payload,
      };
      break;
    case GET_FEATURES_FLAGS_FAILED:
      state = {
        ...state,
        loading: false,
        error:true,
      };
      break;
    default:
  }
  return state;
};

export default FeaturesFlags;
