import PropTypes from "prop-types";
import React from "react";
import { Modal, ModalBody,ModalHeader } from "reactstrap";

import successLogo from "../../assets/images/success_logo.svg";
import failedLogo from "../../assets/images/failed_logo.svg";

const ResultModal = ({ message, show, onCloseClick, status,title=null,closeBtn=null }) => {
  return (
    <Modal isOpen={show} toggle={onCloseClick} backdrop="static" centered={true}>
      <ModalHeader className="justify-content-center">
        <div className="mt-2">
          {status === "overdue" ? "Warning!" : status === "success" ? "Success!" : "Failed!"}
        </div>
      </ModalHeader>
      <ModalBody className="py-3 px-2 ">
        <div className="w-100 m-1 pt-2 text-center ">
          <img
            className="py-2"
            src={status === "success" ? successLogo : failedLogo}
            alt="success logo"
          />
        </div>
        {title!= null ? 
        <div className="mt-2 text-center">
          <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
            <h4>{title}</h4>
          </div>
        </div> :''}
        <div className="mt-2 text-center">
          <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
            <h6>{message}</h6>
          </div>
        </div>
        <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
          <button
            type="button"
            className="btn  bg-light-red text-white w-50"
            data-bs-dismiss="modal"
            onClick={onCloseClick}
          >
            {closeBtn != null ? closeBtn
             : 'Close'}
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};

ResultModal.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any,
  message: PropTypes.any,
};

export default ResultModal;
