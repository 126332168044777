import React, { useState } from "react";
import { Collapse, Form, Label, Input, FormFeedback } from "reactstrap";
import Places from "../Places";
import { useTranslation } from "react-i18next";

const CollapseFour = ({ currentPage, validation }) => {
  const { t } = useTranslation();
  const [mapModal, setMapModal] = useState(false);
  const [address, setAddress] = useState(false);

  const merchantCategories = [
    { value: "online_travel_agencies", label: t("fastFashion") },
    {
      value: "furniture_home_improvement",
      label: t("furnitureImprovement"),
    },
    {
      value: "electronics_electrical_appliances",
      label: t("electronicsElectricalAppliances"),
    },
    { value: "fast_fashion", label: t("fastFashion") },
    { value: "luxury_retail", label: t("luxuryRetail") },
    { value: "healthcare_wellness", label: t("healthcareWellness") },
    { value: "automotive", label: t("automotive") },
    { value: "education", label: t("education") },
    { value: "leisure_entertainment", label: t("leisureEntertainment") },
    { value: "restaurants_cafes", label: t("restaurantsCafes") },
    { value: "groceries", label: t("groceries") },
    { value: "gas_station", label: t("gasStation") },
    { value: "transportation", label: t("transportation") },
  ];
  return (
    <Collapse in isOpen={currentPage === 3}>
      <Places onCloseClick={() => setMapModal(false)} show={mapModal} setAddress={setAddress} validation={validation} address={address} />
      <div className="p-2 mt-4 mx-4">
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
          className="needs-validation"
          action="#"
        >
          <h3>{t("businessInformation")}</h3>

          <div className="mb-3">
            <Label htmlFor="store_name" className="form-label">
              {t("storeName")}
              <span className="text-danger">*</span>
            </Label>
            <div className="position-relative auth-pass-inputgroup mb-3">
              <Input
                id="store_name"
                name="store_name"
                type="text"
                className="form-control padding_input"
                placeholder={t("enterStoreName")}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.store_name || ""}
                invalid={
                  validation.touched.store_name && validation.errors.store_name
                    ? true
                    : false
                }
              />
              {validation.touched.store_name && validation.errors.store_name ? (
                <FormFeedback type="invalid">
                  <div>{t(validation.errors.store_name)}</div>
                </FormFeedback>
              ) : null}
              <button
                className="btn btn-link position-absolute start-0 top-0 text-decoration-none text-muted"
                type="button"
                disabled={true}
                id="password-addon"
              >
                <i className="ri-store-2-line"></i>
              </button>
            </div>
          </div>

          <div className="mb-3">
            <Label htmlFor="headquarters_location" className="form-label">
              {t("storeLocation")}
              <span className="text-danger">*</span>
            </Label>
            <div className="d-flex justify-content-between gap-1">
              <div className="position-relative auth-pass-inputgroup mb-3 w-100">
                <Input
                  id="headquarters_location"
                  name="headquarters_location"
                  type="text"
                  disabled={true}
                  className="form-control padding_input"
                  placeholder={t("enterstoreLocation")}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.headquarters_location || ""}
                  invalid={
                    validation.touched.headquarters_location &&
                      validation.errors.headquarters_location
                      ? true
                      : false
                  }
                />
                {validation.touched.headquarters_location &&
                  validation.errors.headquarters_location ? (
                  <FormFeedback type="invalid">
                    <div>{t(validation.errors.headquarters_location)}</div>
                  </FormFeedback>
                ) : null}
                <button
                  className="btn btn-link position-absolute start-0 top-0 text-decoration-none text-muted"
                  type="button"
                  disabled={true}
                  id="password-addon"
                >
                  <i className="ri-map-pin-line"></i>
                </button>
              </div>
              <button
                onClick={() => setMapModal(true)}
                className="btn bg-light-red text-white"
                style={{ height: '40px' }}
                type="button"
                id="password-addon"
              >
                <i className="ri-map-pin-line"></i>
              </button>
            </div>
          </div>
          <div className="mb-3">
            <Label
              htmlFor="business_registration_number"
              className="form-label"
            >
              {t("businessRegistrationNumber")}{" "}
              <span className="text-danger">*</span>
            </Label>
            <div className="position-relative auth-pass-inputgroup mb-3">
              <Input
                id="business_registration_number"
                name="business_registration_number"
                type="number"
                className="form-control padding_input"
                placeholder={t("businessRegistrationNumber")}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.business_registration_number || ""}
                invalid={
                  validation.touched.business_registration_number &&
                    validation.errors.business_registration_number
                    ? true
                    : false
                }
              />
              {validation.touched.business_registration_number &&
                validation.errors.business_registration_number ? (
                <FormFeedback type="invalid">
                  <div>{t(validation.errors.business_registration_number)}</div>
                </FormFeedback>
              ) : null}
              <button
                className="btn btn-link position-absolute start-0 top-0 text-decoration-none text-muted"
                type="button"
                disabled={true}
                id="password-addon"
              >
                <i className="ri-briefcase-line"></i>
              </button>
            </div>
          </div>

          <div className="mb-3">
            <Label htmlFor="tax_registration_number" className="form-label">
              {t("taxRegistrationNumber")} <span className="text-danger">*</span>
            </Label>
            <div className="position-relative auth-pass-inputgroup mb-3">
              <Input
                id="tax_registration_number"
                name="tax_registration_number"
                type="number"
                className="form-control padding_input"
                placeholder={t("taxRegistrationNumber")}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.tax_registration_number || ""}
                invalid={
                  validation.touched.tax_registration_number &&
                    validation.errors.tax_registration_number
                    ? true
                    : false
                }
              />
              {validation.touched.tax_registration_number &&
                validation.errors.tax_registration_number ? (
                <FormFeedback type="invalid">
                  <div>{t(validation.errors.tax_registration_number)}</div>
                </FormFeedback>
              ) : null}
              <button
                className="btn btn-link position-absolute start-0 top-0 text-decoration-none text-muted"
                type="button"
                id="password-addon"
              >
                <i className="ri-percent-line"></i>
              </button>
            </div>
          </div>
          <div className="mb-3">
            <label className="form-label" htmlFor="inputGroupSelect01">
              {t("category")} *
            </label>
            <div className="input-group mb-3">
              <select
                id="category"
                value={validation.values.category || ""}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                className={
                  validation.errors.category && validation.touched.category
                    ? "form-select is-invalid"
                    : "form-select"
                }
              >
                <option hidden value="default">
                  {t("selectCategory")}
                </option>
                {merchantCategories.map((item, index) => {
                  return (
                    <option value={item.value} key={index}>
                      {item.label}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="mb-2 mt-4 d-flex justify-content-center  ">
            <button
              style={{
                backgroundColor: "#EE856F",
                color: "white",
                height: "45px",
              }}
              className="border-0 w-100 rounded"
              type="submit"
            >
              {t("saveContinue")}
            </button>
          </div>
        </Form>
      </div>
    </Collapse>
  );
};
export default CollapseFour;
