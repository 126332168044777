import {
  GET_TOTAL_SPENT,
  GET_TOTAL_SPENT_SUCCESS,
  GET_TOTAL_SPENT_FAIL,
  GET_ACTUAL_CUSTOMERS,
  GET_ACTUAL_CUSTOMERS_SUCCESS,
  GET_ACTUAL_CUSTOMERS_FAIL,
  GET_VISITORS,
  GET_VISITORS_SUCCESS,
  GET_VISITORS_FAIL,
  GET_DAILY_VISITORS,
  GET_DAILY_VISITORS_SUCCESS,
  GET_DAILY_VISITORS_FAIL,
  GET_MONTHLY_VISITORS,
  GET_MONTHLY_VISITORS_SUCCESS,
  GET_MONTHLY_VISITORS_FAIL,
  GET_DAILY_CUSTOMERS_SUCCESS,
  GET_3D_CUSTOMERS_SUCCESS,
  GET_3D_CUSTOMERS_FAIL,
  GET_3D_COMMISSIONS,
  GET_3D_COMMISSIONS_SUCCESS,
  GET_3D_COMMISSIONS_FAIL,
  GET_TOTAL_CONVERSION_RATE_SUCCESS,
  GET_TOTAL_CONVERSION_RATE_FAIL,
  GET_PRODUCTS_VISITORS_DATA,
  GET_PRODUCTS_VISITORS_DATA_SUCCESS,
  GET_PRODUCTS_VISITORS_DATA_FAIL,
  GET_WALLET_STATEMENT_DATA,
  GET_WALLET_STATEMENT_DATA_SUCCESS,
  GET_WALLET_STATEMENT_DATA_FAIL,
  GET_ITEMS_CONVERSION_RATE,
  GET_ITEMS_CONVERSION_RATE_SUCCESS,
  GET_ITEMS_CONVERSION_RATE_FAIL,
  RESET_LOADER_FLAG,
  GET_TOTAL_SALES,
  GET_TOTAL_SALES_SUCCESS,
  GET_TOTAL_SALES_FAIL,
  GET_TOP_ITEMS,
  GET_TOP_ITEMS_SUCCESS,
  GET_TOP_ITEMS_FAIL,
  GET_ITEMS_SALES,
  GET_ITEMS_SALES_SUCCESS,
  GET_ITEMS_SALES_FAIL,
  GET_PPC_DATA_SPENDING,
  GET_PPC_DATA_SUCCESS,
  GET_PPC_DATA_FAIL,
  GET_PPC_DATA_TABLE,
  GET_PPC_DATA_TABLE_SUCCESS,
  GET_PPC_DATA_TABLE_FAIL,
  GET_TOTAL_MARKETING_SPENDING,
  GET_TOTAL_MARKETING_SPENDING_SUCCESS,
  GET_TOTAL_MARKETING_SPENDING_FAIL,
  GET_TOTAL_RECEIVABLES_CHART_SUCCESS,
  GET_TOTAL_RECEIVABLES_CHART,
  GET_TOTAL_RECEIVABLES_CHART_FAIL,
  GET_TOTAL_RECIEVABLES_SUCCESS,
  GET_TOTAL_RECIEVABLES,
  GET_TOTAL_RECIEVABLES_FAIL,
  GET_ROI_SUCCESS,
  GET_ROI,
  GET_ROI_FAIL,
  GET_ROI_CHART_SUCCESS,
  GET_ROI_CHART,
  GET_ROI_CHART_FAIL,
  GET_MARKETING_SPENDING_CARDS_SUCCESS,
  GET_MARKETING_SPENDING_CARDS,
  GET_MARKETING_SPENDING_CARDS_FAIL,
  GET_RECEIVABLES_DUE_SUCCESS,
  GET_RECEIVABLES_DUE,
  GET_RECEIVABLES_DUE_FAIL,
} from "./actionTypes";


const initialState = {
  message: null,
  loading: false,
  totalSpent: [],
  error: false,
  actualCustomers: [],
  actual3DCustomers: [],
  totalConversionRate: [],
  actualDailyCustomers: [],
  totalSales: [],
  totalRecievables: [],
  topTenItems: [],
  itemsSales: [],
  visitors: [],
  dailyVisitors: [],
  monthlyVisitors: [],
  ProductsVisitorsData: [],
  itemsConversionRateData: [],
  WalletStatementData: [],
  allPPCData: [],
  allPPCDataTable: [],
  totalMarketingSpending: [],
  totalReceivablesChart: [],
  ROIDataCard:[],
  ROIDataChart:[],
  marketingSpendingCards:[],
  receivablesDue:[],
};

const Charts = (state = initialState, action) => {
  switch (action.type) {
    case GET_TOTAL_SPENT:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_TOTAL_SPENT_SUCCESS:
      state = {
        ...state,
        loading: false,
        totalSpent: action.payload,
      };
      break;
    case GET_TOTAL_SPENT_FAIL:
      state = {
        ...state,
        loading: false,
        error: true,
      };
      break;

    case GET_ACTUAL_CUSTOMERS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_ACTUAL_CUSTOMERS_SUCCESS:
      state = {
        ...state,
        loading: false,
        actualCustomers: action.payload,
      };
      break;
    case GET_ACTUAL_CUSTOMERS_FAIL:
      state = {
        ...state,
        loading: false,
        error: true,
      };
      break;

    case GET_TOTAL_SALES:
      return {
        ...state,
        loading: true,
      };

    case GET_TOTAL_SALES_SUCCESS:
      state = {
        ...state,
        loading: false,
        totalSales: action.payload,
      };
      break;
    case GET_TOTAL_SALES_FAIL:
      state = {
        ...state,
        loading: false,
        error: true,
      };
      break;
      case GET_TOTAL_RECEIVABLES_CHART:
        return {
          ...state,
          loading: true,
        };
      case GET_TOTAL_RECEIVABLES_CHART_SUCCESS:
        state = {
          ...state,
          loading: false,
          totalReceivablesChart: action.payload,
        };
        break;
      case GET_TOTAL_RECEIVABLES_CHART_FAIL:
        state = {
          ...state,
          loading: false,
          error: true,
        };
        break;
    
      
    case GET_ITEMS_SALES:
      return {
        ...state,
        loading: true,
      };

    case GET_ITEMS_SALES_SUCCESS:
      state = {
        ...state,
        loading: false,
        itemsSales: action.payload,
      };
      break;
    case GET_ITEMS_SALES_FAIL:
      state = {
        ...state,
        loading: false,
        error: true,
      };
      break;


      case GET_TOP_ITEMS:
        return {
          ...state,
          loading: true,
        };
  
      case GET_TOP_ITEMS_SUCCESS:
        state = {
          ...state,
          loading: false,
          topTenItems: action.payload,
        };
        break;
      case GET_TOP_ITEMS_FAIL:
        state = {
          ...state,
          loading: false,
          error: true,
        };
        break;


    case GET_3D_CUSTOMERS_SUCCESS:
      state = {
        ...state,
        loading: false,
        actual3DCustomers: action.payload,
      };
      break;
    case GET_3D_CUSTOMERS_FAIL:
      state = {
        ...state,
        loading: false,
        error: true,
      };
      break;

    case GET_3D_COMMISSIONS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_3D_COMMISSIONS_SUCCESS:
      state = {
        ...state,
        loading: false,
        commission: action.payload,
      };
      break;
    case GET_3D_COMMISSIONS_FAIL:
      state = {
        ...state,
        loading: false,
        error: true,
      };
      break;
    case GET_DAILY_CUSTOMERS_SUCCESS:
      state = {
        ...state,
        loading: false,
        actualDailyCustomers: action.payload,
      };
      break;

    case GET_TOTAL_CONVERSION_RATE_SUCCESS:
      state = {
        ...state,
        loading: false,
        totalConversionRate: action.payload,
      };
      break;
    case GET_TOTAL_CONVERSION_RATE_FAIL:
      state = {
        ...state,
        loading: false,
        error: true,
      };
      break;

    case GET_VISITORS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_VISITORS_SUCCESS:
      state = {
        ...state,
        loading: false,
        visitors: action.payload,
      };
      break;
    case GET_VISITORS_FAIL:
      state = {
        ...state,
        loading: false,
        error: true,
      };
      break;
    case GET_PRODUCTS_VISITORS_DATA:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_PRODUCTS_VISITORS_DATA_SUCCESS:
      state = {
        ...state,
        loading: false,
        ProductsVisitorsData: action.payload,
      };
      break;
    case GET_PRODUCTS_VISITORS_DATA_FAIL:
      state = {
        ...state,
        loading: false,
        error: true,
      };
      break;

    case GET_ITEMS_CONVERSION_RATE:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_ITEMS_CONVERSION_RATE_SUCCESS:
      state = {
        ...state,
        loading: false,
        itemsConversionRateData: action.payload,
      };
      break;
    case GET_ITEMS_CONVERSION_RATE_FAIL:
      state = {
        ...state,
        loading: false,
        error: true,
      };
      break;

    case GET_DAILY_VISITORS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_DAILY_VISITORS_SUCCESS:
      state = {
        ...state,
        loading: false,
        dailyVisitors: action.payload,
      };
      break;
    case GET_DAILY_VISITORS_FAIL:
      state = {
        ...state,
        loading: false,
        error: true,
      };
      break;
    case GET_MONTHLY_VISITORS:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_MONTHLY_VISITORS_SUCCESS:
      state = {
        ...state,
        loading: false,
        monthlyVisitors: action.payload,
      };
      break;
    case GET_MONTHLY_VISITORS_FAIL:
      state = {
        ...state,
        loading: false,
        error: true,
      };
      break;
    case GET_WALLET_STATEMENT_DATA:
      state = {
        ...state,
        loading: true,
      };
      break;
    case GET_WALLET_STATEMENT_DATA_SUCCESS:
      state = {
        ...state,
        loading: false,
        WalletStatementData: action.payload,
      };
      break;
    case GET_WALLET_STATEMENT_DATA_FAIL:
      state = {
        ...state,
        loading: false,
        error: true,
      };
      break;
      case GET_PPC_DATA_SPENDING:
        return {
          ...state,
          loading: true,
        };
  
      case GET_PPC_DATA_SUCCESS:
        return {
          ...state,
          loading: false,
          allPPCData: action.payload,
        };
      case GET_PPC_DATA_FAIL:
        return {
          ...state,
          loading: false,
          error: true,
        };
      case GET_PPC_DATA_TABLE:
        return {
          ...state,
          loading: true,
        };
  
      case GET_PPC_DATA_TABLE_SUCCESS:
        return {
          ...state,
          loading: false,
          allPPCDataTable: action.payload,
        };
      case GET_PPC_DATA_TABLE_FAIL:
        return {
          ...state,
          loading: false,
          error: true,
        };
      case GET_TOTAL_MARKETING_SPENDING:
        return {
          ...state,
          loading: true,
        };
  
      case GET_TOTAL_MARKETING_SPENDING_SUCCESS:
        return {
          ...state,
          loading: false,
          totalMarketingSpending: action.payload,
        };
      case GET_TOTAL_MARKETING_SPENDING_FAIL:
        return {
          ...state,
          loading: false,
          error: true,
        };
      case GET_TOTAL_RECIEVABLES:
        return {
          ...state,
          loading: true,
        };
  
      case GET_TOTAL_RECIEVABLES_SUCCESS:
        return {
          ...state,
          loading: false,
          totalRecievables: action.payload,
        };
      case GET_TOTAL_RECIEVABLES_FAIL:
        return {
          ...state,
          loading: false,
          error: true,
        };
      case GET_ROI:
        return {
          ...state,
          loading: true,
        }; 
      case GET_ROI_SUCCESS:
        return {
          ...state,
          loading: false,
          ROIDataCard: action.payload,
        };
      case GET_ROI_FAIL:
        return {
          ...state,
          loading: false,
          error: true,
        };
      case GET_ROI_CHART:
        return {
          ...state,
          loading: true,
        };
  
      case GET_ROI_CHART_SUCCESS:
        return {
          ...state,
          loading: false,
          ROIDataChart: action.payload,
        };
      case GET_ROI_CHART_FAIL:
        return {
          ...state,
          loading: false,
          error: true,
        };
      case GET_MARKETING_SPENDING_CARDS:
        return {
          ...state,
          loading: true,
        };
  
      case GET_MARKETING_SPENDING_CARDS_SUCCESS:
        return {
          ...state,
          loading: false,
          marketingSpendingCards: action.payload,
        };
      case GET_MARKETING_SPENDING_CARDS_FAIL:
        return {
          ...state,
          loading: false,
          error: true,
        };
        case GET_RECEIVABLES_DUE:
          return {
            ...state,
            loading: true,
          };
      case GET_RECEIVABLES_DUE_SUCCESS:
        return {
          ...state,
          loading: false,
          receivablesDue: action.payload,
        };
      case GET_RECEIVABLES_DUE_FAIL:
        return {
          ...state,
          loading: false,
          error: true,
        };
    case RESET_LOADER_FLAG:
      return {
        ...state,

        loading: false,
      };
    default:
  }
  return state;
};

export default Charts;
