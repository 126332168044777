import React, { useState } from "react";
import warning from "../../assets/images/warning.svg";

const WarningAlert = ({ message }) => {
  const [alertShow, setAlertShow] = useState("true");

  return (
    <>
      {alertShow ?
        <div className="alert alert-warning d-flex align-items-center" role="alert">
          <img src={warning} alt="done" />
          <div className="px-3 w-100  d-flex justify-content-between">
            {message}
            <i onClick={() => setAlertShow(false)} className="ri-close-line align-bottom me-1 border border-2 px-1 cursor-pointer"></i>

          </div>
        </div>
        :
        <>
        </>
      }
    </>
  );
};

export default WarningAlert;
