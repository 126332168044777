import React, { useState } from "react";

const TopBar = ({ tab, handleTap }) => {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };
  return (
    <React.Fragment>
      <div className="d-lg-none">
        <button
          className="navbar-toggler"
          type="button"
          onClick={toggleMenu}
        >
          <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 209.33 209.86" fill="#737373">
            <path className="cls-1" d="m89.2,45.51c0,7,.09,14-.02,21-.2,13.24-8.81,22.62-22.02,23-15.15.43-30.33.39-45.49,0C8.94,89.17.14,79.55.05,66.66c-.09-13.67-.02-27.34-.02-41,0-15.25,6.74-24.16,21.76-25.12,15.9-1.01,31.95-.45,47.89.43,13.56.75,19.26,12.17,19.49,22.04.17,7.5.03,15,.04,22.5h-.01Zm-44.57,27.23h16.11c7.04-.12,11.63-4.19,11.81-11.28.26-10.98.26-21.98,0-32.95-.16-6.98-4.6-11.22-11.39-11.3-11.11-.12-22.21-.14-33.32,0-6.5.09-10.84,4.43-10.91,10.86-.12,11.23-.12,22.46,0,33.7.07,6.55,4.62,10.87,11.22,10.95,5.49.07,10.98.01,16.48.01h0Z" />
            <path className="cls-1" d="m.03,165.14v-19.5c0-15.21,6.79-24.13,21.81-25.07,15.9-.99,31.96-.45,47.89.46,13.45.77,19.2,11.89,19.38,21.58.28,15,.29,30.01,0,45-.24,12.55-9.18,21.6-22.01,21.95-15.15.41-30.34.39-45.49-.02-12.52-.34-21.38-9.9-21.56-22.4-.11-7.33-.02-14.67-.02-22h0Zm44.56,37.76c7.16,0,14.32.04,21.48,0,10.01-.07,16.34-5.56,16.58-15.4.36-14.98.36-29.98,0-44.95-.23-9.52-6.3-15.28-15.56-15.38-15.15-.17-30.3-.19-45.45.01-8.86.12-14.75,6.05-14.85,14.84-.17,15.32-.17,30.64,0,45.96.1,8.93,6.31,14.8,15.33,14.91,7.49.09,14.98.02,22.48.02h-.01Z" />
            <path className="cls-1" d="m120.04,45.19v-19.5c0-15.26,6.72-24.17,21.73-25.13,15.89-1.02,31.96-.48,47.88.43,13.71.78,19.28,12.09,19.46,21.51.28,15,.28,30,0,45-.23,12.57-9.12,21.66-21.94,22.02-15.15.43-30.33.39-45.49,0-12.74-.33-21.52-9.95-21.65-22.82-.07-7.17-.01-14.33-.02-21.5h.03Zm44.55,37.67h21.48c10.01-.11,16.35-5.57,16.59-15.4.37-14.98.36-29.98,0-44.95-.23-9.52-6.29-15.29-15.55-15.39-15.15-.17-30.3-.19-45.45.01-8.86.12-14.76,6.05-14.86,14.83-.17,15.32-.17,30.64,0,45.96.1,8.93,6.31,14.81,15.32,14.92,7.49.09,14.98.02,22.48.02h0Z" />
            <path className="cls-1" d="m120.04,165.16v-19.5c0-15.22,6.78-24.14,21.79-25.09,15.9-1,31.96-.46,47.89.45,13.5.77,19.22,11.92,19.4,21.57.28,15,.28,30.01,0,45-.24,12.55-9.17,21.62-21.99,21.97-15.15.41-30.33.39-45.49-.02-12.51-.33-21.4-9.9-21.58-22.38-.11-7.33-.02-14.67-.02-22h0Zm6.69-.41c0,7.49-.09,14.99.02,22.48.15,9.62,6.13,15.61,15.62,15.66,14.65.07,29.3.07,43.95,0,9.7-.05,16.05-5.55,16.3-15.14.4-15.14.4-30.31,0-45.45-.25-9.46-6.43-15.05-15.79-15.14-14.98-.13-29.97-.16-44.95,0-9.11.1-14.94,6.03-15.12,15.11-.15,7.49-.03,14.99-.04,22.48h.01Z" />
            <path className="cls-1" d="m209.23,45.51c0,7,.09,14-.02,21-.2,13.24-8.81,22.62-22.02,23-15.15.43-30.33.39-45.49,0-12.73-.34-21.53-9.96-21.62-22.85-.09-13.67-.02-27.34-.02-41,0-15.25,6.74-24.16,21.76-25.12,15.9-1.01,31.95-.45,47.89.43,13.56.75,19.26,12.17,19.49,22.04.17,7.5.03,15,.04,22.5h-.01Zm-44.57,27.23h16.11c7.04-.12,11.63-4.19,11.81-11.28.26-10.98.26-21.98,0-32.95-.16-6.98-4.6-11.22-11.39-11.3-11.11-.12-22.21-.14-33.32,0-6.5.09-10.84,4.43-10.91,10.86-.12,11.23-.12,22.46,0,33.7.07,6.55,4.62,10.87,11.22,10.95,5.49.07,10.98.01,16.48.01h0Z" />
            <path className="cls-1" d="m89.37,165.25c0,7,.09,14-.02,21-.2,13.24-8.81,22.62-22.02,23-15.15.43-30.33.39-45.49,0-12.73-.34-21.53-9.96-21.62-22.85-.09-13.67-.02-27.34-.02-41,0-15.25,6.74-24.16,21.76-25.12,15.9-1.01,31.95-.45,47.89.43,13.56.75,19.26,12.17,19.49,22.04.17,7.5.03,15,.04,22.5h-.01Zm-44.57,27.23h16.11c7.04-.12,11.63-4.19,11.81-11.28.26-10.98.26-21.98,0-32.95-.16-6.98-4.6-11.22-11.39-11.3-11.11-.12-22.21-.14-33.32,0-6.5.09-10.84,4.43-10.91,10.86-.12,11.23-.12,22.46,0,33.7.07,6.55,4.62,10.87,11.22,10.95,5.49.07,10.98.01,16.48.01h0Z" />
            <path className="cls-1" d="m209.23,165.25c0,7,.09,14-.02,21-.2,13.24-8.81,22.62-22.02,23-15.15.43-30.33.39-45.49,0-12.73-.34-21.53-9.96-21.62-22.85-.09-13.67-.02-27.34-.02-41,0-15.25,6.74-24.16,21.76-25.12,15.9-1.01,31.95-.45,47.89.43,13.56.75,19.26,12.17,19.49,22.04.17,7.5.03,15,.04,22.5h-.01Zm-44.57,27.23h16.11c7.04-.12,11.63-4.19,11.81-11.28.26-10.98.26-21.98,0-32.95-.16-6.98-4.6-11.22-11.39-11.3-11.11-.12-22.21-.14-33.32,0-6.5.09-10.84,4.43-10.91,10.86-.12,11.23-.12,22.46,0,33.7.07,6.55,4.62,10.87,11.22,10.95,5.49.07,10.98.01,16.48.01h0Z" />
          </svg>
        </button>
      </div>
      <div className={`page-title-box pt-3 pb-0 d-flex flex-wrap align-items-center bg-white  justify-content-start border-bottom-1 ${showMenu ? "show" : "hide"
        }`}>

        <div
          onClick={() => handleTap("visitors")}
          className={
            tab === "visitors"
              ? "px-3 text-muted  w-md-33 dashboard--bar  text-light-red  d-flex"
              : "px-3 text-muted w-md-33 cursor-pointer  d-flex"
          }
        >
          <i className="ri-mouse-line align-bottom me-1"></i>
          <p>Visitors</p>
        </div>
        <div
          onClick={() => handleTap("customers")}
          className={
            tab === "customers"
              ? "px-3 text-muted  w-md-33 dashboard--bar  text-light-red  d-flex"
              : "px-3 text-muted  w-md-33 cursor-pointer  d-flex"
          }
        >
          <i className="ri-user-3-line align-bottom me-1"></i>
          <p>Customers</p>
        </div>
        <div
          onClick={() => handleTap("conversion")}
          className={
            tab === "conversion"
              ? "px-3 text-muted  w-md-33 dashboard--bar  text-light-red  d-flex"
              : "px-3 text-muted  w-md-33 cursor-pointer   d-flex"
          }
        >
          <i className="ri-bubble-chart-line align-bottom me-1"></i>
          <p>Conversion rate</p>
        </div>
        <div
          onClick={() => handleTap("budget")}
          className={
            tab === "budget"
              ? "px-3 text-muted   w-md-33 dashboard--bar  text-light-red  d-flex"
              : "px-3 text-muted  w-md-33 cursor-pointer  d-flex"
          }
        >
          <i className="ri-money-dollar-circle-line align-bottom me-1"></i>
          <p>Marketing budget</p>
        </div>
        <div
          onClick={() => handleTap("sales")}
          className={
            tab === "sales"
              ? "px-3 text-muted  w-md-33 dashboard--bar  text-light-red  d-flex"
              : "px-3 text-muted  w-md-33 d-flex cursor-pointer"
          }
        >
          <i className="ri-shopping-bag-line align-bottom me-1"></i>
          <p>Sales</p>
        </div>
        {/* <div
          onClick={() => handleTap("installments")}
          className={
            tab === "installments"
              ? "px-3 text-muted  w-md-33 dashboard--bar  text-light-red  d-flex"
              : "px-3 text-muted  w-md-33 cursor-not-allowed cursor-pointer d-flex"
          }
        >
          <i className="ri-calendar-line align-bottom me-1"></i>
          <p>Installments</p>
        </div> */}
        <div
          onClick={() => handleTap("statement")}
          className={
            tab === "statement"
              ? "px-3 text-muted  w-md-33 dashboard--bar cursor-pointer text-light-red  d-flex"
              : "px-3 text-muted  w-md-33 d-flex cursor-pointer"
          }
        >
          <i className="ri-bill-line align-bottom me-1"></i>
          <p>Statement</p>
        </div>
        <div
          onClick={() => handleTap("investment")}
          className={
            tab === "investment"
              ? "px-3 text-muted  w-md-33 w-md-100 dashboard--bar text-light-red  d-flex"
              : "px-3 text-muted  w-md-33 cursor-not-allowed cursor-pointer d-flex"
          }
        >
          <i className="ri-refund-2-line align-bottom me-1"></i>
          <p>Return on investment</p>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TopBar;
