import React from "react";
import emptyChartIcon from "../../assets/images/empty_chart.svg";

const NoDataFound = () => {

  return (
    <>
      <div className="overlayed d-flex flex-column justify-content-center align-items-center">
        <img src={emptyChartIcon} alt="empty chart" width="34%" height="50%" />
        <p className="text-center">No data found</p>{" "}
      </div>
    </>
  );
};

export default NoDataFound; 
