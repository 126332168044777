import React from "react";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import {
  BarChart,
} from "../../Components/Common/ChartsJs";
import { Link } from "react-router-dom";
import { getTotalSpent } from "../../store/charts/action";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";

const EcommerceChartsJs = () => {
  const dispatch = useDispatch();
  const { topItems, totalSales, totalSpent } = useSelector(
    (state) => ({
      topItems: state.Ecommerce.topItems,
      totalSales: state.Ecommerce.totalSales,
      totalSpent: state.Charts.totalSpent,
    })
  );

  const [totalMonth, setTotalMonth] = useState([]);
  const [totalSale, setTotalSale] = useState([]);
  const [totalSpents, setTotalSpents] = useState([]);
  const [totalMonthSpents, setTotalMonthSpents] = useState([]);

  useEffect(() => {
    // dispatch(getActualCustomers());
    // dispatch(getTopItems());
    // dispatch(getTotalSales());
    const params = {
      year: new Date().getFullYear(),
    };
    dispatch(getTotalSpent(params));
  }, [dispatch]);

  useEffect(() => {
    let MonthSpent = totalSpent.map(function (obj) {
      return obj.created_date__month;
    });
    let spentAmount = totalSpent.map(function (obj) {
      return obj.total_amount;
    });
    setTotalSpents(spentAmount);
    setTotalMonthSpents(MonthSpent);

    let MonthSal = totalSales?.results?.map(function (obj) {
      return obj.Month;
    });
    let saleSal = totalSales?.results?.map(function (obj) {
      return obj.Sales;
    });


    setTotalMonth(MonthSal);
    setTotalSale(saleSal);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalSales, totalSpent]);

  document.title = "Charts Js| Salis";
  return (
    <div className="page-content">
      <Container fluid>
        <Row>
          <Col xl={6}>
            <Card>
              <CardHeader>
                <h4 className="card-title mb-0">
                  Total Budget Spent for special Deals
                </h4>
              </CardHeader>
              <CardBody>
                <BarChart
                  xLabel={"Months"}
                  yLabel={"Amount"}
                  Month={totalMonthSpents}
                  Sales={totalSpents}
                  label={"Amount Of Budget Spent"}
                  dataColors='["--vz-primary-rgb, 0.8", "--vz-primary-rgb, 0.9"]'
                  colorFrom={"#EE856F"}
                  colorTo={"#FFB797"}
                />
              </CardBody>
            </Card>
          </Col>

          <Col xl={6}>
            <Card>
              <CardHeader>
                <h4 className="card-title mb-0">Total Sales Chart</h4>
              </CardHeader>
              <CardBody>
                <BarChart
                  Month={totalMonth}
                  Sales={totalSale}
                  label={"Number Of Total Sales"}
                  dataColors='["--vz-primary-rgb, 0.8", "--vz-primary-rgb, 0.9"]'

                />
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col xl={6}>
            <Card>
              <CardHeader>
                <h4 className="card-title mb-0">Top 10 Selling items</h4>
              </CardHeader>
              <CardBody>
                <div className="table-responsive table-card">
                  <table className="table table-hover table-centered align-middle table-nowrap mb-0">
                    <tbody>
                      {(topItems || []).map((item, key) => (
                        <tr key={key}>
                          <td>
                            <div className="d-flex align-items-center">
                              <div className="avatar-sm bg-light rounded p-1 me-2">
                                <img
                                  src={item.Main_photo}
                                  alt=""
                                  className="img-fluid d-block"
                                />
                              </div>
                              <div>
                                <h5 className="fs-14 my-1">
                                  <Link
                                    to="/product-details"
                                    className="text-reset"
                                  >
                                    {item.Product}
                                  </Link>
                                </h5>
                                {/*<span className="text-muted">{item.date}</span>*/}
                              </div>
                            </div>
                          </td>
                          <td>
                            <h5 className="fs-14 my-1 fw-normal">
                              ${item.Price}
                            </h5>
                            <span className="text-muted">Price</span>
                          </td>
                          {/*<td>*/}
                          {/*    <h5 className="fs-14 my-1 fw-normal">{item.orders}</h5>*/}
                          {/*    <span className="text-muted">Orders</span>*/}
                          {/*</td>*/}
                          <td>
                            <h5 className="fs-14 my-1 fw-normal">
                              {item.Stock ? (
                                item.Stock
                              ) : (
                                <span className="badge badge-soft-danger">
                                  Out of stock
                                </span>
                              )}{" "}
                            </h5>
                            <span className="text-muted">Stock</span>
                          </td>
                          <td>
                            <h5 className="fs-14 my-1 fw-normal">
                              ${item.Total_revenue}
                            </h5>
                            <span className="text-muted">Total Revenue</span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default EcommerceChartsJs;
