import React, { useState, useEffect } from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import { BarChart } from "../../../Components/Common/ChartsJs";
import DateFilter from "../DateFilter";
import { useDispatch, useSelector } from "react-redux";
import { timeFilter, MonthsFinder } from "../../../helpers/charts_helper";
import { getTotalMarketingSpending } from "../../../store/charts/action";
import { useTranslation } from "react-i18next";
import NoDataFound from "../NoDataFound";
import moment from "moment";
import { config } from "../../../config";

const { currency } = config;
const TotalSpendingBudget = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const now = new Date();
    const firstDay = moment(now).startOf('year').format("YYYY-MM-DD");
    const lastDay = moment(now).endOf('year').format('YYYY-MM-DD');
    const [action, setAction] = useState("month");
    const [startDate, setStartDate] = useState(firstDay);
    const [endDate, setEndDate] = useState(lastDay);
    const [xLabel, setxLabel] = useState(MonthsFinder(firstDay, lastDay));
    const [callApi, setCallApi] = useState(false);
    const [totalMarketingSpending3D, settotalMarketingSpending3D] = useState([]);
    const { totalMarketingSpending } = useSelector((state) => ({
        totalMarketingSpending: state.Charts.totalMarketingSpending,
    }));
    useEffect(() => {
        const params = {
            start_date: startDate,
            end_date: endDate,
            action: action,
        };
        dispatch(getTotalMarketingSpending(params));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [callApi, dispatch]);

    useEffect(() => {
        if (totalMarketingSpending?.results) {
            if (totalMarketingSpending.results.length !== 0) {
                let spending = [];
                xLabel.forEach((date) => {
                    const item = totalMarketingSpending.results.find((item) => {
                        return item.date === date;
                    });
                    if (item) {
                        spending.push(item.total_spending);
                    } else {
                        spending.push(0);
                    }
                });
                settotalMarketingSpending3D(spending);
            } else {
                settotalMarketingSpending3D([]);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [totalMarketingSpending, dispatch]);
    return (
        <>
            <Card className="">
                <CardHeader className="border-0 align-items-center d-flex pb-2">
                    {/* eslint-disable-next-line */}
                    <h4 className="card-title mb-0 flex-grow-1">{t("totalSpendingBudget") + " " + "/" + " " + timeFilter[action]}</h4>
                    <DateFilter
                        action={action}
                        setAction={setAction}
                        setxLabel={setxLabel}
                        setStartDate={setStartDate}
                        setEndDate={setEndDate}
                        setCallApi={setCallApi}
                        startDate={startDate}
                        endDate={endDate}
                        callApi={callApi}
                    />
                </CardHeader>
                <CardBody className="position-relative">
                    {totalMarketingSpending?.results?.length === 0 && (
                        <NoDataFound />
                    )}
                    <BarChart
                        xLabel={timeFilter[action]}
                        yLabel={t("marketingSpending")}
                        Month={xLabel}
                        Sales={totalMarketingSpending3D}
                        dataFlag={totalMarketingSpending?.results?.length !== 0}
                        currency={currency}
                        label={t("marketingSpending")}
                        dataColors='["--vz-primary-rgb, 0.8", "--vz-primary-rgb, 0.9"]'
                        colorFrom={"#007960"}
                        colorTo={"#007960"}
                    />
                </CardBody>
            </Card>
        </>
    );
};
export default TotalSpendingBudget;
