import React from "react";
import { Card, CardBody, Col, CardHeader } from "reactstrap";

import { config } from "../../../config";
import Loader from "../Loader";
import {
  OrderDetailsStatus,
  handleDate,
  handleLetter,
} from "../../../helpers/orderColHelper";
import { useTranslation } from "react-i18next";

const { currency } = config;

const SettlementsDetailList = ({ oneSettlement }) => {
  const { t } = useTranslation();

  return (
    <Card className="my-5">
      <CardHeader>
        <Col className="col-sm">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex gap-4  ">
              <h5 className="card-title flex-grow-1 mb-0 ">
                {t("settlementOrders")}
              </h5>
            </div>
          </div>
        </Col>
      </CardHeader>
      <CardBody>
        {oneSettlement.settlement_order?.length === 0 ? (
          <Loader />
        ) : (
          <div className="table-responsive table-card mb-1">
            <table
              className="table align-middle table-nowrap table-borderless"
              id="customerTable"
            >
              <thead className="table-light">
                <tr>
                  <th scope="col">{t("orderId")}</th>
                  <th scope="col">{t("createdAt")} </th>
                  <th scope="col">{t("totalAmount")}</th>
                  <th scope="col">{t("discount")}</th>
                  <th scope="col">{t("paymentType")}</th>
                  <th scope="col">{t("status")}</th>
                </tr>
              </thead>
              <tbody className="list form-check-all">
                {oneSettlement.settlement_order?.map((item, index) => (
                  <tr className="border-top" key={item.webhook_id}>
                    <td>
                      <p className=" align-center m-0">{item.order.order_id}</p>
                    </td>
                    <td>
                      {handleDate(item.order.created_at)}
                    </td>
                    <td>
                      <p className=" align-center m-0">
                        {item.order.total_amount} {currency}
                      </p>
                    </td>
                    <td>
                      <p className=" align-center m-0">
                        {item.order.discount_amount || "0.00"} {currency}
                      </p>
                    </td>
                    <td>
                      <p className=" align-center m-0">
                     {   handleLetter(item.order.payment_type) }
                      </p>
                    </td>

                    <td>
                      <p className=" align-center m-0">
                        <OrderDetailsStatus value={item.order.status} />
                      </p>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </CardBody>
    </Card>
  );
};

export default SettlementsDetailList;
