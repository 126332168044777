import React from "react";

import "antd/dist/antd.css";
import { Card, CardBody, Col, Collapse } from "reactstrap";
import PaymentTypeImage from "../../../assets/images/cardPaymentType.png";
import { useEffect } from "react";

const CollapseTwo = ({
    collapseTwo,
    handleStepper,
    setPaymentType,
    Payment_Type,
    setminAmount,
    setmaxAmount,
    getPaymentTypes,
}) => {
    const handlePaymentType = (value) => {
        setPaymentType(value.target.value);
    };
    useEffect(() => {
    if (Payment_Type === "PAY_BY_INSTALLMENTS") {
        setmaxAmount(getPaymentTypes.results[1].max_limit);
        setminAmount(getPaymentTypes.results[1].min_limit);
    }else if (Payment_Type === "PAY_NOW"){
        setmaxAmount(getPaymentTypes.results[0].max_limit);
        setminAmount(getPaymentTypes.results[0].min_limit);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ Payment_Type]); 
    

    return (
        <Collapse in isOpen={collapseTwo} >
            <Card className=" h-100% d-flex justify-content-center flex-column  align-items-center bg-light w-100 p-5 phoneCollapseOne">
                <CardBody className="border-0 h-100% bg-light d-flex justify-content-center flex-column  align-items-center w-100">
                    <h4 className="card-body mb-0">
                    {/* eslint-disable-next-line react-hooks/exhaustive-deps */}
                        <img
                            className="p-2"
                            src={PaymentTypeImage}
                            alt="Payment Type logo"
                        />
                        Payment type
                    </h4>
                    <p className="card-body color  text-sm  text-muted mb-0">
                        Please choose proper payment type to complete this step.
                    </p>
                    <Col className="p-4 w-100 ">
                        <Card className="mb-20 h-100 border-0 w-100 p-4 PaymentTypeCollapseCard" >
                            <h6 className="font-weight-bold p-2">
                                Payment type
                            </h6>
                            <CardBody>
                                <div className="d-flex align-items-center justify-content-between w-75 PaymentTypeCollapse">
                                    <div className="form-check  p-4"
                                        style={{ width: "40%" }}
                                    >
                                        <input
                                            className="form-check-input mt-3 "
                                            id="pay_now_radio"
                                            name="type"
                                            value={"PAY_NOW"}
                                            type="radio"
                                            onClick={(e) => handlePaymentType(e)}
                                        />

                                        <label
                                            className="form-check-label  p-2 bg-light text-center payNowRadio"
                                            htmlFor="pay_now_radio"
                                        >
                                            Pay now
                                        </label>
                                    </div>
                                    { getPaymentTypes.results?.length > 1 ?
                                        <div className="form-check  p-4"
                                            style={{ width: "40%" }}
                                        >
                                            <input
                                                className="form-check-input mt-3 bg-light"
                                                id="pay_by_installments_radio"
                                                name="type"
                                                value={"PAY_BY_INSTALLMENTS"}
                                                onClick={(e) => handlePaymentType(e)}
                                                type="radio"
                                            />
                                            <label
                                                className="form-check-label  p-2 bg-light text-center payNowRadio"
                                                htmlFor="pay_by_installments_radio"
                                            >
                                                Pay by installment

                                            </label>
                                        </div> : ""}
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <div className="d-flex justify-content-center w-50 phoneCollapseOnebutton" >
                        <button
                            className="btn bg-light-red text-white w-50 my-5 phoneCollapseOnebutton"
                            onClick={() => handleStepper(2)}
                            disabled={Payment_Type === ""}
                        >
                            Continue
                        </button>
                    </div>
                </CardBody>
            </Card>
        </Collapse>
    );
};
export default CollapseTwo;
