import React, { useState } from "react";

import "antd/dist/antd.css";
import { Col, Container, Row } from "reactstrap";
import {
  getProductsData,
  getSpecialDealsSettings,
  getSpecialDealsAvgMaxSettings,
} from "../../../store/ecommerce/action";
import { useDispatch, useSelector } from "react-redux";
import CollapseOne from "./collapseOne";
import CollapseTwo from "./collapseTwo";
import CollapseThree from "./collapseThree";
import CollapseFour from "./collapseFour";

import { useEffect } from "react";
import { Stepper, Step } from "react-form-stepper";
import {
  MdOutlineBrightnessHigh,
  MdOutlineWork,
  MdOutlineGavel,
  MdOutlineTouchApp,
} from "react-icons/md";
import BreadCrumb from "../../../Components/Common/BreadCrumb";

const EcommerceAddSpecialDeal = () => {
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [products, setProducts] = useState([]);

  const [productDetails, setProductDetails] = useState("");
  const [title, setTitle] = useState("");
  const [myBid, setMyBid] = useState(0);

  const [dealDuration, setDealDuration] = useState("");

  const [dealCategory, setDealCategory] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [collapseOne, setCollapseOne] = useState(true);
  const [collapseTwo, setCollapseTwo] = useState(false);
  const [collapseThree, setCollapseThree] = useState(false);
  const [collapseFour, setCollapseFour] = useState(false);
  const [minBid, setMinBid] = useState("");
  const [productName, setProductName] = useState("");
  const [flagSearch, setFlagSearch] = useState(false);

  const { allProductsData, specialDealsSettings, specialDealsAvgMaxSettings } =
    useSelector((state) => ({
      allProductsData: state.Ecommerce.allProductsData,
      specialDealsSettings: state.Ecommerce.specialDealsSettings,
      specialDealsAvgMaxSettings: state.Ecommerce.specialDealsAvgMaxSettings,
      paymentStatusFlag: state.Ecommerce.paymentStatusFlag,
    }));
  useEffect(() => {
    const productsFilter = {
      page: pageNumber,
    };

    dispatch(getProductsData(productsFilter));
  }, [dispatch, pageNumber]);

  const handleProductSearch = () => {
    setFlagSearch(true);

    if (productName !== "") {
      const productsFilter = {
        page: 1,
        product_name: productName,
      };
      dispatch(getProductsData(productsFilter));
    } else {
      setPageNumber(1);
      dispatch(getProductsData());
    }
  };
  

  useEffect(() => {
    const bidCat = `${dealCategory}_min_bid`;
    specialDealsSettings?.results?.forEach((item) => {
      if (item[bidCat]) return setMinBid(item[bidCat]);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, dealCategory]);

  useEffect(() => {
    if (allProductsData.results !== undefined) {
      if (!flagSearch) {
        if (products[0]?.id !== allProductsData.results[0]?.id) {
          setProducts((products) => [...products, ...allProductsData.results]);
        } else if (products.length === 0) {
          setProducts([...allProductsData.results]);
        }
      } else {
        setProducts([...allProductsData.results]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allProductsData]);

  useEffect(() => {
    setTimeout(() => {
      setFlagSearch(false);
    }, 1000);
  }, [flagSearch]);

  const handleStepper = (value) => {
    if (value === 0) {
      setCurrentPage(value);

      setCollapseOne(true);
      setCollapseTwo(false);
      setCollapseThree(false);
      setCollapseFour(false);
    } else if (
      value === 1 &&
      title.length < 30 &&
      title !== "" &&
      productDetails !== ""
    ) {
      setCurrentPage(value);

      setCollapseOne(false);
      setCollapseTwo(true);
      setCollapseThree(false);
      setCollapseFour(false);
      dispatch(getSpecialDealsSettings());
    } else if (
      value === 2 &&
      title.length < 30 &&
      title !== "" &&
      productDetails !== "" &&
      startDate !== ""
    ) {
      const dealSettingsParams = {
        start_date: startDate,
        end_date: endDate,
        category: dealCategory,
        product: productDetails.id,
      };

      dispatch(getSpecialDealsAvgMaxSettings(dealSettingsParams));

      setCurrentPage(value);

      setCollapseOne(false);
      setCollapseTwo(false);
      setCollapseThree(true);
      setCollapseFour(false);
    } else if (
      value === 3 &&
      title.length < 30 &&
      title !== "" &&
      productDetails !== "" &&
      startDate !== "" &&
      myBid !== "" &&
      Number(myBid) >= Number(minBid)
    ) {
      setCurrentPage(value);

      setCollapseOne(false);
      setCollapseTwo(false);
      setCollapseThree(false);
      setCollapseFour(true);
    }
  };

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            subTitle="Add Special Deal"
            title="Special Deals "
            pageTitle="Marketing"
          />
          <Row className="row">
            <Col lg={4} xl={2}>
              <div className="special-deals-stepper stepperPPC" style={{ marginTop: 80 }}>
                <Stepper hideConnectors={true} activeStep={currentPage}>
                  <Step
                    onClick={() => handleStepper(0)}
                    label="Select Product Settings"
                  >
                    {" "}
                    <MdOutlineTouchApp />
                  </Step>
                  <Step
                    onClick={() => handleStepper(1)}
                    label="Special Deal Settings"
                  >
                    {" "}
                    <MdOutlineBrightnessHigh />
                  </Step>
                  <Step onClick={() => handleStepper(2)} label="Bid Settings">
                    {" "}
                    <MdOutlineGavel />
                  </Step>
                  <Step onClick={() => handleStepper(3)} label="Billing">
                    {" "}
                    <MdOutlineWork />
                  </Step>
                </Stepper>
              </div>
            </Col>
            <Col lg={8} xl={10}>
              <CollapseOne
                collapseOne={collapseOne}
                setProductDetails={setProductDetails}
                products={products}
                productDetails={productDetails}
                allProductsData={allProductsData}
                handleProductSearch={handleProductSearch}
                setProductName={setProductName}
                flagSearch={flagSearch}
                setPageNumber={setPageNumber}
                pageNumber={pageNumber}
                title={title}
                setTitle={setTitle}
                handleStepper={handleStepper}
              />

              <CollapseTwo
                collapseTwo={collapseTwo}
                handleStepper={handleStepper}
                setDealCategory={setDealCategory}
                setDealDuration={setDealDuration}
                setEndDate={setEndDate}
                setStartDate={setStartDate}
                startDate={startDate}
                endDate={endDate}
                dealCategory={dealCategory}
              />

              <CollapseThree
                collapseThree={collapseThree}
                handleStepper={handleStepper}
                setMyBid={setMyBid}
                myBid={myBid}
                minBid={minBid}
                specialDealsAvgMaxSettings={specialDealsAvgMaxSettings}
                productDetails={productDetails}
                dealCategory={dealCategory}
                endDate={endDate}
                startDate={startDate}
                title={title}
              />


              <CollapseFour
                title={title}
                collapseFour={collapseFour}
                myBid={myBid}
                handleStepper={handleStepper}
                productDetails={productDetails}
                dealCategory={dealCategory}
                dealDuration={dealDuration}
                endDate={endDate}
                startDate={startDate}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default EcommerceAddSpecialDeal;
