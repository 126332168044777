import React, { useEffect, useState, useMemo } from "react";
import { Container, Row, TabContent, TabPane } from "reactstrap";
import OrderRatingModal from "./OrderRatingModal";
import { Link } from "react-router-dom";
import Select from "react-select";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import TableContainer from "../../../Components/Common/TableContainer";
import { OrderDetailsStatus, handleDate } from "../../../helpers/orderColHelper";
// Formik

//redux
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";

//Import actions
import {
  resetEcomFlag,
  getOrdersData,
  addOrderRate,
} from "../../../store/ecommerce/action";

import "react-toastify/dist/ReactToastify.css";
import { config } from "../../../config";
import Loader from "../Loader";
const { TOAST_AUTOCLOSE_TIMER } = config;

const { currency } = config;
const storeOptions = [
  { value: "", label: "All Stores" },
  { value: "Online", label: "Online Stores" },
  { value: "Offline", label: "Offline Stores" },
];
const EcommerceOrders = () => {
  const dispatch = useDispatch();
  const {
    ordersDataGet,
    isOrderSuccess,
    error,
    isAddRateSuccessFlag,
    isAddRateFailFlag,
  } = useSelector((state) => ({
    orders: state.Ecommerce.orders,
    error: state.Ecommerce.error,
    ordersDataGet: state.Ecommerce.ordersDataGet,
    isAddRateSuccessFlag: state.Ecommerce.isAddRateSuccessFlag,
    isAddRateFailFlag: state.Ecommerce.isAddRateFailFlag,
  }));
  const [ratingModal, setRatingModal] = useState(false);
  const [orderId, setOrderId] = useState("");
  const [orderStoreType, setOrderStoreType] = useState("");

  const fetchData = (params = {}) => {
    const filterData = { ...params };

    orderStoreType !== "" && (filterData["checkout__type"] = orderStoreType);
    dispatch(getOrdersData(filterData));
  };


  useEffect(() => {
    fetchData({ page: 1 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    orderStoreType,
    isAddRateSuccessFlag,
  ]);

  useEffect(() => {
    if (isAddRateSuccessFlag) {
      toast(isAddRateSuccessFlag, {
        position: "top-right",
        hideProgressBar: true,
        className: "bg-success text-white",
        progress: undefined,
        toastId: "",
      });
    } else if (isAddRateFailFlag) {
      toast(isAddRateFailFlag, {
        position: "top-right",
        hideProgressBar: true,
        className: "bg-danger text-white",
        progress: undefined,
        toastId: "",
      });
    }
  }, [isAddRateSuccessFlag, isAddRateFailFlag]);

  const handleOrderRate = (data) => {
    const orderRateData = {
      rate: data.rate,
      feedback: data.feedback,
      order: orderId,
    };
    dispatch(addOrderRate(orderRateData));
    setRatingModal(false);
  };

  const handleOrderId = (orderId) => {
    setRatingModal(true);
    setOrderId(orderId);
  };
  const dataOrders = ordersDataGet.results;
  const dataOrdersCount = ordersDataGet.count;
  useEffect(() => {
    dispatch(resetEcomFlag());
  }, [
    dispatch,
    isOrderSuccess,
    error,
    isAddRateSuccessFlag,
    isAddRateFailFlag,
  ]);

  const columns = useMemo(
    () => [
      {
        Header: "Order Reference Id",
        filterable: false,
        Cell: (dataOrders) => {
          return (
            <React.Fragment>#{dataOrders.row.original.order_reference_id}</React.Fragment>
          );
        },
      },
      {
        Header: "Created Date",
        filterable: false,
        Cell: (dataOrders) => {
          return (
            <React.Fragment>
              {handleDate(dataOrders.row.original.created_at)}
            </React.Fragment>
          );
        },
      },

      {
        Header: "Amount",
        accessor: "total_amount",
        Cell: (dataOrders) => {
          return (
            <React.Fragment>
              <p className="amount-font-weight align-center m-0">
                {dataOrders.row.original.total_amount} {currency}
              </p>
            </React.Fragment>
          );
        },
      },
      {
        Header: "Discount",
        accessor: "discount_amount",
        Cell: (dataOrders) => {
          return (
            <React.Fragment>
              <p className="amount-font-weight align-center m-0">
                {dataOrders.row.original.discount_amount} {currency}
              </p>
            </React.Fragment>
          );
        },
      },

      {
        Header: "Consumer Name",
        Cell: (dataOrders) => {
          return (
            <React.Fragment>
              {dataOrders.row.original.consumer?.first_name || "N/A"}{" "}
              {dataOrders.row.original.consumer?.last_name || ""}
            </React.Fragment>
          );
        },
      },

      {
        Header: "Payment Type",
        accessor: "payment_type",
        Cell: (cellProps) => {
          return <OrderDetailsStatus {...cellProps} />;
        },
      },

      {
        Header: "Status",
        accessor: "status",
        Cell: (cellProps) => {
          return <OrderDetailsStatus {...cellProps} />;
        },
      },
      {
        Header: "Order Rating ",
        Cell: (dataOrders) => {
          return (
            dataOrders.row.original.order_rating?.rate ?
              <p className="text-light-red text-center "> {dataOrders.row.original.order_rating.rate}</p>
              :
              <div className="text-center">
                <i
                  className="ri-thumb-up-line text-light-red fs-16 cursor-pointer "
                  onClick={(e) =>
                    handleOrderId(dataOrders.row.original.order_id)
                  }
                ></i>
              </div>
          );
        },
      },
      {
        Header: "View Details",
        Cell: (cellProps) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0 justify-content-center">
              <li className="list-inline-item">
                <Link
                  to={`order-details/${cellProps.row.original.order_id}`}
                  className="text-primary d-inline-block"
                >
                  <i className="ri-eye-fill text-light-red fs-16"></i>
                </Link>
              </li>
            </ul>
          );
        },
      },
    ],
    []
  );

  document.title = "Orders | Salis";
  const callback_url = `merchants/payment-types`;
  return (
    <div className="page-content">
      <ToastContainer autoClose={TOAST_AUTOCLOSE_TIMER} style={{ width: "400px" }} />

      <Container fluid>
        <BreadCrumb title="Orders" pageTitle="Marketing" />
        <OrderRatingModal
          show={ratingModal}
          setShow={setRatingModal}
          handleOrderRate={handleOrderRate}
        />

        <Row className="justify-content-center">
          <div className="col">
            <div>
              <div className="card">
                <div className="card-header border-0">
                  <div className="row g-4 table-header-margin">
                    <div className="col-sm-auto">
                      <Link
                        to="/in-store-order"
                        className="btn bg-light-red text-white"
                        callback_url={callback_url}
                      >
                        <i className="ri-add-line align-bottom me-1"></i> Create
                        in store order
                      </Link>
                    </div>

                    <div className="col-sm">
                      <div className="d-flex justify-content-sm-end">
                        <div className="d-flex">
                          <h6 className="pt-2 px-2">Orders place:</h6>

                          <Select
                            onChange={(e) => {
                              setOrderStoreType(e.value);
                            }}
                            options={storeOptions}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card-header">
                  <div className="row align-items-center">
                    <div className="col-auto">
                      <div id="selection-element">
                        <div className="my-n1 d-flex align-items-center text-muted">
                          Select
                          <div
                            id="select-content"
                            className="text-body fw-semibold px-1"
                          ></div>
                          Result
                          <button
                            type="button"
                            className="btn btn-link link-danger p-0 ms-3"
                          >
                            Remove
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card-body table-responsive">
                  <TabContent className="text-muted">
                    <TabPane>
                      <div
                        id="table-product-list-all"
                        className="table-card gridjs-border-none pb-2"
                      >
                        {dataOrders && dataOrders.length > 0 ? (

                          <TableContainer
                            columns={columns}
                            data={dataOrders || []}
                            customPageSize={10}
                            numOfPages={dataOrdersCount}
                            fetchData={fetchData}
                            divClass="overflow-visible table table-responsive mb-1"
                            tableClass="mb-0 align-middle table-borderless pb-5"
                            theadClass="table-light text-muted"
                          />
                        ) : (
                          <Loader />
                        )}
                      </div>
                    </TabPane>
                  </TabContent>
                </div>
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default EcommerceOrders;
