import React, { useState, useEffect, useCallback } from "react";
import { Card, CardBody, Container, Row, Col, Input, Label } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
// import validator from "../../../helpers/Validator";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import ConfirmationModal from "../../../Components/Common/ConfirmationModal";
import LoadingModal from "../../../Components/Common/LoadingModal";
import PaymentConfirmImg from "../../../assets/images/payment_vector.svg";
import AddBankModal from "../EcommerceCards/addBankPopup";
import ResultModal from "../../../Components/Common/ResultModal";
import Otp from "../../Authentication/OtpValidation";
import { ToastContainer, toast } from "react-toastify";
import { config } from "../../../config";
import moment from "moment-timezone";

import {
  getBankAccounts,
  resetEcomFlag,
  withdraw,
  getBanks,
} from "../../../store/ecommerce/action";
import { sendOtp, verifyOtp, resetOtpFlag } from "../../../store/actions";
const Step = ({ title, number }) => {
  return (
    <div className="mb-3 mt-2">
      <span className="badge badge-soft-green align-middle badge-span rounded-pill mx-2">
        <p className="badge-icon">{number}</p>
      </span>
      {title}
    </div>
  );
};
const validationSchema = Yup.object().shape({
  amount: Yup.number()
    .typeError("You must enter a number.")
    .min(1, "The minimum value is 1.")
    .max(20000, "The maximum value is 20,000SAR.")
    .required("The amount is required."),
  credit_account: Yup.string().required("Choose bank account."),
});
const { TOAST_AUTOCLOSE_TIMER } = config;

const WalletWithdraw = () => {
  const dispatch = useDispatch();
  const History = useHistory();
  const [addBankModal, setAddBankModal] = useState(false);
  const [showSendOtp, setShowSendOtp] = useState(false);
  const [otp, setOTP] = useState("");

  const [inValidCreate, setInValidCreate] = useState(false);
  const [validCreate, setValidCreate] = useState(false);

  const [confirmationModal, setConfirmationModal] = useState(false);
  const [resultModal, setResultModal] = useState(false);
  const [resultModalMsg, setResultModalMsg] = useState("");
  const [resultModalStatus, setResultModalStatus] = useState("");
  const [delayMessage, setDelayMessage] = useState("");
  const {
    banks,
    bankAccountsData,
    isBankAdded,
    withdrawFailed,
    withdrawLoading,
    withdrawSuccess,
    otpSuccess,
    otpError,
    userData
  } = useSelector((state) => ({
    bankAccountsData: state.Ecommerce.bankAccountsData,
    isBankAdded: state.Ecommerce.isBankAdded,
    withdrawFailed: state.Ecommerce.withdrawFailed,
    withdrawSuccess: state.Ecommerce.withdrawSuccess,
    withdrawLoading: state.Ecommerce.withdrawLoading,
    banks: state.Ecommerce.banksData,
    otpSuccess: state.Otp.otpSuccess,
    otpError: state.Otp.otpError,
    userData: state.Profile.userData,

  }));
 
  useEffect(() => {
    if(confirmationModal){
      let date = moment(new Date()).tz('Asia/Riyadh');
      let day=date.format('dddd');
      let time=date.format('HH:mm');
      let closed_time="15:58";
      if (day === "Thursday" && closed_time < time){
        setDelayMessage("Your bank account will transfer it next workday.")
      }else if (day === "Friday"){
        setDelayMessage("Your bank account will transfer it next workday.")
      }else if (day === "Saturday" || closed_time < time){
        setDelayMessage("Your bank account will transfer it next workday.")
      }else{
        setDelayMessage("")
      }
    }
  }, [confirmationModal]);
  useEffect(() => {
    dispatch(getBanks());
    dispatch(getBankAccounts());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
  useEffect(() => {
    if (isBankAdded) {
      dispatch(getBankAccounts());
    }
    setTimeout(() => {
      dispatch(resetEcomFlag());
    }, 3000);
  }, [dispatch, isBankAdded, withdrawFailed]);

  const formik = useFormik({
    initialValues: {
      amount: "",
      credit_account: "",
    },
    validationSchema,
    onSubmit: (values) => {
      setConfirmationModal(true);
    },
  });

  useEffect(() => {
    if (withdrawLoading) {
      setConfirmationModal(false);
    }
    if (isBankAdded) {
      setAddBankModal(false);
    }
    if (withdrawSuccess) {
      setResultModalMsg("Your bank account has been successfully credited");
      setResultModal(true);
      setResultModalStatus("success");
    }

    if (withdrawFailed) {
      setResultModalMsg(
        "Invalid transaction. Please check the submitted bank information and try again."
      );
      setResultModal(true);
      setResultModalStatus("failed");
    }
  }, [dispatch, withdrawFailed, withdrawSuccess, withdrawLoading, isBankAdded]);

  useEffect(() => {
    setTimeout(() => {
      setInValidCreate(false);
      setValidCreate(false);
    }, 3000);
  }, [validCreate, inValidCreate]);

  document.title = "Wallet withdraw | Salis- Merchants Dashboard";
  const handleNotValidForm = (e) => {
    e.preventDefault();
    setInValidCreate(true);
    formik.touched.credit_account = true;
    formik.touched.amount = true;
  };
  function handleResultClose() {
    setResultModal(false);
    History.push("/cards/");
  }

  function handleShowSendOtp() {
    setShowSendOtp(true);
    setConfirmationModal(false);
    dispatch(sendOtp({ phone_number: userData.mobile_number }));
  }
  const handleVerifyOtp = () => {
    dispatch(verifyOtp({ phone_number: userData.mobile_number, otp: otp }));
  };
  const handleTransferMoney = useCallback(() => {
    let formData = new FormData();
    formData.append("amount", formik.values.amount);
    formData.append("credit_account", formik.values.credit_account);

    dispatch(withdraw(formData));

    /* eslint-disable */
  }, [formik.values.amount, formik.values.credit_account]);
  useEffect(() => {
    if (otpSuccess) {
      handleTransferMoney();
      setShowSendOtp(false);
    }
  }, [otpSuccess]);
  useEffect(() => {
    if (otpError) {
      toast(otpError, {
        position: "top-right",
        hideProgressBar: true,
        className: "bg-danger text-white",
        progress: undefined,
        toastId: "",
      });
    }
    dispatch(resetOtpFlag());
  }, [dispatch, otpError, otpSuccess]);
  return (
    <div className="page-content bg-alice-blue">
      <ToastContainer
        autoClose={TOAST_AUTOCLOSE_TIMER}
        style={{ width: "auto" }}
      />
      <ResultModal
        show={resultModal}
        message={resultModalMsg}
        status={resultModalStatus}
        onCloseClick={() => handleResultClose()}
      />
      <ConfirmationModal
        show={confirmationModal}
        onCloseClick={() => setConfirmationModal(false)}
        title={"Withdraw confirmation"}
        message={`Are you sure you want to withdraw ${formik.values.amount}SAR from your bank account IBAN number ${formik.values.credit_account}. ${delayMessage}`}
        action={handleShowSendOtp}
        image={PaymentConfirmImg}
      />
      <LoadingModal
        show={withdrawLoading}
        message={"We are processing your transaction."}
      />
      <AddBankModal
        banks={banks}
        show={addBankModal}
        onCloseClick={() => setAddBankModal(false)}
      />
      <Container fluid>
        {showSendOtp ? (
          <>
            <BreadCrumb title="OTP verification" pageTitle="Wallet withdraw" />
            <Row className="justify-content-center">
              <Col lg={4}>
                <Otp
                  otp={otp}
                  setOTP={setOTP}
                  handleAction={() => handleVerifyOtp()}
                  phoneNumber={userData.mobile_number}
                />
              </Col>
            </Row>
          </>
        ) : (
          <>
            <BreadCrumb title="Wallet withdraw" pageTitle="Wallet withdraw" />
            <Row>
              <form
                method="post"
                onSubmit={(e) =>
                  formik.isValid
                    ? formik.handleSubmit(e)
                    : handleNotValidForm(e)
                }
              >
                <Col lg={8}>
                  <Step title="Withdraw amount" number="1" />
                  <Card className="mb-20 p-0 h-100 border-0 mx-2">
                    <CardBody className=" border-0">
                      <div className="input-group">
                        <span className="input-group-text" id="basic-addon3">
                          SAR
                        </span>
                        <Input
                          id="amount"
                          name="amount"
                          type="number"
                          placeholder="Your Amount"
                          value={formik.values.amount || ""}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className={
                            formik.errors.amount && formik.touched.amount
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                        />
                      </div>
                      {formik.errors.amount && formik.touched.amount ? (
                        <div className="text-danger">
                          {formik.errors.amount}
                        </div>
                      ) : null}
                    </CardBody>
                  </Card>
                  <div className="d-flex justify-content-between align-items-center">
                    <Step
                      title="Choose one of saved bank accounts"
                      number="2"
                    />
                    <button
                      type="button"
                      className="btn text-light-red border-0"
                      onClick={() => setAddBankModal(true)}
                    >
                      + Add a new bank account
                    </button>
                  </div>
                  {formik.errors.credit_account &&
                  formik.touched.credit_account ? (
                    <div className="text-danger">
                      {formik.errors.credit_account}
                    </div>
                  ) : null}
                  {bankAccountsData &&
                    bankAccountsData?.results?.map((item, index) => {
                      return (
                        <Card key={index} className="border-0 mb-3 ">
                          <Label
                            for={`credit_account_${index}`}
                            className={`d-flex rounded p-4 m-0 ${
                              formik.values.credit_account === item.iban
                                ? "border-light-red"
                                : "border"
                            }`}
                          >
                            <p className="p-0 m-0 w-1/3">{item.holder_name}</p>
                            <p className="p-0 m-0 w-1/3">
                              {item?.bank?.en_name}
                            </p>
                            <p className="p-0 m-0 w-1/3">{item.iban}</p>
                            <Input
                              id={`credit_account_${index}`}
                              name="credit_account"
                              type="radio"
                              value={item.iban}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              checked={
                                formik.values.credit_account === item.iban
                              }
                              className={"form-control "}
                            />
                          </Label>
                        </Card>
                      );
                    })}
                  <div className="text-center my-4">
                    <button
                      type="submit"
                      className="btn btn bg-light-red text-white w-sm"
                    >
                      Transfer to your bank account
                    </button>
                  </div>
                </Col>
              </form>
            </Row>
          </>
        )}
      </Container>
    </div>
  );
};

export default WalletWithdraw;
