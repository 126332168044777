const profitSvg = (fill) => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48" height="48" rx="4" fill={fill} fillOpacity="0.1" />
      <g clipPath="url(#clip0_824_4154)">
        <path
          d="M24 34C18.477 34 14 29.523 14 24C14 18.477 18.477 14 24 14C29.523 14 34 18.477 34 24C34 29.523 29.523 34 24 34ZM24 32C26.1217 32 28.1566 31.1571 29.6569 29.6569C31.1571 28.1566 32 26.1217 32 24C32 21.8783 31.1571 19.8434 29.6569 18.3431C28.1566 16.8429 26.1217 16 24 16C21.8783 16 19.8434 16.8429 18.3431 18.3431C16.8429 19.8434 16 21.8783 16 24C16 26.1217 16.8429 28.1566 18.3431 29.6569C19.8434 31.1571 21.8783 32 24 32ZM20.5 26H26C26.1326 26 26.2598 25.9473 26.3536 25.8536C26.4473 25.7598 26.5 25.6326 26.5 25.5C26.5 25.3674 26.4473 25.2402 26.3536 25.1464C26.2598 25.0527 26.1326 25 26 25H22C21.337 25 20.7011 24.7366 20.2322 24.2678C19.7634 23.7989 19.5 23.163 19.5 22.5C19.5 21.837 19.7634 21.2011 20.2322 20.7322C20.7011 20.2634 21.337 20 22 20H23V18H25V20H27.5V22H22C21.8674 22 21.7402 22.0527 21.6464 22.1464C21.5527 22.2402 21.5 22.3674 21.5 22.5C21.5 22.6326 21.5527 22.7598 21.6464 22.8536C21.7402 22.9473 21.8674 23 22 23H26C26.663 23 27.2989 23.2634 27.7678 23.7322C28.2366 24.2011 28.5 24.837 28.5 25.5C28.5 26.163 28.2366 26.7989 27.7678 27.2678C27.2989 27.7366 26.663 28 26 28H25V30H23V28H20.5V26Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_824_4154">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(12 12)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default profitSvg;
