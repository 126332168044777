import "antd/dist/antd.css";
import { Collapse, Form, Label, Input, FormFeedback } from "reactstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
const CollapseOne = ({ currentPage, validation }) => {
  const { t } = useTranslation();
  return (
    <Collapse in isOpen={currentPage === 0}>
      <div className="text-start mt-2 mx-4">
        <h5 className="text-dark">{t("createNewAccount")}</h5>
        <p className="mb-0">
          {t("haveAccount")}{" "}
          <Link
            to="/login"
            className="fw-semibold text-primary text-decoration"
          >
            {" "}
            <p style={{ display: "inline", color: "#EE856F" }}>{t("signIn")}</p>
          </Link>{" "}
        </p>
      </div>
      <div className="p-2 mt-4 mx-4">
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
          className="needs-validation"
          action="#"
        >
          <div className="mb-3">
            <Label htmlFor="company_name" className="form-label">
              {t("companyName")} <span className="text-danger">*</span>
            </Label>
            <div className="position-relative auth-pass-inputgroup mb-3">
              <Input
                id="company_name"
                name="company_name"
                type="text"
                className="form-control padding_input"
                placeholder={t("enterCompanyName")}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.company_name || ""}
                invalid={
                  validation.touched.company_name &&
                    validation.errors.company_name
                    ? true
                    : false
                }
              />
              {validation.touched.company_name &&
                validation.errors.company_name ? (
                <FormFeedback type="invalid">
                  <div>{t(validation.errors.company_name)}</div>
                </FormFeedback>
              ) : null}
              <button
                className="btn btn-link position-absolute start-0 top-0 text-decoration-none text-muted"
                type="button"
                disabled={true}
                id="password-addon"
              >
                <i className="ri-building-line"></i>
              </button>
            </div>
          </div>
          <div className="mb-3">
            <Label htmlFor="useremail" className="form-label">
              {t("Email")} <span className="text-danger">*</span>
            </Label>
            <div className="position-relative auth-pass-inputgroup mb-3">
              <Input
                id="email"
                name="email"
                className="form-control padding_input"
                placeholder={t("enterEmailAddress")}
                type="email"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.email || ""}
                invalid={
                  validation.touched.email && validation.errors.email
                    ? true
                    : false
                }
              />
              {validation.touched.email && validation.errors.email ? (
                <FormFeedback type="invalid">
                  <div>{t(validation.errors.email)}</div>
                </FormFeedback>
              ) : null}
              <button
                className="btn btn-link position-absolute start-0 top-0 text-decoration-none text-muted"
                type="button"
                disabled={true}
                id="password-addon"
              >
                <i className="ri-mail-line"></i>
              </button>
            </div>
          </div>

          <div className="mb-3">
            <Label htmlFor="username" className="form-label">
              {t("userName")}<span className="text-danger">*</span>
            </Label>
            <div className="position-relative auth-pass-inputgroup mb-3">
              <Input
                name="username"
                type="text"
                className="form-control padding_input"
                placeholder={t("enterUserName")}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.username || ""}
                invalid={
                  validation.touched.username && validation.errors.username
                    ? true
                    : false
                }
              />
              {validation.touched.username && validation.errors.username ? (
                <FormFeedback type="invalid">
                  <div>{t(validation.errors.username)}</div>
                </FormFeedback>
              ) : null}
              <button
                className="btn btn-link position-absolute start-0 top-0 text-decoration-none text-muted"
                type="button"
                disabled={true}
                id="password-addon"
              >
                <i className="ri-shield-user-line"></i>
              </button>
            </div>
          </div>

          <div className="mb-3">
            <Label htmlFor="mobile_number" className="form-label">
              {t("phoneNumber")} <span className="text-danger">*</span>
            </Label>
            <div className="position-relative auth-pass-inputgroup mb-3">
              <Input
                id="mobile_number"
                name="mobile_number"
                type="text"
                className="form-control padding_input"
                placeholder={t("enterPhoneNumber")}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.mobile_number || ""}
                invalid={
                  validation.touched.mobile_number &&
                    validation.errors.mobile_number
                    ? true
                    : false
                }
              />
              {validation.touched.mobile_number &&
                validation.errors.mobile_number ? (
                <FormFeedback type="invalid">
                  <div>{t(validation.errors.mobile_number)}</div>
                </FormFeedback>
              ) : null}
              <button
                className="btn btn-link position-absolute start-0 top-0 text-decoration-none text-muted"
                type="button"
                disabled={true}
                id="password-addon"
              >
                <i className="ri-phone-fill"></i>
              </button>
            </div>
          </div>

          <div className="mb-2 mt-4 d-flex justify-content-center  ">
            <button
              style={{
                backgroundColor: "#EE856F",
                color: "white",
                height: "45px",
              }}
              className="border-0 w-100 rounded"
              type="submit"
            >
              {t("sendOTP")}
            </button>
          </div>
        </Form>
      </div>
    </Collapse>
  );
};
export default CollapseOne;
