import React from "react";
import "antd/dist/antd.css";
import { Card, CardBody, Col, Collapse, Row } from "reactstrap";
import { FcCheckmark } from "react-icons/fc";
import Checkout from "../../../Components/Common/Checkout";

const CollapseTwo = ({
  collapseTwo,
  handleStepper,
  endDate,
  startDate,
  title,
  budget,
  productDetails,
}) => {
  const callback_url = `pay-per-click/?product=${productDetails.id
    }&start_date=${startDate}&end_date=${endDate}&budget=${budget}&title=${title.replace(
      / /g,
      "-"
    )}`;
  const currency = "$";
  return (
    <Collapse in isOpen={collapseTwo}>
      <Card className="bg-alice-blue h-100%">
        <CardBody className=" h-100% ">
          <h4 className="card-body mb-0">Billing and payment</h4>
          <p className="card-body color  text-sm  text-muted mb-0">
            Here you are! your billing. select card please to be able to
            complete the process.
          </p>
          <Row>
            <Col xl={8} className="table-responsive">
              <div className="mb-3">
                <span className="badge badge-soft-danger align-middle badge-span rounded-pill m-2">
                  <p className="badge-icon">{1}</p>
                </span>
                pay per click details
              </div>
              <table className="m-3 table table-centered  align-middle table-nowrap m-0 ">
                <thead>
                  <th className="border-0 m-2 fw-light">Pay per click title</th>
                  <th className="border-0 m-2 fw-light">Budget</th>
                  <th className="border-0 m-2 fw-light">Promotion payment date</th>
                </thead>
                <tbody className="bg-white">
                  <tr>
                    <td className="border-0">
                      <div className="form-check">{title}</div>
                    </td>
                    <td className="border-0">
                      <div className="badge badge-soft-danger align-middle rounded-pill">
                        {currency} {budget}
                      </div>
                    </td>
                    <td className="border-0">
                      <div className="form-check">
                        <span className="fw-medium">From </span> {startDate}{" "}
                        <span className="fw-medium ml-5"> To </span> {endDate}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>

              <table className="m-3 table table-centered align-middle table-nowrap m-0">
                <thead>
                  <th className="border-0 fw-light">Product details</th>
                  <th className="border-0 fw-light"> Push notification <strong>$12</strong></th>
                  <th className="border-0 fw-light"> Email marketing <strong>$10</strong></th>
                </thead>
                <tbody className="bg-white">
                  <tr>
                    <td className="border-0">
                      <div className="flex-shrink-0 me-3">
                        <div className="avatar-sm bg-light rounded p-1">
                          <img
                            src={productDetails.image}
                            className="img-fluid"
                            alt=""
                          />
                          <span className="fw-medium p-3">
                            {productDetails.name}
                          </span>
                        </div>
                      </div>
                    </td>
                    <td className="border-0">
                      <div className="ml-4">

                        <FcCheckmark
                          style={{
                            width: "24px",
                            height: "17.63px",
                            marginLeft: "2em",
                          }}
                        />
                      </div>
                    </td>
                    <td className="border-0">
                      <div className="">
                        <FcCheckmark
                          style={{
                            width: "24px",
                            height: "17.63px",
                            marginLeft: "2em",
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Col>
            <Col xl={4}>
              <div className="mb-3">
                <span className="badge badge-soft-danger align-middle badge-span rounded-pill  mr-2">
                  <p className="badge-icon">{2}</p>
                </span>{" "}
                Select your credit card:
              </div>
              <Checkout
                trigger={collapseTwo}
                amount={0}
                callback_url={callback_url}
                payment_type={"PA"}
                source={"pay_per_click"}
                save_card={"yes"}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Collapse>
  );
};
export default CollapseTwo;
