import { SEND_OTP, SEND_OTP_SUCCESS, SEND_OTP_FAIL, VERIFY_OTP, VERIFY_OTP_SUCCESS, VERIFY_OTP_FAIL,RESET_CHECK_OTP_FLAG } from "./actionTypes";

const initialState = {
  message: null,
  loading: false,
  otpSuccess: false,
  mobile_number: "",
  error: false,
  otpError:"",
};

const Otp = (state = initialState, action) => {
  switch (action.type) {
    case SEND_OTP:
      state = {
        ...state,
        mobile_number: action.payload.phone_number,
        loading: true,
      };
      break;
    case SEND_OTP_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;
    case SEND_OTP_FAIL:
      state = {
        ...state,
        user: null,
        loading: false,
        error: true,
      };
      break;



    case VERIFY_OTP:
      state = {
        ...state,
        loading: true,
      };
      break;
    case VERIFY_OTP_SUCCESS:
      state = {
        ...state,
        loading: false,
        otpSuccess: true,
      };
      break;
    case VERIFY_OTP_FAIL:
      state = {
        ...state,
        user: null,
        loading: false,
        otpError: "Wrong Otp Verification",
        otpSuccess: false,

      };
      break;

    case RESET_CHECK_OTP_FLAG:
      state = {
        ...state,
        otpError:"",
        otpSuccess: false,

      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Otp;
