import React, { useEffect } from "react";
import { Col, Row } from "reactstrap";
import DashboardCards from "../../Components/Common/DashboardCards";
import {
  getDailyCustomers,
} from "../../store/charts/action";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

import personSvg from "../../assets/images/dashboardIcons/personSvg";

import CustomersNumber3DChart from "./CustomersNumber3DChart";
import CustomersNumberChart from "./CustomersNumberChart";

const Customers = () => {
  const dispatch = useDispatch();

  const { actualDailyCustomers } = useSelector((state) => ({
    actualDailyCustomers: state.Charts.actualDailyCustomers,
  }));

  function disabledDate(current) {
    // Can not select days before today and today
    return current && current >= moment().endOf("year");
  }

  useEffect(() => {
    const params = {
      start_date: "2023-01-01",
      end_date: "2023-12-31",
      action: "day",
    };

    dispatch(getDailyCustomers(params));
  }, [dispatch]);



  return (
    <React.Fragment>
      <Row className="bg-alice-blue">
        <Col xl={12}>
          <div className="d-md-flex gap-4 justify-content-center justify-content-sm-between py-4   ">
            <DashboardCards
              iconComponent={personSvg("#367868")}
              text={"Customers"}
              amount={actualDailyCustomers?.current_day || 0}
              title={"Customers / Current Day"}
              fullWidth="cards-full-width"
            />
            <DashboardCards
              iconComponent={personSvg("#4E5AA8")}
              text={"Customers"}
              amount={actualDailyCustomers?.current_month || 0}
              title={"Customers / Current Month"}
              fullWidth="cards-full-width"
            />
            <DashboardCards
              iconComponent={personSvg("#EE907B")}
              text={"Customers"}
              amount={actualDailyCustomers?.current_year}
              title={"Customers / Current Year"}
              fullWidth="cards-full-width"
            />
          </div>
          <CustomersNumberChart
            disabledDate={disabledDate}
          />
          <CustomersNumber3DChart />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Customers;
