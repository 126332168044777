import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import MsgToast from "../../../Components/Common/MsgToast";
import "react-simple-tree-menu/dist/main.css";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Row,
  Input,
  Label,
} from "reactstrap";
import { config } from "../../../config";

import { Link, useHistory, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import * as Yup from "yup";
// Import React FilePond
import { registerPlugin } from "react-filepond";
// Import FilePond styles
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import {
  resetEcomFlag,
  getOneProductData,
  getProductsData,
  updateProductData,
  getUploaderLink,
  uploadImage,
} from "../../../store/actions";
import validator from "../../../helpers/Validator";
import ConfirmationModal from "../../../Components/Common/ConfirmationModal";

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);
const validationSchema = Yup.object().shape({
  product_name: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required")
    .matches(validator.titleValidation, "Input a valid title"),
  description: Yup.string().required("Required").min(2, "Too Short!").max(200, "Too Long!"),

  price: Yup.string()
    .matches(/^\d{1,6}(\.\d{1,2})?$/, 'Input a valid number with up to two decimal places or an integer')
    .test('min-value', 'Too Low!', function (value) {
      const minValue = 1;
      if (value && parseFloat(value) < minValue) {
        return false;
      }
      return true;
    })
    .test('max-value', 'Too High!', function (value) {
      const maxValue = 999999.99;
      if (value && parseFloat(value) > maxValue) {
        return false;
      }
      return true;
    })
    .required('Required'),



  product_url: Yup.string()
    .required("Required")
    .matches(
      validator.urlValidation,
      "Input a valid url that starts with http"
    ),
    reference_id: Yup.string()
    .required("Required")
    .min(1, "Too Short!")
    .max(50, "Too Long!"),
  short_description: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),

  product_image: Yup.mixed()
    .required("Required")

    .test("fileSize", "Maximum image size is 5 mb", (value) => {
      if (typeof value === "string") {
        return true;
      } else {
        return value && value.size <= validator.FILE_SIZE;
      }
    })
    .test("fileFormat", "Unsupported Format", (value) => {
      if (typeof value === "string") {
        return true;
      } else {
        return value && validator.SUPPORTED_FORMATS.includes(value.type);
      }
    }),
});
const { LOADER_RESET_TIMER } = config;
const { TOAST_AUTOCLOSE_TIMER } = config;
const { currency } = config;

const EcommerceUpdateProduct = () => {
  const History = useHistory();
  const dispatch = useDispatch();
  const [image, setImage] = useState([]);

  const { oneProduct, imageUploadLink, imageUpload, imageUploadError } =
    useSelector((state) => ({
      oneProduct: state.Ecommerce.oneProduct,
      imageUploadLink: state.Ecommerce.imageUploadLink,
      imageUpload: state.Ecommerce.imageUpload,
      imageUploadError: state.Ecommerce.imageUploadError,
    }));

  let { id } = useParams();
  useEffect(() => {
    setProductId(id);
    dispatch(getOneProductData(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (formik.values !== oneProduct) {
      formik.setValues(oneProduct);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oneProduct]);

  useEffect(() => {
    if (imageUpload) {
      let productImageUrl;
      imageUploadLink.forEach((item, index) => {
        if (item.type === "product_image") {
          productImageUrl = item["link"].substring(
            0,
            item["link"].indexOf("?")
          );
        }
      });
      let formData = new FormData();
      formData.append("id", productId);
      formData.append("product_name", formik.values.product_name);
      formData.append("description", formik.values.description);
      formData.append("short_description", formik.values.short_description);
      formData.append("price", formik.values.price);
      formData.append("product_url", formik.values.product_url);
      formData.append("reference_id", formik.values.reference_id);
      formData.append("product_image", productImageUrl);

      dispatch(updateProductData(formData));
      dispatch(getProductsData(1));
      History.push("/products");
    }
    if (imageUploadError) {
      toast("An error accord while uploading image", {
        position: "top-right",
        hideProgressBar: true,
        className: "bg-danger text-white",
        progress: undefined,
        toastId: "",
      });
      setConfirmationModal(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageUpload]);

  useEffect(() => {
    setTimeout(() => {
      dispatch(resetEcomFlag());
    }, LOADER_RESET_TIMER);
  }, [dispatch, imageUpload, imageUploadError]);
  const formik = useFormik({
    initialValues: {
      product_name: "",
      description: "",
      short_description: "",
      product_image: "",
      price: "",
      product_url: "",
    },
    validationSchema,
    onSubmit: () => {
      handleShowConfirmationModal();
    },
  });

  const handleUpload = (value, type) => {
    let object = {};
    const file_type = value.type.split("/").pop();
    object[type] = { file: value, fileType: file_type };
    setImage([...image, object]);
    const params = {
      file_type: file_type,
      product_action: "add",
    }
    dispatch(getUploaderLink(params, type));
  }

  const handleUpdateProduct = () => {
    if (imageUploadLink.length === 0) {
      let formData = new FormData();
      formData.append("id", productId);
      formData.append("product_name", formik.values.product_name);
      formData.append("description", formik.values.description);
      formData.append("short_description", formik.values.short_description);
      formData.append("price", formik.values.price);
      formData.append("product_url", formik.values.product_url);
      formData.append("reference_id", formik.values.reference_id);
      formData.append("product_image", oneProduct.product_image);

      dispatch(updateProductData(formData));
      dispatch(getProductsData(1));
      History.push("/products");
    }

    let data;

    imageUploadLink.forEach((item, index) => {
      data = {
        url: item.link,
        image: image[index][item.type]["file"],
        imageType: image[index][item.type]["fileType"],
      };
      dispatch(uploadImage(data));
    });
  };

  const [inValidCreate, setInValidCreate] = useState(false);
  const [validCreate, setValidCreate] = useState(false);
  const [productId, setProductId] = useState("");
  const [confirmationModal, setConfirmationModal] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setInValidCreate(false);
      setValidCreate(false);
    }, 3000);
  }, [validCreate, inValidCreate]);

  const handleDeleteMainImage = () => {
    formik.setFieldValue("product_image", null);
  };
  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
  const handleShowConfirmationModal = () => {
    setConfirmationModal(true);
  };

  document.title = "Update Product | Salis- Merchants Dashboard";
  const handleNotValidForm = (e) => {
    e.preventDefault();
    setInValidCreate(true);
    formik.touched.price = true;
    formik.touched.description = true;
    formik.touched.short_description = true;
    formik.touched.product_name = true;
    formik.touched.product_image = true;
    formik.touched.product_url = true;
    formik.touched.reference_id = true;
  };
  return (
    <React.Fragment>
      <ToastContainer
        autoClose={TOAST_AUTOCLOSE_TIMER}
        style={{ width: "auto" }}
      />
      <ConfirmationModal
        show={confirmationModal}
        onCloseClick={() => setConfirmationModal(false)}
        message={"Yor are going to update the product! "}
        action={handleUpdateProduct}
      />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Products / Update Product" pageTitle="Marketing" />
          {<Link to="/products" className="text-muted font-weight-bold"></Link>}
          <Row>
            <Col lg={8}>
              <form
                method="post"
                onSubmit={(e) =>
                  formik.isValid
                    ? formik.handleSubmit(e)
                    : handleNotValidForm(e)
                }
              >
                <Card>
                  {inValidCreate && (
                    <MsgToast
                      msg="Invalid Fields"
                      color="danger"
                      icon="ri-checkbox-circle-line"
                    />
                  )}

                  <CardBody>
                    <div className="mb-3">
                      <label htmlFor="title">Product Title*</label>
                      <input
                        id="product_name"
                        type="text"
                        placeholder="Enter your title"
                        value={formik.values.product_name || ""}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={
                          formik.errors.product_name &&
                          formik.touched.product_name
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                      />
                      {formik.errors.product_name &&
                      formik.touched.product_name ? (
                        <div className="text-danger">
                          {formik.errors.product_name}
                        </div>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label>Product Description*</Label>

                      <Input
                        id="description"
                        type="textarea"
                        placeholder="Enter your description"
                        value={formik.values.description || ""}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={
                          formik.errors.description &&
                          formik.touched.description
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                      />
                      {formik.errors.description &&
                      formik.touched.description ? (
                        <div className="text-danger">
                          {formik.errors.description}
                        </div>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Product Url*</Label>
                      <div className="input-group">
                        <Input
                          id="product_url"
                          type="text"
                          placeholder="Example :https://yourstore.com/"
                          value={formik.values.product_url || ""}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className={
                            formik.errors.product_url &&
                            formik.touched.product_url
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                        />
                      </div>

                      {formik.errors.product_url &&
                      formik.touched.product_url ? (
                        <div className="text-danger">
                          {formik.errors.product_url}
                        </div>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <Label>Product Reference ID*</Label>
                      <div className="input-group">
                        <Input
                          id="reference_id"
                          type="text"
                          placeholder="Enter product reference ID"
                          value={formik.values.reference_id || ""}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          className={
                            formik.errors.reference_id &&
                              formik.touched.reference_id
                              ? "form-control is-invalid"
                              : "form-control"
                          }
                        />
                      </div>

                      {formik.errors.reference_id &&
                        formik.touched.reference_id ? (
                        <div className="text-danger">
                          {formik.errors.reference_id}
                        </div>
                      ) : null}
                    </div>
                  </CardBody>
                </Card>

                <Card>
                  <CardHeader>
                    <h5 className="card-title mb-0">Product Gallery</h5>
                  </CardHeader>
                  <CardBody>
                    <div className="mb-4">
                      <h5 className="fs-14 mb-1">Product Image*</h5>
                      <div>
                        <>
                          <p className="text-muted">Add Product main Image.</p>
                          {formik.values.product_image ? (
                            <div className="list-unstyled mb-0" id="file-previews">
                              <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                                <div className="p-2">
                                  <Row className="align-items-center">
                                    <a
                                      style={{ display: "contents" }}
                                      href={
                                        formik.values.product_image?.preview ||
                                        `${oneProduct.product_image}?${oneProduct.updated_at}`
                                      }
                                      target={"_blank"}
                                      rel="noreferrer"
                                    >
                                      <Col className="col-auto px-3">
                                        <img
                                          data-dz-thumbnail=""
                                          height="80"
                                          className="avatar-sm rounded bg-light"
                                          src={
                                            formik.values.product_image?.preview ||
                                            `${oneProduct.product_image}?${oneProduct.updated_at}`
                                          }
                                          alt=""
                                        />
                                      </Col>
                                    </a>

                                    <Col>
                                      {formik.values.product_image.name}
                                      <p className="mb-0">
                                        <strong>
                                          {formik.values.product_image.formattedSize}
                                        </strong>
                                      </p>
                                    </Col>
                                    <Col lg={"auto"}>
                                      <Button
                                        className="btn btn-soft-danger"
                                        id="product_image"
                                        onBlur={formik.handleBlur}
                                        onClick={handleDeleteMainImage}
                                      >
                                        <i className="ri-delete-bin-2-line"></i>
                                      </Button>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            </div>
                          ) : (
                            <input
                              accept=".png,.jpeg,.jpg,.gif"
                              id="product_image"
                              type="file"
                              onChange={(event) => {
                                Object.assign(event.currentTarget.files[0], {
                                  preview: URL.createObjectURL(
                                    event.currentTarget.files[0]
                                  ),
                                  formattedSize: formatBytes(
                                    event.currentTarget.files[0].size
                                  ),
                                });
                                formik.setFieldValue(
                                  "product_image",
                                  event.currentTarget.files[0]
                                );
                                handleUpload(event.currentTarget.files[0], "product_image");
                              }}
                              onBlur={formik.handleBlur}
                              className={
                                formik.errors.product_image &&
                                  formik.touched.product_image
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                            />
                          )}
                        </>
                      </div>

                    </div>
                    {formik.errors.product_image &&
                      formik.touched.product_image ? (
                      <div className="text-danger">
                        {formik.errors.product_image}
                      </div>
                    ) : null}
                  </CardBody>
                </Card>
                <div className="text-start mb-3">
                  <button
                    type="submit"
                    className="btn btn bg-light-red text-white w-sm"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </Col>

            <Col lg={4}>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Price*</h5>
                </CardHeader>
                <CardBody>
                  <div className="mb-3">
                    <Label className="form-label" htmlFor="product-price-input">
                      Add your product price
                    </Label>
                    <div className="input-group mb-3">
                      <span
                        className="input-group-text"
                        id="product-price-addon"
                      >
                        {currency}
                      </span>
                      <input
                        id="price"
                        type="text"
                        placeholder="Enter sale price"
                        value={formik.values.price || ""}
                        aria-label="Price"
                        aria-describedby="product-price-addon"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={
                          formik.errors.price && formik.touched.price
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                      />
                    </div>
                    {formik.errors.price && formik.touched.price ? (
                      <div className="text-danger">{formik.errors.price}</div>
                    ) : null}
                  </div>
                </CardBody>
              </Card>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">
                    Product Short Description*
                  </h5>
                </CardHeader>
                <CardBody>
                  <p className="text-muted mb-2">
                    Add short description for product
                  </p>
                  <Input
                    id="short_description"
                    type="textarea"
                    placeholder="Must enter maximum of a 50 characters"
                    value={formik.values.short_description || ""}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className={
                      formik.errors.short_description &&
                      formik.touched.short_description
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                  />
                  {formik.errors.short_description &&
                  formik.touched.short_description ? (
                    <div className="text-danger">
                      {formik.errors.short_description}
                    </div>
                  ) : null}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EcommerceUpdateProduct;
