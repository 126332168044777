import React from "react";
import { Label, Input } from "reactstrap";

import Multiselect from "multiselect-react-dropdown";
import webhookInfo from "../../../assets/images/webhook_info.svg";
import { useTranslation } from "react-i18next";

const WebhookForm = ({ setWebhookModal, selected, setSelected, formik }) => {
  const { t } = useTranslation();
  const options = [
    { name: "order_approved" },
    { name: "order_declined" },
    { name: "order_authorized" },
    { name: "order_canceled" },
    { name: "order_captured" },
    { name: "order_refunded" },
    { name: "order_expired" },
  ];

  const handleNotValidForm = (e) => {
    e.preventDefault();
    formik.touched.url = true;
  };
  const addEvent = (selectedList, selectedItem) => {
    setSelected(selectedList);
  };
  const removeEvent = (selectedList, selectedItem) => {
    setSelected(selectedList);
  };
  const optionDecorator = (label) => {
    return t(label);
  };

  const handleRev = (name) => {
    selected = selected.filter(function (obj) {
      return obj.name !== name;
    });
    setSelected(selected);
  };
  return (
    <form
      className="w-md-33-webhook"
      method="post"
      data-testid="form"
      onSubmit={(e) =>
        formik.isValid ? formik.handleSubmit(e) : handleNotValidForm(e)
      }
    >
      <div className="mb-3">
        <Label>
          <h6>
            {t("addWebhookUrl")}*
            <img
              className="cursor-pointer"
              onClick={() => setWebhookModal(true)}
              src={webhookInfo}
              alt="webhook"
            />
          </h6>
        </Label>
        <div className="input-group">
          <Input
            id="url"
            type="text"
            data-testid="events-input"
            placeholder="http://example.com"
            value={formik.values.url || ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className={
              formik.errors.url && formik.touched.url
                ? "form-control is-invalid  mb-4"
                : "form-control  mb-4"
            }
          />
        </div>

        {formik.errors.url && formik.touched.url ? (
          <div className="text-danger">{formik.errors.url}</div>
        ) : null}
      </div>

      <div className="mb-3">
        <Label htmlFor="events">
          <h6> {t("selectEvents")}*</h6>
        </Label>
        <Multiselect
          options={options}
          displayValue="name"
          hideSelectedList={true}
          onSelect={addEvent}
          selectedValues={selected}
          onRemove={removeEvent}
          optionValueDecorator={optionDecorator}
          showCheckbox
          showArrow
          avoidHighlightFirstOption={true}
        />
      </div>
      <div>
        {selected?.map((element, index) => (
          <ul className="d-inline-flex p-1 mb-0" key={index}>
            <div className="bg-alice-blue rounded-2 p-2">
              <li className="d-inline-flex">{t(element.name)}</li>
              <i
                className="ri-close-line px-1 align-middle text-black fw-bold text-muted fs-18 cursor-pointer"
                onClick={() => {
                  handleRev(element.name);
                }}
              ></i>
            </div>
          </ul>
        ))}
      </div>
      <div className="mt-5 d-center">
        <button
          type="submit"
          disabled={selected.length === 0}
          className="btn mt-5 bg-light-red text-white w-50 "
        >
          {t("addService")}
        </button>
      </div>
    </form>
  );
};

export default WebhookForm;
