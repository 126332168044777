import React from "react";
import { Line, Bar, Pie, Doughnut, Polar, Radar } from "react-chartjs-2";
function getChartColorsArray(colors) {
  colors = JSON.parse(colors);
  return colors.map(function (value) {
    var newValue = value.replace(" ", "");
    if (newValue.indexOf(",") === -1) {
      var color = getComputedStyle(document.documentElement).getPropertyValue(
        newValue
      );
      if (color.indexOf("#") !== -1) color = color.replace(" ", "");
      if (color) return color;
      else return newValue;
    } else {
      var val = value.split(",");
      if (val.length === 2) {
        var rgbaColor = getComputedStyle(
          document.documentElement
        ).getPropertyValue(val[0]);
        rgbaColor = "rgba(" + rgbaColor + "," + val[1] + ")";
        return rgbaColor;
      } else {
        return newValue;
      }
    }
  });
}

const LineChart = ({
  dataColors,
  label,
  Month,
  Sales,
  dataFlag,
  xLabel,
  yLabel,
  colorFrom,
  colorTo,
}) => {
  const barChartColor = getChartColorsArray(dataColors);
  window["barChartColor"] = barChartColor;
  const data = (canvas) => {
    const ctx = canvas.getContext("2d");
    const gradient = ctx.createLinearGradient(0, 0, 0, 300);
    gradient.addColorStop(0, colorFrom || "black");
    gradient.addColorStop(1, colorTo || "black");
    return {
      labels: Month,
      datasets: [
        {
          label: label,
          backgroundColor: gradient, // Put the gradient here as a fill color
          borderWidth: 2,
          data: Sales,
          fill: false,
          borderColor: gradient,
        },
      ],
    };
  };

  const option = {
    scales: {
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: yLabel,
          },
          gridLines: {
            display: dataFlag
          },
          ticks: {
            beginAtZero: true
          }

        },
      ],
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: xLabel,
          },
          gridLines: {
            display: dataFlag
          },
          ticks: {
            beginAtZero: true
          }
        },
      ],
    },
    x: {
      ticks: {
        font: {
          family: "Poppins",
        },
      },
    },
    y: {
      ticks: {
        font: {
          family: "Poppins",
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          // This more specific font property overrides the global property
          font: {
            family: "Poppins",
          },
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  };
  return (
    <React.Fragment>
      <Line width={723} height={320} data={data} options={option} />
    </React.Fragment>
  );
};

//Bar Chart

const BarChart = ({
  dataColors,
  label,
  Month,
  Sales,
  xLabel,
  dataFlag,
  currency,
  yLabel,
  colorFrom,
  colorTo,
}) => {
  const barChartColor = getChartColorsArray(dataColors);
  window["barChartColor"] = barChartColor;
  const data = (canvas) => {
    const ctx = canvas.getContext("2d");
    const gradient = ctx.createLinearGradient(0, 0, 0, 300);
    gradient.addColorStop(0, colorFrom || "black");
    gradient.addColorStop(1, colorTo || "black");

    return {
      labels: Month,
      datasets: [
        {
          label: label,
          backgroundColor: gradient, // Put the gradient here as a fill color
          borderWidth: 2,
          data: Sales,
        },
      ],
    };
  };

  const option = {
    tooltips: {
      callbacks: {
        label: function(tooltipItem, data) {
          var value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          if (currency) {
            return  value + " " + currency ;
          } else {
            return value;
          }
        }
      }
    },
    scales: {
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: yLabel,
          },
          gridLines: {
            display: dataFlag
          },
          ticks: {
            beginAtZero: true
          }

        },
      ],
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: xLabel,
          },
          gridLines: {
            display: dataFlag
          },
          ticks: {
            beginAtZero: true
          }
        },
      ],
    },
    x: {
      ticks: {
        font: {
          family: "Poppins",
        },
      },
    },
    y: {
      ticks: {
        font: {
          family: "Poppins",
        },
      },
    },
    plugins: {
      legend: {
        labels: {
          font: {
            family: "Poppins",
          },
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  };
  return (
    <React.Fragment>
      <Bar width={723} height={320} data={data} options={option} />
    </React.Fragment>
  );
};

//Pie Chart
const PieChart = ({ dataColors }) => {
  var pieChartColors = getChartColorsArray(dataColors);
  const data = {
    labels: ["Desktops", "Tablets"],
    datasets: [
      {
        data: [300, 180],
        backgroundColor: pieChartColors,
        hoverBackgroundColor: pieChartColors,
        hoverBorderColor: "#fff",
      },
    ],
  },
    option = {
      plugins: {
        legend: {
          labels: {
            font: {
              family: "Poppins",
            },
          },
        },
      },
    };
  return (
    <React.Fragment>
      <Pie width={723} height={320} data={data} options={option} />
    </React.Fragment>
  );
};

//Donut Chart
const DonutChart = ({ dataColors }) => {
  var doughnutChartColors = getChartColorsArray(dataColors);
  const data = {
    labels: ["Desktops", "Tablets"],
    datasets: [
      {
        data: [300, 210],
        backgroundColor: doughnutChartColors,
        hoverBackgroundColor: doughnutChartColors,
        hoverBorderColor: "#fff",
      },
    ],
  },
    option = {
      plugins: {
        legend: {
          labels: {
            font: {
              family: "Poppins",
            },
          },
        },
      },
    };
  return (
    <React.Fragment>
      <Doughnut width={723} height={320} data={data} options={option} />
    </React.Fragment>
  );
};

//Polar Chart
const PolarChart = ({ dataColors }) => {
  var polarAreaChartColors = getChartColorsArray(dataColors);
  const data = {
    labels: ["Series 1", "Series 2", "Series 3", "Series 4"],
    datasets: [
      {
        data: [11, 16, 7, 18],
        backgroundColor: polarAreaChartColors,
        label: "My dataset", // for legend
        hoverBorderColor: "#fff",
      },
    ],
  };
  const option = {
    plugins: {
      legend: {
        labels: {
          font: {
            family: "Poppins",
          },
        },
      },
    },
  };
  return (
    <React.Fragment>
      <Polar width={723} height={320} data={data} options={option} />
    </React.Fragment>
  );
};

//Radar Chart
const RadarChart = ({ dataColors }) => {
  var radarChartColors = getChartColorsArray(dataColors);
  const data = {
    labels: [
      "Eating",
      "Drinking",
      "Sleeping",
      "Designing",
      "Coding",
      "Cycling",
      "Running",
    ],
    datasets: [
      {
        label: "Desktops",
        backgroundColor: radarChartColors[0],
        borderColor: radarChartColors[1], //"#2ab57d",
        pointBackgroundColor: radarChartColors[1], //"#2ab57d",
        pointBorderColor: "#fff",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: radarChartColors[1], //"#2ab57d",
        data: [65, 59, 90, 81, 56, 55, 40],
      },
      {
        label: "Tablets",
        backgroundColor: radarChartColors[2], //"rgba(81, 86, 190, 0.2)",
        borderColor: radarChartColors[3], //"#5156be",
        pointBackgroundColor: radarChartColors[3], //"#5156be",
        pointBorderColor: "#fff",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: radarChartColors[3], //"#5156be",
        data: [28, 48, 40, 19, 96, 27, 100],
      },
    ],
  },
    option = {
      plugins: {
        legend: {
          labels: {
            font: {
              family: "Poppins",
            },
          },
        },
      },
    };
  return (
    <React.Fragment>
      <Radar width={723} height={320} data={data} options={option} />
    </React.Fragment>
  );
};

export { LineChart, BarChart, PieChart, DonutChart, PolarChart, RadarChart };
