import {
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,
  DELETE_PRODUCT,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAIL,
  RESET_ECOM_FLAG,
  RESET_LOADER_FLAG,
  GET_PRODUCTS_DATA,
  GET_PRODUCTS_DATA_SUCCESS,
  GET_PRODUCTS_DATA_FAIL,
  GET_ONE_PRODUCTS_DATA,
  ADD_PRODUCT_DATA,
  ADD_PRODUCT_DATA_SUCCESS,
  ADD_PRODUCT_DATA_FAIL,
  UPDATE_ONE_PRODUCT_DATA,
  UPDATE_ONE_PRODUCT_DATA_SUCCESS,
  UPDATE_ONE_PRODUCT_DATA_FAIL,
  UPDATE_CURRENT_PRODUCT_STATE,
  GET_ORDERS_DATA,
  GET_ORDERS_DATA_SUCCESS,
  GET_ORDERS_DATA_FAIL,
  UPDATE_ORDERS_DATA,
  GET_ONE_ORDERS_DATA,
  GET_SPECIAL_DEALS_DATA,
  ADD_SPECIAL_DEALS,
  SPECIAL_DEALS_settings,
  SPECIAL_AVG_MAX_SETTINGS,
  GET_CARDS,
  PRE_ADD_CARD,
  ADD_CARD,
  PRE_CHANGE_CARD,
  GET_CARD_TOKEN,
  CHANGE_CARD,
  DELETE_CARD,
  PRE_CHECKOUT,
  CHECKOUT,
  CHECKOUT_SUCCESS,
  CHECKOUT_FAIL,
  IN_STORE_ORDER,
  IN_STORE_ORDER_SUCCESS,
  IN_STORE_ORDER_FAIL,
  ADD_ORDER_RATING,
  ADD_ORDER_RATING_SUCCESS,
  ADD_ORDER_RATING_FAIL,
  GET_BANKS,
  GET_BANK_ACCOUNTS,
  DELETE_BANK_ACCOUNT,
  ADD_BANK_ACCOUNT,
  CHANGE_BANK_ACCOUNT,
  CANCEL_ORDER,
  CANCEL_ORDER_SUCCESS,
  CANCEL_ORDER_FAIL,
  ADD_BANK_ACCOUNT_FAIL,
  ADD_BANK_ACCOUNT_SUCCESS,
  CHANGE_BANK_ACCOUNT_FAIL,
  CHANGE_BANK_ACCOUNT_SUCCESS,
  GET_WALLET,
  GET_WALLET_SUCCESS,
  GET_WALLET_FAIL,
  GET_ITEMS,
  GET_ITEMS_SUCCESS,
  GET_ITEMS_FAIL,
  GET_CAPTURES,
  GET_CAPTURES_SUCCESS,
  GET_CAPTURES_FAIL,
  REFUND_PAYMENT,
  REFUND_PAYMENT_SUCCESS,
  REFUND_PAYMENT_FAIL,
  CAPTURE_PAYMENT,
  CAPTURE_PAYMENT_SUCCESS,
  CAPTURE_PAYMENT_FAIL,
  REGISTER_WEBHOOK,
  REGISTER_WEBHOOK_SUCCESS,
  REGISTER_WEBHOOK_FAIL,
  GET_WEBHOOK,
  GET_WEBHOOK_SUCCESS,
  GET_WEBHOOK_FAIL,
  DELETE_WEBHOOK,
  DELETE_WEBHOOK_SUCCESS,
  DELETE_WEBHOOK_FAIL,
  GET_SETTLEMENTS,
  GET_SETTLEMENTS_SUCCESS,
  GET_SETTLEMENTS_FAIL,
  GET_TRANSACTION,
  GET_TRANSACTION_SUCCESS,
  GET_TRANSACTION_FAIL,
  PRE_UPLOAD_IMAGE,
  PRE_UPLOAD_IMAGE_SUCCESS,
  PRE_UPLOAD_IMAGE_FAIL,
  UPLOAD_IMAGE,
  UPLOAD_IMAGE_SUCCESS,
  UPLOAD_IMAGE_FAIL,
  WITHDRAW,
  WITHDRAW_SUCCESS,
  WITHDRAW_FAILED,
  PayPerClick,
  PayPerClick_SUCCESS,
  PayPerClick_FAILED,
  GET_ONE_SETTLEMENTS,
  GET_ONE_SETTLEMENTS_SUCCESS,
  GET_ONE_SETTLEMENTS_FAIL,
  GET_PPC_DATA,
  GET_PPC_DATA_SUCCESS,
  GET_PPC_DATA_FAIL,
  CANCEL_PPC_FAIL,
  CANCEL_PPC_SUCCESS,
  CANCEL_PPC,
  GET_PAYMENT_TYPES_FAILED,
  GET_PAYMENT_TYPES_SUCCESS,
  GET_PAYMENT_TYPES,
} from "./actionType";



//items
// common success
export const ecommerceApiResponseSuccess = (actionType, data) => ({
  type: API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const ecommerceApiResponseError = (actionType, error) => ({
  type: API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const ORDER_RATING = "ORDER_RATING";
export const ORDER_RATING_SUCCESS = "ORDER_RATING_SUCCESS";
export const ORDER_RATING_FAIL = "ORDER_RATING_FAIL";

export const resetEcomFlag = () => {
  return {
    type: RESET_ECOM_FLAG,
  };
};
export const resetLoaderFlag = () => {
  return {
    type: RESET_LOADER_FLAG,
  };
};

export const getProductsData = (data) => ({
  type: GET_PRODUCTS_DATA,
  payload: data,
});

export const getProductsDataSuccess = (data) => ({
  type: GET_PRODUCTS_DATA_SUCCESS,
  payload: data,
});

export const getProductsDataFail = (error) => ({
  type: GET_PRODUCTS_DATA_FAIL,
  payload: error,
});

export const getOneProductData = (data) => ({
  type: GET_ONE_PRODUCTS_DATA,
  payload: data,
});

export const updateProductData = (data) => ({
  type: UPDATE_ONE_PRODUCT_DATA,
  payload: data,
});
export const updateProductDataSuccess = (data) => ({
  type: UPDATE_ONE_PRODUCT_DATA_SUCCESS,
  payload: data,
});
export const updateProductDataFail = (data) => ({
  type: UPDATE_ONE_PRODUCT_DATA_FAIL,
  payload: data,
});

export const addProductData = (data) => ({
  type: ADD_PRODUCT_DATA,
  payload: data,
});

export const addProductDataSuccess = (data) => ({
  type: ADD_PRODUCT_DATA_SUCCESS,
  payload: data,
});

export const addProductDataFail = (error) => ({
  type: ADD_PRODUCT_DATA_FAIL,
  payload: error,
});

export const deleteProducts = (product) => ({
  type: DELETE_PRODUCT,
  payload: product,
});

export const deleteProductSuccess = (product) => ({
  type: DELETE_PRODUCT_SUCCESS,
  payload: product,
});

export const deleteProductFail = (error) => ({
  type: DELETE_PRODUCT_FAIL,
  payload: error,
});

export const updateCurrentProductState = (product) => ({
  type: UPDATE_CURRENT_PRODUCT_STATE,
  payload: product,
});

// ORDERS SALIS
export const getOrdersData = (data) => ({
  type: GET_ORDERS_DATA,
  payload: data,
});

export const getOneOrdersData = (data) => ({
  type: GET_ONE_ORDERS_DATA,
  payload: data,
});

export const getOrdersDataSuccess = (data) => ({
  type: GET_ORDERS_DATA_SUCCESS,
  payload: data,
});

export const getOrdersDataFail = (error) => ({
  type: GET_ORDERS_DATA_FAIL,
  payload: error,
});

export const updateOrdersData = (data) => ({
  type: UPDATE_ORDERS_DATA,
  payload: data,
});

// Special Deals
export const getSpecialDeals = (data) => ({
  type: GET_SPECIAL_DEALS_DATA,
  payload: data,
});
export const addSpecialDeals = (data) => ({
  type: ADD_SPECIAL_DEALS,
  payload: data,
});
export const getSpecialDealsSettings = (data) => ({
  type: SPECIAL_DEALS_settings,
  payload: data,
});
export const getSpecialDealsAvgMaxSettings = (data) => ({
  type: SPECIAL_AVG_MAX_SETTINGS,
  payload: data,
});

export const getCards = (data) => ({
  type: GET_CARDS,
  payload: data,
});

export const preAddCard = (data) => ({
  type: PRE_ADD_CARD,
  payload: data,
});
export const addCard = (data) => ({
  type: ADD_CARD,
  payload: data,
});

export const preChangeCard = (data) => ({
  type: PRE_CHANGE_CARD,
  payload: data,
});

export const getCardToken = (data) => ({
  type: GET_CARD_TOKEN,
  payload: data,
});

export const changeCard = (data) => ({
  type: CHANGE_CARD,
  payload: data,
});

export const deleteCard = (data) => ({
  type: DELETE_CARD,
  payload: data,
});

export const preCheckout = (data) => ({
  type: PRE_CHECKOUT,
  payload: data,
});
export const checkout = (data) => ({
  type: CHECKOUT,
  payload: data,
});

export const checkoutSuccess = (data) => ({
  type: CHECKOUT_SUCCESS,
  payload: data,
});

export const checkoutFail = (error) => ({
  type: CHECKOUT_FAIL,
  payload: error,
});


export const addInStoreOrder= (data) => ({
  type: IN_STORE_ORDER,
  payload: data,
});

export const addInStoreOrderSuccess = (data) => ({
  type: IN_STORE_ORDER_SUCCESS,
  payload: data,
});

export const addInStoreOrderFail = (error) => ({
  type: IN_STORE_ORDER_FAIL,
  payload: error,
});

//rate
export const addOrderRate = (data) => ({
  type: ADD_ORDER_RATING,
  payload: data,
});

export const addOrderRateSuccess = (data) => ({
  type: ADD_ORDER_RATING_SUCCESS,
  payload: data,
});

export const addOrderRateFail = (error) => ({
  type: ADD_ORDER_RATING_FAIL,
  payload: error,
});
export const getBanks = (data) => ({
  type: GET_BANKS,
  payload: data,
});

export const getBankAccounts = (data) => ({
  type: GET_BANK_ACCOUNTS,
  payload: data,
});

export const deleteBankAccount = (data) => ({
  type: DELETE_BANK_ACCOUNT,
  payload: data,
});
export const addBankAccount = (data) => ({
  type: ADD_BANK_ACCOUNT,
  payload: data,
});
export const addBankAccountSuccess = (data) => ({
  type: ADD_BANK_ACCOUNT_SUCCESS,
  payload: data,
});
export const addBankAccountFail = (error) => ({
  type: ADD_BANK_ACCOUNT_FAIL,
  payload: error,
});
export const changeBankAccount = (data) => ({
  type: CHANGE_BANK_ACCOUNT,
  payload: data,
});
export const changeBankAccountSuccess = (data) => ({
  type: CHANGE_BANK_ACCOUNT_SUCCESS,
  payload: data,
});
export const changeBankAccountFail = (error) => ({
  type: CHANGE_BANK_ACCOUNT_FAIL,
  payload: error,
});

//cancel order
export const cancelOrder = (data) => ({
  type: CANCEL_ORDER,
  payload: data,
});

export const cancelOrderSuccess = (data) => ({
  type: CANCEL_ORDER_SUCCESS,
  payload: data,
});

export const cancelOrderFail = (error) => ({
  type: CANCEL_ORDER_FAIL,
  payload: error,
});

//wallet

export const getWallet = (data) => ({
  type: GET_WALLET,
  payload: data,
});

export const getWalletSuccess = (data) => ({
  type: GET_WALLET_SUCCESS,
  payload: data,
});

export const getWalletFail = (error) => ({
  type: GET_WALLET_FAIL,
  payload: error,
});


export const getOrderItem = (data) => ({
  type: GET_ITEMS,
  payload: data,
});

export const getOrderItemSuccess = (data) => ({
  type: GET_ITEMS_SUCCESS,
  payload: data,
});

export const getOrderItemFail = (error) => ({
  type: GET_ITEMS_FAIL,
  payload: error,
});  

export const getOrderCaptures = (data) => ({
  type: GET_CAPTURES,
  payload: data,
});

export const getOrderCapturesSuccess = (data) => ({
  type: GET_CAPTURES_SUCCESS,
  payload: data,
});

export const getOrderCapturesFail = (error) => ({
  type: GET_CAPTURES_FAIL,
  payload: error,
});  




export const addRefundPayment = (data) => ({
  type: REFUND_PAYMENT,
  payload: data,
});

export const addRefundPaymentSuccess = (data) => ({
  type: REFUND_PAYMENT_SUCCESS,
  payload: data,
});

export const addRefundPaymentFail = (error) => ({
  type: REFUND_PAYMENT_FAIL,
  payload: error,
});  


export const addCapturePayment = (data) => ({
  type: CAPTURE_PAYMENT,
  payload: data,
});

export const addCapturePaymentSuccess = (data) => ({
  type: CAPTURE_PAYMENT_SUCCESS,
  payload: data,
});

export const addCapturePaymentFail = (error) => ({
  type: CAPTURE_PAYMENT_FAIL,
  payload: error,
});  



export const addWebhook = (data) => ({
  type: REGISTER_WEBHOOK,
  payload: data,
});

export const addWebhookSuccess = (data) => ({
  type: REGISTER_WEBHOOK_SUCCESS,
  payload: data,
});

export const addWebhookFail = (error) => ({
  type: REGISTER_WEBHOOK_FAIL,
  payload: error,
});  


export const getWebhook = (data) => ({
  type: GET_WEBHOOK,
  payload: data,
});

export const getWebhookSuccess = (data) => ({
  type: GET_WEBHOOK_SUCCESS,
  payload: data,
});

export const getWebhookFail = (error) => ({
  type: GET_WEBHOOK_FAIL,
  payload: error,
});  


export const deleteWebhook = (data) => ({
  type: DELETE_WEBHOOK,
  payload: data,
});

export const deleteWebhookSuccess = (data) => ({
  type: DELETE_WEBHOOK_SUCCESS,
  payload: data,
});

export const deleteWebhookFail = (error) => ({
  type: DELETE_WEBHOOK_FAIL,
  payload: error,
});  
export const getSettlements = (data) => ({
  type: GET_SETTLEMENTS,
  payload: data,
});

export const getSettlementsSuccess = (data) => ({
  type: GET_SETTLEMENTS_SUCCESS,
  payload: data,
});

export const getSettlementsFail = (error) => ({
  type: GET_SETTLEMENTS_FAIL,
  payload: error,
});
export const getOneSettlements = (data) => ({
  type: GET_ONE_SETTLEMENTS,
  payload: data,
});

export const getOneSettlementsSuccess = (data) => ({
  type: GET_ONE_SETTLEMENTS_SUCCESS,
  payload: data,
});

export const getOneSettlementsFail = (error) => ({
  type: GET_ONE_SETTLEMENTS_FAIL,
  payload: error,
});

export const getTransactions = (data) => ({
  type: GET_TRANSACTION,
  payload: data,
});

export const getTransactionsSuccess = (data) => ({
  type: GET_TRANSACTION_SUCCESS,
  payload: data,
});

export const getTransactionsFail = (error) => ({
  type: GET_TRANSACTION_FAIL,
  payload: error,
});
export const getUploaderLink = (data,types) => ({
  type: PRE_UPLOAD_IMAGE,
  payload: data,
  types:types

});

export const getUploaderLinkSuccess = (types,data) => ({
  type: PRE_UPLOAD_IMAGE_SUCCESS,
  payload: data,
  types:types

});

export const getUploaderLinkFail = (error) => ({
  type: PRE_UPLOAD_IMAGE_FAIL,
  payload: error,
});



export const uploadImage = (data) => ({
  type: UPLOAD_IMAGE,
  payload: data,
});

export const uploadImageSuccess = (data) => ({
  type: UPLOAD_IMAGE_SUCCESS,
  payload: data,

});

export const uploadImageFail = (error) => ({
  type: UPLOAD_IMAGE_FAIL,
  payload: error,
});

export const withdraw = (data) => ({
  type: WITHDRAW,
  payload: data,
});

export const withdrawSuccess = (data) => ({
  type: WITHDRAW_SUCCESS,
  payload: data,
});

export const withdrawFail = (error) => ({
  type: WITHDRAW_FAILED,
  payload: error,
});

//PPC
export const payperclick = (data) => ({
  type: PayPerClick,
  payload: data,
});

export const payperclickSuccess = (data) => ({
  type: PayPerClick_SUCCESS,
  payload: data,
});

export const payperclickFail = (error) => ({
  type: PayPerClick_FAILED,
  payload: error,
});


export const getPPCData = (data) => ({
  type: GET_PPC_DATA,
  payload: data,
});

export const getPPCDataSuccess = (data) => ({
  type: GET_PPC_DATA_SUCCESS,
  payload: data,
});

export const getPPCDataFail = (data) => ({
  type: GET_PPC_DATA_FAIL,
  payload: data,
});

//cancel PPC
export const cancelPPC = (data) => ({
  type: CANCEL_PPC,
  payload: data,
});

export const cancelPPCSuccess = (data) => ({
  type: CANCEL_PPC_SUCCESS,
  payload: data,
});

export const cancelPPCFail = (error) => ({
  type: CANCEL_PPC_FAIL,
  payload: error,
});
export const getpaymenttypes = (data) => ({
  type: GET_PAYMENT_TYPES,
  payload: data,
});

export const getpaymenttypesSuccess = (data) => ({
  type: GET_PAYMENT_TYPES_SUCCESS,
  payload: data,
});

export const getpaymenttypesFail = (error) => ({
  type: GET_PAYMENT_TYPES_FAILED,
  payload: error,
});