import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Card, CardBody, CardHeader } from "reactstrap";

import { useDispatch, useSelector } from "react-redux";

import { getActualCustomers } from "../../store/charts/action";
import { MonthsFinder, timeFilter } from "../../helpers/charts_helper";
import { LineChart } from "../../Components/Common/ChartsJs";
import NoDataFound from "./NoDataFound";
import YearsFilter from "./YearsFilter";

const monthShortNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
const EvolutionOfCustomers = () => {
  const { t } = useTranslation();

  const year = new Date().getFullYear();
  const firstDay = `${year}-01-01`;
  const lastDay = `${year}-12-31`;
  const [action, setAction] = useState("month");
  const [xLabel, setxLabel] = useState(MonthsFinder(firstDay, lastDay));
  const [startDate, setStartDate] = useState(firstDay);
  const [endDate, setEndDate] = useState(lastDay);
  const [callApi, setCallApi] = useState(false);
  const dispatch = useDispatch();
  const { actualCustomers } = useSelector((state) => ({
    actualCustomers: state.Charts.actualCustomers,
  }));
  const [customers, setCustomers] = useState("");
  useEffect(() => {
    const params = {
      start_date: startDate,
      end_date: endDate,
      action: action,
    };

    dispatch(getActualCustomers(params));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, startDate]);

  useEffect(() => {
    if (actualCustomers?.data) {
      let customers = [];

      xLabel.forEach((month) => {
        const item = actualCustomers.data.find((item) => {
          return item.date === month;
        });

        if (item) {
          customers.push(item.evolution);
        } else {
          customers.push(0);
        }
      });
      setCustomers(customers);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualCustomers, xLabel]);
  return (
    <Card>
      <CardHeader className="py-3 d-flex justify-content-between">
        <h4 className="card-title mb-0">{t("evolutionOfCustomers") + "/"  + timeFilter[action]}</h4>
        <div className="dashboard_picker">
          <YearsFilter
            action={action}
            setAction={setAction}
            setxLabel={setxLabel}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            setCallApi={setCallApi}
            startDate={startDate}
            endDate={endDate}
            callApi={callApi}
          />
        </div>
      </CardHeader>
      <CardBody className="position-relative">
        <>
          {actualCustomers?.data?.length === 0 && (
            <NoDataFound />
          )}
          <LineChart
            xLabel={timeFilter[action]}
            yLabel={"Customers"}
            Month={monthShortNames}
            Sales={customers}
            dataFlag={actualCustomers?.data?.length !== 0}
            label={"Evolution of customers"}
            dataColors='["--vz-primary-rgb, 0.8", "--vz-primary-rgb, 0.9"]'
            colorFrom={"#FFDDD6"}
            colorTo={"rgba(78, 90, 168, 0.08)"}
          />
        </>
      </CardBody>
    </Card>
  );
};
export default EvolutionOfCustomers;
