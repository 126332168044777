import React from "react";

import { DatePicker } from "antd";
import "antd/dist/antd.css";
import moment from "moment";
import { Card, CardBody, Col, Row, Collapse } from "reactstrap";



const CollapseTwo = ({
  collapseTwo,
  handleStepper,
  dealCategory,
  endDate,
  startDate,
  setStartDate,
  setEndDate,
  setDealDuration,
  setDealCategory,
}) => {
  function disabledDate(current) {
    // Can not select days before today and today
    if (dealCategory === "day") {
      return current && current < moment().endOf("day");
    }
    if (dealCategory === "week") {
      return current && current < moment().endOf("week");
    }
    if (dealCategory === "month") {
      return current && current < moment().endOf("month");
    }
  }

  function onWeekChange(date) {
    

    const weekNumber = moment(date).week();
    const year = date.format("YYYY")
    const startOfWeek = moment().year(year).week(weekNumber).day(0);
    const endOfWeek = moment().year(year).week(weekNumber).day(6);
    setStartDate(startOfWeek.format("YYYY-MM-DD"))
    setEndDate(endOfWeek.format("YYYY-MM-DD"))
  }

  function onMonthChange(date) {
    if (!date) {
      return;
    }
    const startDate = moment(date).startOf('month');
    const endDate = moment(date).endOf('month');
    setStartDate(startDate.format("YYYY-MM-DD"))
    setEndDate(endDate.format("YYYY-MM-DD"))
  }

  const onDayChange = (e) => {
    e = e.format("YYYY-MM-DD")
    if (!e) {
      return;
    }
    setStartDate(e)
    setEndDate(e)
  };
  const handleDealCategory = (value) => {
    setDealCategory(value.target.value);
  };
  return (
    <Collapse in isOpen={collapseTwo}>
      <Card className=" h-100%">
        <CardBody className=" h-100% DurationtTable">
          <h4 className="card-body mb-0"> Categories  & Duration</h4>

          <p className="card-body color  text-sm  text-muted mb-0">
            Please select the deal category and duration
          </p>
          <Row>
            <Col
              xl={6}
              className="CardBodyBillingSpecialDeals"
            >
              <Card className="m10 border-0 h-100">
                <div className="mb-3">
                  <span className="badge badge-soft-danger align-middle badge-span rounded-pill m-2">
                    <p className="badge-icon">{1}</p>
                  </span>
                  Select special deal category
                </div>
                <CardBody>
                  <div className="table-responsive table-card">
                    <table className="table table-hover table-centered align-middle table-nowrap m-0 ">
                      <tbody>
                        <tr>
                          <td className="border-0">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                id="deal_category_day"
                                name="deal_category"
                                value={"day"}
                                type="radio"
                                onClick={(e) => handleDealCategory(e)}
                              />

                              <label
                                style={{ marginLeft: "10px" }}
                                className="form-check-label"
                                htmlFor="deal_category_day"
                              >
                                Deal Of The Day
                              </label>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="border-0">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                id="deal_category_week"
                                name="deal_category"
                                value={"week"}
                                onClick={(e) => handleDealCategory(e)}
                                type="radio"
                              />

                              <label
                                style={{ marginLeft: "10px" }}
                                className="form-check-label"
                                htmlFor="deal_category_week"
                              >
                                Deal Of The Week
                              </label>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td className="border-0">
                            <div className="form-check">
                              <input
                                className="form-check-input "
                                id="deal_category_month"
                                name="deal_category"
                                value={"month"}
                                onClick={(e) => handleDealCategory(e)}
                                type="radio"
                              />

                              <label
                                style={{ marginLeft: "10px" }}
                                className="form-check-label"
                                htmlFor="deal_category_month"
                              >
                                Deal Of The Month
                              </label>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col
              className="CardBodyBillingSpecialDeals"
              xl={6}
            >
              <Card className="mb-20 p-0 h-100 border-0">
                <div className="mb-3">
                  <span className="badge badge-soft-danger align-middle badge-span rounded-pill m-2">
                    <p className="badge-icon">{2}</p>
                  </span>
                  Select special deal duration
                </div>
                <CardBody>
                  {dealCategory === "day" && (
                    <DatePicker
                      allowClear={false}
                      disabledDate={disabledDate}
                      min={new Date()}
                      onChange={(date) => {
                        onDayChange(date);
                      }}
                    />
                  )}
                  {dealCategory === "week" && (
                    <DatePicker
                    showWeekNumber
                      allowClear={false}
                      disabledDate={disabledDate}
                      onChange={(date) => {
                        onWeekChange(date);
                      }}
                      min={new Date()}
                      picker="week"
                      
                    />
                    )}
                  {dealCategory === "month" && (
                    <DatePicker
                      allowClear={false}
                      disabledDate={disabledDate}
                      onChange={(date) => {
                        onMonthChange(date);
                      }}
                      min={new Date()}
                      picker="month"
                    />
                  )}
                </CardBody>
              </Card>
            </Col>
            <div className="d-flex justify-content-center mt-5" >
              <button

                className="btn button-width-sd text-white bg-light-red ContinuebtnPPC"
                onClick={() => handleStepper(2)}
                disabled={startDate === "" }
              >
                Continue
              </button>
            </div>
          </Row>
        </CardBody>
      </Card>
    </Collapse>
  );
};
export default CollapseTwo;
