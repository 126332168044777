import React, { useEffect, useState, useMemo } from "react";
import { FcCheckmark } from "react-icons/fc";
import {
  Container,
  TabContent,
  TabPane,
  Row,
} from "reactstrap";
import * as moment from "moment";


// RangeSlider
import "nouislider/distribute/nouislider.css";
import ResultModal from "../../../Components/Common/ResultModal";
import DeleteModal from "../../../Components/Common/DeleteModal";

import {
  Status,
  Budget,
} from "./EcommercePayPerClickCol";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import TableContainer from "../../../Components/Common/TableContainer";

//Import actions
import {
  getPPCData,
  cancelPPC,
  checkout,
  resetEcomFlag,
} from "../../../store/ecommerce/action";

//redux
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Loader from "../Loader";


const EcommercePayPerClick = (props) => {
  const History = useHistory();
  const queryParams = new URLSearchParams(window.location.search);
  const dispatch = useDispatch();
  const dispatchCheckout = useDispatch();

  useEffect(() => {
    const checkout_id = queryParams.get("id");
    
    if (checkout_id) {
      const newPayPerClick = {
        product: queryParams.get("product"),
        start_date: queryParams.get("start_date"),
        end_date: queryParams.get("end_date"),
        budget: queryParams.get("budget"),
        push_notification: true,
        email_marketing: true,
        title: queryParams.get("title").replace(/-/g, " "),
      };
      dispatchCheckout(checkout({ checkout_id: checkout_id,source:"pay_per_click", pay_per_click:newPayPerClick }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatchCheckout]);

  const { allPPCData } = useSelector(
    (state) => ({
      allPPCData: state.Ecommerce.allPPCData,
    })
  );

  const [productsId, setProductsId] = useState([]);
  const dealsData = allPPCData.results;
  const dealsCount = allPPCData.count;

  const [PPCCategory, setPPCCategory] = useState("");
  const [resultModal, setResultModal] = useState(false);
  const [resultModalMsg, setResultModalMsg] = useState("");
  const [resultModalStatus, setResultModalStatus] = useState("");
  const [resultModaltitle, setResultModaltitle] = useState("");
  const [resultModalcloseBtn, setResultModalcloseBtn] = useState("");

  const fetchData = (params = {}) => {
    const filterData = { ...params };
    PPCCategory !== "" && (filterData["category"] = PPCCategory);
    dispatch(getPPCData(filterData));
  };

  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format("YYYY-MM-DD");
    return date1;
  };
  const {
    PPCCancelError,
    PPCCancelSuccess,
    paymentStatusFlag,
  } = useSelector((state) => ({
    PPCCancelSuccess: state.Ecommerce.PPCCancelSuccess,
    PPCCancelError: state.Ecommerce.PPCCancelError,
    paymentStatusFlag: state.Ecommerce.paymentStatusFlag,
  }));

  //Cancel PPC
  const [deleteModal, setDeleteModal] = useState(false);

  const handleCancelPPC = () => {
    setDeleteModal(false);
    dispatch(cancelPPC(productsId));
  };

  const handleShowDeleteModal = (productsId) => {
    setDeleteModal(true);
    setProductsId(productsId);
  };

  useEffect(() => {
    if (PPCCancelSuccess) {
      setResultModalMsg("");
      setResultModalStatus("success");
      setResultModaltitle("The campaign has been canceled successfully!");
      setResultModalcloseBtn("Close");
      setResultModal(true);
      fetchData({ page: 1 });
    }
    else if (PPCCancelError) {
      setResultModalMsg("");
      setResultModalStatus("failed");
      setResultModaltitle("Oops!");
      setResultModalcloseBtn("Ok");
      setResultModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, PPCCancelError, PPCCancelSuccess,]);

  useEffect(() => {
    if (paymentStatusFlag) {
      setResultModalMsg("We will keep you informed about your pay per click campaign and you can pay your invoice by the end of this campaign.");
      setResultModalStatus("success");
      setResultModaltitle("Thank you!");
      setResultModalcloseBtn("Close");
      setResultModal(true);
      fetchData({ page: 1 });
    }
    else if (paymentStatusFlag==="failed"){
      setResultModalMsg( "You cannot add this campaign at this time. It appears that you have entered incorrect payment information.");
      setResultModalStatus("failed");
      setResultModaltitle("Oops!");
      setResultModalcloseBtn("Ok");
      setResultModal(true);
    }
    fetchData({ page: 1 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, paymentStatusFlag]);

  useEffect(() => {
    setTimeout(() => {
      dispatch(resetEcomFlag());
    }, 3000);
  }, [dispatch, paymentStatusFlag,PPCCancelError, PPCCancelSuccess,]);
  const columns = useMemo(
    () => [

      {
        Header: "Pay per click details",
        accessor: "product_image",
        Cell: (dataProduct) => {
          return (
            <>
              <div className="d-flex align-items-center flex-row">
                <div className="flex-shrink-0 me-3 w-100">
                  <div className="avatar-sm  rounded p-1 d-flex w-50 justify-content-between align-items-center imgTitle">
                    <img
                      src={`${dataProduct.row.original.product.product_image}?${dataProduct.row.original.updated_at}`}
                      alt=""
                      className="img-fluid d-block"
                      style={{ width: "40%", height: "100%" }}
                    />
                    <div>
                      {dataProduct.row.original.title}
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        }
      },
      {
        Header: "Budget",
        accessor: "budget",
        Cell: (cellProps) => {
          return <Budget {...cellProps} />;
        },
      },
      {
        Header: "Email",
        accessor: "email_marketing",
        Cell: (Email) => {
          return Email.value === true ? <FcCheckmark style={{ width: "24px", height: "17.63px" }} /> : '';
        },
      },
      {
        Header: "Notifications",
        accessor: "push_notification",
        Cell: (notification) => {
          return notification.value === true ? <FcCheckmark style={{ width: "24px", height: "17.63px" }} /> : '';
        },
      },
      {
        Header: "Start Date",
        accessor: "start_date",
        Cell: (cellProps) => {
          return handleValidDate(cellProps.value);
        },
      },

      {
        Header: "End Date",
        accessor: "end_date",
        Cell: (cellProps) => {
          return handleValidDate(cellProps.value);
        },
      },
      {
        Header: "Status",
        accessor: "status",
        filterable: false,
        Cell: (cellProps) => {
          return <Status {...cellProps} />;
        },
      },
      {
        Header: "Actions",
        accessor: "id",
        Cell: (cellProps) => {
          if (cellProps.row.original.status === "canceled"){
            return <i className="ri-close-line text-light-red text-muted fs-24 opacity-50 cursor-not-allowed"></i> 
          }
          else{
            return <i className="ri-close-line text-light-red text-muted fs-24 cursor-pointer" 
                onClick={() =>
              handleShowDeleteModal(cellProps.row.original.id)
            } ></i> 
          }
        }
      },
    ],
    []
  );
  document.title = "Pay Per Click | Salis- Merchants Dashboard";
  const handleCloseClick = () => {
    setResultModal(false);
    History.push("/pay-per-click");
  }
  return (
    <div className="page-content">
      
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleCancelPPC}
        onCloseClick={() => setDeleteModal(false)}
        deleteItem={"Are you sure you want to cancel this PPC ?"}
        BtnDelete={"Yes, Cancel It!"}
      />
      <ResultModal
        show={resultModal}
        onCloseClick={handleCloseClick}
        message={resultModalMsg}
        status={resultModalStatus}
        title={resultModaltitle}
        setPPCCategory={setPPCCategory}
        closeBtn={resultModalcloseBtn}
      />
      
      <Container fluid>
        <BreadCrumb title="Pay Per Click" pageTitle="Marketing" />

        <Row>
          <div className="row PPCTable">
            <div className="col">
              <div>
                <div className="card">
                  <div className="card-header border-0">
                    <div className="row g-4 table-header-margin">
                      <div className="col-sm-auto ">
                        <Link
                          to="/add-pay-per-click"
                          className="btn"
                          style={{ backgroundColor: "#EE856F", color: "white" }}
                        >
                          <i className="ri-add-line align-bottom me-1"></i>
                          Create pay per click
                        </Link>
                      </div>

                      <div className="col-sm">
                      </div>
                    </div>
                  </div>

                  <div className="card-header">
                    <div className="row align-items-center">
                      <div className="col-auto">
                        <div id="selection-element">
                          <div className="my-n1 d-flex align-items-center text-muted">
                            Select
                            <div
                              id="select-content"
                              className="text-body fw-semibold px-1"
                            ></div>
                            Result
                            <button
                              type="button"
                              className="btn btn-link link-danger p-0 ms-3"
                            >
                              Remove
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card-body table-responsive border h-100">
                    <TabContent className="text-muted  ">
                      <TabPane>
                        <div
                          id="table-product-list-all"
                          className="table-card gridjs-border-none pb-2"
                        >
                          {dealsData && dealsData.length > 0 ? (

                            <TableContainer
                              columns={columns}
                              data={dealsData || []}
                              customPageSize={10}
                              numOfPages={dealsCount}
                              fetchData={fetchData}
                              divClass="overflow-visible table table-responsive mb-1"
                              tableClass="mb-0 align-middle table-borderless pb-5"
                              theadClass="table-light text-muted"
                            />
                          ) : (
                            <Loader />
                          )}
                        </div>
                      </TabPane>
                    </TabContent>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default EcommercePayPerClick;
