import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Card, CardBody, CardHeader } from "reactstrap";
import { BarChart } from "../../Components/Common/ChartsJs";
import DateFilter from "./DateFilter";
import { timeFilter, MonthsFinder } from "../../helpers/charts_helper";
import { useDispatch, useSelector } from "react-redux";
import { get3DCustomers } from "../../store/charts/action";
import NoDataFound from "./NoDataFound";
import moment from "moment";
const EvolutionOfCustomers3D = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const now = new Date();
  const firstDay = moment(now).startOf('year').format("YYYY-MM-DD");
  const lastDay = moment(now).endOf('year').format('YYYY-MM-DD');
  const [action, setAction] = useState("month");
  const [startDate, setStartDate] = useState(firstDay);
  const [endDate, setEndDate] = useState(lastDay);
  const [xLabel, setxLabel] = useState(MonthsFinder(firstDay, lastDay));
  const [callApi, setCallApi] = useState(false);
  const [acctual3Dcustomers, setAcctual3Dcustomers] = useState("");

  const { actual3DCustomers } = useSelector((state) => ({
    actual3DCustomers: state.Charts.actual3DCustomers,
  }));
  useEffect(() => {
    const params = {
      start_date: startDate,
      end_date: endDate,
      action: action,
    };
    dispatch(get3DCustomers(params));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, callApi]);
  useEffect(() => {
    if (actual3DCustomers) {
      let customers = [];

      xLabel.forEach((date) => {
        const item = actual3DCustomers?.data?.find((item) => {
          return item.date === date;
        });

        if (item) {
          customers.push(item.customers);
        } else {
          customers.push(0);
        }
      });
      setAcctual3Dcustomers(customers);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actual3DCustomers, xLabel]);
  return (
    <>
      <Card>
        <CardHeader className="border-0 align-items-center d-flex pb-2">
          <h4 className="card-title mb-0 flex-grow-1">
          {t("numberOfCustomers") +  " / " + timeFilter[action]}
          </h4>
          <div className="dashboard_picker">
            <DateFilter
              action={action}
              setAction={setAction}
              setxLabel={setxLabel}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              setCallApi={setCallApi}
              startDate={startDate}
              endDate={endDate}
              callApi={callApi}
            />
          </div>
        </CardHeader>

        <CardBody className="position-relative">
          {actual3DCustomers?.data?.length === 0 && (
            <NoDataFound />
          )}
          <BarChart
            xLabel={timeFilter[action]}
            yLabel={"Customers"}
            Month={xLabel}
            Sales={acctual3Dcustomers}
            dataFlag={actual3DCustomers?.data?.length !== 0}
            label={"Number of customers"}
            dataColors='["--vz-primary-rgb, 0.8", "--vz-primary-rgb, 0.9"]'
            colorFrom={"#4E5AA8"}
            colorTo={"#EE856F"}
          />
        </CardBody>
      </Card>
    </>
  );
};
export default EvolutionOfCustomers3D;
