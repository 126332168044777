import React, { useEffect } from "react";
import { Col, Row } from "reactstrap";
import DashboardCards from "../../Components/Common/DashboardCards";
import { getDailyVisitors } from "../../store/charts/action";
import { useDispatch, useSelector } from "react-redux";
import VisitorsLineChart from "./VisitorsLineChart";
import Visitors3DChart from "./Visitors3DChart";
import ProductsVisitors from "./ProductsVisitors"
import visitorsSvg from "../../assets/images/dashboardIcons/visitorsSvg";

const VisitorsTab = ({ totalMonth }) => {
  const dispatch = useDispatch();
  const { dailyVisitors } = useSelector((state) => ({
    dailyVisitors: state.Charts.dailyVisitors,
  }));
  useEffect(() => {
    const params = {
      action_type: "visit_on_merchant_website",
      start_date: new Date().getFullYear(),
      end_date: new Date().getFullYear() + 1,
      action: "day",
    };

    dispatch(getDailyVisitors(params));
  }, [dispatch]);


  return (
    <Row className="bg-alice-blue">
      <Col xs={12} sm={12} md={12} lg={12} xl={8}>
        <div className="d-md-flex gap-4 justify-content-center justify-content-sm-between py-4  ">
          <DashboardCards
            iconComponent={visitorsSvg("#367868")}
            text={"Visitors"}
            amount={dailyVisitors.current_day}
            title={"Visitors / Current Day"}
            fullWidth="cards-full-width"
          />
          <DashboardCards
            iconComponent={visitorsSvg("#4E5AA8")}
            text={"Visitors"}
            amount={dailyVisitors.current_month}
            title={"Visitors / Current Month"}
            fullWidth="cards-full-width"
          />
          <DashboardCards
            iconComponent={visitorsSvg("#EE856F")}
            text={"Visitors"}
            amount={dailyVisitors.current_year}
            title={"Visitors / Current Year"}
            fullWidth="cards-full-width"
          />
        </div>
        <VisitorsLineChart />
        <Visitors3DChart />
      </Col>
      <Col xl={4} lg={12} md={12} sm={12} xs={12}>
        <ProductsVisitors />
      </Col>
    </Row>
  );
};

export default VisitorsTab;
