import React, { useState, useEffect, useMemo } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Ecommerce/Loader";
import { config } from "../../../config";
import moment from "moment";
import { useTranslation } from "react-i18next";
import DateFilterCards from "../DateFilterCards";
import { getPPCDataTable } from "../../../store/charts/action";
import { timeFilter } from "../../../helpers/charts_helper";
const { currency } = config;
const PayPerClickTable = () => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [callApi, setCallApi] = useState(false);
    const now = new Date();
    const firstDay = moment(now).startOf('month').format("YYYY-MM-DD");
    const lastDay = moment(now).endOf('month').format('YYYY-MM-DD');
    const [action, setAction] = useState("month");
    const [startDate, setStartDate] = useState(firstDay);
    const [endDate, setEndDate] = useState(lastDay);


    const { allPPCDataTable } = useSelector((state) => ({
        allPPCDataTable: state.Charts.allPPCDataTable,

    }));

    useEffect(() => {
        const params = {
            start_date: startDate,
            end_date: endDate,
            action: action,
        };
        dispatch(getPPCDataTable(params));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, callApi]);
    const columns = useMemo(
        () => [
            {
                name: <span className="fw-normal text-gray-650">{t("payPerClickDetails")}</span>,
                selector: (row) => (
                    <>
                        <p className="m-0 p-0 text-wrap fw-bold">{row.name}</p>
                        <p className="m-0 p-0 text-gray-650">{<img src={row.product.product_image} className="img-fluid d-block" style={{ width: "50px", height: "50px" }} alt={row.product.product_name} />}
                        </p>
                    </>
                ),
            },
            {
                name: <span className="fw-normal text-gray-650"></span>,
                selector: (row) => (
                    <>
                        <p className="m-0 p-0 text-gray-650"> {row.product.product_name}</p>
                    </>
                ),
            },

            {
                name: <span className="fw-normal text-gray-650">{t("spendingAmount")}</span>,
                selector: (row) => (
                    <p className="m-0 p-0 fw-bold">{row.total + " " + currency}</p>
                ),
                sortable: false,
            },
            {
                name: <span className="fw-normal text-gray-650">{t("date")}</span>,
                selector: (row) => (
                    <p className="m-0 p-0 fw-normal">{moment(new Date(row.date)).format("DD/MM/YYYY")}</p>
                ),
                sortable: false,
            },
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );
    const columnNames = columns.map((col) => col.name.props.children);
    return (
        <div >
            <div className=" d-flex bg-white justify-content-between ">
                <div className="rounded d-flex justify-content-between align-items-center py-3 p-2">
                    {/* eslint-disable-next-line */}
                    <h4 className="card-title mb-0">{t(`ppcPerItem`)+ " " + "/" + " " + timeFilter[action]}</h4>
                </div>
                <div className="d-flex flex justify-content-center ">
                    <div className="my-auto px-2 dashboard_picker">
                        <DateFilterCards
                            action={action}
                            setAction={setAction}
                            setStartDate={setStartDate}
                            setEndDate={setEndDate}
                            startDate={startDate}
                            endDate={endDate}
                            setCallApi={setCallApi}
                            callApi={callApi}
                        />
                    </div>
                </div>
            </div>
            <div className="product-visitors-table-rdt">
                {allPPCDataTable.results && allPPCDataTable.results.length > 0 ? (
                    <DataTable columns={columns} data={allPPCDataTable.results} responsive pagination
                        paginationRowsPerPageOptions={[7, 10, 15, 20, 25, 30]}
                        paginationPerPage={7} />
                ) : (
                    <Loader columnNames={columnNames} />
                )}
            </div>
        </div>
    );
};
export default PayPerClickTable;