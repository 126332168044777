import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Row, Col } from "reactstrap";
import { useParams } from "react-router-dom";

import { getOneOrdersData,resetEcomFlag} from "../../../store/actions";
import { ToastContainer, toast } from "react-toastify";
import { config } from "../../../config";
import invoiceLogo from "../../../assets/images/invoice_logo.svg";
//redux
import Barcode from "react-barcode";
import { handleDate } from "../../../helpers/orderColHelper";

const { TOAST_AUTOCLOSE_TIMER } = config;

const EcommerceInvoice = () => {
  const dispatch = useDispatch();

  const { orderInvoice } = useSelector((state) => ({
    orderInvoice: state.Ecommerce.orderInvoice,
  }));

  let { order_id } = useParams();
  useEffect(() => {
    dispatch(getOneOrdersData(order_id));
  }, [dispatch, order_id]);

  useEffect(() => {
    if (!orderInvoice.order_id) {
      toast("Order not found", {
        position: "top-right",
        hideProgressBar: true,
        className: "bg-danger text-white",
        progress: undefined,
        toastId: "",
      });
    }
    dispatch(resetEcomFlag());
  }, [dispatch, orderInvoice]);

  document.title = "Invoice | Salis";
  return (
    <React.Fragment>
      {orderInvoice.order_id ? (
        <>
          <Row className="mt-5 w-100 pt-5">
            <Col
              className="mx-auto d-flex flex justify-content-start "
              xs={10}
              md={8}
              xl={5}
              xxl={4}
            >
              <div
                className={`rounded rounded-3 w-100 m-1 pt-2  text-center  `}
              >
                <img
                  className={`rounded rounded-3 w-50 m-1 pt-2  text-center  `}
                  src={invoiceLogo}
                  alt="invoice"
                />

                <h6 className="fw-bold pt-2">Success!</h6>
                <p className="text-muted">
                  The customer’s purchase is completed
                </p>
                <p>
                  {handleDate (orderInvoice.created_at)}
                </p>
              </div>
            </Col>
          </Row>
          <Row className="  w-100 h-100">
            <Col className="mx-auto" md={10} xl={4}>
              <div className="  w-100 m-1 pt-2  text-center">
                <div className="d-flex flex border-top justify-content-between w-100 m-1 pt-2  text-center">
                  <p className="text-muted">Approval code</p>
                  <p>{orderInvoice.order_id}</p>
                </div>
                <div className="d-flex flex justify-content-between w-100 m-1 pt-2  text-center">
                  <p className="text-muted">Paid amount</p>
                  <p>{orderInvoice.total_amount} SAR</p>
                </div>

                <div className="border-top  w-100 m-1 pt-2  text-center">
                  <div className="d-flex flex justify-content-between w-100 m-1 pt-2  text-center">
                    <h6 className="fw-bold">Total amount</h6>
                    <p className="text-light-red fw-bold">
                      {orderInvoice.total_amount} SAR
                    </p>
                  </div>
                </div>
              </div>
            </Col>
            <Barcode value={orderInvoice.order_id} />,
          </Row>
        </>
      ) : (
        <ToastContainer autoClose={TOAST_AUTOCLOSE_TIMER} />
      )}
    </React.Fragment>
  );
};

export default EcommerceInvoice;
