import React from "react";
import * as moment from "moment";
import { config } from "../../../config";

const handleValidDate = (date) => {
  const date1 = moment(new Date(date)).format("DD MMM Y");
  return date1;
};

const { currency } = config;
const handleValidTime = (time) => {
  const time1 = new Date(time);
  const getHour = time1.getUTCHours();
  const getMin = time1.getUTCMinutes();
  const getTime = `${getHour}:${getMin}`;
  var meridiem = "";
  if (getHour >= 12) {
    meridiem = "PM";
  } else {
    meridiem = "AM";
  }
  const updateTime = moment(getTime, "hh:mm").format("hh:mm") + " " + meridiem;
  return updateTime;
};

const Status = (cell) => {
  let statusBgColor = "white";
  let statusColor = "black";
  if (cell.value === "active"||cell.value === "pending") {
    statusBgColor = "#E5FFE2";
    statusColor = "#22A717";
  } else if (cell.value === "canceled" || cell.value === "inactive") {
    statusBgColor = "rgba(191, 29, 29, 0.1)";
    statusColor = "#BF1D1D";
  } else if (cell.value === "invoice_due") {
    statusBgColor = "rgba(237, 164, 111, 0.1)";
    statusColor = "#F6A300";
  }else if (cell.value === "ended" || cell.value === "paid" || cell.value === "ended_by_budget" ) {
    statusBgColor = "rgba(78, 90, 168, 0.1)";
    statusColor = "#4E5AA8";
  }

  return (
    <React.Fragment>
      <span>
        <span
          className="badge  fs-12 fw-medium "
          style={{ backgroundColor: statusBgColor, color: statusColor ,padding: "10px", gap: "10px", width: "142px",height: "32px", }}
        >
          {cell.value}
        </span>
      </span>
    </React.Fragment>
  );
};

const Published = (cell) => {
  return (
    <React.Fragment>
      <span>
        {handleValidDate(cell.value)}
        <small className="text-muted ms-1">{handleValidTime(cell.value)}</small>
      </span>
    </React.Fragment>
  );
};


const Budget = (cell) => {
  return (
    <React.Fragment>
       {cell.row.original.budget} {currency}
    </React.Fragment>
  );
};

export { Status, Published, handleValidTime,Budget };
