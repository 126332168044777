import { call, put, takeEvery, all, fork } from "redux-saga/effects";

// Crypto Redux States
import {
  GET_FEATURES_FLAGS,
} from "./actionType";

import {
  getFeaturesFlagsSuccess,
  getFeaturesFlagsFailed,
} from "./action";

//Include Both Helper File with needed methods
import {
  getFeaturesFlags as getFeaturesFlagsApi,
} from "../../helpers/salis_api";

function* getFeaturesFlags() {
  try {
    const response = yield call(getFeaturesFlagsApi);
    yield put(getFeaturesFlagsSuccess(response));
  } catch (error) {
    yield put(getFeaturesFlagsFailed(error));
  }
}

export function* watchgetFeaturesFlags() {
  yield takeEvery(GET_FEATURES_FLAGS, getFeaturesFlags);
}

function* featuresFlagsSaga() {
  yield all([
    fork(watchgetFeaturesFlags),
    
  ]);
}

export default featuresFlagsSaga;
