import React, { useEffect } from "react";
import { Container } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import BreadCrumb from "../../../Components/Common/BreadCrumb";

import SettlementsDetailList from "./SettlementsDetailList";
import SettlementsDetailOrderList from "./SettlementsDetailOrderList";

import { getOneSettlements } from "../../../store/ecommerce/action";

const EcommerceSettlementsDetail = (props) => {
  const dispatch = useDispatch();

  const { oneSettlement } = useSelector((state) => ({
    oneSettlement: state.Ecommerce.oneSettlement,
  }));
  let { id } = useParams();
  useEffect(() => {
    dispatch(getOneSettlements(id));
  }, [dispatch, id]);

  document.title = "Settlement Details";
  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Settlement Details" pageTitle="Settlement" />

        <SettlementsDetailList oneSettlement={oneSettlement} />
        <SettlementsDetailOrderList oneSettlement={oneSettlement} />
      </Container>
    </div>
  );
};

export default EcommerceSettlementsDetail;
