import { all, fork } from "redux-saga/effects";
//layout
import LayoutSaga from "./layouts/saga";
//Auth
import AccountSaga from "./auth/register/saga";
import AuthSaga from "./auth/login/saga";
import otpSaga from "./auth/otp/saga";
import chartsSaga from "./charts/saga";
import forgetPasswordSaga from "./auth/forgetpwd/saga";
import ProfileSaga from "./auth/profile/saga";

//calendar
//ecommerce
import ecommerceSaga from "./ecommerce/saga";
//Project
import projectSaga from "./projects/saga";
// Task
import taskSaga from "./tasks/saga";
//invoice
import invoiceSaga from "./invoice/saga";
//mailbox

import featuresFlagsSaga from "./featuresFlags/saga"

export default function* rootSaga() {
  yield all([
    //public
    fork(LayoutSaga),
    fork(AccountSaga),
    fork(AuthSaga),
    fork(forgetPasswordSaga),
    fork(ProfileSaga),
    fork(projectSaga),
    fork(taskSaga),
    fork(ecommerceSaga),
    fork(invoiceSaga),
    fork(otpSaga),
    fork(chartsSaga),
    fork(featuresFlagsSaga),
  ]);
}
