import React, { useState, useEffect } from "react";

import { Card, CardBody, CardHeader } from "reactstrap";

import { useDispatch, useSelector } from "react-redux";

import { getROIChart } from "../../../store/charts/action";
import { MonthsFinder, timeFilter } from "../../../helpers/charts_helper";
import { LineChart } from "../../../Components/Common/ChartsJs";
import NoDataFound from "../NoDataFound";
import { useTranslation } from "react-i18next";
import DateFilter from "../DateFilter";
import moment from "moment";
const ROILineChart = ({ disabledDate }) => {
    const { t } = useTranslation();
    const now = new Date();
    const firstDay = moment(now).startOf('year').format("YYYY-MM-DD");
    const lastDay = moment(now).endOf('year').format('YYYY-MM-DD');
    const [action, setAction] = useState("month");
    const [startDate, setStartDate] = useState(firstDay);
    const [endDate, setEndDate] = useState(lastDay);
    const [xLabel, setxLabel] = useState(MonthsFinder(firstDay, lastDay));
    const [callApi, setCallApi] = useState(false);
    const dispatch = useDispatch();
    const { ROI } = useSelector((state) => ({
        ROI: state.Charts.ROIDataChart,
    }));
    const [roi, setROI] = useState("");
    useEffect(() => {
        const params = {
            start_date: startDate,
            end_date: endDate,
            action: action,
        };

        dispatch(getROIChart(params));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, startDate]);

    useEffect(() => {
        if (ROI?.data) {
            let roi = [];

            xLabel.forEach((month) => {
                const item = ROI.data.find((item) => {
                    return item.date === month;
                });

                if (item) {
                    roi.push(item.evolution);
                } else {
                    roi.push(0);
                }
            });
            setROI(roi);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ROI, xLabel]);
    return (
        <Card>
            <CardHeader className="py-3 d-flex justify-content-between">
                <h4 className="card-title mb-0">{t("EROI") + " / " + timeFilter[action]}</h4>
                <div className="dashboard_picker">
                    <DateFilter
                        action={action}
                        setAction={setAction}
                        setxLabel={setxLabel}
                        xLabel={xLabel}
                        setStartDate={setStartDate}
                        setEndDate={setEndDate}
                        setCallApi={setCallApi}
                        startDate={startDate}
                        endDate={endDate}
                        callApi={callApi}
                        disabledDate={disabledDate}
                    />
                </div>
            </CardHeader>
            <CardBody className="position-relative">
                <>

                    {ROI?.data?.length === 0 && (
                        <NoDataFound />
                    )}
                    <LineChart
                        xLabel={timeFilter[action]}
                        yLabel={t("ROICard")}
                        Month={xLabel}
                        Sales={roi}
                        dataFlag={ROI?.data?.length !== 0}
                        label={t("EROI")}
                        dataColors='["--vz-primary-rgb, 0.8", "--vz-primary-rgb, 0.9"]'
                        colorFrom={"#FFDDD6"}
                        colorTo={"rgba(78, 90, 168, 0.4)"}
                    />
                </>
            </CardBody>
        </Card>
    );
};
export default ROILineChart;
