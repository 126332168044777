import React, { useState, useRef } from "react";
import "antd/dist/antd.css";
import { Col, Container, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import CollapseOne from "./collapseOne";
import CollapseTwo from "./collapseTwo";
import CollapseThree from "./collapseThree";
import { ToastContainer, toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import { Stepper, Step } from "react-form-stepper";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { BsPhone, BsCreditCard } from "react-icons/bs";
import { AiOutlineDollarCircle } from "react-icons/ai";
import ResultModal from "../../../Components/Common/ResultModal";
import { Redirect } from "react-router-dom";
import {
  addInStoreOrder,
  resetEcomFlag,
  getOneOrdersData,
  getpaymenttypes,
} from "../../../store/actions";
import { config } from "../../../config";
const { currency } = config;


const EcommerceInStoreOrderAdd = () => {
  const dispatch = useDispatch();
  const History = useHistory();

  const [mobile_number, setMobile_number] = useState("");
  const [Payment_Type, setPaymentType] = useState("");
  const [totalAmount, setTotalAmount] = useState("");



  const [currentPage, setCurrentPage] = useState(0);
  const [collapseOne, setCollapseOne] = useState(true);
  const [collapseTwo, setCollapseTwo] = useState(false);
  const [collapseThree, setCollapseThree] = useState(false);

  const [flagSearch, setFlagSearch] = useState(false);


  const [resultModaltitle, setResultModaltitle] = useState("");
  const [resultModalcloseBtn, setResultModalcloseBtn] = useState("");



  useEffect(() => {
    setTimeout(() => {
      setFlagSearch(false);
    }, 1000);
  }, [flagSearch]);






  const [resultModal, setResultModal] = useState(false);
  const [resultModalMsg, setResultModalMsg] = useState("");
  const [resultModalStatus, setResultModalStatus] = useState("");
  const [redirect, setRedirect] = useState("");
  const [minAmount, setminAmount] = useState("");
  const [maxAmount, setmaxAmount] = useState("");
  const orderCancelInterval = useRef();
  const {
    inStoreOrder,
    oneOrder,
    getPaymentTypes,
    inStoreErrorFlag,
    inStoreSuccessFlag,
  } = useSelector((state) => ({
    inStoreOrder: state.Ecommerce.inStoreOrder,
    oneOrder: state.Ecommerce.oneOrder,
    inStoreSuccessFlag: state.Ecommerce.inStoreSuccessFlag,
    inStoreErrorFlag: state.Ecommerce.inStoreErrorFlag,
    getPaymentTypes: state.Ecommerce.getpaymenttypes,
    paymenttypesSuccess: state.Ecommerce.paymenttypesSuccess,
    
  }));
  const handleNewOrder = () => {
    const newInStoreOrder = {
      phone_number: mobile_number,
      order: {
        currency :currency,
        total_amount: totalAmount,
        payment_type: Payment_Type,
      },
    };
    dispatch(addInStoreOrder(newInStoreOrder));

  };

  const handleNewOrderPhone = () => {
    const PhoneStoreOrder = {
      phone_number: mobile_number,
    };
    dispatch(getpaymenttypes(PhoneStoreOrder));
  };




  useEffect(() => {
    if (inStoreSuccessFlag) {
      setResultModalMsg("Tap close if you wish to quit this process and cancel this order");
      setResultModal(true);
      setResultModalStatus("success");
      setResultModaltitle("Thank you!");
      setResultModalcloseBtn("Go to orders page");
    }
    if (inStoreErrorFlag) {
      setResultModalMsg("");
      setResultModal(true);
      setResultModalStatus("failed");
      setResultModaltitle("Oops!");
      setResultModalcloseBtn("Return back");

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, inStoreErrorFlag, inStoreSuccessFlag,]);


  // check order status every three seconds
  useEffect(() => {
    if (inStoreOrder.order_id) {
      orderCancelInterval.current = setInterval(() => {
        dispatch(getOneOrdersData(inStoreOrder.order_id));

      }, 3000);
    }
  }, [dispatch, inStoreOrder]);


  const handleResetorderState = () => {
    clearInterval(orderCancelInterval.current);
    setResultModal(false);
    dispatch(resetEcomFlag());

  };
  useEffect(() => {
    if (oneOrder.status === "captured") {
      handleResetorderState();

      setTimeout(() => {
        // History.push(`/invoice/${inStoreOrder.order_id}`);
        History.push(`/orders`);
      }, 1000);
    } else if (oneOrder.status === "expired") {
      handleResetorderState();
      toast("Order Expired!", {
        position: "top-right",
        hideProgressBar: true,
        className: "bg-danger text-white",
        progress: undefined,
        toastId: "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, oneOrder, History]);

  const handleStepper = (value) => {
    if (value === 0) {
      setCurrentPage(value);

      setCollapseOne(true);
      setCollapseTwo(false);
      setCollapseThree(false);
    } else if (
      value === 1 &&
      mobile_number !== "" &&
      mobile_number.length < 15
    ) {
      setCurrentPage(value);
      handleNewOrderPhone();
      setCollapseOne(false);
      setCollapseTwo(true);
      setCollapseThree(false);

    } else if (
      value === 2 &&
      mobile_number !== "" &&
      mobile_number.length < 14 &&
      Payment_Type !== ""
    ) {
      setCollapseOne(false);
      setCollapseTwo(false);
      setCollapseThree(true);

    } else if (
      value === 3
    ) {
      setCurrentPage(value);
      handleNewOrder();
    }
  };

  const handleCloseClick = () => {
    setResultModal(false);
    if (resultModalStatus === 'success')
      setRedirect(true);
    else
      setRedirect(false);
  }


  return (
    <>
      <div className="page-content">
        <Container fluid>
          {redirect && <Redirect to='/orders' />}
          <BreadCrumb
            subTitle="Create in store order"
            title="Create new order "
            pageTitle="Order"
          />
          <ResultModal
            show={resultModal}
            onCloseClick={handleCloseClick}
            message={resultModalMsg}
            status={resultModalStatus}
            title={resultModaltitle}
            closeBtn={resultModalcloseBtn}
          />

          <Row className="row">
            <Col lg={12} xl={3}>
              <div className="special-deals-stepper" style={{ marginTop: 80 }}>
                <Stepper hideConnectors={true} activeStep={currentPage}>
                  <Step
                    onClick={() => handleStepper(0)}
                    label="Customer phone number"
                  >
                    {" "}
                    <BsPhone />
                  </Step>
                  <Step
                    onClick={() => handleStepper(1)}
                    label="Payment type"
                  >
                    {" "}
                    <BsCreditCard />
                  </Step>
                  <Step onClick={() => handleStepper(2)} label="Order total amount">
                    {" "}
                    <AiOutlineDollarCircle />
                  </Step>

                </Stepper>
              </div>
            </Col>
            <Col lg={12} xl={9}>

              <CollapseOne
                collapseOne={collapseOne}
                mobile_number={mobile_number}
                setMobile_number={setMobile_number}
                handleStepper={handleStepper}
                ToastContainer={ToastContainer}
              />

              <CollapseTwo
                collapseTwo={collapseTwo}
                handleStepper={handleStepper}
                setPaymentType={setPaymentType}
                Payment_Type={Payment_Type}
                setmaxAmount={setmaxAmount}
                setminAmount={setminAmount}
                getPaymentTypes={getPaymentTypes}
              />

              <CollapseThree
                collapseThree={collapseThree}
                handleStepper={handleStepper}
                setTotalAmount={setTotalAmount}
                totalAmount={totalAmount}
                maxAmount={maxAmount}
                minAmount={minAmount}
              />

            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default EcommerceInStoreOrderAdd;
