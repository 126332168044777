import PropTypes from "prop-types";
import React from "react";
import { Modal, ModalBody } from "reactstrap";

const LoadingModal = ({ message, show }) => {
  return (
    <Modal isOpen={show} centered={true}>
      <ModalBody className="py-3 px-5">
        <div className="mt-2 text-center">
          <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
            {show &&
              <div className="py-4 text-center">
                <div>
                  <lord-icon
                    src="https://cdn.lordicon.com/hllpzwls.json"
                    trigger="loop"
                    colors="outline:#ee856f,primary:#ee856f,secondary:#ffffff"
                    style={{
                      width: "72px",
                      height: "72px",
                    }}
                  ></lord-icon>
                </div>

                <div className="mt-4">
                  <h5>Please wait a moment....</h5>
                </div>
                <p className="text-muted mx-4 mb-0">{message}</p>
              </div>
            }
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

LoadingModal.propTypes = {
  show: PropTypes.any,
  message: PropTypes.any,
};

export default LoadingModal;
