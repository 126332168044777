import React, { useEffect, useState } from "react";
import { Row } from "reactstrap";

import DashboardCards from "../../Components/Common/DashboardCards";
import balanceIcon from "../../assets/images/dashboardIcons/balance.svg";
import customersIcon from "../../assets/images/dashboardIcons/customers.svg";
import ordersIcon from "../../assets/images/dashboardIcons/orders.svg";
import profitIcon from "../../assets/images/dashboardIcons/profit.svg";
import transactionsIcon from "../../assets/images/dashboardIcons/transactions.svg";

import { config } from "../../config";
const { currency } = config;

const AllCards = () => {
  const [userName, setUserName] = useState("Admin");

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"));

      setUserName(obj.data.username || "Merchant");
    }
  }, [userName]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return (
    <>
      <h5 className="fw-bold test"> Welcome {userName}, </h5>
      <p className="text-muted">
        Here's what's happening with your store today.
      </p>
      <Row className="d-flex gap-4 justify-content-center justify-content-sm-start pt-4  bg-alice-blue">
        <DashboardCards
          icon={balanceIcon}
          text={"My balance"}
          amount={`130.00 ${currency}`}
          link={"/cards"}
        />
        <DashboardCards
          icon={ordersIcon}
          text={"Orders"}
          amount={1000.0}
          link={"/orders"}
        />
        <DashboardCards
          icon={transactionsIcon}
          text={"Transactions"}
          amount={`340K ${currency}`}
          link={"/"}
        />
        <DashboardCards
          icon={profitIcon}
          text={"Profit"}
          amount={`530K ${currency}`}
          link={"/"}
        />
        <DashboardCards
          icon={customersIcon}
          text={"Customers"}
          amount={`100K`}
          link={"/"}
        />
      </Row>
    </>
  );
};

export default AllCards;
