import React from "react";
import {
    Container,
    Row,

} from "reactstrap";

import BreadCrumb from "../../../Components/Common/BreadCrumb";
import Checkout from "../../../Components/Common/Checkout";


const Checkoutpage = () => {
    

    return (
        <div className="page-content" style={{backgroundColor:"#F3F6F9"}}>
            <Container fluid>
                <BreadCrumb title="Change card" pageTitle="Ecommerce" />
                <Row>
                    <div className="col-xl-9 col-lg-8">
                        <Checkout amount={200} callback_url="/result/"/>
                    </div>
                </Row>
            </Container>
        </div>
    );
};

export default Checkoutpage;
