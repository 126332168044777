import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import DashboardCards from "../../Components/Common/DashboardCards";
import totalTransfers from "../../assets/images/dashboardIcons/totalTransfers.svg";
import totalRecievable from "../../assets/images/dashboardIcons/totalRecievables.svg";
import StatementTable from "./StatementTable";
import { useSelector, useDispatch } from "react-redux";
import { getWalletStatementData, getTotalRecievables, getTotalReceivablesChart } from "../../store/charts/action";
// import { GetFlagValue } from "../../helpers/getFlagValue";
import { config } from "../../config";
import ReceivablesDue3D from "./statement/receivablesDue3D";
import marketingSvg from "../../assets/images/dashboardIcons/marketingBudgetSvg";
import ReceivablePaymentsTable from "./statement/receivablePaymentsTable";
const { currency } = config;
const StatementTab = () => {
  const dispatch = useDispatch();
  const { WalletStatement, totalRecievables, totalReceivablesChart } = useSelector((state) => ({
    WalletStatement: state.Charts.WalletStatementData,
    totalRecievables: state.Charts.totalRecievables,
    totalReceivablesChart: state.Charts.totalReceivablesChart,
  }));

  const [startDay, setStartDay] = useState(
    new Date().toLocaleDateString("sv", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    })
  );

  const [endDay, setEndDay] = useState(
    new Date().toLocaleDateString("sv", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    })
  );
  const [startDayReceivablesTable, setStartDayReceivablesTable] = useState(
    new Date().toLocaleDateString("sv", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }).replace(/(\d{4})-(\d{2})-(\d{2})/, "$1-$2-01")
  );
  const today = new Date();
  const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  const [endDayReceivablesTable, setEndDayReceivablesTable] = useState(
    endOfMonth.toLocaleDateString("sv", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    })
  );
  useEffect(() => {
    let date = new Date(endDay);
    const filterData = {
      start_date: startDay,
      end_date: new Date(date.setDate(date.getDate() + 1)).toLocaleDateString(
        "sv",
        { year: "numeric", month: "2-digit", day: "2-digit" }
      ),
      action: "day",
    };
    dispatch(getWalletStatementData(filterData));
  }, [dispatch, startDay, endDay]);
  ////
  useEffect(() => {
    let date = new Date(endDayReceivablesTable);
    const filterData = {
      start_date: startDayReceivablesTable,
      end_date: new Date(date.setDate(date.getDate())).toLocaleDateString(
        "sv",
        { year: "numeric", month: "2-digit", day: "2-digit" }
      ),
      action: "day",
    };
    dispatch(getTotalReceivablesChart(filterData));
  }, [dispatch, startDayReceivablesTable, endDayReceivablesTable]);
  ////
  useEffect(() => {
    let currentDate = new Date();
    let currentYear = currentDate.getFullYear();
    let currentMonth = currentDate.getMonth();
    const filterData = {
      month: new Date(currentYear, currentMonth, 1).toLocaleDateString(
        "sv"),
    };

    dispatch(getTotalRecievables(filterData));
  }, [dispatch]);
  return (
    <Row className="bg-alice-blue">
      <Col xs={12} sm={12} md={12} lg={12} xl={12}>
        <div className="d-md-flex gap-4 justify-content-center justify-content-md-start py-4  ">
          {/* {GetFlagValue("transfer_statement") ? (
            <DashboardCards
              icon={totalTransfers}
              text={"Transfers by salis"}
              amount={`${(WalletStatement?.total_transfers?.amount) ? (WalletStatement?.total_transfers?.amount) : "0 "}` + currency}
              title={"Transfers / Current Month"}
              fullWidth="cards-full-width"
            />
          ) : (
            ""
          )} */}

            <DashboardCards
              icon={totalTransfers}
              text={"Transfers by salis"}
              amount={`${(WalletStatement?.total_transfers?.amount) ? (WalletStatement?.total_transfers?.amount + " " ) : "0 "}` + currency}
              title={"Transfers / Current Month"}
              fullWidth="cards-full-width"
            />
          <DashboardCards
            icon={totalRecievable}
            text={"Received amount"}
            amount={`${(totalRecievables?.total_recieved) ? (totalRecievables?.total_recieved + " "  ) : "0 "}` + currency}
            title={"Amount / Current Month"}
            fullWidth="cards-full-width"
          />
          <DashboardCards
            iconComponent={marketingSvg("#4E5AA8")}
            text={"Total Outstanding"}
            amount={`${(totalRecievables?.total_outstanding_amount) ? (totalRecievables?.total_outstanding_amount + " " ) : "0 "}` + currency}
            title={"Amount / Current Month"}
            fullWidth="cards-full-width"
          />
        </div>
      </Col>
      <Col xl={12}>
        <ReceivablesDue3D />

        <ReceivablePaymentsTable
          setStartDay={setStartDayReceivablesTable}
          setEndDay={setEndDayReceivablesTable}
          totalReceivables={totalReceivablesChart?.results?.[0]?.data}
        />
        {/* {GetFlagValue("transfer_statement") ? (
          <StatementTable
            setStartDay={setStartDay}
            setEndDay={setEndDay}
            WalletStatement={WalletStatement?.results}
          />
        ) : (
          ""
        )} */}
                <StatementTable
            setStartDay={setStartDay}
            setEndDay={setEndDay}
            WalletStatement={WalletStatement?.results}
          />
      </Col>

    </Row >
  );
};
export default StatementTab;
