import React from "react";
import { Link } from "react-router-dom";

const BreadCrumb = ({ title, pageTitle, subTitle }) => {
  return (
    <React.Fragment>
      <div className="page-title-box d-flex  justify-content-between bredcrum">
        <h4 className="mb-sm-0">{title}</h4>

        {pageTitle &&
          <div className="page-title-right">
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item">
                <Link to="#">{pageTitle}</Link>
              </li>
              <li className="breadcrumb-item">
                <Link to="#">{title}</Link>
              </li>
              {subTitle && (
                <li className="breadcrumb-item">
                  <Link to="#">{subTitle}</Link>
                </li>
              )}
            </ol>
          </div>
        }
      </div>
    </React.Fragment>
  );
};

export default BreadCrumb;
