import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import DashboardCards from "../../Components/Common/DashboardCards";
import {
  getTotalConversionRate,
  getItemConversionRate,
} from "../../store/charts/action";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import DateFilterCards from "./DateFilterCards";
import { timeFilter } from "../../helpers/charts_helper";
import personSvg from "../../assets/images/dashboardIcons/personSvg";
import conversionRateSvg from "../../assets/images/dashboardIcons/conversionRateSvg";
import visitorsSvg from "../../assets/images/dashboardIcons/visitorsSvg";
import { useTranslation } from "react-i18next";
import ItemsConversionRate from "./conversionRate/ItemsConversionRate";
const ConversionRate = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const [totalCustomers, settotalCustomers] = useState(0);
  const [totalVisitors, setTotalVisitors] = useState(0);
  const [callApi, setCallApi] = useState(false);
  const [totalConversionRateAmount, setTotalConversionRateAmount] = useState(0);
  const { totalConversionRate, dataProducts } = useSelector((state) => ({
    totalConversionRate: state.Charts.totalConversionRate,
    dataProducts: state.Charts.itemsConversionRateData,
  }));
  const now = new Date();

  const firstDay = moment(now).startOf('month').format("YYYY-MM-DD");
  const lastDay = moment(now).endOf('month').format('YYYY-MM-DD');
  const [action, setAction] = useState("month");
  const [startDate, setStartDate] = useState(firstDay);
  const [endDate, setEndDate] = useState(lastDay);

  useEffect(() => {

    const params = {
      start_date: startDate,
      end_date: endDate,
      action: action,
    };
    dispatch(getTotalConversionRate(params));
    dispatch(getItemConversionRate(params));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, callApi]);
  useEffect(() => {
    if (totalConversionRate?.data) {
      let totalCus = totalConversionRate.data.customers;
      let totalVis = totalConversionRate.data.visitors;
      let totalConv = totalConversionRate.data.conversion_rate;

      settotalCustomers(totalCus);
      setTotalVisitors(totalVis);
      setTotalConversionRateAmount(totalConv);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalConversionRate]);

  return (
    <React.Fragment>
      <Row className=" flex-column-reverse flex-xl-row bg-alice-blue">
        <Col xs={12} sm={12} md={12} lg={12} xl={8}>
          <div className="d-md-flex gap-4 justify-content-center justify-content-sm-between py-4  ">
            <DashboardCards
              iconComponent={conversionRateSvg("#EE856F")}
              text={t("conversionRate")}
              amount={totalConversionRateAmount}
              title={`${t("conversionRate")}  / ${timeFilter[action]}`}
            />
            <DashboardCards
              iconComponent={visitorsSvg("#367868")}
              text={t("visitors")}
              amount={totalVisitors}
              title={`${t("visitors")}  / ${timeFilter[action]}`}
            />
            <DashboardCards
              iconComponent={personSvg("#4E5AA8")}
              text={"Buyers"}
              amount={totalCustomers}
              title={`Buyers / ${timeFilter[action]}`}
            />
          </div>
        </Col>
        <Col xl={4}>
          <div className="dashboard_picker d-flex gap-2 justify-content-start justify-content-xxl-end">
            <p className="my-auto">{t("ConversionRateFilter")} : </p>
            <DateFilterCards
              action={action}
              setAction={setAction}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              startDate={startDate}
              endDate={endDate}
              setCallApi={setCallApi}
              callApi={callApi}
            />
          </div>
        </Col>
      </Row>
      <Row className=" flex-column-reverse flex-xl-row bg-alice-blue">
        <Col className="row justify-content-between" xl={12}>
          <ItemsConversionRate dataProducts={dataProducts} action={action} />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default ConversionRate;
