import {
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_FAIL,
  RESET_PASSWORD_FLAG,
} from "./actionTypes";

export const forgetPassword = (data) => {
  return {
    type: FORGET_PASSWORD,
    payload: data,
  };
};

export const forgetPasswordSuccess = (data) => {
  return {
    type: FORGET_PASSWORD_SUCCESS,
    payload: data,
  };
};

export const forgetPasswordFail = (data) => {
  return {
    type: FORGET_PASSWORD_FAIL,
    payload: data,
  };
};

export const resetPasswordFlag = () => {
  return {
    type: RESET_PASSWORD_FLAG,
  };
};
