import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { resetLoaderFlag } from "../../store/ecommerce/action";

import { config } from "../../config";

const { LOADER_RESET_TIMER } = config
const Loader = (columnNames) => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => ({
    loading: state.Ecommerce.loading,
  }));
  useEffect(() => {
    setTimeout(() => {
      dispatch(resetLoaderFlag());
    }, LOADER_RESET_TIMER);
  }, [dispatch, loading]);
  return (
    <>
      {loading ? (
        <div className="py-4 text-center">
          <div>
            <lord-icon
              src="https://cdn.lordicon.com/hllpzwls.json"
              trigger="loop"
              colors="outline:#ee856f,primary:#ee856f,secondary:#ffffff"
              style={{
                width: "72px",
                height: "72px",
              }}
            ></lord-icon>
          </div>

          <div className="mt-4">
            <h5>Loading....</h5>
          </div>
        </div>
      ) : (
        <div className="py-4 text-center">

          {columnNames.columnNames?.length > 0 ? (
            <>
              <table className="w-100 bg-white rounded d-flex justify-content-between align-items-center py-3 p-2 ">
                <thead className="w-100">
                  <tr className="d-flex justify-content-between">
                    {columnNames.columnNames.map((name, index) => (
                      <th key={index}><span className=" ">{name}</span></th>
                    ))}
                  </tr>
                </thead>
              </table>
              <>
                <div>
                </div>
                <div className="mt-4">
                  <h5 className="w-100 bg-white rounded d-flex justify-content-center align-items-center py-3 p-2">Sorry! No Data Found</h5>
                </div>
              </>
            </>
          ) : (
          <>
            <div>
              <lord-icon
                src="https://cdn.lordicon.com/msoeawqm.json"
                trigger="loop"
                colors="primary:#405189,secondary:#0ab39c"
                style={{
                  width: "72px",
                  height: "72px",
                }}
              ></lord-icon>
            </div>
            <div className="mt-4">
              <h5>Sorry! No Result Found</h5>
            </div>
          </>
          )}
        </div>
      )}
    </>
  );
};

export default Loader; 
