import React, { useEffect, useState } from "react";

import { Container, Row, Col, Card, CardBody, CardHeader } from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";
import {
  getUserData,
  resetProfileFlag,
} from "../../store/auth/profile/actions";
import { resetEcomFlag } from "../../store/actions";
import Geocode from "react-geocode";

import { useTranslation } from "react-i18next";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import store_logo from "../../assets/images/profile/store_logo.png";
import store_image from "../../assets/images/profile/store_image.png";
import { ToastContainer, toast } from "react-toastify";
import { config } from "../../config";
import Skeleton from "@mui/material/Skeleton";
import { Link } from "react-router-dom";
const { TOAST_AUTOCLOSE_TIMER } = config;
const { LOADER_RESET_TIMER } = config;
const { SKELELTON_RESET_TIMER } = config;
const { GOOGLE_MAP_API_KEY } = config;

Geocode.setApiKey(GOOGLE_MAP_API_KEY);
Geocode.setLanguage("en");
const Profile = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [skeltonTimer, setSkeltonTimer] = useState(true);
  const [address, setAddress] = useState(false);

  const { userData, profileUpdateFlag } = useSelector((state) => ({
    userData: state.Profile.userData,
    success: state.Profile.success,
    error: state.Profile.error,
    profileUpdateFlag: state.Profile.profileUpdateFlag,
  }));

  useEffect(() => {
    setTimeout(() => {
      dispatch(resetEcomFlag());
      dispatch(resetProfileFlag());
    }, LOADER_RESET_TIMER);

    setTimeout(() => {
      setSkeltonTimer(false);
    }, SKELELTON_RESET_TIMER);
  }, [dispatch, profileUpdateFlag, userData]);

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      dispatch(getUserData(obj.data.id));
    }
  }, [dispatch, profileUpdateFlag]);

  useEffect(() => {
    if (profileUpdateFlag) {
      toast(profileUpdateFlag, {
        position: "top-right",
        hideProgressBar: true,
        className: profileUpdateFlag.includes("error")
          ? "bg-danger text-white"
          : "bg-success text-white",
        progress: undefined,
        toastId: "",
      });
    }
  }, [profileUpdateFlag]);

  useEffect(() => {
    Geocode.fromLatLng(userData.latitude, userData.longitude).then(
      (response) => {
        const address = response.results[0].formatted_address;
        setAddress(address);
      },
      (error) => {
        // console.error(error);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);
  document.title = "Profile";
  return (
    <React.Fragment>
      <div className="page-content bg-alice-blue h-100">
        <Container fluid>
          <BreadCrumb title="Profile" />
          <ToastContainer
            autoClose={TOAST_AUTOCLOSE_TIMER}
            style={{ width: "auto" }}
          />
          <Row>
            <Col lg="4">
              <Card className="h-100">
                <CardHeader>
                  <div className="d-flex flex justify-content-between mx-3">
                    <p className="fw-bold my-auto">{t("profileInfo")}.</p>

                    <Link to={"profile-edit"}>
                      <button className="border-0 rounded-1 bg-light-red">
                        <i className="ri-edit-box-line text-white"></i>
                      </button>
                    </Link>
                  </div>
                </CardHeader>
                <CardBody>
                  <div className="d-flex flex flex-column mx-3">
                    <div className="images-box-holder">
                      <a
                        style={{ display: "contents" }}
                        href={userData.store_image || store_image}
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        {!skeltonTimer ? (
                          <img
                            key={new Date()}
                            className="store-image-box object-fit-images NO-CACHE"
                            alt=""
                            src={
                              userData.store_image
                                ? `${userData.store_image}?${userData.date_update}`
                                : store_image
                            }
                            loading="lazy"
                          />
                        ) : (
                          <Skeleton
                            animation="pulse"
                            style={{
                              width: "98px",
                              height: "98px",
                              position: "absolute",
                              left: "0px",
                              top: "0px",
                            }}
                            variant="circular"
                            width={50}
                            height={50}
                          />
                        )}
                      </a>
                      <a
                        style={{ display: "contents" }}
                        href={userData.store_logo || store_logo}
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        {!skeltonTimer ? (
                          <img
                            className="store-image-box store-logo-box object-fit-images NO-CACHE"
                            alt=""
                            src={
                              userData.store_logo
                                ? `${userData.store_logo}?${userData.date_update}`
                                : store_logo
                            }
                            loading="lazy"
                          />
                        ) : (
                          <Skeleton
                            style={{
                              width: "50px",
                              height: "50px",
                              position: "absolute",
                              left: "56px",
                              top: "59px",
                            }}
                            variant="circular"
                            width={50}
                            height={50}
                          />
                        )}
                      </a>
                    </div>
                    <div className="d-flex flex mt-4">
                      <i className="ri-store-2-line align-middle text-light-red text-muted fs-20"></i>
                      <p className="my-auto mx-3">
                        Store name : {userData.store_name}
                      </p>
                    </div>
                    <div className="d-flex flex  my-1">
                      <i className="ri-links-line align-middle text-light-red text-muted fs-20"></i>
                      <p className="my-auto mx-3">
                        Store url : {userData.store_url}
                      </p>
                    </div>
                    <div className="d-flex flex  my-1">
                      <i className="ri-map-pin-line  text-light-red text-muted fs-20"></i>
                      <p className="my-auto mx-3">Location : {address}</p>
                    </div>
                    <div className="d-flex flex  my-1">
                      <i className="ri-apps-2-line  text-light-red text-muted fs-20"></i>
                      <p className="my-auto mx-3">Category : {userData.category}</p>
                    </div>
                    <div className="d-flex flex  my-1">
                      <i className="ri-timer-line  text-light-red text-muted fs-20"></i>
                      <p className="my-auto mx-3">Return period : {userData.return_period} {t("Days")}</p>
                    </div>
                
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col className="mt-lg-0 mt-4" lg="8">
              <Card className="h-100">
                <CardHeader>
                  <div className="d-flex flex justify-content-between mx-3">
                    <p className="fw-bold my-auto py-1">
                      {t("registrationInfo")}.
                    </p>
                  </div>
                </CardHeader>
                <Row>
                  <Col lg="6">
                    <CardBody>
                      <div className="d-flex flex flex-column my-1 mx-3">
                        <div className="d-flex flex mt-3">
                          <i className="ri-building-line align-middle text-light-red text-muted fs-20"></i>
                          <p className="my-auto mx-3">
                            Company name : {userData.company_name}
                          </p>
                        </div>
                        <div className="d-flex flex  my-1">
                          <i className="ri-mail-line  text-light-red text-muted fs-20"></i>
                          <p className="my-auto mx-3">
                            Email : {userData.user?.email}
                          </p>
                        </div>
                        <div className="d-flex flex  my-1">
                          <i className="ri-smartphone-line  text-light-red text-muted fs-20"></i>
                          <p className="my-auto mx-3">
                            Mobile number :{userData.mobile_number}
                          </p>
                        </div>
                        <div className="d-flex flex  my-1">
                          <i className="ri-briefcase-line  text-light-red text-muted fs-20"></i>
                          <p className="my-auto mx-3">
                            Business number :
                            {userData.business_registration_number}
                          </p>
                        </div>
                        <div className="d-flex flex ">
                          <i className="ri-percent-line  text-light-red text-muted fs-20"></i>
                          <p className="my-auto mx-3">
                            Tax number : {userData.tax_registration_number}
                          </p>
                        </div>
                      </div>
                    </CardBody>
                  </Col>
                  <Col
                    className="d-flex justify-content-center flex-column"
                    lg="6"
                  >
                    <div className="d-flex justify-content-center gap-4">
                      <a
                        style={{ display: "contents" }}
                        href={
                          userData.image_tax_registration_certificate ||
                          store_image
                        }
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        <img
                          data-dz-thumbnail=""
                          height="150"
                          width="100"
                          className="rounded object-fit-images"
                          src={
                            userData.image_tax_registration_certificate ||
                            store_image
                          }
                          alt=""
                        />
                      </a>
                      <a
                        style={{ display: "contents" }}
                        href={userData.image_trade_license || store_image}
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        <img
                          data-dz-thumbnail=""
                          height="150"
                          width="100"
                          className="rounded object-fit-images"
                          src={userData.image_trade_license || store_image}
                          alt=""
                        />
                      </a>
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <Row className="my-4">
            <Col lg="12">
              <Card className="h-100">
                <CardHeader>
                  <div className="d-flex flex justify-content-between mx-3">
                    <p className="fw-bold my-auto py-2">
                      {t("integrationInfo")}.
                    </p>
                  </div>
                </CardHeader>
                <CardBody>
                  <div className="d-flex flex flex-nowrap my-1 mx-3">
                    <i className="ri-key-2-line  text-light-red text-muted fs-20"></i>
                    <p className="mw-100 my-auto  mx-3">
                      API key :
                      {userData.api_key || "Your account is not activated"}
                    </p>
                  </div>

                  <div className="d-flex flex flex-nowrap my-1 mx-3">
                    <i className="ri-key-2-line  text-light-red text-muted fs-20"></i>
                    <p className="mw-100 my-auto  mx-3">
                      Sandbox API key :
                      {userData.sandbox_api_key || ""}
                    </p>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Profile;
