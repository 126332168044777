import React from "react";
import { Card, CardBody, Col, CardHeader } from "reactstrap";
import { SettlementsDetailsStatus } from "../../../helpers/orderColHelper";

import { config } from "../../../config";
import Loader from "../Loader";
import { useTranslation } from "react-i18next";
const { currency } = config;

const SettlementsDetailList = ({ oneSettlement }) => {
  
  const { t } = useTranslation();
  return (
    <Card className="my-5">
      <CardHeader>
        <Col className="col-sm">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex gap-4  ">
              <h5 className="card-title flex-grow-1 mb-0 ">{t("settlement")}</h5>
            </div>
          </div>
        </Col>
      </CardHeader>
      <CardBody>
        {oneSettlement?.length === 0 ? (
          <Loader />
        ) : (
          <div className="table-responsive table-card mb-1">
            <table
              className="table align-middle table-nowrap table-borderless"
              id="customerTable"
            >
              <thead className="table-light">
                <tr>
                  <th scope="col">{t("refundsAmount")}</th>
                  <th scope="col">{t("capturesAmount")} </th>
                  <th scope="col">{t("commissionAmount")}</th>
                  <th scope="col">{t("correctionAmount")}</th>
                  <th scope="col">{t("settlementAmount")}</th>
                  <th scope="col">{t("status")}</th>
                </tr>
              </thead>

              <tbody className="list form-check-all">
                <tr className="border-top">
                  <td>
                    <p className=" align-center m-0">
                      {oneSettlement.refunds_amount} {currency}
                    </p>
                  </td>
                  <td>
                    <p className=" align-center m-0">
                      {oneSettlement.captures_amount} {currency}
                    </p>
                  </td>
                  <td>
                    <p className=" align-center m-0">
                      {oneSettlement.commission_amount} {currency}
                    </p>
                  </td>
                  <td>
                    <p className=" align-center m-0">
                      {oneSettlement.correction_amount} {currency}
                    </p>
                  </td>
                  <td>
                    <p className=" align-center m-0">
                      {oneSettlement.settlement_amount} {currency}
                    </p>
                  </td>
                  <td>
                    <p className=" align-center m-0">
                      <SettlementsDetailsStatus value={oneSettlement.status} />
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </CardBody>
    </Card>
  );
};

export default SettlementsDetailList;
