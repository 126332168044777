import React from "react";

const AddButton = ({onClickFunction, img, text}) => {
  return (
    <div className="bg-white p-2 p-md-4 rounded add-card">
      <button
        type="button"
        className="w-100 h-100 border rounded border-dashed btn text-gray-650 d-flex flex-column align-items-center justify-content-center gap-2"
        onClick={onClickFunction}
      >
        {img}
        <p className="text-gray-650 fw-normal fs-6">{text}</p>
      </button>
    </div>
  );
};

export default AddButton;

