import React, { useEffect} from "react";
import { Container,  Row } from "reactstrap";

import BreadCrumb from "../../../Components/Common/BreadCrumb";

//Import actions
import { checkout } from "../../../store/ecommerce/action";
//redux
import { useSelector, useDispatch } from "react-redux";

const CheckoutResult = () => {
  const dispatch = useDispatch();


  const { paymentStatusFlag } = useSelector((state) => ({
    paymentStatusFlag: state.Ecommerce.paymentStatusFlag,
  }));

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const checkout_id = queryParams.get("id");
    if (checkout_id) {
      dispatch(checkout({ checkout_id: checkout_id }));
      // navigate.push("/checkout/")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, paymentStatusFlag]);

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Change card" pageTitle="Ecommerce" />
        <Row>
          <div className="col-xl-9 col-lg-8"></div>
        </Row>
      </Container>
    </div>
  );
};

export default CheckoutResult;
