import React, { useEffect, useState, useMemo } from "react";

import {
  Container,
  TabContent,
  TabPane,
  Row,
} from "reactstrap";
import * as moment from "moment";
import chartsIcon from "../../../assets/images/charts-dash.svg";

// RangeSlider
import "nouislider/distribute/nouislider.css";
import ResultModal from "../../../Components/Common/ResultModal";

import {
  Status,
  Bid,
} from "./EcommerceSpecialDealCol";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import TableContainer from "../../../Components/Common/TableContainer";

//Import actions
import {
  getSpecialDeals,
  addSpecialDeals,
  checkout,
  resetEcomFlag,
} from "../../../store/ecommerce/action";
import Select from "react-select";

//redux
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Loader from "../Loader";
const dealCategoryOptions = [
  { value: "", label: "All deals" },
  { value: "day", label: "Deal of the day" },
  { value: "week", label: "Deal of the week" },
  { value: "month", label: "Deal of the month" },
];

const EcommerceSpecialDeals = (props) => {
  const History = useHistory();

  const dispatch = useDispatch();
  const dispatchCheckout = useDispatch();
  const queryParams = new URLSearchParams(window.location.search);

  const { specialDeals, paymentStatusFlag, isSpecialDealAdd } = useSelector(
    (state) => ({
      specialDeals: state.Ecommerce.specialDeals,
      paymentStatusFlag: state.Ecommerce.paymentStatusFlag,
      isSpecialDealAdd: state.Ecommerce.isSpecialDealAdd,
    })
  );

  const dealsData = specialDeals.results;
  const dealsCount = specialDeals.count;

  const [dealCategory, setDealCategory] = useState("");
  const [resultModal, setResultModal] = useState(false);
  const [resultModalMsg, setResultModalMsg] = useState("");
  const [status, setStatus] = useState("");

  useEffect(() => {
    const checkout_id = queryParams.get("id");
    if (checkout_id) {
      dispatchCheckout(checkout({ checkout_id: checkout_id }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatchCheckout]);

  useEffect(() => {

    if (paymentStatusFlag === true) {
      const newSpecialDeal = {
        product: queryParams.get("product"),
        start_date: queryParams.get("start_date"),
        end_date: queryParams.get("end_date"),
        category: queryParams.get("category"),
        bid: queryParams.get("bid"),
        title: queryParams.get("title").replace(/-/g, " "),
      };

      dispatch(addSpecialDeals(newSpecialDeal));
      setStatus('success');
      setResultModalMsg("A new special deal was added successfully.");
      setResultModal(true)

      History.push("/special-deals");
    } else if (paymentStatusFlag === "failed") {
      setStatus('failed');
      setResultModalMsg("Adding a new special deal failed.");
      setResultModal(true)
      History.push("/special-deals");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, paymentStatusFlag]);

  useEffect(() => {
    setTimeout(() => {
      dispatch(resetEcomFlag());
    }, 3000);
  }, [dispatch, paymentStatusFlag]);

  const fetchData = (params = {}) => {
    const filterData = {...params};

    dealCategory !== "" && (filterData["category"] = dealCategory);
    dispatch(getSpecialDeals(filterData));
  };

  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format("YYYY-MM-DD");
    return date1;
  };


  useEffect(() => {
    fetchData({page:1});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dealCategory,
    isSpecialDealAdd,
    ]);



  const columns = useMemo(
    () => [

      {
        Header: "Created Date",
        accessor: "created_date",
        Cell: (cellProps) => {
          return handleValidDate(cellProps.value);
        },
      },
      {
        Header: "Special deal title",
        accessor: "title",
        filterable: true,
      },

      {
        Header: "Start Date",
        accessor: "start_date",
        filterable: false,
      },

      {
        Header: "End Date",
        accessor: "end_date",
        filterable: false,
      },

      {
        Header: "Bid",
        accessor: "bid",
        filterable: false,
        Cell: (cellProps) => {
          return <Bid {...cellProps} />;
        },
      },
 
      {
        Header: "Rank",
        accessor: "rank",
        filterable: false,
      },

      {
        Header: "Status",
        accessor: "status",
        filterable: false,
        Cell: (cellProps) => {
          return <Status {...cellProps} />;
        },
      },
    ],
    []
  );
  document.title = "Special Deals | Salis- Merchants Dashboard";

  return (
    <div className="page-content">
      <ResultModal
        show={resultModal}
        onCloseClick={() => setResultModal(false)}
        message={resultModalMsg}
        status={status}
      />
      <Container fluid>
        <BreadCrumb title="Special Deals" pageTitle="Marketing" />

        <Row>
          <div className="row">
            <div className="col">
              <div>
                <div className="card">
                  <div className="card-header border-0 ">
                    <div className="row g-4 table-header-margin headTable">
                      <div className="col-sm-auto ">
                        <Link
                          to="/add-special-deals"
                          className="btn"
                          style={{ backgroundColor: "#EE856F", color: "white" }}
                        >
                          <i className="ri-add-line align-bottom me-1"></i>
                          Create Special Deal
                        </Link>
                      </div>

                      <div className="col-sm ">
                        <div className="d-flex justify-content-lg-end justify-content-sm-between categoryHead">
                          <Link
                            to={`/dashboard`}
                            className="d-inline  text-decoration"
                          >
                            <img
                              className={"w-75"}
                              alt={"charts"}
                              src={chartsIcon}
                            ></img>
                          </Link>

                          <div style={{ display: "flex" }}>
                            <h6 className="pt-2 px-2">Deals By Category:</h6>

                            <Select
                              styles={{ width: "300px" }}
                              onChange={(e) => {
                                setDealCategory(e.value);
                              }}
                              options={dealCategoryOptions}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card-header">
                    <div className="row align-items-center">
                      <div className="col-auto">
                        <div id="selection-element">
                          <div className="my-n1 d-flex align-items-center text-muted">
                            Select
                            <div
                              id="select-content"
                              className="text-body fw-semibold px-1"
                            ></div>
                            Result
                            <button
                              type="button"
                              className="btn btn-link link-danger p-0 ms-3"
                            >
                              Remove
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card-body table-responsive border h-100">
                    <TabContent className="text-muted">
                      <TabPane>
                        <div
                          id="table-product-list-all"
                          className="table-card gridjs-border-none pb-2"
                        >
                          {dealsData && dealsData.length > 0 ? (
                  
                              <TableContainer
                              columns={columns}
                              data={dealsData || []}
                              customPageSize={10}
                              numOfPages={dealsCount}
                              fetchData={fetchData}
                              divClass="overflow-visible table table-responsive mb-1"
                              tableClass="mb-0 align-middle table-borderless pb-5"
                              theadClass="table-light text-muted"
                              />
                          ) : (
                            <Loader />
                          )}
                        </div>
                      </TabPane>
                    </TabContent>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default EcommerceSpecialDeals;
