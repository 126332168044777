import React, { useState, useEffect, useMemo } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Ecommerce/Loader";
import { config } from "../../../config";
import moment from "moment";
import { useTranslation } from "react-i18next";
import DateFilterCards from "../DateFilterCards";
import { getItemsSales } from "../../../store/charts/action";
import { TableToPDF } from "../../../helpers/tableToPdf";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
const { currency } = config;
const ItemsSales = () => {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [callApi, setCallApi] = useState(false);
  const now = new Date();
  const firstDay = moment(now).startOf('month').format("YYYY-MM-DD");
  const lastDay = moment(now).endOf('month').format('YYYY-MM-DD');
  const [action, setAction] = useState("month");
  const [startDate, setStartDate] = useState(firstDay);
  const [endDate, setEndDate] = useState(lastDay);


  const { itemsSales } = useSelector((state) => ({
    itemsSales: state.Charts.itemsSales,

  }));

  useEffect(() => {
    const params = {
      start_date: startDate,
      end_date: endDate,
      action: action,
    };
    dispatch(getItemsSales(params));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, callApi]);
  const columns = useMemo(
    () => [
      {
        name: <span className="fw-normal text-gray-650">{t("item")}</span>,
        selector: (row) => (
          <>

            <p className="m-0 p-0 text-wrap fw-bold">{row.name}</p>
            <p className="m-0 p-0 text-gray-650">#{row.reference_id}</p>
          </>
        ),
      },

      {
        name: <span className="fw-normal text-gray-650">{t("price")}</span>,
        selector: (row) => (
          <p className="m-0 p-0 fw-bold">{row.price + " " + currency}</p>
        ),
        sortable: false,
      },
      {
        name: <span className="fw-normal text-gray-650">{t("unitSold")}</span>,
        selector: (row) => (
          <p className="m-0 p-0 fw-normal">{row.quantity_sold}</p>
        ),
        sortable: false,
      },
      {
        name: <span className="fw-normal text-gray-650">{t("date")}</span>,
        selector: (row) => (
          <p className="m-0 p-0 fw-normal">{moment(new Date(row.date)).format("DD/MM/YYYY")}</p>
        ),
        sortable: false,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const printTableToPDF = () => {
    TableToPDF(itemsSales.data, "Items Sales");
  };
  const columnNames = columns.map((col) => col.name.props.children);
  return (
    <div className="py-4 mt-2 ">
      <div className=" d-flex bg-white justify-content-between">
        <div className="rounded d-flex justify-content-between align-items-center py-3 p-2">
          <h4 className="card-title mb-0">{t(`salesReport${action}`)}</h4>
        </div>
        <div className="d-flex flex justify-content-center "> 
        <div className="my-auto px-2 dashboard_picker">
          <DateFilterCards
            action={action}
            setAction={setAction}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            startDate={startDate}
            endDate={endDate}
            setCallApi={setCallApi}
            callApi={callApi}
          />
        </div>
        <UncontrolledDropdown className="dropdown my-auto d-inline-block">
            <DropdownToggle className="btn border-0 btn-sm" tag="button">
              <i className="ri-more-2-fill align-middle"></i>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              <DropdownItem href="#!" onClick={printTableToPDF}>
                <i className="ri-printer-line align-bottom me-2 text-muted"></i>
                Print as PDF
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          </div>
      </div>
      <div className="product-visitors-table-rdt">
        {itemsSales.data && itemsSales.data.length > 0 ? (
          <DataTable columns={columns} data={itemsSales.data} responsive />
        ) : (
          <Loader columnNames={columnNames} />
        )}
      </div>
    </div>
  );
};
export default ItemsSales;
