import React, { useEffect, useMemo } from "react";
import { Container, Row, TabContent, TabPane } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import TableContainer from "../../../Components/Common/TableContainer";
import { SettlementsDetailsStatus, handleDate } from "../../../helpers/orderColHelper";
//redux
import { useSelector, useDispatch } from "react-redux";
//Import actions
import { getTransactions } from "../../../store/ecommerce/action";
import { config } from "../../../config";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../Loader";
const { currency } = config;
const EcommerceSettlements = () => {
  const dispatch = useDispatch();
  const { transactions } = useSelector((state) => ({
    transactions: state.Ecommerce.transactions,
  }));

  const fetchData = (params = {}) => {
    const filterData = { ...params };

    dispatch(getTransactions(filterData));
  };

  useEffect(() => {
    fetchData({ page: 1 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dataTransactions = transactions.results;
  const dataTransactionsCount = transactions.count;

  const columns = useMemo(
    () => [
      {
        Header: "Transaction Id",
        filterable: false,
        Cell: (dataOrders) => {
          return (
            <React.Fragment>#{dataOrders.row.original.id}</React.Fragment>
          );
        },
      },
      {
        Header: "Source",
        accessor: "source",
        Cell: (dataOrders) => {
          return (
            <React.Fragment>
              <p className="amount-font-weight align-center m-0">
                {dataOrders.row.original.source}
              </p>
            </React.Fragment>
          );
        },
      },
      {
        Header: "Created Date",
        filterable: false,
        Cell: (dataOrders) => {
          return (
            <React.Fragment>
              {handleDate(dataOrders.row.original.created_at)}
            </React.Fragment>
          );
        },
      },
      {
        Header: "Amount",
        accessor: "amount",
        Cell: (dataOrders) => {
          return (
            <React.Fragment>
              <p className="amount-font-weight align-center m-0">
                {dataOrders.row.original.amount} {currency}
              </p>
            </React.Fragment>
          );
        },
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: (cellProps) => {
          return <SettlementsDetailsStatus {...cellProps} />;
        },
      },
    ],
    []
  );

  document.title = "Transactions | Salis";
  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Transactions list" pageTitle="Transactions" />

        <Row className="justify-content-center">
          <div className="col">
            <div>
              <div className="card">
                <div className="card-body table-responsive">
                  <TabContent className="text-muted">
                    <TabPane>
                      <div
                        id="table-product-list-all"
                        className="table-card gridjs-border-none pb-2"
                      >
                        {dataTransactions && dataTransactions.length > 0 ? (
                          <TableContainer
                            columns={columns}
                            data={dataTransactions || []}
                            numOfPages={dataTransactionsCount}
                            customPageSize={10}
                            fetchData={fetchData}
                            divClass="overflow-visible table table-responsive mb-1"
                            tableClass="mb-0 align-middle table-borderless pb-5"
                            theadClass="table-light text-muted"
                          />

                        ) : (
                          <Loader />
                        )}
                      </div>
                    </TabPane>
                  </TabContent>
                </div>
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default EcommerceSettlements;
