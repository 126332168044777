import { env } from './env'

const config = {
  API_URL: "https://salis-django-db3b75b6f714.herokuapp.com/",
  API_VERSION:"api/v1",
  PUBLIC_URL:env.REACT_APP_PUBLIC_URL,
  GOOGLE_MAP_API_KEY:"AIzaSyBvOXOEZaYCGtZtML3LK6m8womMB3oSYmw",
  TOAST_AUTOCLOSE_TIMER:5000,
  TOAST_AUTOOPEN_TIMER:1200,
  LOADER_RESET_TIMER:2000,
  SKELELTON_RESET_TIMER:1000,
  IN_STORE_MAX_AMOUNT:2500,
  currency:"SAR",
};

export { config }
