import {
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_FAIL,
  RESET_PASSWORD_FLAG,
} from "./actionTypes";

const initialState = {
  forgetPasswordFlag: "",
  changePasswordFlag: "",
};

const forgetPassword = (state = initialState, action) => {
  switch (action.type) {
    case FORGET_PASSWORD_SUCCESS:
      state = {
        ...state,
        forgetPasswordFlag:
          "An email has been sent to your email. Check your inbox and click the reset email link provided",
        changePasswordFlag: "Password has been changed successfully",
      };
      break;
    case FORGET_PASSWORD_FAIL:
      state = {
        ...state,
        forgetPasswordFlag: "No account found with this email address",
        changePasswordFlag: "Changing password was fail",
      };
      break;

    case RESET_PASSWORD_FLAG:
      return {
        ...state,

        forgetPasswordFlag: "",
        changePasswordFlag: "",
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default forgetPassword;
