import { takeEvery, fork, put, all, call } from "redux-saga/effects";

//Account Redux states
import {
  GET_TOTAL_SPENT,
  GET_ACTUAL_CUSTOMERS,
  GET_VISITORS,
  GET_DAILY_VISITORS,
  GET_MONTHLY_VISITORS,
  GET_DAILY_CUSTOMERS,
  GET_3D_CUSTOMERS,
  GET_3D_COMMISSIONS,
  GET_TOTAL_CONVERSION_RATE,
  GET_PRODUCTS_VISITORS_DATA,
  GET_WALLET_STATEMENT_DATA,
  GET_ITEMS_CONVERSION_RATE,
  GET_TOTAL_SALES,
  GET_TOP_ITEMS,
  GET_ITEMS_SALES,
  GET_PPC_DATA_SPENDING,
  GET_PPC_DATA_TABLE,
  GET_TOTAL_MARKETING_SPENDING,
  GET_TOTAL_RECEIVABLES_CHART,
  GET_TOTAL_RECIEVABLES,
  GET_ROI,
  GET_ROI_CHART,
  GET_MARKETING_SPENDING_CARDS,
  GET_RECEIVABLES_DUE
} from "./actionTypes";




import {
  getTotalRecievablesSuccess,
  getTotalRecievablesFail,
  getItemsSalesSuccess,
  getItemsSalesFail,
  getTopItemsSuccess,
  getTopItemsFail,
  getTotalSalesSuccess,
  getTotalSalesFail,
  getItemConversionRateSuccess,
  getItemConversionRateFail,
  getCommissionSuccess,
  getCommissionFail,
  getTotalConversionRateSuccess,
  getTotalConversionRateFail,
  get3DCustomersSuccess,
  get3DCustomersFail,
  getTotalSpentFail,
  getTotalSpentSuccess,
  getActualCustomersSuccess,
  getActualCustomersFail,
  getVisitorsSuccess,
  getVisitorsFail,
  getDailyVisitorsSuccess,
  getDailyVisitorsFail,
  getMonthlyVisitorsSuccess,
  getMonthlyVisitorsFail,
  getDailyCustomersSuccess,
  getDailyCustomersFail,
  getProductsVisitorsDataSuccess,
  getProductsVisitorsDataFail,
  getWalletStatementDataSuccess,
  getWalletStatementDataFail,
  getPPCDataChartSuccess,
  getPPCDataChartFail,
  getPPCDataTableSuccess,
  getPPCDataTableFail,
  getTotalMarketingSpendingSuccess,
  getTotalMarketingSpendingFail,
  getTotalReceivablesChartSuccess,
  getTotalReceivablesChartFail,
  getROISuccess,
  getROIFail,
  getROIChartSuccess,
  getROIChartFail,
  getMarketingSpendingCardsSuccess,
  getMarketingSpendingCardsFail,
  getReceivablesDueFail,
  getReceivablesDueSuccess
} from "./action";

//Include Both Helper File with needed methods
import {
  getTotalSpent as getTotalSpentApi,
  getActualCustomers as getActualCustomersApi,
  getVisitors as getVisitorsApi,
  getDailyVisitors as getDailyVisitorsApi,
  getMonthlyVisitors as getMonthlyVisitorsApi,
  getCommission as getCommissionApi,
  getTotalConversionRate as getTotalConversionRateApi,
  getProductVisitors as getProductVisitorsApi,
  getWalletStatement as getWalletStatementApi,
  getItemConversionRate as getItemConversionRateApi,
  getTotalSales as getTotalSalesApi,
  getTopItems as getTopItemsApi,
  getItemsSales as getItemsSalesApi,
  getPPC as getPPCApi,
  getPPCTable as getPPCTableApi,
  getTotalMarketingSpending as getTotalMarketingSpendingApi,
  getTotalRecievables as getTotalRecievablesApi,
  getTotalReceivablesChart as getTotalReceivablesChartApi,
  getROI as getROIApi,
  getROIChart as getROIChartApi,
  getMarketingSpendingCards as getMarketingSpendingCardsApi,
  getReceivablesDue as getReceivablesDueApi
} from "../../helpers/salis_api";

function* getTotalRecievables({ payload: data }) {
  try {
    const response = yield call(getTotalRecievablesApi, data);
    yield put(getTotalRecievablesSuccess(response));
  } catch (error) {
    yield put(getTotalRecievablesFail(error));
  }
}

export function* watchGetTotalRecievables() {
  yield takeEvery(GET_TOTAL_RECIEVABLES, getTotalRecievables);
}



function* getItemsSales({ payload: data }) {
  try {
    const response = yield call(getItemsSalesApi, data);
    yield put(getItemsSalesSuccess(response));
  } catch (error) {
    yield put(getItemsSalesFail(error));
  }
}

export function* watchGetItemsSales() {
  yield takeEvery(GET_ITEMS_SALES, getItemsSales);
}




function* getTopItems({ payload: data }) {
  try {
    const response = yield call(getTopItemsApi, data);
    yield put(getTopItemsSuccess(response));
  } catch (error) {
    yield put(getTopItemsFail(error));
  }
}

export function* watchGetTopItems() {
  yield takeEvery(GET_TOP_ITEMS, getTopItems);
}



function* getTotalSales({ payload: data }) {
  try {
    const response = yield call(getTotalSalesApi, data);
    yield put(getTotalSalesSuccess(response));
  } catch (error) {
    yield put(getTotalSalesFail(error));
  }
}

export function* watchGetTotalSales() {
  yield takeEvery(GET_TOTAL_SALES, getTotalSales);
}

function* getItemConversionRate({ payload: data }) {
  try {
    const response = yield call(getItemConversionRateApi, data);
    yield put(getItemConversionRateSuccess(response));
  } catch (error) {
    yield put(getItemConversionRateFail(error));
  }
}

export function* watchGetItemConversionRate() {
  yield takeEvery(GET_ITEMS_CONVERSION_RATE, getItemConversionRate);
}

function* getTotalConversionRate({ payload: data }) {
  try {
    const response = yield call(getTotalConversionRateApi, data);
    yield put(getTotalConversionRateSuccess(response));
  } catch (error) {
    yield put(getTotalConversionRateFail(error));
  }
}

export function* watchGetTotalConversionRate() {
  yield takeEvery(GET_TOTAL_CONVERSION_RATE, getTotalConversionRate);
}

function* getTotalSpent({ payload: data }) {
  try {
    const response = yield call(getTotalSpentApi, data);
    yield put(getTotalSpentSuccess(response));
  } catch (error) {
    yield put(getTotalSpentFail(error));
  }
}

export function* watchGetTotalSpent() {
  yield takeEvery(GET_TOTAL_SPENT, getTotalSpent);
}

function* getCommission({ payload: data }) {
  try {
    const response = yield call(getCommissionApi, data);
    yield put(getCommissionSuccess(response));
  } catch (error) {
    yield put(getCommissionFail(error));
  }
}

export function* watchGetCommission() {
  yield takeEvery(GET_3D_COMMISSIONS, getCommission);
}

function* get3DCustomers({ payload: data }) {
  try {
    const response = yield call(getActualCustomersApi, data);
    yield put(get3DCustomersSuccess(response));
  } catch (error) {
    yield put(get3DCustomersFail(error));
  }
}

export function* watchGet3DCustomers() {
  yield takeEvery(GET_3D_CUSTOMERS, get3DCustomers);
}

function* getDailyCustomers({ payload: data }) {
  try {
    const response = yield call(getActualCustomersApi, data);
    yield put(getDailyCustomersSuccess(response));
  } catch (error) {
    yield put(getDailyCustomersFail(error));
  }
}

export function* watchGetDailyCustomers() {
  yield takeEvery(GET_DAILY_CUSTOMERS, getDailyCustomers);
}
function* getActualCustomers({ payload: data }) {
  try {
    const response = yield call(getActualCustomersApi, data);
    yield put(getActualCustomersSuccess(response));
  } catch (error) {
    yield put(getActualCustomersFail(error));
  }
}

export function* watchGetActualCustomers() {
  yield takeEvery(GET_ACTUAL_CUSTOMERS, getActualCustomers);
}
function* getVisitors({ payload: data }) {
  try {
    const response = yield call(getVisitorsApi, data);
    yield put(getVisitorsSuccess(response));
  } catch (error) {
    yield put(getVisitorsFail(error));
  }
}

export function* watchGetVisitors() {
  yield takeEvery(GET_VISITORS, getVisitors);
}
function* getDailyVisitors({ payload: data }) {
  try {
    const response = yield call(getDailyVisitorsApi, data);
    yield put(getDailyVisitorsSuccess(response));
  } catch (error) {
    yield put(getDailyVisitorsFail(error));
  }
}

export function* watchGetDailyVisitors() {
  yield takeEvery(GET_DAILY_VISITORS, getDailyVisitors);
}
function* getMonthlyVisitors({ payload: data }) {
  try {
    const response = yield call(getMonthlyVisitorsApi, data);
    yield put(getMonthlyVisitorsSuccess(response));
  } catch (error) {
    yield put(getMonthlyVisitorsFail(error));
  }
}

export function* watchGetMonthlyVisitors() {
  yield takeEvery(GET_MONTHLY_VISITORS, getMonthlyVisitors);
}
function* getProductVisitors({ payload: data }) {
  try {
    const response = yield call(getProductVisitorsApi, data);
    yield put(getProductsVisitorsDataSuccess(response));
  } catch (error) {
    yield put(getProductsVisitorsDataFail(error));
  }
}

export function* watchGetProductVisitors() {
  yield takeEvery(GET_PRODUCTS_VISITORS_DATA, getProductVisitors);
}
function* getWalletStatement({ payload: data }) {
  try {
    const response = yield call(getWalletStatementApi, data);
    yield put(getWalletStatementDataSuccess(response));
  } catch (error) {
    yield put(getWalletStatementDataFail(error));
  }
}

export function* watchGetWalletStatement() {
  yield takeEvery(GET_WALLET_STATEMENT_DATA, getWalletStatement);
}
function* getPPC({ payload: data }) {
  try {
    const response = yield call(getPPCApi, data);
    yield put(getPPCDataChartSuccess(response));
  } catch (error) {
    yield put(getPPCDataChartFail(error));
  }
}

export function* watchGetPPC() {
  yield takeEvery(GET_PPC_DATA_SPENDING, getPPC);
}
function* getPPCTable({ payload: data }) {
  try {
    const response = yield call(getPPCTableApi, data);
    yield put(getPPCDataTableSuccess(response));
  } catch (error) {
    yield put(getPPCDataTableFail(error));
  }
}

export function* watchGetPPCTable() {
  yield takeEvery(GET_PPC_DATA_TABLE, getPPCTable);
}
function* getTotalMarketingSpending({ payload: data }) {
  try {
    const response = yield call(getTotalMarketingSpendingApi, data);
    yield put(getTotalMarketingSpendingSuccess(response));
  } catch (error) {
    yield put(getTotalMarketingSpendingFail(error));
  }
}

export function* watchGetTotalMarketingSpending() {
  yield takeEvery(GET_TOTAL_MARKETING_SPENDING, getTotalMarketingSpending);
}
function* getTotalReceivablesChart({ payload: data }) {
  try {
    const response = yield call(getTotalReceivablesChartApi, data);
    yield put(getTotalReceivablesChartSuccess(response));
  } catch (error) {
    yield put(getTotalReceivablesChartFail(error));
  }
}

export function* watchGetTotalReceivablesChart() {
  yield takeEvery(GET_TOTAL_RECEIVABLES_CHART, getTotalReceivablesChart);
}
function* getROI({ payload: data }) {
  try {
    const response = yield call(getROIApi, data);
    yield put(getROISuccess(response));
  } catch (error) {
    yield put(getROIFail(error));
  }
}

export function* watchGetROI() {
  yield takeEvery(GET_ROI, getROI);
}
function* getROIChart({ payload: data }) {
  try {
    const response = yield call(getROIChartApi, data);
    yield put(getROIChartSuccess(response));
  } catch (error) {
    yield put(getROIChartFail(error));
  }
}

export function* watchGetROIChart() {
  yield takeEvery(GET_ROI_CHART, getROIChart);
}
function* getMarketingSpendingCards({ payload: data }) {
  try {
    const response = yield call(getMarketingSpendingCardsApi, data);
    yield put(getMarketingSpendingCardsSuccess(response));
  } catch (error) {
    yield put(getMarketingSpendingCardsFail(error));
  }
}

export function* watchGetgetMarketingSpendingCards() {
  yield takeEvery(GET_MARKETING_SPENDING_CARDS, getMarketingSpendingCards);
}
function* getReceivablesDue({ payload: data }) {
  try {
    const response = yield call(getReceivablesDueApi, data);
    yield put(getReceivablesDueSuccess(response));
  } catch (error) {
    yield put(getReceivablesDueFail(error));
  }
}

export function* watchGetReceivablesDue() {
  yield takeEvery(GET_RECEIVABLES_DUE, getReceivablesDue);
}


function* chartsSaga() {
  yield all([fork(watchGetTotalSpent)]);
  yield all([fork(watchGetActualCustomers)]);
  yield all([fork(watchGetVisitors)]);
  yield all([fork(watchGetDailyVisitors)]);
  yield all([fork(watchGetMonthlyVisitors)]);
  yield all([fork(watchGetDailyCustomers)]);
  yield all([fork(watchGet3DCustomers)]);
  yield all([fork(watchGetCommission)]);
  yield all([fork(watchGetTotalConversionRate)]);
  yield all([fork(watchGetProductVisitors)]);
  yield all([fork(watchGetWalletStatement)]);
  yield all([fork(watchGetItemConversionRate)]);
  yield all([fork(watchGetTotalSales)]);
  yield all([fork(watchGetTopItems)]);
  yield all([fork(watchGetItemsSales)]);
  yield all([fork(watchGetPPC)]);
  yield all([fork(watchGetPPCTable)]);
  yield all([fork(watchGetTotalMarketingSpending)]);
  yield all([fork(watchGetTotalReceivablesChart)]);
  yield all([fork(watchGetTotalRecievables)]);
  yield all([fork(watchGetROI)]);
  yield all([fork(watchGetROIChart)]);
  yield all([fork(watchGetgetMarketingSpendingCards)]);
  yield all([fork(watchGetReceivablesDue)]);
  
}

export default chartsSaga;
