const personSvg = (fill ) => {
    return (
      <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" role="img">
        <rect width="48" height="48" rx="4" fill={fill} fillOpacity="0.1" />
        <g clipPath="url(#clip0_824_4285)">
          <path d="M32 34H30V32C30 31.2044 29.6839 30.4413 29.1213 29.8787C28.5587 29.3161 27.7956 29
           27 29H21C20.2044 29 19.4413 29.3161 18.8787 29.8787C18.3161 30.4413 18 31.2044 18 32V34H16V32C16
            30.6739 16.5268 29.4021 17.4645 28.4645C18.4021 27.5268 19.6739 27 21 27H27C28.3261 27 29.5979
             27.5268 30.5355 28.4645C31.4732 29.4021 32 30.6739 32 32V34ZM24 25C23.2121 25 22.4319 24.8448
              21.7039 24.5433C20.9759 24.2417 20.3145 23.7998 19.7574 23.2426C19.2002 22.6855 18.7583 
              22.0241 18.4567 21.2961C18.1552 20.5681 18 19.7879 18 19C18 18.2121 18.1552 17.4319
               18.4567 16.7039C18.7583 15.9759 19.2002 15.3145 19.7574 14.7574C20.3145 14.2002 
               20.9759 13.7583 21.7039 13.4567C22.4319 13.1552 23.2121 13 24 13C25.5913 13
                27.1174 13.6321 28.2426 14.7574C29.3679 15.8826 30 17.4087 30 19C30 20.5913
                 29.3679 22.1174 28.2426 23.2426C27.1174 24.3679 25.5913 25 24 25ZM24 23C25.0609
                  23 26.0783 22.5786 26.8284 21.8284C27.5786 21.0783 28 20.0609 28 19C28 17.9391
                   27.5786 16.9217 26.8284 16.1716C26.0783 15.4214 25.0609 15 24 15C22.9391 15 21.9217
                    15.4214 21.1716 16.1716C20.4214 16.9217 20 17.9391 20 19C20 20.0609 20.4214 21.0783 
                    21.1716 21.8284C21.9217 22.5786 22.9391 23 24 23Z" fill={fill} />
        </g>
        <defs>
          <clipPath id="clip0_824_4285">
            <rect width="24" height="24" fill="white" transform="translate(12 12)" />
          </clipPath>
        </defs>
      </svg>
  
  
    );
  };

  export default personSvg;