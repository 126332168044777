import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Container, FormFeedback, Input, Label } from "reactstrap";
import { withRouter, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import ConfirmationModal from "../../Components/Common/ConfirmationModal";

import salisLogo from "../../assets/images/logo.png";
import lockLogo from "../../assets/images/forgetPassword/lock.svg";
import { useFormik } from "formik";
import { forgetPassword, resetPasswordFlag } from "../../store/actions";
import { config } from "../../config";
import * as Yup from "yup";
const { TOAST_AUTOCLOSE_TIMER } = config;
const { PUBLIC_URL } = config;

const validationSchema = Yup.object({
  email: Yup.string()
    .email()
    .required("Please Enter Your Email")
    .max(40, "Too Long!"),
});

const ForgetPasswordPage = (props) => {
  const dispatch = useDispatch();
  const { forgetPasswordFlag } = useSelector((state) => ({
    forgetPasswordFlag: state.ForgetPassword.forgetPasswordFlag,
  }));
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [sendingButton, setsendingButton] = useState(true);

  const formik = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
    },
    validationSchema,
    onSubmit: (values) => {
      setConfirmationModal(true);
    },
  });
  const handleNotValidForm = (e) => {
    e.preventDefault();
    formik.touched.email = true;
  };

  useEffect(() => {
    if (forgetPasswordFlag) {
      toast(forgetPasswordFlag, {
        position: "top-right",
        hideProgressBar: true,
        className: forgetPasswordFlag.includes("No account")
          ? "bg-danger text-white"
          : "bg-success text-white",
        progress: undefined,
        toastId: "",
      });
      forgetPasswordFlag.includes("No account") &&  setsendingButton(true)
    }
    dispatch(resetPasswordFlag());
  }, [dispatch, forgetPasswordFlag]);

  const handleForgetPassword = () => {
    setConfirmationModal(false);

    const data = {
      callback_url: `${PUBLIC_URL}change-password/`,
      email: formik.values.email,
    };
    dispatch(forgetPassword(data));
    setsendingButton(false);
  };
  document.title = "Reset Password | Salis- Merchants Dashboard";
  return (
    <div className="auth-page-content">
      <Container>
        <ToastContainer
          autoClose={TOAST_AUTOCLOSE_TIMER}
          style={{ width: "auto" }}
        />
        <ConfirmationModal
          show={confirmationModal}
          onCloseClick={() => setConfirmationModal(false)}
          message={"Yor are going to request resetting password link! "}
          action={handleForgetPassword}
        />
        <Row className="justify-content-center">
          <Col md={8} lg={6} xl={5}>
            <div className="text-center mt-sm-5 mb-4 ">
              <div>
                <Link to="/" className="d-inline-block auth-logo">
                  <img src={salisLogo} alt="" height="50" />
                </Link>
              </div>
              <div className="pt-5">
                <Link to="/" className="d-inline-block auth-logo">
                  <img src={lockLogo} alt="" />
                </Link>
              </div>
              <h4 className="mt-3"> Reset my password</h4>
              <p className="mt-3 fs-15 fw-medium text-muted">
                we will send you a link to your email for changing your password
              </p>
            </div>
          </Col>
        </Row>

        <Row className="justify-content-center pt-4">
          <Col md={8} lg={6} xl={5}>
            <form
              method="post"
              onSubmit={(e) =>
                formik.isValid ? formik.handleSubmit(e) : handleNotValidForm(e)
              }
            >
              <div className="mb-3">
                <Label htmlFor="useremail" className="form-label">
                  Email address <span className="text-danger">*</span>
                </Label>
                <div className="position-relative auth-pass-inputgroup mb-3">
                  <Input
                    id="email"
                    name="email"
                    className="form-control padding_input"
                    placeholder="Please enter your email address"
                    type="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email || ""}
                    invalid={
                      formik.touched.email && formik.errors.email ? true : false
                    }
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <FormFeedback type="invalid">
                      <div>{formik.errors.email}</div>
                    </FormFeedback>
                  ) : null}
                  <button
                    className="btn btn-link position-absolute start-0 top-0 text-decoration-none text-muted"
                    type="button"
                    disabled
                    id="password-addon"
                  >
                    <i className="ri-mail-line"></i>
                  </button>
                </div>
              </div>
              <div className="mt-4 text-center">
                {sendingButton ? (
                  <button
                    type="submit"
                    className="btn  bg-light-red text-white w-100"
                  >
                    Send
                  </button>
                ) : (
                  <button
                  type="button"

                    className="btn  bg-light-red text-white w-100"
                  >
                    Ok
                  </button>
                )}

         
              </div>
              <div className="mt-4 text-center">
                <p className="mb-0">
                  Wait, I remember my password...
                  <Link
                    to="/login"
                    className="fw-semibold text-primary text-decoration-underline text-light-red"
                  >
                    
                    Login...
                  </Link>
                </p>
              </div>
            </form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

ForgetPasswordPage.propTypes = {
  history: PropTypes.object,
};

export default withRouter(ForgetPasswordPage);
