import { takeEvery, fork, put, all, call } from "redux-saga/effects";

//Account Redux states
import { ADD_NEW_MERCHANT } from "./actionTypes";
import { addMerchantSuccessful, addMerchantFailed } from "./actions";

//Include Both Helper File with needed methods
import { addMerchant } from "../../../helpers/salis_api";

// initialize relavant method of both Auth

// Is user register successfull then direct plot user in redux.
function* registerMerchant({ payload: merchant }) {
  try {
    const response = yield call(addMerchant, merchant);

    yield put(addMerchantSuccessful(response));
  } catch (error) {
    yield put(addMerchantFailed(error));
  }
}

export function* watchMerchantRegister() {
  yield takeEvery(ADD_NEW_MERCHANT, registerMerchant);
}

function* accountSaga() {
  yield all([fork(watchMerchantRegister)]);
}

export default accountSaga;
