export const DaysFinder = (start, end) => {
  let result = [], current = new Date(start);
  while (current <= end)
    result.push(current.toISOString().slice(0, 10)) && (current = new Date(current)) && current.setDate(current.getDate() + 1);
  return result;
}

export const DaysNumber = (date_1, date_2) => {
  let difference = date_1.getTime() - date_2.getTime();
  let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
  return TotalDays;
}

export const MonthsFinder = (startDate, endDate) => {
  var start = startDate.split("-");
  var end = endDate.split("-");
  var startYear = parseInt(start[0]);
  var endYear = parseInt(end[0]);
  var dates = [];

  for (var i = startYear; i <= endYear; i++) {
    var endMonth = i !== endYear ? 11 : parseInt(end[1]) - 1;
    var startMon = i === startYear ? parseInt(start[1]) - 1 : 0;
    for (var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
      var month = j + 1;
      var displayMonth = month < 10 ? "0" + month : month;
      dates.push([i, displayMonth, "01"].join("-"));
    }
  }
  return dates;
};
export const QuartersFinder = (startDate, endDate) => {
  let quarters = [];
  let start = startDate.split("-");
  let end = endDate.split("-");
  let start_year = parseInt(start[0]);
  let end_year = parseInt(end[0]);
  let start_month = parseInt(start[1]);
  let end_month = parseInt(end[1]);
  let start_quarter = Math.ceil(start_month / 3);
  let end_quarter = Math.ceil(end_month / 3);

  // Swap start and end date if start is later than end
  if (start_year > end_year || (start_year === end_year && start_quarter > end_quarter)) {
    [startDate, endDate] = [endDate, startDate];
    [start_year, end_year] = [end_year, start_year];
    [start_month, end_month] = [end_month, start_month];
    [start_quarter, end_quarter] = [end_quarter, start_quarter];
  }

  let quarter = start_quarter;

  for (let y = start_year; y <= end_year; y++) {
    let max_qtr;
    if (y === end_year) {
      max_qtr = end_quarter;
    } else {
      max_qtr = 4;
    }

    for (let q = quarter; q <= max_qtr; q++) {
      let start_month_num = (q * 3) - 2;
      let displayMonth = start_month_num < 10 ? "0" + start_month_num : start_month_num;
      quarters.push([y, displayMonth, "01"].join("-"));
    }

    // Reset quarter to 1 after the first year
    quarter = 1;
  }
  return quarters;
};



export const YearsFinder = (startDate, endDate) => {
  var start = startDate.split("-");
  var end = endDate.split("-");
  var startYear = parseInt(start[0]);
  var endYear = parseInt(end[0]);
  var dates = [];

  for (var i = startYear; i <= endYear; i++) {
  dates.push([i,"01-01"].join("-"));
  }
  return dates;
};

export const timeFilter = {
  day: "Day",
  month: "Month",
  quarter: "Quarter",
  year: "Year",
};
