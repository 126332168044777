import { useState, useEffect } from "react";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import { Modal, ModalBody } from "reactstrap";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import Geocode from "react-geocode";
import Combobox from "react-widgets/Combobox";
import "react-widgets/styles.css";

import { config } from "../../config";
const { GOOGLE_MAP_API_KEY } = config;

Geocode.setApiKey(GOOGLE_MAP_API_KEY);
Geocode.setLanguage("en");
export default function Places({ show, onCloseClick, setAddress, validation }) {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: GOOGLE_MAP_API_KEY,
    libraries: ["places"],
  });
  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalBody className="py-3 px-3">
        <>
          <div className="mt-2 text-center">
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-3">
              {isLoaded && (
                <Map setAddress={setAddress} validation={validation} />
              )}
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm bg-light-red text-white "
              data-bs-dismiss="modal"
              onClick={onCloseClick}
            >
              Close
            </button>
          </div>
        </>
      </ModalBody>
    </Modal>
  );
}

const containerStyle = {
  width: "433px",
  height: "400px",
};

function Map({ setAddress, validation }) {
  const [selected, setSelected] = useState({});
  useEffect(() => {
    navigator.geolocation.getCurrentPosition((positions) => {
      setSelected({
        lat: positions.coords.latitude,
        lng: positions.coords.longitude,
      });
    });
  }, []);

  const handleClickedMap = (e) => {
    let latitude = e.latLng.lat();
    let longtitude = e.latLng.lng();
    setSelected({ lat: latitude, lng: longtitude });
    Geocode.fromLatLng(latitude, longtitude).then(
      (response) => {
        const address = response.results[0].formatted_address;
        setAddress(address);
        validation.setFieldValue("headquarters_location", address);
        validation.setFieldValue("latitude", latitude);
        validation.setFieldValue("longitude", longtitude);
      },
      (error) => {
        // console.error(error);
      }
    );
  };
  return (
    <>
      <div className="places-container">
        <PlacesAutocomplete
          setSelected={setSelected}
          setAddress={setAddress}
          validation={validation}
        />
      </div>

      <GoogleMap
        zoom={10}
        center={selected}
        onClick={handleClickedMap}
        mapContainerStyle={containerStyle}
      >
        {<Marker position={selected} />}
      </GoogleMap>
    </>
  );
}

const PlacesAutocomplete = ({ setSelected, setAddress, validation }) => {
  const {
    setValue,
    suggestions: { data },
    clearSuggestions,
  } = usePlacesAutocomplete();
  const handleSelect = async (address) => {
    address = address.target.innerText;
    setValue(address, false);
    clearSuggestions();

    const results = await getGeocode({ address });
    const { lat, lng } = await getLatLng(results[0]);

    Geocode.fromLatLng(lat, lng).then(
      (response) => {
        const address = response.results[0].formatted_address;
        setAddress(address);
        validation.setFieldValue("headquarters_location", address);
        validation.setFieldValue("latitude", lat);
        validation.setFieldValue("longitude", lng);
      },
      (error) => {
        // console.error(error);
      }
    );
    setSelected({ lat, lng });
  };

  return (
    <Combobox
      hideCaret
      hideEmptyPopup
      onChange={(e) => setValue(e)}
      placeholder="Search an address"
      onClick={handleSelect}
      data={data}
      dataKey="description"
      textField="description"
    />
  );
};
