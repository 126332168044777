// Actions

export const API_RESPONSE_SUCCESS = "API_RESPONSE_SUCCESS";
export const API_RESPONSE_ERROR = "API_RESPONSE_ERROR";

/**
 * Delete PRODUCT
 */
export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS";
export const DELETE_PRODUCT_FAIL = "DELETE_PRODUCT_FAIL";

export const RESET_ECOM_FLAG = "RESET_ECOM_FLAG";
export const RESET_LOADER_FLAG = "RESET_LOADER_FLAG";

export const GET_PRODUCTS_DATA = "GET_PRODUCTS_DATA";
export const GET_PRODUCTS_DATA_SUCCESS = "GET_PRODUCTS_DATA_SUCCESS";
export const GET_PRODUCTS_DATA_FAIL = "GET_PRODUCTS_DATA_FAIL";

export const GET_ONE_PRODUCTS_DATA = "GET_ONE_PRODUCTS_DATA";

export const UPDATE_ONE_PRODUCT_DATA = "UPDATE_ONE_PRODUCT_DATA";
export const UPDATE_ONE_PRODUCT_DATA_SUCCESS =
  "UPDATE_ONE_PRODUCT_DATA_SUCCESS";
export const UPDATE_ONE_PRODUCT_DATA_FAIL = "UPDATE_ONE_PRODUCT_DATA_FAIL";

export const ADD_PRODUCT_DATA = "ADD_PRODUCT_DATA";
export const ADD_PRODUCT_DATA_SUCCESS = "ADD_PRODUCT_DATA_SUCCESS";
export const ADD_PRODUCT_DATA_FAIL = "ADD_PRODUCT_DATA_FAIL";

export const UPDATE_CURRENT_PRODUCT_STATE = "UPDATE_CURRENT_PRODUCT_STATE";

// orders salis
export const GET_ORDERS_DATA = "GET_ORDERS_DATA";
export const GET_ORDERS_DATA_SUCCESS = "GET_ORDERS_DATA_SUCCESS";
export const GET_ORDERS_DATA_FAIL = "GET_ORDERS_DATA_FAIL";

export const UPDATE_ORDERS_DATA = "UPDATE_ORDERS_DATA";

export const GET_ONE_ORDERS_DATA = "GET_ONE_ORDERS_DATA";


// special deal
export const GET_SPECIAL_DEALS_DATA = "GET_SPECIAL_DEALS_DATA";
export const ADD_SPECIAL_DEALS = "ADD_SPECIAL_DEALS";
export const SPECIAL_DEALS_settings = "SPECIAL_DEALS_settings";
export const SPECIAL_AVG_MAX_SETTINGS = "SPECIAL_AVG_MAX_SETTINGS";

// Cards
export const GET_CARDS = "GET_CARDS";
export const PRE_ADD_CARD = "PRE_ADD_CARD";
export const ADD_CARD = "ADD_CARD";
export const PRE_CHANGE_CARD = "PRE_CHANGE_CARD";
export const GET_CARD_TOKEN = "GET_CARD_TOKEN";
export const CHANGE_CARD = "CHANGE_CARD";
export const DELETE_CARD = "DELETE_CARD";

//Checkout
export const PRE_CHECKOUT = "PRE_CHECKOUT";
export const CHECKOUT = "CHECKOUT";
export const CHECKOUT_FAIL = "CHECKOUT_FAIL";
export const CHECKOUT_SUCCESS = "CHECKOUT_SUCCESS";

//in store order
export const IN_STORE_ORDER = "IN_STORE_ORDER";
export const IN_STORE_ORDER_SUCCESS = "IN_STORE_ORDER_SUCCESS";
export const IN_STORE_ORDER_FAIL = "IN_STORE_ORDER_FAIL";

//rate
export const ADD_ORDER_RATING = "ADD_ORDER_RATING";
export const ADD_ORDER_RATING_SUCCESS = "ADD_ORDER_RATING_SUCCESS";
export const ADD_ORDER_RATING_FAIL = "ADD_ORDER_RATING_FAIL";
//Banks
export const GET_BANKS = "GET_BANKS";
export const GET_BANK_ACCOUNTS = "GET_BANK_ACCOUNTS";
export const DELETE_BANK_ACCOUNT = "DELETE_BANK_ACCOUNT";
export const ADD_BANK_ACCOUNT = "ADD_BANK_ACCOUNT";
export const ADD_BANK_ACCOUNT_SUCCESS = "ADD_BANK_ACCOUNT_SUCCESS";
export const ADD_BANK_ACCOUNT_FAIL = "ADD_BANK_ACCOUNT_FAIL";
export const CHANGE_BANK_ACCOUNT = "CHANGE_BANK_ACCOUNT";
export const CHANGE_BANK_ACCOUNT_SUCCESS = "CHANGE_BANK_ACCOUNT_SUCCESS";
export const CHANGE_BANK_ACCOUNT_FAIL = "CHANGE_BANK_ACCOUNT_FAIL";

// cancel order

export const CANCEL_ORDER = "CANCEL_ORDER";
export const CANCEL_ORDER_SUCCESS = "CANCEL_ORDER_SUCCESS";
export const CANCEL_ORDER_FAIL = "CANCEL_ORDER_FAIL";

//wallet

export const GET_WALLET = "GET_WALLET";
export const GET_WALLET_SUCCESS = "GET_WALLET_SUCCESS";
export const GET_WALLET_FAIL = "GET_WALLET_FAIL";

//items
export const GET_ITEMS = "GET_ITEMS";
export const GET_ITEMS_SUCCESS = "GET_ITEMS_SUCCESS";
export const GET_ITEMS_FAIL = "GET_ITEMS_FAIL";
//captures
export const GET_CAPTURES = "GET_CAPTURES";
export const GET_CAPTURES_SUCCESS = "GET_CAPTURES_SUCCESS";
export const GET_CAPTURES_FAIL = "GET_CAPTURES_FAIL";

// REFUND_PAYMENT
export const REFUND_PAYMENT = "REFUND_PAYMENT";
export const REFUND_PAYMENT_SUCCESS = "REFUND_PAYMENT_SUCCESS";
export const REFUND_PAYMENT_FAIL = "REFUND_PAYMENT_FAIL";


// REFUND_PAYMENT
export const CAPTURE_PAYMENT = "CAPTURE_PAYMENT";
export const CAPTURE_PAYMENT_SUCCESS = "CAPTURE_PAYMENT_SUCCESS";
export const CAPTURE_PAYMENT_FAIL = "CAPTURE_PAYMENT_FAIL";


// webhook
export const REGISTER_WEBHOOK = "REGISTER_WEBHOOK";
export const REGISTER_WEBHOOK_SUCCESS = "REGISTER_WEBHOOK_SUCCESS";
export const REGISTER_WEBHOOK_FAIL = "REGISTER_WEBHOOK_FAIL";

export const GET_WEBHOOK = "GET_WEBHOOK";
export const GET_WEBHOOK_SUCCESS = "GET_WEBHOOK_SUCCESS";
export const GET_WEBHOOK_FAIL = "GET_WEBHOOK_FAIL";

export const DELETE_WEBHOOK = "DELETE_WEBHOOK";
export const DELETE_WEBHOOK_SUCCESS = "DELETE_WEBHOOK_SUCCESS";
export const DELETE_WEBHOOK_FAIL = "DELETE_WEBHOOK_FAIL";


// GET_SETTLEMENTS
export const GET_SETTLEMENTS = "GET_SETTLEMENTS";
export const GET_SETTLEMENTS_SUCCESS = "GET_SETTLEMENTS_SUCCESS";
export const GET_SETTLEMENTS_FAIL = "GET_SETTLEMENTS_FAIL";

export const GET_ONE_SETTLEMENTS = "GET_ONE_SETTLEMENTS";
export const GET_ONE_SETTLEMENTS_SUCCESS = "GET_ONE_SETTLEMENTS_SUCCESS";
export const GET_ONE_SETTLEMENTS_FAIL = "GET_ONE_SETTLEMENTS_FAIL";


// GET_TRANSACTION
export const GET_TRANSACTION = "GET_TRANSACTION";
export const GET_TRANSACTION_SUCCESS = "GET_TRANSACTION_SUCCESS";
export const GET_TRANSACTION_FAIL = "GET_TRANSACTION_FAIL";

// PRE_UPLOAD_IMAGE
export const PRE_UPLOAD_IMAGE = "PRE_UPLOAD_IMAGE";
export const PRE_UPLOAD_IMAGE_SUCCESS = "PRE_UPLOAD_IMAGE_SUCCESS";
export const PRE_UPLOAD_IMAGE_FAIL = "PRE_UPLOAD_IMAGE_FAIL";


// Upload image
export const UPLOAD_IMAGE = "UPLOAD_IMAGE";
export const UPLOAD_IMAGE_SUCCESS = "UPLOAD_IMAGE_SUCCESS";
export const UPLOAD_IMAGE_FAIL = "UPLOAD_IMAGE_FAIL";

// WITHDRAW
export const WITHDRAW = "WITHDRAW";
export const WITHDRAW_SUCCESS = "WITHDRAW_SUCCESS";
export const WITHDRAW_FAILED = "WITHDRAW_FAILED";



// PayPerClick
export const PayPerClick = "PayPerClick";
export const PayPerClick_SUCCESS = "PayPerClick_SUCCESS";
export const PayPerClick_FAILED = "PayPerClick_FAILED";

export const GET_PPC_DATA = "GET_PPC_DATA";
export const GET_PPC_DATA_SUCCESS = "GET_PPC_DATA_SUCCESS";
export const GET_PPC_DATA_FAIL = "GET_PPC_DATA_FAIL";

export const CANCEL_PPC = "CANCEL_PPC";
export const CANCEL_PPC_SUCCESS = "CANCEL_PPC_SUCCESS";
export const CANCEL_PPC_FAIL = "CANCEL_PPC_FAIL";
// 
export const GET_PAYMENT_TYPES = "GET_PAYMENT_TYPES";
export const GET_PAYMENT_TYPES_SUCCESS = "GET_PAYMENT_TYPES_SUCCESS";
export const GET_PAYMENT_TYPES_FAILED = "GET_PAYMENT_TYPES_FAILED";
