
// import ar from "../assets/images/languages/ar.svg";
import en from "../assets/images/languages/en.svg";

const languages = {

  en: {
    label: "English",
    flag: en,
  },
  // ar: {
  //   label: "Arabic",
  //   flag: ar,
  // },
};

export default languages;
