import React, { useState, useEffect } from "react";

import { Container, Row, Col, Card, CardBody, Label, Input } from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";
import { getUserData, updateUserData } from "../../store/auth/profile/actions";
import Geocode from "react-geocode";
import {
  getUploaderLink,
  uploadImage,
  resetEcomFlag,
} from "../../store/actions";
import Skeleton from "@mui/material/Skeleton";
import { ToastContainer, toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import validator from "../../helpers/Validator";
import ConfirmationModal from "../../Components/Common/ConfirmationModal";
import { config } from "../../config";
import cameraSvg from "../../assets/images/profile/camera.svg";
import store_logo from "../../assets/images/profile/store_logo.png";
import store_image from "../../assets/images/profile/store_image.png";
import Places from "./Places";

const validationSchema = Yup.object().shape({
  store_name: Yup.string()
    .min(2, "Too Short!")
    .max(20, "Too Long!")
    .required("Required")
    .nullable()
    .matches(validator.titleValidation, "Input a valid title"),
  store_url: Yup.string()
    .required("Required")
    .matches(
      validator.urlValidation,
      "Input a valid url that starts with http"
    ),
  return_period: Yup.number()
    .required("Required")
    .integer("Must be an integer")
    .min(5, "Too low!")
    .max(14, "Too high!"),
});
const { TOAST_AUTOCLOSE_TIMER } = config;
const { LOADER_RESET_TIMER } = config;
const { SKELELTON_RESET_TIMER } = config;
const { GOOGLE_MAP_API_KEY } = config;

Geocode.setApiKey(GOOGLE_MAP_API_KEY);
Geocode.setLanguage("en");
const EditProfile = () => {
  const { t } = useTranslation();
  const History = useHistory();

  const dispatch = useDispatch();
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [userId, setUserid] = useState(false);
  const [image, setImage] = useState([]);
  const [skeltonTimer, setSkeltonTimer] = useState(true);
  const [mapModal, setMapModal] = useState(false);
  const [address, setAddress] = useState(false);
  const [userReturnPeriod, setUserReturnPeriod] = useState("");
  const [rerender, setRerender] = useState(false);

  const { userData, imageUploadLink, imageUpload, imageUploadError } =
    useSelector((state) => ({
      userData: state.Profile.userData,
      success: state.Profile.success,
      error: state.Profile.error,
      imageUploadLink: state.Ecommerce.imageUploadLink,
      imageUpload: state.Ecommerce.imageUpload,
      imageUploadError: state.Ecommerce.imageUploadError,
    }));
  const formik = useFormik({
    initialValues: {
      store_name: "",
      store_url: "",
      headquarters_location: "",
      latitude: "",
      longitude: "",
      return_period: "",
    },
    validationSchema,
    onSubmit: (values) => {
      handleShowConfirmationModal();
    },
  });
  const handleShowConfirmationModal = () => {
    setConfirmationModal(true);
  };

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      setUserid(obj.data.id);
      dispatch(getUserData(obj.data.id));
    }
  }, [dispatch]);
  const handleNotValidForm = (e) => {
    e.preventDefault();
    formik.touched.store_name = true;
    formik.touched.store_url = true;
    formik.touched.return_period = true;
    formik.touched.headquarters_location = true;
    setRerender(!rerender)
  };
  useEffect(() => {
    if (formik.values !== userData) {
      formik.setValues(userData);
      setUserReturnPeriod(userData.return_period);
    }
    setTimeout(() => {
      setSkeltonTimer(false);
    }, SKELELTON_RESET_TIMER);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);
  const handleUpload = (value, type) => {
    let object = {};
    const file_type = value.type.split("/").pop();
    object[type] = { file: value, fileType: file_type };
    setImage([...image, object]);
    const params = {
      file_type: file_type,
    };
    // if (userData[type]) {
    //   let oldProductPath = userData[type].substring(
    //     userData[type].indexOf("merchants"),
    //     userData[type].length
    //   );

    //   params["product_path"] = oldProductPath;
    // }

    dispatch(getUploaderLink(params, type));
  };

  useEffect(() => {
    Geocode.fromLatLng(userData.latitude, userData.longitude).then(
      (response) => {
        const address = response.results[0].formatted_address;
        setAddress(address);
        formik.setFieldValue("headquarters_location", address);
      },
      (error) => {
        // console.error(error);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);
  useEffect(() => {
    if (imageUpload) {
      let storeImage;
      let storeLogo;
      imageUploadLink.forEach((item, index) => {
        if (item.type === "store_image") {
          storeImage = item["link"].substring(0, item["link"].indexOf("?"));
        }
        if (item.type === "store_logo") {
          storeLogo = item["link"].substring(0, item["link"].indexOf("?"));
        }
      });

      const data = {
        id: userId,
        store_name: formik.values.store_name,
        latitude: formik.values.latitude,
        longitude: formik.values.longitude,
        store_url:formik.values.store_url,
        store_image: storeImage,
        store_logo: storeLogo,
        return_period: formik.values.return_period,
      };

      dispatch(updateUserData(data));
      setImage([]);
      setTimeout(() => {
        setConfirmationModal(false);
        History.push("/profile");
      }, LOADER_RESET_TIMER);
    }

    if (imageUploadError) {
      toast("An error accord while uploading image", {
        position: "top-right",
        hideProgressBar: true,
        className: "bg-danger text-white",
        progress: undefined,
        toastId: "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageUpload, imageUploadError]);
  useEffect(() => {
    setTimeout(() => {
      dispatch(resetEcomFlag());
    }, LOADER_RESET_TIMER);
  }, [dispatch, imageUpload, imageUploadError]);
  const handleUpdateProfile = () => {
    let data;

    if (imageUploadLink.length === 0) {
      const data = {
        id: userId,
        store_name: formik.values.store_name,
        store_url: formik.values.store_url,
        latitude: formik.values.latitude,
        longitude: formik.values.longitude,
        return_period: formik.values.return_period,
      };

      dispatch(updateUserData(data));

      setTimeout(() => {
        History.push("/profile");
      }, LOADER_RESET_TIMER);
    }

    imageUploadLink.forEach((item, index) => {
      data = {
        url: item.link,
        image: image[index][item.type]["file"],
        imageType: image[index][item.type]["fileType"],
      };
      dispatch(uploadImage(data));
    });
  };
  useEffect(() => {
   
  }, [rerender]);
  document.title = "Profile Edit";
  return (
    <React.Fragment>
      <div className="page-content bg-alice-blue h-100">
        <Container fluid>
          <Places
            onCloseClick={() => setMapModal(false)}
            show={mapModal}
            setAddress={setAddress}
            validation={formik}
            address={address}
          />

          <BreadCrumb title="profile edit" />
          <ToastContainer
            autoClose={TOAST_AUTOCLOSE_TIMER}
            style={{ width: "auto" }}
          />

          <ConfirmationModal
            show={confirmationModal}
            onCloseClick={() => setConfirmationModal(false)}
            message={"Yor are going to update your profile! "}
            action={handleUpdateProfile}
          />
          <Row>
            <Col className="mx-auto" lg="6">
              <Card className="h-100">
                <CardBody className="mt-3">
                  <div className="d-flex flex flex-column mx-3">
                    <form
                      method="post"
                      onSubmit={(e) =>
                        formik.isValid
                          ? formik.handleSubmit(e)
                          : handleNotValidForm(e)
                      }
                    >
                      <div className="d-flex flex gap-5 mb-4">
                        <div>
                          <p className="text-center fw-bold">
                            {t("storeImage")}
                          </p>
                          <div className="images-box-holder">
                            <a
                              style={{ display: "contents" }}
                              href={
                                formik.values.store_image?.preview ||
                                userData.store_image ||
                                store_image
                              }
                              target={"_blank"}
                              rel="noreferrer"
                            >
                              {!skeltonTimer ? (
                                <img
                                  className="store-image-box object-fit-images"
                                  alt=""
                                  src={
                                    formik.values.store_image?.preview ||
                                    (userData.store_image && userData.date_update ? `${userData.store_image}?${userData.date_update}` : null) ||
                                    store_image
                                  }
                                />
                              ) : (
                                <Skeleton
                                  animation="pulse"
                                  style={{
                                    width: "98px",
                                    height: "98px",
                                    position: "absolute",
                                    left: "0px",
                                    top: "0px",
                                  }}
                                  variant="circular"
                                  width={50}
                                  height={50}
                                />
                              )}
                            </a>
                            <div className="mb-4 text-center">
                              <label
                                style={{ cursor: "pointer" }}
                                htmlFor="store_image"
                              >
                                <input
                                  id="store_image"
                                  type="file"
                                  style={{ display: "none" }}
                                  onChange={(event) => {
                                    Object.assign(
                                      event.currentTarget.files[0],
                                      {
                                        preview: URL.createObjectURL(
                                          event.currentTarget.files[0]
                                        ),
                                      }
                                    );
                                    formik.setFieldValue(
                                      "store_image",
                                      event.currentTarget.files[0]
                                    );
                                    handleUpload(
                                      event.currentTarget.files[0],
                                      "store_image"
                                    );
                                  }}
                                  onBlur={formik.handleBlur}
                                  className={
                                    formik.errors.store_image &&
                                    formik.touched.store_image
                                      ? "form-control is-invalid"
                                      : "form-control"
                                  }
                                />
                                <img
                                  data-dz-thumbnail=""
                                  height="100%"
                                  width="100%"
                                  className="upload-image-icon"
                                  src={cameraSvg}
                                  alt="store"
                                />
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="mx-2">
                          <p className="text-center fw-bold">
                            {t("storeLogo")}
                          </p>
                          <div className="images-box-holder">
                            <a
                              style={{ display: "contents" }}
                              href={
                                formik.values.store_logo?.preview ||
                                userData.store_logo ||
                                store_logo
                              }
                              target={"_blank"}
                              rel="noreferrer"
                            >
                              {!skeltonTimer ? (
                                <img
                                  className="store-image-box object-fit-images"
                                  alt=""
                                  src={
                                    formik.values.store_logo?.preview ||
                                    (userData.store_logo && userData.date_update ? `${userData.store_logo}?${userData.date_update}` : null) ||
                                    store_logo
                                  }
                                />
                              ) : (
                                <Skeleton
                                  animation="pulse"
                                  style={{
                                    width: "98px",
                                    height: "98px",
                                    position: "absolute",
                                    left: "0px",
                                    top: "0px",
                                  }}
                                  variant="circular"
                                  width={50}
                                  height={50}
                                />
                              )}
                            </a>
                            <div className="mb-4 text-center">
                              <label
                                style={{ cursor: "pointer" }}
                                htmlFor="store_logo"
                              >
                                <input
                                  id="store_logo"
                                  type="file"
                                  style={{ display: "none" }}
                                  onChange={(event) => {
                                    Object.assign(
                                      event.currentTarget.files[0],
                                      {
                                        preview: URL.createObjectURL(
                                          event.currentTarget.files[0]
                                        ),
                                      }
                                    );
                                    formik.setFieldValue(
                                      "store_logo",
                                      event.currentTarget.files[0]
                                    );
                                    handleUpload(
                                      event.currentTarget.files[0],
                                      "store_logo"
                                    );
                                  }}
                                  onBlur={formik.handleBlur}
                                  className={
                                    formik.errors.store_logo &&
                                    formik.touched.store_logo
                                      ? "form-control is-invalid"
                                      : "form-control"
                                  }
                                />
                                <img
                                  data-dz-thumbnail=""
                                  height="100%"
                                  width="100%"
                                  className="upload-image-icon"
                                  src={cameraSvg}
                                  alt="store logo"
                                />
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="mb-3">
                        <Label className="form-label" htmlFor="store_name">
                          {t("storeName")}
                        </Label>
                        <div className="position-relative auth-pass-inputgroup mb-3">
                          <input
                            id="store_name"
                            type="text"
                            placeholder="Please enter store name"
                            value={formik.values.store_name || ""}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className={
                              formik.errors.store_name &&
                              formik.touched.store_name
                                ? "form-control is-invalid  padding_input"
                                : "form-control  padding_input"
                            }
                          />
                          {formik.errors.store_name &&
                          formik.touched.store_name ? (
                            <div className="text-danger">
                              {formik.errors.store_name}
                            </div>
                          ) : null}
                          <button
                            className="btn btn-link position-absolute start-0 top-0 text-decoration-none text-muted"
                            type="button"
                            disabled={true}
                            id="password-addon"
                          >
                            <i className="ri-store-2-line"></i>
                          </button>
                        </div>
                      </div>
                      <div className="mb-3">
                        <Label className="form-label" htmlFor="store_url">
                          {t("storeUrl")}
                        </Label>
                        <div className="position-relative auth-pass-inputgroup mb-3">
                          <input
                            id="store_url"
                            type="text"
                            placeholder="Please enter store url"
                            value={formik.values.store_url || ""}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className={
                              formik.errors.store_url &&
                              formik.touched.store_url
                                ? "form-control is-invalid  padding_input"
                                : "form-control  padding_input"
                            }
                          />
                          {formik.errors.store_url &&
                          formik.touched.store_url ? (
                            <div className="text-danger">
                              {formik.errors.store_url}
                            </div>
                          ) : null}
                          <button
                            className="btn btn-link position-absolute start-0 top-0 text-decoration-none text-muted"
                            type="button"
                            disabled={true}
                            id="password-addon"
                          >
                            <i className="ri-links-line"></i>
                          </button>
                        </div>
                      </div>

                      <div className="mb-3">
                        <Label className="form-label" htmlFor="return_period">
                          {t("returnPeriod")}
                        </Label>
                        <div className="position-relative auth-pass-inputgroup mb-3">
                          <input
                            id="return_period"
                            type="text"
                            placeholder="Please enter return period"
                            value={formik.values.return_period || ""}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            className={
                              formik.errors.return_period &&
                              formik.touched.return_period
                                ? "form-control is-invalid  padding_input"
                                : "form-control  padding_input"
                            }
                          />
                          {formik.errors.return_period &&
                          formik.touched.return_period ? (
                            <div className="text-danger">
                              {formik.errors.return_period}
                            </div>
                          ) : null}
                          {userReturnPeriod !== formik.values.return_period && (
                            <div className="text-muted pt-1">
                              {" "}
                              {t("returnPeriodWarning")}
                            </div>
                          )}
                          <button
                            className="btn btn-link position-absolute start-0 top-0 text-decoration-none text-muted"
                            type="button"
                            disabled={true}
                            id="password-addon"
                          >
                            <i className="ri-timer-line"></i>
                          </button>
                        </div>
                      </div>
                      <div className="mb-3">
                        <Label
                          htmlFor="headquarters_location"
                          className="form-label"
                        >
                          {t("headquarterLocation")}
                        </Label>
                        <div className="d-flex justify-content-between gap-1">
                          <div className="position-relative auth-pass-inputgroup mb-3 w-100">
                            <Input
                              id="headquarters_location"
                              name="headquarters_location"
                              type="text"
                              className="form-control  padding_input"
                              placeholder="Enter location"
                              onChange={formik.handleChange}
                              disabled
                              onBlur={formik.handleBlur}
                              value={formik.values.headquarters_location || ""}
                              invalid={
                                formik.touched.headquarters_location &&
                                formik.errors.headquarters_location
                                  ? true
                                  : false
                              }
                            />
                            {formik.errors.headquarters_location &&
                            formik.touched.headquarters_location ? (
                              <div className="text-danger">
                                {formik.errors.headquarters_location}
                              </div>
                            ) : null}
                            <button
                              className="btn btn-link position-absolute start-0 top-0 text-decoration-none text-muted"
                              type="button"
                              disabled={true}
                              id="password-addon"
                            >
                              <i className="ri-map-pin-line"></i>
                            </button>
                          </div>
                          <button
                            onClick={() => setMapModal(true)}
                            className="btn bg-light-red text-white"
                            style={{ height: "40px" }}
                            type="button"
                            id="password-addon"
                          >
                            <i className="ri-map-pin-line"></i>
                          </button>
                        </div>
                      </div>
                      <div className="text-center mt-5">
                        <button
                          type="submit"
                          className="btn bg-light-red text-white w-50"
                        >
                          {t("update")}
                        </button>
                      </div>
                    </form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EditProfile;
