import {
  PROFILE_ERROR,
  PROFILE_SUCCESS,
  EDIT_PROFILE,
  RESET_PROFILE_FLAG,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAIl,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAIl,
} from "./actionTypes";

const initialState = {
  error: "",
  success: "",
  userData: {},
  profileUpdateFlag: "",
};

const profile = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_PROFILE:
      state = { ...state };
      break;
    case PROFILE_SUCCESS:
      state = {
        ...state,
        success: action.payload.status,
        user: action.payload.data,
      };
      break;
    case PROFILE_ERROR:
      state = {
        ...state,
        error: action.payload,
      };
      break;

    case GET_PROFILE_SUCCESS:
      state = {
        ...state,
        userData: action.payload,
      };
      break;
    case UPDATE_PROFILE_SUCCESS:
      state = {
        ...state,
        profileUpdateFlag: "Your profile has been updated successfully",
      };
      break;
    case UPDATE_PROFILE_FAIl:
      state = {
        ...state,
        profileUpdateFlag: "An error occurred while updating your profile",
      };
      break;

    case GET_PROFILE_FAIl:
      state = {
        ...state,
        error: action.payload,
      };
      break;
    case RESET_PROFILE_FLAG:
      state = {
        ...state,
        success: "",
        profileUpdateFlag:"",
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default profile;
