import React, { useState } from "react";
import { DatePicker } from "antd";
import moment from "moment";
import { } from "../../helpers/charts_helper";
const DateFilterCards = ({ action, setAction, setStartDate, setEndDate, startDate, endDate, setCallApi, callApi }) => {
  const { RangePicker } = DatePicker;

  const [prevAction, setPrevAction] = useState("");
  const [dateHolder, setDateHolder] = useState("");

  function disabledDate(current) {
    // Can not select days before today and today
    if (action === "day") {
      return current && current >= moment().endOf("day");
    }
    if (action === "month") {
      return current && current >= moment().endOf("month");
    }
    if (action === "quarter") {
      return current && current >= moment().endOf("quarter");
    }
    if (action === "year") {
      return current && current >= moment().endOf("year");
    }
  }
  function getLastDayOfMonth(year, month) {
    return new Date(year, month, 0).getDate();
  }
  const handleOnClick = (e, action) => {

    setCallApi(!callApi)
    let lastDayInMonth = getLastDayOfMonth(
      e[1].format("YYYY"),
      e[1].format("MM")
    );
    setAction(action);
    setPrevAction(action)
    if (action === "day") {
      setStartDate(e[0].format("YYYY-MM-DD"));
      setEndDate(e[1].format("YYYY-MM-DD"));
    } else if (action === "month") {
      setStartDate(e[0].format(`YYYY-MM-01`));
      setEndDate(e[1].format(`YYYY-MM-${lastDayInMonth}`));
    }
  };
  const handleResetDates = (date) => {

    if (date !== prevAction) {
      setDateHolder(null)
    } else {
      setDateHolder([
        moment(startDate, dateFormat),
        moment(endDate, dateFormat)
      ])
    }

  }
  const dateFormat = "YYYY-MM-DD";
  return (
    <div className="d-flex gap-2 dashboard_picker">
      <RangePicker
        bordered={false}
        allowClear={false}
        value={dateHolder}
        min={new Date()}
        suffixIcon={
          <button
            type="button"
            onClick={() => handleOnClick("day")}
            className="btn text-light-red bg-light-pink btn-sm"
          >
            D
          </button>
        }
        disabledDate={disabledDate}
        onClick={() => handleResetDates("day")}
        onChange={(e) => {
          handleOnClick(e, "day");
        }}
        size="small"
      />
      <RangePicker
        bordered={false}
        allowClear={false}
        min={new Date()}
        suffixIcon={
          <button
            type="button"
            className="btn text-light-red bg-light-pink btn-sm"
          >
            M
          </button>
        }
        disabledDate={disabledDate}
        onClick={() => handleResetDates("month")}
        onChange={(e) => {
          handleOnClick(e, "month");
        }}
        value={dateHolder}

        size="small"
        month
        picker={"month"}
      />
    </div>
  );
};
export default DateFilterCards;
