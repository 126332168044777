import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import { useDispatch } from "react-redux";
const DashboardCards = ({ icon = null, text, amount, link, iconComponent = null, title = null, fullWidth = "" }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <div className={`p-3 p-md-4 m-2 dashboard-cards ${fullWidth}`}>
      {icon ? <img className="mb-2 d-inline" src={icon} alt="card icon" />
        : iconComponent}
      <h6 className="fw-normal px-3 d-inline mobile-small">{text}</h6>
      <h5 className="fw-bold pt-3 mobile-small">{amount}</h5>
      {title ?
        <p className="text-gray-100 mobile-small">{title}</p>
        :
        <button
          type="button"
          className="btn fw-normal fs-6 p-0 text-decoration-underline"
        >
          <Link to={`${link}`} className="text-dark"> View details</Link>
        </button>}
    </div>
  );
};
export default DashboardCards;
