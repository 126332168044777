import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import InstallmentTable from "./installments/installmentsTable";
import { useSelector, useDispatch } from "react-redux";
import { getTotalReceivablesChart } from "../../store/charts/action";
const InstallmentTab = () => {
    const dispatch = useDispatch();
    const { totalReceivablesChart } = useSelector((state) => ({
        totalReceivablesChart: state.Charts.totalReceivablesChart,
    }));
    const [startDay, setStartDay] = useState(
        new Date().toLocaleDateString("sv", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
        })
    );

    const [endDay, setEndDay] = useState(
        new Date().toLocaleDateString("sv", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
        })
    );
    useEffect(() => {
        let date = new Date(endDay);
        const filterData = {
            start_date: startDay,
            end_date: new Date(date.setDate(date.getDate() + 1)).toLocaleDateString(
                "sv",
                { year: "numeric", month: "2-digit", day: "2-digit" }
            ),
            action: "day",
        };

        dispatch(getTotalReceivablesChart(filterData));
    }, [dispatch, startDay, endDay]);
    return (
        <Row className="bg-alice-blue">
            <Col xl={12}>
                <InstallmentTable
                    setStartDay={setStartDay}
                    setEndDay={setEndDay}
                    totalReceivables={totalReceivablesChart?.results}
                />
            </Col>

        </Row>
    );
};
export default InstallmentTab;
