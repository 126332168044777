import { SEND_OTP, SEND_OTP_SUCCESS, SEND_OTP_FAIL,VERIFY_OTP,VERIFY_OTP_SUCCESS ,VERIFY_OTP_FAIL,RESET_CHECK_OTP_FLAG} from "./actionTypes";



export const sendOtp = (otp) => {
  return {
    type: SEND_OTP,
    payload: otp,
  };
};

export const sendOtpSuccess = () => {
  return {
    type: SEND_OTP_SUCCESS,
    payload: {},
  };
};

export const sendOtpFail = (error) => {
  return {
    type: SEND_OTP_FAIL,
    payload: error,
  };
};

export const verifyOtp = (otp) => {
  return {
    type: VERIFY_OTP,
    payload: otp,
  };
};

export const verifyOtpSuccess = () => {
  return {
    type: VERIFY_OTP_SUCCESS,
    payload: {},
  };
};

export const verifyOtpFail = (error) => {
  return {
    type: VERIFY_OTP_FAIL,
    payload: error,
  };
};

export const resetOtpFlag = () => {
  return {
    type: RESET_CHECK_OTP_FLAG,
  };
};

