import React, { useEffect, useMemo } from "react";
import { Container, Row, TabContent, TabPane } from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import TableContainer from "../../../Components/Common/TableContainer";
import { Link } from "react-router-dom";
import {
  SettlementsDetailsStatus,
  handleDate,
} from "../../../helpers/orderColHelper";
// Formik
import { config } from "../../../config";

//redux
import { useSelector, useDispatch } from "react-redux";

//Import actions
import { getSettlements } from "../../../store/ecommerce/action";

import "react-toastify/dist/ReactToastify.css";
import Loader from "../Loader";


const { currency } = config;
const EcommerceSettlements = () => {
  const dispatch = useDispatch();
  const { settlements } = useSelector((state) => ({
    settlements: state.Ecommerce.settlements,
  }));

  const fetchData = (params = {}) => {
    const filterData = { ...params };

    dispatch(getSettlements(filterData));
  };

  useEffect(() => {
    fetchData({ page: 1 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dataSettlements = settlements.results;
  const dataSettlementsCount = settlements.count;

  const columns = useMemo(
    () => [
      {
        Header: "Settlements Id",
        filterable: false,
        Cell: (dataOrders) => {
          return (
            <React.Fragment>
              #{dataOrders.row.original.settlement_id}
            </React.Fragment>
          );
        },
      },
      {
        Header: "Created Date",
        filterable: false,
        Cell: (dataOrders) => {
          return (
            <React.Fragment>
              {handleDate(dataOrders.row.original.created_at)}
            </React.Fragment>
          );
        },
      },

      {
        Header: "Refunds amount",
        accessor: "refunds_amount",
        Cell: (dataOrders) => {
          return (
            <React.Fragment>
              <p className="amount-font-weight align-center m-0">
                {dataOrders.row.original.refunds_amount} {currency}
              </p>
            </React.Fragment>
          );
        },
      },
      {
        Header: "Captures amount",
        accessor: "captures_amount",
        Cell: (dataOrders) => {
          return (
            <React.Fragment>
              <p className="amount-font-weight align-center m-0">
                {dataOrders.row.original.captures_amount} {currency}
              </p>
            </React.Fragment>
          );
        },
      },
      {
        Header: "Commission amount",
        accessor: "commission_amount",
        Cell: (dataOrders) => {
          return (
            <React.Fragment>
              <p className="amount-font-weight align-center m-0">
                {dataOrders.row.original.commission_amount} {currency}
              </p>
            </React.Fragment>
          );
        },
      },
      {
        Header: "Correction amount",
        accessor: "correction_amount",
        Cell: (dataOrders) => {
          return (
            <React.Fragment>
              <p className="amount-font-weight align-center m-0">
                {dataOrders.row.original.correction_amount} {currency}
              </p>
            </React.Fragment>
          );
        },
      },
      {
        Header: "Settlement amount",
        accessor: "settlement_amount",
        Cell: (dataOrders) => {
          return (
            <React.Fragment>
              <p className="amount-font-weight align-center m-0">
                {dataOrders.row.original.settlement_amount} {currency}
              </p>
            </React.Fragment>
          );
        },
      },

      {
        Header: "Status",
        accessor: "status",
        Cell: (cellProps) => {
          return <SettlementsDetailsStatus {...cellProps} />;
        },
      },

      {
        Header: "View Details",
        Cell: (cellProps) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0 justify-content-center">
              <li className="list-inline-item">
                <Link
                  to={`settlements-details/${cellProps.row.original.settlement_id}`}
                  className="text-primary d-inline-block"
                >
                  <i className="ri-eye-fill text-light-red fs-16"></i>
                </Link>
              </li>
            </ul>
          );
        },
      },
    ],
    []
  );

  document.title = "Settlements | Salis";
  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Settlements" pageTitle="Marketing" />

        <Row className="justify-content-center">
          <div className="col">
            <div>
              <div className="card">
                <div className="card-body table-responsive">
                  <TabContent className="text-muted">
                    <TabPane>
                      <div
                        id="table-product-list-all"
                        className="table-card gridjs-border-none pb-2"
                      >
                        {dataSettlements && dataSettlements.length > 0 ? (
                          <TableContainer
                            columns={columns}
                            data={dataSettlements || []}
                            customPageSize={10}
                            numOfPages={dataSettlementsCount}
                            handlePageNumber={fetchData}
                            divClass="overflow-visible table table-responsive mb-1"
                            tableClass="mb-0 align-middle table-borderless pb-5"
                            theadClass="table-light text-muted"
                          />
                        ) : (
                          <Loader />
                        )}
                      </div>
                    </TabPane>
                  </TabContent>
                </div>
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default EcommerceSettlements;
