import React from "react";

import { Card, Col, Row, Collapse, Form, Button } from "reactstrap";
import merchantLicence from "../../../assets/images/merchant_licence.svg";
import merchantCertificate from "../../../assets/images/merchant_certificate.svg";
import { getUploaderLink } from "../../../store/actions";

//redux
import { useDispatch } from "react-redux";
const CollapseFour = ({ currentPage, validation, setImage, image,ref_image_trade_license,ref_image_tax_registration_certificate }) => {
  const dispatch = useDispatch();
  const handleUpload = (value, type) => {
    
    let object = {};
    const file_type = value.type.split("/").pop();
    object[type] = { file: value, fileType: file_type };
    setImage([...image, object]);
    const params = {
      file_type: file_type,
    };
    dispatch(getUploaderLink(params, type));
  };



  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
  const handleDeleteMainImage = (value) => {
    if (value === "image_trade_license") {
      ref_image_trade_license.current.value = "";

      validation.setFieldValue("image_trade_license", "");
    } else if (value === "image_tax_registration_certificate") {
      ref_image_tax_registration_certificate.current.value = "";

      validation.setFieldValue("image_tax_registration_certificate", "");
    }
  };
  return (
    <Collapse in isOpen={currentPage === 4}>
      <div className="p-2 mt-4">
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
          className="needs-validation"
          action="#"
        >
          <Row className="justify-content-center mt-5 mb-5 mx-0 px-0">
            <Col className="justify-content-center" md={8} lg={6} xl={6}>
              <div className="mb-4 text-center">
                <h6>Trade license</h6>
                <label
                  style={{ cursor: "pointer" }}
                  htmlFor="image_trade_license"
                >
                  <input
                    id="image_trade_license"
                    type="file"
                    ref={ref_image_trade_license}
                    style={{ display: "none" }}
                    onChange={(event) => {
                      Object.assign(event.currentTarget.files[0], {
                        preview: URL.createObjectURL(
                          event.currentTarget.files[0]
                        ),
                        formattedSize: formatBytes(
                          event.currentTarget.files[0].size
                        ),
                      });
                      validation.setFieldValue(
                        "image_trade_license",
                        event.currentTarget.files[0]
                      );
                      handleUpload(
                        event.currentTarget.files[0],
                        "image_trade_license"
                      );
                    }}
                    onBlur={validation.handleBlur}
                    className={
                      validation.errors.image_trade_license &&
                      validation.touched.image_trade_license
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                  />

                  <img
                    data-dz-thumbnail=""
                    height="100%"
                    width="100%"
                    src={merchantLicence}
                    alt="trade license"
                  />
                </label>
              </div>
              {validation.errors.image_trade_license &&
              validation.touched.image_trade_license ? (
                <div className="text-danger text-center">
                  {validation.errors.image_trade_license}
                </div>
              ) : null}
            </Col>

            <Col className="justify-content-center" md={6} lg={6} xl={6}>
              <div className="mb-4 text-center">
                <h6>Tax registration certificate</h6>
                <label
                  style={{ cursor: "pointer" }}
                  htmlFor="image_tax_registration_certificate"
                >
                  <input
                    style={{ display: "none" }}
                    id="image_tax_registration_certificate"
                    type="file"
                    ref={ref_image_tax_registration_certificate}
                    onChange={(event) => {
                      Object.assign(event.currentTarget.files[0], {
                        preview: URL.createObjectURL(
                          event.currentTarget.files[0]
                        ),
                        formattedSize: formatBytes(
                          event.currentTarget.files[0].size
                        ),
                      });
                      validation.setFieldValue(
                        "image_tax_registration_certificate",
                        event.currentTarget.files[0]
                      );
                      handleUpload(
                        event.currentTarget.files[0],
                        "image_tax_registration_certificate"
                      );
                    }}
                    onBlur={validation.handleBlur}
                    className={
                      validation.errors.image_tax_registration_certificate &&
                      validation.touched.image_tax_registration_certificate
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                  />

                  <img
                    data-dz-thumbnail=""
                    height="100%"
                    width="100%"
                    src={merchantCertificate}
                    alt="trade license"
                  />
                </label>
              </div>
              {validation.errors.image_tax_registration_certificate &&
              validation.touched.image_tax_registration_certificate ? (
                <div className="text-danger text-center">
                  {validation.errors.image_trade_license}
                </div>
              ) : null}
            </Col>
          </Row>
          {validation.values.image_trade_license && (
            <div className="list-unstyled mb-0" id="file-previews">
              <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                <div className="p-2">
                  <Row className="align-items-center">
                    <a
                      style={{ display: "contents" }}
                      href={validation.values.image_trade_license?.preview}
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      <Col className="col-auto mx-3">
                        <img
                          data-dz-thumbnail=""
                          height="80"
                          className="avatar-sm rounded bg-light"
                          src={validation.values.image_trade_license?.preview}
                          alt="trade license"
                        />
                      </Col>
                    </a>

                    <Col>
                      {validation.values.image_trade_license.name}
                      <p className="mb-0">
                        <strong>
                          {validation.values.image_trade_license.formattedSize}
                        </strong>
                      </p>
                    </Col>
                    <Col xs={"auto"}>
                      <Button
                        id="image_trade_license"
                        className="btn btn-soft-danger"
                        onBlur={validation.handleBlur}
                        onClick={() =>
                          handleDeleteMainImage("image_trade_license")
                        }
                      >
                        <i className="ri-delete-bin-2-line"></i>
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Card>
            </div>
          )}
          {validation.values.image_tax_registration_certificate && (
            <div className="list-unstyled mb-0" id="file-previews">
              <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                <div className="p-2">
                  <Row className="align-items-center">
                    <a
                      style={{ display: "contents" }}
                      href={
                        validation.values.image_tax_registration_certificate
                          ?.preview
                      }
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      <Col className="col-auto mx-3">
                        <img
                          data-dz-thumbnail=""
                          height="100%"
                          width="100%"
                          className="avatar-sm rounded bg-light"
                          src={
                            validation.values.image_tax_registration_certificate
                              ?.preview
                          }
                          alt="certificte"
                        />
                      </Col>
                    </a>

                    <Col>
                      {
                        validation.values.image_tax_registration_certificate
                          .name
                      }
                      <p className="mb-0">
                        <strong>
                          {
                            validation.values.image_tax_registration_certificate
                              .formattedSize
                          }
                        </strong>
                      </p>
                    </Col>
                    <Col xs={"auto"}>
                      <Button
                        id="image_tax_registration_certificate"
                        className="btn btn-soft-danger"
                        onBlur={validation.handleBlur}
                        onClick={() =>
                          handleDeleteMainImage(
                            "image_tax_registration_certificate"
                          )
                        }
                      >
                        <i className="ri-delete-bin-2-line"></i>
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Card>
            </div>
          )}

          <div className="mb-2 mt-4 d-flex justify-content-center  ">
            <button
              style={{
                backgroundColor: "#EE856F",
                color: "white",
                height: "45px",
              }}
              className="border-0 w-100 rounded"
              type="submit"
            >
              Create account
            </button>
          </div>
        </Form>
      </div>
    </Collapse>
  );
};
export default CollapseFour;
