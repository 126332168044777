// Ecoomerce Redux States
import {
  ADD_PRODUCT_DATA,
  ADD_SPECIAL_DEALS,
  DELETE_PRODUCT,
  GET_ONE_ORDERS_DATA,
  GET_ONE_PRODUCTS_DATA,
  GET_ORDERS_DATA,
  GET_PRODUCTS_DATA,
  GET_SPECIAL_DEALS_DATA,
  SPECIAL_DEALS_settings,
  SPECIAL_AVG_MAX_SETTINGS,
  UPDATE_ONE_PRODUCT_DATA,
  GET_CARDS,
  PRE_ADD_CARD,
  ADD_CARD,
  PRE_CHANGE_CARD,
  CHANGE_CARD,
  DELETE_CARD,
  PRE_CHECKOUT,
  CHECKOUT,
  IN_STORE_ORDER,
  ADD_ORDER_RATING,
  GET_BANKS,
  GET_BANK_ACCOUNTS,
  DELETE_BANK_ACCOUNT,
  ADD_BANK_ACCOUNT,
  CHANGE_BANK_ACCOUNT,
  CANCEL_ORDER,
  GET_WALLET,
  GET_ITEMS,
  GET_CAPTURES,
  REFUND_PAYMENT,
  CAPTURE_PAYMENT,
  REGISTER_WEBHOOK,
  GET_WEBHOOK,
  DELETE_WEBHOOK,
  GET_SETTLEMENTS,
  PRE_UPLOAD_IMAGE,
  UPLOAD_IMAGE,
  GET_TRANSACTION,
  WITHDRAW,
  GET_ONE_SETTLEMENTS,
  PayPerClick,
  GET_PPC_DATA,
  CANCEL_PPC,
  GET_PAYMENT_TYPES,
} from "./actionType";



import {
  getOneSettlementsSuccess,
  getOneSettlementsFail,
  getTransactionsSuccess,
  getTransactionsFail,
  uploadImageSuccess,
  uploadImageFail,
  getUploaderLinkSuccess,
  getUploaderLinkFail,
  deleteWebhookSuccess,
  deleteWebhookFail,
  getWebhookSuccess,
  getWebhookFail,
  getSettlementsSuccess,
  getSettlementsFail,
  addWebhookSuccess,
  addWebhookFail,
  addCapturePaymentSuccess,
  addCapturePaymentFail,
  cancelOrderSuccess,
  cancelOrderFail,
  addRefundPaymentSuccess,
  addRefundPaymentFail,
  getOrderCapturesSuccess,
  getOrderCapturesFail,
  getOrderItemSuccess,
  getOrderItemFail,
  addOrderRateSuccess,
  addOrderRateFail,
  updateProductDataSuccess,
  updateProductDataFail,
  addProductDataSuccess,
  addProductDataFail,
  getProductsDataSuccess,
  getProductsDataFail,
  deleteProductFail,
  deleteProductSuccess,
  ecommerceApiResponseError,
  ecommerceApiResponseSuccess,
  checkoutSuccess,
  checkoutFail,
  addInStoreOrderSuccess,
  addInStoreOrderFail,
  addBankAccountSuccess,
  addBankAccountFail,
  changeBankAccountFail,
  changeBankAccountSuccess,
  getWalletFail,
  getWalletSuccess,
  withdrawFail,
  withdrawSuccess,
  payperclickFail,
  payperclickSuccess,
  getPPCDataSuccess,
  getPPCDataFail,
  cancelPPCFail,
  cancelPPCSuccess,
  getpaymenttypesFail,
  getpaymenttypesSuccess,
} from "./action";
//Include Both Helper File with needed methods
import {
  addProductData,
  addSpecialDeals as addSpecialDealsApi,
  deleteProducts as deleteProductsApi,
  getOneOrdersData,
  getOneProductData,
  getOrdersData,
  getProductsData as getProductsDataApi,
  getSpecialDeals as getSpecialDealsApi,
  getSpecialDealsSettings as getSpecialDealsSettingsApi,
  getSpecialDealsAvgMaxSettings as getSpecialDealsAvgMaxSettingsApi,

  updateProduct,
  getCards as getCardsAPI,
  preAddCard as preAddCardsAPI,
  AddCard as AddCardsAPI,
  preChangeCard as preChangeCardsAPI,
  changeCard as changeCardsAPI,
  deleteCard as deleteCardsAPI,
  preCheckout as preCheckoutAPI,
  checkout as checkoutAPI,
  addInStoreOrder as addInStoreOrderAPI,
  addOrderRate as addOrderRateAPI,
  getBanks as getBanksAPI,
  getBankAccounts as getBankAccountAPI,
  deleteBankAccount as deleteBankAccountApi,
  addBankAccount as addBankAccountApi,
  changeBankAccount as changeBankAccountApi,
  cancelOrder as cancelOrderAPI,
  getWallet as getWalletAPI,
  getOrderItem as getOrderItemAPI,
  getOrderCaptures as getOrderCapturesAPI,
  addRefundPayment as addRefundPaymentAPI,
  addCapturePayment as addCapturePaymentAPI,
  addWebhook as addWebhookAPI,
  getWebhook as getWebhookAPI,
  deleteWebhook as deleteWebhookAPI,
  getSettlements as getSettlementsAPI,
  getOneSettlements as getOneSettlementsAPI,
  
  getTransactions as getTransactionsAPI,
  getUploaderLink as getUploaderLinkAPI,
  uploadImage as uploadImageAPI,
  withdraw as withdrawAPI,
  postPayPerClick as payperclickAPI,
  getPPCTableData as getPPCApi,
  CancelPPC as canclePPCApi,
  getpaymenttypes as getpaymenttypesAPI,
} from "../../helpers/salis_api";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";


function* uploadImage({ payload: data }) {
  try {
    const response = yield call(uploadImageAPI, data);
    yield put(uploadImageSuccess(response));
  } catch (error) {
    yield put(uploadImageFail(error));
  }
}
export function* watchUploadImage() {
  yield takeEvery(UPLOAD_IMAGE, uploadImage);
}


function* getUploaderLink({ payload: data,types:typess }) {
  try {
    const response = yield call(getUploaderLinkAPI, data);
    yield put(getUploaderLinkSuccess(typess,response));
  } catch (error) {
    yield put(getUploaderLinkFail(error));
  }
}
export function* watchGetUploaderLink() {
  yield takeEvery(PRE_UPLOAD_IMAGE, getUploaderLink);
}

function* getTransactions({ payload: data }) {
  try {
    const response = yield call(getTransactionsAPI, data);
    yield put(getTransactionsSuccess(response));
  } catch (error) {
    yield put(getTransactionsFail(error));
  }
}
export function* watchGetTransactions() {
  yield takeEvery(GET_TRANSACTION, getTransactions);
}

function* deleteWebhook({ payload: data }) {
  try {
    const response = yield call(deleteWebhookAPI, data);
    yield put(deleteWebhookSuccess(response));
  } catch (error) {
    yield put(deleteWebhookFail(error));
  }
}
export function* watchDeleteWebhook() {
  yield takeEvery(DELETE_WEBHOOK, deleteWebhook);
}

function* getWebhook({ payload: data }) {
  try {
    const response = yield call(getWebhookAPI, data);
    yield put(getWebhookSuccess(response));
  } catch (error) {
    yield put(getWebhookFail(error));
  }
}
export function* watchGetWebhook() {
  yield takeEvery(GET_WEBHOOK, getWebhook);
}

function* addWebhook({ payload: data }) {
  try {
    const response = yield call(addWebhookAPI, data);
    yield put(addWebhookSuccess(response));
  } catch (error) {
    yield put(addWebhookFail(error));
  }
}
export function* watchAddWebhook() {
  yield takeEvery(REGISTER_WEBHOOK, addWebhook);
}


function* getSettlements({ payload: data }) {
  try {
    const response = yield call(getSettlementsAPI, data);
    yield put(getSettlementsSuccess(response));
  } catch (error) {
    yield put(getSettlementsFail(error));
  }
}
export function* watchGetSettlements() {
  yield takeEvery(GET_SETTLEMENTS, getSettlements);
}


function* getOneSettlements({ payload: data }) {
  try {
    const response = yield call(getOneSettlementsAPI, data);
    yield put(getOneSettlementsSuccess(response));
  } catch (error) {
    yield put(getOneSettlementsFail(error));
  }
}
export function* watchGetOneSettlements() {
  yield takeEvery(GET_ONE_SETTLEMENTS, getOneSettlements);
}

function* addCapturePayment({ payload: data }) {
  try {
    const response = yield call(addCapturePaymentAPI, data);
    yield put(addCapturePaymentSuccess(response));
  } catch (error) {
    yield put(addCapturePaymentFail(error));
  }
}
export function* watchAddCapturePayment() {
  yield takeEvery(CAPTURE_PAYMENT, addCapturePayment);
}

function* addRefundPayment({ payload: data }) {
  try {
    const response = yield call(addRefundPaymentAPI, data);
    yield put(addRefundPaymentSuccess(response));
  } catch (error) {
    yield put(addRefundPaymentFail(error));
  }
}
export function* watchAddRefundPayment() {
  yield takeEvery(REFUND_PAYMENT, addRefundPayment);
}

function* getOrderCaptures({ payload: data }) {
  try {
    const response = yield call(getOrderCapturesAPI, data);
    yield put(getOrderCapturesSuccess(response));
  } catch (error) {
    yield put(getOrderCapturesFail(error));
  }
}
export function* watchGetOrderCaptures() {
  yield takeEvery(GET_CAPTURES, getOrderCaptures);
}

function* getOrderItem({ payload: data }) {
  try {
    const response = yield call(getOrderItemAPI, data);
    yield put(getOrderItemSuccess(response));
  } catch (error) {
    yield put(getOrderItemFail(error));
  }
}
export function* watchGetOrderItems() {
  yield takeEvery(GET_ITEMS, getOrderItem);
}

function* cancelOrder({ payload: data }) {
  try {
    const response = yield call(cancelOrderAPI, data);
    yield put(cancelOrderSuccess(response));
  } catch (error) {
    yield put(cancelOrderFail(error));
  }
}
export function* watchCancelOrder() {
  yield takeEvery(CANCEL_ORDER, cancelOrder);
}

function* addOrderRate({ payload: data }) {
  try {
    const response = yield call(addOrderRateAPI, data);
    yield put(addOrderRateSuccess(response));
  } catch (error) {
    yield put(addOrderRateFail(error));
  }
}
export function* watchAddOrderRater() {
  yield takeEvery(ADD_ORDER_RATING, addOrderRate);
}

function* addInStoreOrder({ payload: data }) {
  try {
    const response = yield call(addInStoreOrderAPI, data);
    yield put(addInStoreOrderSuccess(response));
  } catch (error) {
    yield put(addInStoreOrderFail(error));
  }
}
export function* watchAddInStoreOrder() {
  yield takeEvery(IN_STORE_ORDER, addInStoreOrder);
}

function* fetchCards() {
  try {
    const response = yield call(getCardsAPI);
    yield put(ecommerceApiResponseSuccess(GET_CARDS, response));
  } catch (error) {
    yield put(ecommerceApiResponseError(GET_CARDS, error));
  }
}
export function* watchfetchCards() {
  yield takeEvery(GET_CARDS, fetchCards);
}

function* preAddCards() {
  try {
    const response = yield call(preAddCardsAPI);
    yield put(ecommerceApiResponseSuccess(PRE_ADD_CARD, response));
  } catch (error) {
    yield put(ecommerceApiResponseError(PRE_ADD_CARD, error));
  }
}
export function* watchpreAddCards() {
  yield takeEvery(PRE_ADD_CARD, preAddCards);
}

function* AddCards({ payload: data }) {
  try {
    const response = yield call(AddCardsAPI, data);
    yield put(ecommerceApiResponseSuccess(ADD_CARD, response));
  } catch (error) {
    yield put(ecommerceApiResponseError(ADD_CARD, error));
  }
}

export function* watchAddCards() {
  yield takeEvery(ADD_CARD, AddCards);
}

function* preChangeCards({ payload: data }) {
  try {
    const response = yield call(preChangeCardsAPI, data);
    yield put(ecommerceApiResponseSuccess(PRE_CHANGE_CARD, response));
  } catch (error) {
    yield put(ecommerceApiResponseError(PRE_CHANGE_CARD, error));
  }
}
export function* watchpreChangeCards() {
  yield takeEvery(PRE_CHANGE_CARD, preChangeCards);
}

function* changeCards({ payload: data }) {
  try {
    const response = yield call(changeCardsAPI, data);
    yield put(ecommerceApiResponseSuccess(CHANGE_CARD, response));
  } catch (error) {
    yield put(ecommerceApiResponseError(CHANGE_CARD, error));
  }
}
export function* watchchangeCards() {
  yield takeEvery(CHANGE_CARD, changeCards);
}
function* deleteCards({ payload: data }) {
  try {
    const response = yield call(deleteCardsAPI, data);
    yield put(ecommerceApiResponseSuccess(DELETE_CARD, response));
  } catch (error) {
    yield put(ecommerceApiResponseError(DELETE_CARD, error));
  }
}
export function* watchdeleteCards() {
  yield takeEvery(DELETE_CARD, deleteCards);
}
function* preCheckout({ payload: data }) {
  try {
    const response = yield call(preCheckoutAPI, data);
    yield put(ecommerceApiResponseSuccess(PRE_CHECKOUT, response));
  } catch (error) {
    yield put(ecommerceApiResponseError(PRE_CHECKOUT, error));
  }
}
export function* watchpreCheckout() {
  yield takeEvery(PRE_CHECKOUT, preCheckout);
}
function* checkout({ payload: data }) {
  try {
    yield call(checkoutAPI, data);
    yield put(checkoutSuccess());
  } catch (error) {
    yield put(checkoutFail());
  }
}
export function* watchcheckout() {
  yield takeEvery(CHECKOUT, checkout);
}

function* fetchOrdersData({ payload: orders }) {
  try {
    const response = yield call(getOrdersData, orders);

    yield put(ecommerceApiResponseSuccess(GET_ORDERS_DATA, response));
  } catch (error) {
    yield put(ecommerceApiResponseError(GET_ORDERS_DATA, error));
  }
}
export function* watchFetchOrdersData() {
  yield takeEvery(GET_ORDERS_DATA, fetchOrdersData);
}



// get special deals
function* getSpecialDeals({ payload: deals }) {
  try {
    const response = yield call(getSpecialDealsApi, deals);
    yield put(ecommerceApiResponseSuccess(GET_SPECIAL_DEALS_DATA, response));
  } catch (error) {
    yield put(ecommerceApiResponseError(GET_SPECIAL_DEALS_DATA, error));
  }
}

export function* watchGetSpecialDeals() {
  yield takeEvery(GET_SPECIAL_DEALS_DATA, getSpecialDeals);
}

function* getSpecialDealsSettings({ payload: deals }) {
  try {
    const response = yield call(getSpecialDealsSettingsApi, deals);
    yield put(ecommerceApiResponseSuccess(SPECIAL_DEALS_settings, response));
  } catch (error) {
    yield put(ecommerceApiResponseError(SPECIAL_DEALS_settings, error));
  }
}

export function* watchGetSpecialDealsSettings() {
  yield takeEvery(SPECIAL_DEALS_settings, getSpecialDealsSettings);
}

function* getSpecialDealsAvgMaxSettings({ payload: deals }) {
  try {
    const response = yield call(getSpecialDealsAvgMaxSettingsApi, deals);
    yield put(ecommerceApiResponseSuccess(SPECIAL_AVG_MAX_SETTINGS, response));
  } catch (error) {
    yield put(ecommerceApiResponseError(SPECIAL_AVG_MAX_SETTINGS, error));
  }
}

export function* watchGetSpecialDealsAvgMaxSettings() {
  yield takeEvery(SPECIAL_AVG_MAX_SETTINGS, getSpecialDealsAvgMaxSettings);
}

function* onAddNewSpecialDeal({ payload: deal }) {
  try {
    const response = yield call(addSpecialDealsApi, deal);
    yield put(ecommerceApiResponseSuccess(ADD_SPECIAL_DEALS, response));
  } catch (error) {
    yield put(ecommerceApiResponseError(error));
  }
}
export function* watchAddNewSpecialDeal() {
  yield takeEvery(ADD_SPECIAL_DEALS, onAddNewSpecialDeal);
}

// getproducts

function* fetchProductsData({ payload: products }) {
  try {
    const response = yield call(getProductsDataApi, products);
    yield put(getProductsDataSuccess(response));
  } catch (error) {
    yield put(getProductsDataFail(error));
  }
}
export function* watchFetchProductsData() {
  yield takeEvery(GET_PRODUCTS_DATA, fetchProductsData);
}

// getOneproduct

function* fetchOneProductsData({ payload: products }) {
  try {
    const response = yield call(getOneProductData, products);

    yield put(ecommerceApiResponseSuccess(GET_ONE_PRODUCTS_DATA, response));
  } catch (error) {
    yield put(ecommerceApiResponseError(GET_ONE_PRODUCTS_DATA, error));
  }
}
export function* watchFetchOneProductsData() {
  yield takeEvery(GET_ONE_PRODUCTS_DATA, fetchOneProductsData);
}

function* fetchOneOrdersData({ payload: order }) {
  try {
    const response = yield call(getOneOrdersData, order);
    yield put(ecommerceApiResponseSuccess(GET_ONE_ORDERS_DATA, response));
  } catch (error) {
    yield put(ecommerceApiResponseError(GET_ONE_ORDERS_DATA, error));
  }
}
export function* watchFetchOneOrderData() {
  yield takeEvery(GET_ONE_ORDERS_DATA, fetchOneOrdersData);
}

// addproducts
function* onAddNewProduct({ payload: product }) {
  try {
    const response = yield call(addProductData, product);
    yield put(addProductDataSuccess(response));
  } catch (error) {
    yield put(addProductDataFail(error));
  }
}

export function* watchAddNewProduct() {
  yield takeEvery(ADD_PRODUCT_DATA, onAddNewProduct);
}
// update product
function* onUpdateProduct({ payload: product }) {
  try {
    const response = yield call(updateProduct, product);
    yield put(updateProductDataSuccess(response));
  } catch (error) {
    yield put(updateProductDataFail(error));
  }
}

export function* watchUpdateProduct() {
  yield takeEvery(UPDATE_ONE_PRODUCT_DATA, onUpdateProduct);
}

function* deleteProducts({ payload: product }) {
  try {
    const response = yield call(deleteProductsApi, product);
    yield put(deleteProductSuccess(response));
  } catch (error) {
    yield put(deleteProductFail(error));
  }
}

export function* watchDeleteProducts() {
  yield takeEvery(DELETE_PRODUCT, deleteProducts);
}
function* fetchBanks() {
  try {
    const response = yield call(getBanksAPI);
    yield put(ecommerceApiResponseSuccess(GET_BANKS, response));
  } catch (error) {
    yield put(ecommerceApiResponseError(GET_BANKS, error));
  }
}
export function* watchfetchBanks() {
  yield takeEvery(GET_BANKS, fetchBanks);
}
function* fetchBankAccounts() {
  try {
    const response = yield call(getBankAccountAPI);
    yield put(ecommerceApiResponseSuccess(GET_BANK_ACCOUNTS, response));
  } catch (error) {
    yield put(ecommerceApiResponseError(GET_BANK_ACCOUNTS, error));
  }
}
export function* watchfetchBankAccounts() {
  yield takeEvery(GET_BANK_ACCOUNTS, fetchBankAccounts);
}
function* deleteBank({ payload: data }) {
  try {
    const response = yield call(deleteBankAccountApi, data);
    yield put(ecommerceApiResponseSuccess(DELETE_BANK_ACCOUNT, response));
  } catch (error) {
    yield put(ecommerceApiResponseError(DELETE_BANK_ACCOUNT, error));
  }
}

export function* watchDeleteBank() {
  yield takeEvery(DELETE_BANK_ACCOUNT, deleteBank);
}
function* addBank({ payload: data }) {
  try {
    const response = yield call(addBankAccountApi, data);
    yield put(addBankAccountSuccess(ADD_BANK_ACCOUNT, response));
  } catch (error) {
    yield put(addBankAccountFail(ADD_BANK_ACCOUNT, error));
  }
}

export function* watchAddBank() {
  yield takeEvery(ADD_BANK_ACCOUNT, addBank);
}
function* changeBank({ payload: data }) {
  try {
    const response = yield call(changeBankAccountApi, data);
    yield put(changeBankAccountSuccess(CHANGE_BANK_ACCOUNT, response));
  } catch (error) {
    yield put(changeBankAccountFail(CHANGE_BANK_ACCOUNT, error));
  }
}
export function* watchChangeBank() {
  yield takeEvery(CHANGE_BANK_ACCOUNT, changeBank);
}
function* payperclick({ payload: data }) {
  try {
    const response = yield call(payperclickAPI, data);
    yield put(payperclickSuccess(PayPerClick, response));
  } catch (error) {
    yield put(payperclickFail(PayPerClick, error));
  }
}

export function* watchPayperclick() {
  yield takeEvery(PayPerClick, payperclick);
}
function* getPPC({ payload: data }) {
  try {
    const response = yield call(getPPCApi, data);
    yield put(getPPCDataSuccess(response));
  } catch (error) {
    yield put(getPPCDataFail(error));
  }
}

export function* watchGetPPC() {
  yield takeEvery(GET_PPC_DATA, getPPC);
}



function* cancelPPC({ payload: data }) {
  try {
    const response = yield call(canclePPCApi, data);
    yield put(cancelPPCSuccess(response));
  } catch (error) {
    yield put(cancelPPCFail(error));
  }
}

export function* watchcancelPPC() {
  yield takeEvery(CANCEL_PPC, cancelPPC);
}


function* getWalletfunc() {
  try {
    const response = yield call(getWalletAPI);
    yield put(getWalletSuccess(response));
  } catch (error) {
    yield put(getWalletFail(error));
  }
}

export function* watchGetWallet() {
  yield takeEvery(GET_WALLET, getWalletfunc);
}

function* withdraw({ payload: data }) {
  try {
    const response = yield call(withdrawAPI, data);
    yield put(withdrawSuccess(response));
  } catch (error) {
    yield put(withdrawFail(error));
  }
}

export function* watchWithdraw() {
  yield takeEvery(WITHDRAW, withdraw);
}


function* getpaymenttypes({ payload: data }) {
  try {
    const response = yield call(getpaymenttypesAPI, data);
    yield put(getpaymenttypesSuccess(response));
  } catch (error) {
    yield put(getpaymenttypesFail(error));
  }
}

export function* watchgetpaymenttypes() {
  yield takeEvery(GET_PAYMENT_TYPES, getpaymenttypes);
}


function* ecommerceSaga() {
  yield all([
    fork(watchDeleteProducts),
    fork(watchFetchProductsData),
    fork(watchAddNewProduct),
    fork(watchFetchOneProductsData),
    fork(watchUpdateProduct),
    fork(watchFetchOrdersData),
    fork(watchFetchOneOrderData),
    fork(watchGetSpecialDeals),
    fork(watchAddNewSpecialDeal),
    fork(watchGetSpecialDealsSettings),
    fork(watchGetSpecialDealsAvgMaxSettings),
    fork(watchfetchCards),
    fork(watchpreAddCards),
    fork(watchAddCards),
    fork(watchpreChangeCards),
    fork(watchchangeCards),
    fork(watchdeleteCards),
    fork(watchpreCheckout),
    fork(watchcheckout),
    fork(watchAddInStoreOrder),
    fork(watchAddOrderRater),
    fork(watchfetchBanks),
    fork(watchfetchBankAccounts),
    fork(watchDeleteBank),
    fork(watchAddBank),
    fork(watchChangeBank),
    fork(watchCancelOrder),
    fork(watchGetWallet),
    fork(watchGetOrderItems),
    fork(watchGetOrderCaptures),
    fork(watchAddRefundPayment),
    fork(watchAddCapturePayment),
    fork(watchAddWebhook),
    fork(watchGetWebhook),
    fork(watchDeleteWebhook),
    fork(watchGetSettlements),
    fork(watchGetOneSettlements),
    fork(watchGetTransactions),
    fork(watchGetUploaderLink),
    fork(watchUploadImage),
    fork(watchWithdraw),
    fork(watchPayperclick),
    fork(watchGetPPC),
    fork(watchcancelPPC),
    fork(watchgetpaymenttypes),
  ]);
}

export default ecommerceSaga;
