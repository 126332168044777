import {
  ADD_NEW_MERCHANT,
  ADD_NEW_MERCHANT_FAILED,
  ADD_NEW_MERCHANT_SUCCESSFUL,
  RESET_REGISTER_FLAG,
} from "./actionTypes";

const initialState = {
  registrationError: false,
  message: null,
  loading: false,
  user: null,
  success: false,
  error: false,
};

const Account = (state = initialState, action) => {
  switch (action.type) {
    case ADD_NEW_MERCHANT:
      state = {
        ...state,
        loading: true,
        mobile_number: action.payload.mobile_number,
      };
      break;
    case ADD_NEW_MERCHANT_SUCCESSFUL:
      state = {
        ...state,
        loading: false,
        user: null,
        success: true,
      };
      break;
    case ADD_NEW_MERCHANT_FAILED:
      state = {
        ...state,
        user: null,
        loading: false,
        registrationError:"Registeration failed",
        error: true,
      };
      break;
      case RESET_REGISTER_FLAG:
        state = {
          ...state,
          registrationError: false,
          message: null,
          loading: false,
          user: null,
          success: false,
          error: false,
  
        };
        break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Account;
