import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Card, CardBody, CardHeader } from "reactstrap";
import { BarChart } from "../../Components/Common/ChartsJs";
import DateFilter from "./DateFilter";
import { useDispatch, useSelector } from "react-redux";
import { timeFilter, MonthsFinder } from "../../helpers/charts_helper";
import { getVisitors } from "../../store/charts/action";
import NoDataFound from "./NoDataFound";
import moment from "moment";
const Visitors3DChart = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const now = new Date();
  const firstDay = moment(now).startOf('year').format("YYYY-MM-DD");
  const lastDay = moment(now).endOf('year').format('YYYY-MM-DD');
  const [action, setAction] = useState("month");
  const [startDate, setStartDate] = useState(firstDay);
  const [endDate, setEndDate] = useState(lastDay);
  const [xLabel, setxLabel] = useState(MonthsFinder(firstDay, lastDay));
  const [visitors3D, setvisitors3D] = useState("");
  const [callApi, setCallApi] = useState(false);

  const { visitors } = useSelector((state) => ({
    visitors: state.Charts.visitors,
  }));
  useEffect(() => {

    const params = {
      action_type: "visit_on_merchant_website",
      start_date: startDate,
      end_date: endDate,
      action: action,
    };
    dispatch(getVisitors(params));


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, callApi]);

  useEffect(() => {
    if (visitors?.data) {
      let customers = [];
      xLabel.forEach((date) => {
        const item = visitors?.data?.find((item) => {
          return item.date === date;
        });

        if (item) {
          customers.push(item.visitors);
        } else {
          customers.push(0);
        }
      });
      setvisitors3D(customers);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visitors, xLabel]);

  return (
    <>
      <Card>
        <CardHeader className="border-0 align-items-center d-flex pb-2">
          <h4 className="card-title mb-0 flex-grow-1">
          {t("numberOfVisitors") + " / " + timeFilter[action]}
          </h4>
          <DateFilter
            action={action}
            setAction={setAction}
            setxLabel={setxLabel}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            setCallApi={setCallApi}
            startDate={startDate}
            endDate={endDate}
            callApi={callApi}
          />
        </CardHeader>

        <CardBody className="p-0 py-3 position-relative">
          <div className="w-100">
            <div>
              {visitors?.data?.length === 0 && (
                <NoDataFound />
              )}
              <BarChart
                xLabel={timeFilter[action]}
                yLabel={"Visitors"}
                Month={xLabel}
                Sales={visitors3D}
                label={"Number of visitors"}
                dataFlag={visitors?.data?.length !== 0}

                dataColors='["--vz-primary-rgb, 0.8", "--vz-primary-rgb, 0.9"]'
                colorFrom={"#4E5AA8"}
                colorTo={"#EE856F"}
              />
            </div>
          </div>
        </CardBody>
      </Card>
    </>
  );
};
export default Visitors3DChart;
