import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Container, FormFeedback, Input, Label } from "reactstrap";
import { withRouter, Link, useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";

import ConfirmationModal from "../../Components/Common/ConfirmationModal";
import { ToastContainer, toast } from "react-toastify";

import salisLogo from "../../assets/images/logo.png";
import lockLogo from "../../assets/images/forgetPassword/lock.svg";

import { config } from "../../config";

import { forgetPassword, resetPasswordFlag } from "../../store/actions";
import validator from "../../helpers/Validator";
const { TOAST_AUTOCLOSE_TIMER } = config;

const validationSchema = Yup.object({
  password: Yup.string()
    .required("Please Enter Your Password")
    .matches(
      validator.passwordValidation,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
  confirm_password: Yup.string()
    .required("Please Enter Your Password")
    .when("password", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "Confirm Password Isn't Match"
      ),
    }),
});

const Changepassword = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const queryParams = new URLSearchParams(window.location.search);
  const [confirmationModal, setConfirmationModal] = useState(false);

  const { changePasswordFlag } = useSelector((state) => ({
    changePasswordFlag: state.ForgetPassword.changePasswordFlag,
  }));
  const formik = useFormik({
    initialValues: {
      password: "",
      confirm_password: "",
    },
    validationSchema,
    onSubmit: (values) => {
      setConfirmationModal(true);
    },
  });
  const handleNotValidForm = (e) => {
    e.preventDefault();
    formik.touched.email = true;
  };

  useEffect(() => {
    if (changePasswordFlag) {
      toast(changePasswordFlag, {
        position: "top-right",
        hideProgressBar: true,
        className: changePasswordFlag.includes("fail")
          ? "bg-danger text-white"
          : "bg-success text-white",
        progress: undefined,
        toastId: "",
      });
      !changePasswordFlag.includes("fail") &&
        setTimeout(() => history.replace("/login"), 1000);
    }
    dispatch(resetPasswordFlag());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, changePasswordFlag]);

  const handleForgetPassword = () => {
    setConfirmationModal(false);

    const data = {
      uid: queryParams.get("uid"),
      token: queryParams.get("token"),
      password: formik.values.password,
    };
    dispatch(forgetPassword(data));
  };
  document.title = "Reset Password | Salis- Merchants Dashboard";
  return (
    <div className="auth-page-content">
      <Container>
        <ToastContainer
          autoClose={TOAST_AUTOCLOSE_TIMER}
          style={{ width: "auto" }}
          
        />
        <ConfirmationModal
          show={confirmationModal}
          onCloseClick={() => setConfirmationModal(false)}
          message={"Yor are going to request resetting password link! "}
          action={handleForgetPassword}
        />
        <Row className="justify-content-center">
          <Col md={8} lg={6} xl={5}>
            <div className="text-center mt-sm-5 mb-4 ">
              <div>
                <Link to="/" className="d-inline-block auth-logo">
                  <img src={salisLogo} alt="" height="50" />
                </Link>
              </div>
              <div className="pt-5">
                <Link to="/" className="d-inline-block auth-logo">
                  <img src={lockLogo} alt="" />
                </Link>
              </div>
              <h4 className="mt-3"> Change my password</h4>
            </div>
          </Col>
        </Row>

        <Row className="justify-content-center pt-4">
          <Col md={8} lg={6} xl={5}>
            <form
              method="post"
              onSubmit={(e) =>
                formik.isValid ? formik.handleSubmit(e) : handleNotValidForm(e)
              }
            >
              <div className="mb-3">
                <Label htmlFor="userpassword" className="form-label">
                  New password <span className="text-danger">*</span>
                </Label>
                <div className="position-relative auth-pass-inputgroup mb-3">
                  <Input
                    name="password"
                    type="password"
                    className="form-control padding_input"
                    placeholder="Please enter your password"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password || ""}
                    invalid={
                      formik.touched.password && formik.errors.password
                        ? true
                        : false
                    }
                  />
                  {formik.touched.password && formik.errors.password ? (
                    <FormFeedback type="invalid">
                      <div>{formik.errors.password}</div>
                    </FormFeedback>
                  ) : null}
                  <button
                    className="btn btn-link position-absolute start-0 top-0 text-decoration-none text-muted"
                    type="button"
                    disabled
                    id="password-addon"
                  >
                    <i className="ri-lock-password-line"></i>
                  </button>
                </div>
              </div>

              <div className="mb-3">
                <Label htmlFor="confirmPassword" className="form-label">
                  Confirm new password <span className="text-danger">*</span>
                </Label>
                <div className="position-relative auth-pass-inputgroup mb-3">
                  <Input
                    name="confirm_password"
                    type="password"
                    className="form-control padding_input"
                    placeholder="Please confirm your password"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.confirm_password || ""}
                    invalid={
                      formik.touched.confirm_password &&
                      formik.errors.confirm_password
                        ? true
                        : false
                    }
                  />
                  {formik.touched.confirm_password &&
                  formik.errors.confirm_password ? (
                    <FormFeedback type="invalid">
                      <div>{formik.errors.confirm_password}</div>
                    </FormFeedback>
                  ) : null}
                  <button
                    className="btn btn-link position-absolute start-0 top-0 text-decoration-none text-muted"
                    type="button"
                    disabled
                    id="password-addon"
                  >
                    <i className="ri-lock-password-line"></i>
                  </button>
                </div>
              </div>
              <div className="mt-4 text-center pt-4">
                <button
                  type="submit"
                  className="btn  bg-light-red text-white w-100"
                >
                  Save & change
                </button>
              </div>
              <div className="mt-4 text-center">
                <p className="mb-0">
                  Wait, I remember my password...
                  <Link
                    to="/login"
                    className="fw-semibold text-primary text-decoration-underline text-light-red"
                  >
                    
                    Login...
                  </Link>
                </p>
              </div>
            </form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

Changepassword.propTypes = {
  history: PropTypes.object,
};

export default withRouter(Changepassword);
