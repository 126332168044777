import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  VERIFY_ERROR,
  RESET_LOGIN_FLAG,
} from "./actionTypes";

const initialState = {
  loginError: "",
  loginSuccess: false,
  unVerified: false,
  loading: false,
  loginFlag: false,
};

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case LOGIN_SUCCESS:
      state = {
        ...state,
        loading: false,
        loginFlag: true,
        loginSuccess: true,
      };
      break;
    case LOGOUT_USER:
      state = { ...state, isUserLogout: false };
      break;
    case LOGOUT_USER_SUCCESS:
      state = { ...state, isUserLogout: true,  };

      break;
    case API_ERROR:
      state = {
        ...state,
        loginError: "Invalid username or password",
        loading: false,
        isUserLogout: false,
      };
      break;
    case VERIFY_ERROR:
      state = {
        ...state,
        unVerified: true,
      };
      break;

    case RESET_LOGIN_FLAG:
      return {
        ...state,
        loginError: "",
        unVerified: false,
        loading: false,
        loginFlag: false,
        loginSuccess:false,
      };
    default:
      state = { ...state };
      break;
  }

  return state;
};

export default login;
