const theme = {
    defaultStatusStyle:{backgroundColor: "white", color: "black"},
    openStatusStyle:{backgroundColor: "rgba(3, 161, 212, 0.1)", color: "#03A1D4"},
    failedStatusStyle:{backgroundColor: "#FFDCDC", color: "#BF1D1D"},
    successStatusStyle:{backgroundColor: "#E5FFE2", color: "#22A717"},
    pendingStatusStyle:{backgroundColor: "rgba(78, 90, 168, 0.1)", color: "#4E5AA8"},
   
  };


  export default theme ;
