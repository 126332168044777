import React from "react";
import { Label, Form } from "reactstrap";
import OTPInput, { ResendOTP } from "otp-input-react";
//redux
import { useDispatch } from "react-redux";

import { withRouter } from "react-router-dom";

import { sendOtp } from "../../store/actions";
import { useTranslation } from "react-i18next";
import OtpImage from "../../assets/images/OTP.png";
const Otp = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleResendOtp = () => {
    dispatch(sendOtp({ phone_number: props.phoneNumber }));
  };
  const renderButton = (buttonProps) => {
    return (
      <button
        onClick={handleResendOtp}
        className="btn p-0 border-0 font-bold text-light-red"
        {...buttonProps}
      >
        {buttonProps.remainingTime !== 0
          ? `${buttonProps.remainingTime} ${t("sec")}`
          : "Send now"}
      </button>
    );
  };

  const renderTime = () => React.Fragment;
  return (
    <React.Fragment>
      <div className="text-center mt-4">
        <img src={OtpImage} alt="OTP" width="100" />
        <h3 className="mt-4">{t("OTPVerification")}</h3>
      </div>
      <div className="px-2 mx-4">
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            return false;
          }}
          action="#"
        >
          <div className="mb-3 d-flex justify-content-center  ">
            <Label htmlFor="otp" className="text-muted">
              {t("weSentOTP")}{" "}
              <span className="text-light-red">{props.phoneNumber}</span>
            </Label>
          </div>
          <div className="mb-4 d-flex justify-content-center">
            <OTPInput
              value={props.otp}
              onChange={props.setOTP}
              autoFocus
              OTPLength={4}
              otpType="number"
              disabled={false}
            />
          </div>
          <div className="mt-4">
            <button
              onClick={props.handleAction}
              style={{
                backgroundColor: "#EE856F",
                color: "white",
                height: "45px",
              }}
              className="border-0 w-100 rounded"
            >
              {t("verify")}
            </button>
          </div>
          <div className="d-flex justify-content-center align-items-center gap-2 mt-2">
            <p className="text-muted p-0 m-0">{t("didn'tReceive")}</p>
            <ResendOTP renderButton={renderButton} renderTime={renderTime} />
          </div>
        </Form>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Otp);
