import React, { useState, useEffect } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { config } from "../../config";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getUserData } from "../../store/auth/profile/actions";
//import images
import avatar1 from "../../assets/images/users/user-dummy-img.jpg";
import ConfirmationModal from "./ConfirmationModal";
import { useHistory } from "react-router-dom";
const ProfileDropdown = () => {
  const History = useHistory();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => ({
    user: state.Profile.user,
  }));


  const { userData } = useSelector((state) => ({
    userData: state.Profile.userData,
    success: state.Profile.success,
    error: state.Profile.error,
  }));

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      dispatch(getUserData(obj.data.id));
    }
  }, [dispatch]);
  const [userName, setUserName] = useState("");
  const [userImage, setUserImage] = useState("");
  const [confirmationModal, setConfirmationModal] = useState(false);
  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"));

      if (obj.data.exp < Date.now() / 1000) {
        localStorage.clear();
        History.push("/logout");
      }
if(userData?.store_logo){

  setUserImage(`${userData?.store_logo}?${userData?.date_update}` || avatar1);
}else{
  setUserImage(avatar1);

}
      setUserName(userData?.store_name || userData.user?.username);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userName, user,userData]);

  const handleShowConfirmationModal = () => {
    setConfirmationModal(true);
  };
  //Dropdown Toggle
  const [isProfileDropdown, setIsProfileDropdown] = useState(false);
  const toggleProfileDropdown = () => {
    setIsProfileDropdown(!isProfileDropdown);
  };
  const handleLogout = () => {
    History.push("/logout");
  };
  return (
    <React.Fragment>
      <ConfirmationModal
        show={confirmationModal}
        onCloseClick={() => setConfirmationModal(false)}
        message={"You are going to logout! "}
        action={handleLogout}
      />
      <Dropdown
        isOpen={isProfileDropdown}
        toggle={toggleProfileDropdown}
        className="ms-sm-3 header-item topbar-user"
      >
        <DropdownToggle tag="button" type="button" className="btn">
          <span className="d-flex align-items-center">
            <img
              className="rounded-circle header-profile-user"
              src={userImage || avatar1}
              alt=""
            />
            <span className="text-start ms-xl-2">
              <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">
                {userName}
              </span>
            
            </span>
          </span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <h6 className="dropdown-header">Welcome {userName}!</h6>
          <Link to="/profile" className="text-dark">
            <DropdownItem>
              <i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>
              <span className="align-middle">Profile</span>
            </DropdownItem>
          </Link>
          <DropdownItem href={`${config.API_URL + `docs`}`}>
            <i className="mdi mdi-lifebuoy text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle">Documentation</span>
          </DropdownItem>
          <div className="dropdown-divider"></div>
          <DropdownItem href="#" onClick={() => handleShowConfirmationModal()}>
            <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>{" "}
            <span className="align-middle" data-key="t-logout">
              Logout
            </span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default ProfileDropdown;
