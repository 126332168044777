import {
  API_RESPONSE_SUCCESS,
  API_RESPONSE_ERROR,
  RESET_ECOM_FLAG,
  RESET_LOADER_FLAG,
  GET_PRODUCTS_DATA,
  GET_PRODUCTS_DATA_SUCCESS,
  GET_PRODUCTS_DATA_FAIL,
  GET_ONE_PRODUCTS_DATA,
  ADD_PRODUCT_DATA_SUCCESS,
  ADD_PRODUCT_DATA_FAIL,
  UPDATE_ONE_PRODUCT_DATA_SUCCESS,
  UPDATE_ONE_PRODUCT_DATA_FAIL,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAIL,
  UPDATE_CURRENT_PRODUCT_STATE,
  GET_ORDERS_DATA,
  UPDATE_ORDERS_DATA,
  GET_ONE_ORDERS_DATA,
  GET_SPECIAL_DEALS_DATA,
  ADD_SPECIAL_DEALS,
  SPECIAL_DEALS_settings,
  SPECIAL_AVG_MAX_SETTINGS,
  GET_CARDS,
  PRE_ADD_CARD,
  ADD_CARD,
  PRE_CHANGE_CARD,
  CHANGE_CARD,
  GET_CARD_TOKEN,
  DELETE_CARD,
  PRE_CHECKOUT,
  CHECKOUT,
  CHECKOUT_FAIL,
  CHECKOUT_SUCCESS,
  IN_STORE_ORDER,
  IN_STORE_ORDER_SUCCESS,
  IN_STORE_ORDER_FAIL,
  ADD_ORDER_RATING_SUCCESS,
  ADD_ORDER_RATING_FAIL,
  GET_BANKS,
  GET_BANK_ACCOUNTS,
  DELETE_BANK_ACCOUNT,
  ADD_BANK_ACCOUNT,
  ADD_BANK_ACCOUNT_FAIL,
  ADD_BANK_ACCOUNT_SUCCESS,
  CHANGE_BANK_ACCOUNT,
  CANCEL_ORDER_SUCCESS,
  CANCEL_ORDER_FAIL,
  CHANGE_BANK_ACCOUNT_SUCCESS,
  CHANGE_BANK_ACCOUNT_FAIL,
  GET_WALLET_SUCCESS,
  GET_WALLET_FAIL,
  GET_ITEMS_SUCCESS,
  GET_CAPTURES_SUCCESS,
  REFUND_PAYMENT_SUCCESS,
  REFUND_PAYMENT_FAIL,
  CAPTURE_PAYMENT_SUCCESS,
  CAPTURE_PAYMENT_FAIL,
  REGISTER_WEBHOOK_SUCCESS,
  REGISTER_WEBHOOK_FAIL,
  GET_WEBHOOK,
  GET_WEBHOOK_SUCCESS,
  DELETE_WEBHOOK_SUCCESS,
  DELETE_WEBHOOK_FAIL,
  GET_SETTLEMENTS,
  GET_SETTLEMENTS_SUCCESS,
  GET_ONE_SETTLEMENTS,
  GET_ONE_SETTLEMENTS_SUCCESS,
  GET_TRANSACTION,
  GET_TRANSACTION_SUCCESS,
  PRE_UPLOAD_IMAGE_SUCCESS,
  UPLOAD_IMAGE_SUCCESS,
  UPLOAD_IMAGE_FAIL,
  WITHDRAW,
  WITHDRAW_SUCCESS,
  WITHDRAW_FAILED,
  PayPerClick,
  PayPerClick_SUCCESS,
  PayPerClick_FAILED,
  GET_PPC_DATA,
  GET_PPC_DATA_SUCCESS,
  GET_PPC_DATA_FAIL,
  CANCEL_PPC,
  CANCEL_PPC_FAIL,
  CANCEL_PPC_SUCCESS,
  GET_PAYMENT_TYPES_FAILED,
  GET_PAYMENT_TYPES_SUCCESS,
  GET_PAYMENT_TYPES,
} from "./actionType";


const INIT_STATE = {
  loading: true,
  actualCustomers: [],
  totalSales: [],
  products: [],
  specialDeals: [],
  specialDealsSettings: [],
  specialDealsAvgMaxSettings: [],
  newSpecialDeal: {},
  oneSettlement:{},
  orders: [],
  transactions: [],
  oneOrder: [],
  orderInvoice: [],
  sellers: [],
  customers: [],
  settlements: [],
  error: {},
  ordersDataGet: [],
  allProductsData: [],
  allPPCData:[],
  inStoreOrder: {},
  orderUpdater: {},
  productUpdater: {},
  productDeleter: [],
  oneProduct: [],
  weebhooks: [],
  ProductData: {},
  get_CardsData: [],
  orderItems: [],
  orderCaptures: [],
  preAddCard: {},
  addCard: {},
  preChangeCard: {},
  CardToken: "",
  changeCard: {},
  deleteCard: {},
  preCheckout: {},
  checkout: {},
  imageUploadLink: [],
  imageUpload: false,
  imageUploadError: false,
  specialDealError: false,
  specialDealErrorLoading: false,
  paymentStatusFlag: false,
  preChangeCardFlag: false,
  isSpecialDealAdd: false,
  isProductAddSuccessFlag: false,
  isProductAddFailFlag: false,
  isProductUpdateSuccessFlag: false,
  isProductUpdateFailFlag: false,
  isProductDeleteFailFlag: false,
  isProductDeleteSuccessFlag: false,
  inStoreErrorFlag: false,
  inStoreSuccessFlag: false,
  productsGetErrorFlag: false,
  productsGetSuccessFlag: false,
  isAddRateSuccessFlag: "",
  isAddRateFailFlag: "",
  banksData: {},
  bankAccountsData: {},
  deleteBankAccount: {},
  addBankAccount: {},
  changeBankAccount: {},
  isBankAdded: false,
  isBankChanged: false,
  isBankDelete: false,
  cancelOrderFlag: false,
  isBankAddFailed: "",
  isBankChangeFailed: "",
  wallet: {},
  getWalletFailed: false,
  refundFlag: "",
  captureFlag: false,
  addWebhookFlag: "",
  deleteWebhookFlag: "",
  withdraw:{},
  withdrawFailed:false,
  withdrawSuccess:false,
  withdrawLoading:false,
  payperclickFail:false,
  payperclickSuccess:false,
  getPPCDataFail:false,
  getTransactionsFail:false,
  getTransactionsSuccess:false,
  getPPCDataSuccess:false,
  PPCCancelSuccess:false,
  PPCCancelError:false,
  GET_PAYMENT_TYPES:{},
  GET_PAYMENT_TYPES_FAILED:false,
  GET_PAYMENT_TYPES_SUCCESS:false,
  getpaymenttypes:{},
  paymenttypesFailed:false,
  paymenttypesSuccess:false,
};

const Ecommerce = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SPECIAL_DEALS_DATA:
      return {
        ...state,
        loading: true,
      };
    case GET_ORDERS_DATA:
      return {
        ...state,
        loading: true,
      };

      case GET_TRANSACTION:
        return {
          ...state,
          loading: true,
        };
      
    case GET_ONE_ORDERS_DATA:
      return {
        ...state,
        loading: true,
      };
    case GET_WEBHOOK:
      return {
        ...state,
        loading: true,
      };

    case IN_STORE_ORDER:
      return {
        ...state,
        inStoreOrder: action,
      };
    case REGISTER_WEBHOOK_SUCCESS:
      return {
        ...state,
        addWebhookFlag: "A new webhook was registered successfully",
      };

    case REGISTER_WEBHOOK_FAIL:
      return {
        ...state,
        addWebhookFlag: "You can only register up to 5 webhooks.",
      };

    case PRE_UPLOAD_IMAGE_SUCCESS:
      return {
        ...state,
        imageUploadLink: [...state.imageUploadLink,{"link":action.payload,"type":action.types}],
      };

    case UPLOAD_IMAGE_SUCCESS:
      return {
        ...state,
        imageUpload: true,
      };

      case UPLOAD_IMAGE_FAIL:
        return {
          ...state,
          imageUploadError: true,
        };
      
    case DELETE_WEBHOOK_SUCCESS:
      return {
        ...state,
        deleteWebhookFlag: "Webhook has been deleted successfully",
      };

    case DELETE_WEBHOOK_FAIL:
      return {
        ...state,
        deleteWebhookFlag: "Deleting webhook has failed",
      };

    case GET_WEBHOOK_SUCCESS:
      return {
        ...state,
        weebhooks: action.payload,
      };
      case GET_TRANSACTION_SUCCESS:
        return {
          ...state,
          transactions: action.payload,
        };
      
    case IN_STORE_ORDER_SUCCESS:
      return {
        ...state,
        inStoreOrder: action.payload,
        inStoreSuccessFlag: true,
      };

    case IN_STORE_ORDER_FAIL:
      return {
        ...state,
        inStoreErrorFlag: true,
      };
    case GET_SETTLEMENTS:
      return {
        ...state,
        loading: true,
      };
    case GET_SETTLEMENTS_SUCCESS:
      return {
        ...state,
        settlements: action.payload,
      };
      case GET_ONE_SETTLEMENTS:
        return {
          ...state,
          loading: true,
        };
      case GET_ONE_SETTLEMENTS_SUCCESS:
        return {
          ...state,
          oneSettlement: action.payload,
        };
    case GET_CARD_TOKEN:
      return {
        ...state,
        CardToken: action.payload,
      };
    case UPDATE_CURRENT_PRODUCT_STATE:
      return {
        ...state,
      };
    case GET_PRODUCTS_DATA:
      return {
        ...state,
        loading: true,
      };

    case GET_PRODUCTS_DATA_SUCCESS:
      return {
        ...state,
        allProductsData: action.payload,
        productsGetSuccessFlag: true,
      };
    case GET_PRODUCTS_DATA_FAIL:
      return {
        ...state,
        productsGetSuccessFlag: true,
      };

    case DELETE_PRODUCT_SUCCESS:
      return {
        ...state,
        isProductDeleteSuccessFlag: true,
      };

    case DELETE_PRODUCT_FAIL:
      return {
        ...state,
        error: action.payload,
        isProductDeleteFailFlag: true,
      };

    case ADD_PRODUCT_DATA_SUCCESS:
      return {
        ...state,
        isProductAddSuccessFlag: true,
      };
    case ADD_PRODUCT_DATA_FAIL:
      return {
        ...state,
        isProductAddFailFlag: true,
      };
    case UPDATE_ONE_PRODUCT_DATA_SUCCESS:
      return {
        ...state,
        isProductUpdateSuccessFlag: true,
      };
    case UPDATE_ONE_PRODUCT_DATA_FAIL:
      return {
        ...state,
        isProductUpdateFailFlag: true,
      };
    case ADD_ORDER_RATING_SUCCESS:
      return {
        ...state,
        isAddRateSuccessFlag: "The order was rated successfully",
      };
    case ADD_ORDER_RATING_FAIL:
      return {
        ...state,
        isAddRateFailFlag: "You already rated this order",
      };
    case CANCEL_ORDER_SUCCESS:
      return {
        ...state,
        cancelOrderFlag: "The order was canceled successfully",
      };
    case CANCEL_ORDER_FAIL:
      return {
        ...state,
        cancelOrderFlag: "Canceling order was failed",
      };

    case CHECKOUT_SUCCESS:
      return {
        ...state,
        paymentStatusFlag: true,
      };
    case CHECKOUT_FAIL:
      return {
        ...state,
        paymentStatusFlag: "failed",
      };

    case ADD_BANK_ACCOUNT_SUCCESS:
      return {
        ...state,
        isBankAdded: true,
      };
    case ADD_BANK_ACCOUNT_FAIL:
      return {
        ...state,
        isBankAddFailed: "Bank account with this iban already exists.",
      };
    case CHANGE_BANK_ACCOUNT_SUCCESS:
      return {
        ...state,
        isBankChanged: true,
      };
    case CHANGE_BANK_ACCOUNT_FAIL:
      return {
        ...state,
        isBankChangeFailed: "Bank account with this iban already exists.",
      };
    case GET_WALLET_FAIL:
      return {
        ...state,
        getWalletFailed: true,
      };
    case GET_WALLET_SUCCESS:
      return {
        ...state,
        wallet: action.payload,
      };
    case PayPerClick_FAILED:
      return {
        ...state,
        payperclickLoading:false,
        payperclickFail: true,
        payperclick: action.payload,

      };
    case PayPerClick:
      return {
        ...state,
        payperclickLoading:true,
      };
    case PayPerClick_SUCCESS:
      return {
        ...state,
        payperclickLoading:false,
        payperclickSuccess:true,
        payperclick: action.payload,
      };


      case GET_PPC_DATA_FAIL:
        return {
          ...state,
          PPCGetErrorFlag: true,
          loading:false,
        };
      case GET_PPC_DATA:
        return {
          ...state,
          loading: true,
        };
  
      case GET_PPC_DATA_SUCCESS:
        return {
          ...state,
          allPPCData: action.payload,
          PPCGetSuccessFlag:true,
          loading:false,
        };

        case CANCEL_PPC_FAIL:
          return {
            ...state,
            PPCCancelError: true,
            loading:false,
          };
        case CANCEL_PPC:
          return {
            ...state,
            loading: true,
          };
    
        case CANCEL_PPC_SUCCESS:
          return {
            ...state,
            PPCCancelSuccess:true,
            loading:false,
          };

      case WITHDRAW_FAILED:
      return {
        ...state,
        withdrawLoading:false,
        withdrawFailed: true,
        withdraw: action.payload,

      };
    case WITHDRAW:
      return {
        ...state,
        withdrawLoading:true,
        // withdraw: action.payload,
      };
      case WITHDRAW_SUCCESS:
      return {
        ...state,
        withdrawLoading:false,
        withdrawSuccess:true,
        withdraw: action.payload,
      };
    case GET_ITEMS_SUCCESS:
      return {
        ...state,
        orderItems: action.payload,
      };
    case GET_CAPTURES_SUCCESS:
      return {
        ...state,
        orderCaptures: action.payload,
      };

    case REFUND_PAYMENT_SUCCESS:
      return {
        ...state,
        refundFlag: "Refund process completed successfully",
      };

    case REFUND_PAYMENT_FAIL:
      return {
        ...state,
        refundFlag: "Refund process was failed",
      };

    case CAPTURE_PAYMENT_SUCCESS:
      return {
        ...state,
        captureFlag: "Capture process completed successfully",
      };

    case CAPTURE_PAYMENT_FAIL:
      return {
        ...state,
        captureFlag: "Capture process was failed",
      };

    case API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case GET_CARDS:
          return {
            ...state,
            get_CardsData: action.payload.data,
          };
        case PRE_ADD_CARD:
          return {
            ...state,
            preAddCard: action.payload.data,
          };
        case ADD_CARD:
          state.isCardAdd = true;
          return {
            ...state,
            isCardAdd: true,
            addCard: action.payload.data,
          };
        case PRE_CHANGE_CARD:
          return {
            ...state,
            preChangeCard: action.payload.data,
            preChangeCardFlag: true,
          };
        case CHANGE_CARD:
          state.isCardChange = true;
          return {
            ...state,
            isCardChange: true,
            changeCard: action.payload.data,
          };
        case DELETE_CARD:
          state.isCardDelete = true;
          return {
            ...state,
            isCardDelete: true,
            deleteCard: action.payload.data,
          };
        case PRE_CHECKOUT:
          return {
            ...state,
            preCheckout: action.payload.data,
          };
        case CHECKOUT:
          return {
            ...state,
            checkout: action.payload.data,
          };
        case GET_ORDERS_DATA:
          return {
            ...state,
            ordersDataGet: action.payload.data,
          };
        case GET_SPECIAL_DEALS_DATA:
          return {
            ...state,
            loading: true,
            specialDeals: action.payload.data,
          };
        case SPECIAL_DEALS_settings:
          return {
            ...state,
            specialDealsSettings: action.payload.data,
          };
        case SPECIAL_AVG_MAX_SETTINGS:
          return {
            ...state,
            specialDealsAvgMaxSettings: action.payload.data,
            specialDealError: false,
            specialDealErrorLoading: true,
          };


        case GET_ONE_PRODUCTS_DATA:
          return {
            ...state,
            oneProduct: action.payload.data,
          };

        case GET_ONE_ORDERS_DATA:
          return {
            ...state,
            oneOrder: action.payload.data,
            orderInvoice: action.payload.data,
          };

        case UPDATE_ORDERS_DATA:
          return {
            ...state,
            orderUpdater: action.payload,
          };

        case ADD_SPECIAL_DEALS:
          state.isSpecialDealAdd = true;
          return {
            ...state,
            isSpecialDealAdd: true,
            newSpecialDeal: [...state.newSpecialDeal, action.payload.data],
          };
        //banks
        case GET_BANKS:
          return {
            ...state,
            banksData: action.payload.data,
          };
        case GET_BANK_ACCOUNTS:
          return {
            ...state,
            bankAccountsData: action.payload.data,
          };
        case DELETE_BANK_ACCOUNT:
          return {
            ...state,
            deleteBankAccount: action.payload.data,
            isBankDelete: true,
          };
        case ADD_BANK_ACCOUNT:
          return {
            ...state,
            addBankAccount: action.payload.data,
            isBankAdded: true,
          };
        case CHANGE_BANK_ACCOUNT:
          return {
            ...state,
            changeBankAccount: action.payload.data,
            isBankChanged: true,
          };

        default:
          return { ...state };
      }
      case GET_PAYMENT_TYPES_FAILED:
        return {
          ...state,
          paymenttypesFailed: true,
          getpaymenttypes: action.payload,
  
        };
      case GET_PAYMENT_TYPES:
        return {
          ...state,
        };
        case GET_PAYMENT_TYPES_SUCCESS:
        return {
          ...state,
          paymenttypesSuccess:true,
          getpaymenttypes: action.payload,
        };
    case API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case SPECIAL_AVG_MAX_SETTINGS:
          return {
            ...state,
            specialDealError: true,
            specialDealErrorLoading: true,
          };

        case DELETE_CARD:
          state.isCardDelete = false;
          return {
            ...state,
            isCardDelete: false,
            hasErrors: true,
          };
          case ADD_CARD:
            return {
              ...state,
              isCardAdd: false,
              AddCardError: true,
            };
        default:
          return { ...state };
      }

    case RESET_ECOM_FLAG:
      return {
        ...state,
        paymentStatusFlag: false,
        isCustomerAdd: false,
        isCustomerAddFail: false,
        isCustomerDelete: false,
        isCustomerDeleteFail: false,
        isCustomerUpdate: false,
        isCustomerUpdateFail: false,
        isOrderAdd: false,
        isProductAddSuccessFlag: false,
        isProductAddFailFlag: false,
        isSpecialDealAdd: false,
        isOrderAddFail: false,
        isOrderDelete: false,
        isOrderDeleteFail: false,
        isOrderUpdate: false,
        isOrderUpdateFail: false,
        isProductDeleteSuccessFlag: false,
        isProductDeleteFailFlag: false,
        isProductUpdateSuccessFlag: false,
        isProductUpdateFailFlag: false,
        isCardAdd: false,
        isCardChange: false,
        isCardDelete: false,
        error: false,
        hasErrors: false,
        isPaymentProccessed: false,
        preChangeCardFlag: false,
        inStoreErrorFlag: false,
        inStoreSuccessFlag: false,
        productsGetErrorFlag: false,
        productsGetSuccessFlag: false,
        isAddRateSuccessFlag: "",
        isAddRateFailFlag: "",
        isBankDelete: false,
        isBankAdded: false,
        isBankChanged: false,
        cancelOrderFlag: false,
        inStoreOrder: {},
        isBankAddFailed: "",
        isBankChangeFailed: "",
        refundFlag: "",
        captureFlag: false,
        addWebhookFlag: "",
        deleteWebhookFlag: "",
        imageUpload: false,
        imageUploadError: false,
        withdrawFailed:false,
        withdrawSuccess:false,
        paymenttypesSuccess:false,
        paymenttypesFailed:false,
        AddCardError:false,
        payperclickSuccess:false,
        getpayperclickSuccess:false,
        getpayperclickFail:false,
        payperclickFail:false,
        imageUploadLink: [],
        PPCCancelSuccess:false,
        PPCCancelError:false,
      };
    case RESET_LOADER_FLAG:
      return {
        ...state,

        loading: false,
      };

    default:
      return { ...state };
  }
};

export default Ecommerce;
