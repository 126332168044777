import "antd/dist/antd.css";
import { TextField } from "@material-ui/core";
import PhoneNumber from "../../../assets/images/svgexport-7.png";

import { Card, CardBody, Col, Collapse } from "reactstrap";

const CollapseOne = ({
  collapseOne,
  handleStepper,
  setMobile_number,
  mobile_number,
}) => {
  return (
    <Collapse in isOpen={collapseOne} >
      <Card className=" h-100% d-flex justify-content-center flex-column  align-items-center bg-light w-100 p-5 phoneCollapseOne">
        <CardBody className="border-0 h-100% bg-light d-flex justify-content-center flex-column  align-items-center w-100 ">
          <h4 className="card-body mb-3">
            <img
              className="p-2"
              src={PhoneNumber}
              alt="PhoneNumber logo"
            />
            Customer phone number</h4>

          <p className="card-body color  text-sm  text-center mb-0 w-50 phoneCollapseOneP">
            Please enter customer phone number to be able send
            him a link to reach the next steps.
          </p>
          <Col className="p-5 w-100 phoneCollapseOneCard">
            <Card className="mb-20 h-100 border-0 w-100" >
              <CardBody className="p-5 phoneCollapseOneCard">
                <h4 className="card-title mb-3">
                  Customer phone number</h4>
                <TextField
                  id="mobile_number"
                  name="mobile_number"
                  type="text"
                  className="form-control padding_input border"
                  placeholder="Enter phone number"
                  value={mobile_number}
                  onChange={(event) => {
                    const phoneRegex = /^\+?[0-9]{0,13}$/; // Regex to match phone number with '+' and up to 13 digits
                    if (phoneRegex.test(event.target.value)) {
                      setMobile_number(event.target.value);
                    }
                  }}
                  helperText={
                   "Ex: +966512121212"
                  }
                />
              </CardBody>
            </Card>
          </Col>
          <div
            className="d-flex justify-content-center w-100"
          >
            <button
              className="btn bg-light-red text-white w-50 my-5 phoneCollapseOnebutton"
              onClick={() => handleStepper(1)}
              disabled={
                (mobile_number.length !== 13)
              }
            >
              Continue
            </button>
          </div>
        </CardBody>
      </Card>


    </Collapse>
  );
};
export default CollapseOne;
