import { call, put, takeEvery } from "redux-saga/effects";
import jwt_decode from "jwt-decode";
// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes";
import { apiError, loginSuccess, logoutUserSuccess } from "./actions";
import axios from "axios";

//Include Both Helper File with needed methods

import { logIn } from "../../../helpers/salis_api";

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(logIn, {
      username: user.username,
      password: user.password,
    });
    yield put(loginSuccess(response));
    const token = response.access
    const decodedAccess = jwt_decode(token);


    axios.defaults.headers.common["Authorization"] = "Bearer " + token;

    // TODO Get this data from DB and remove this constant
    const userData = {
      data: {
        id: decodedAccess.user_id,
        exp: decodedAccess.exp,
        user_type: decodedAccess.user_type,
      },

      status: "success",
      token: token,
    };
    localStorage.setItem("authUser", JSON.stringify(userData));

  } catch (error) {
    yield put(apiError(error));
  }
}

function* logoutUser() {
  try {
    localStorage.removeItem("authUser");
    yield put(logoutUserSuccess(LOGOUT_USER, true));
  } catch (error) {
    yield put(apiError(LOGOUT_USER, error));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;
