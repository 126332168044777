import React, { useEffect } from "react";

import { Helmet } from "react-helmet";
//Import actions
import { getCards, preCheckout } from "../../store/ecommerce/action";
//redux
import { config } from "../../config";
import { useSelector, useDispatch } from "react-redux";

const Checkout = ({trigger, amount, callback_url, source, payment_type= "DB" ,save_card="no"}) => {
  const dispatch = useDispatch();


  const { preCheckoutRes, cards } = useSelector((state) => ({
    preCheckoutRes: state.Ecommerce.preCheckout,
    cards: state.Ecommerce.get_CardsData,
  }));
  const baseURL = preCheckoutRes.baseURL;
  const scriptURL = preCheckoutRes.scriptURL;
  useEffect(() => {
    if (trigger) {
      dispatch(getCards());
      dispatch(
        preCheckout({
          amount: amount,
          callback_url: config.PUBLIC_URL + callback_url,
          source: source,
          payment_type: payment_type,
          save_card:save_card
        })
      );
    
    
}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, amount, trigger]);

  return (
    <>
      <Helmet>
        <script src="https://code.jquery.com/jquery-1.11.3.min.js"></script>
        <script>
          {`
            var wpwlOptions = {
            style: "plain",
            requireCvv:true,
            onReady: function(){ 
            ${payment_type==="PA"? "$('button[aria-label=\"Pay now\"]').html('Save & continue');": ""}    
            if($('div#wpwl-registrations').length !=0){
              $('div#wpwl-registrations').before("<label id='wpwl-registrations-label' class='text-light-red d-flex align-items-center gap-2'><input type='radio' name='paymentOptions' value='#wpwl-registrations' checked>Choose one of saved cards</label>");
              $('div.wpwl-container-card').before("<label id='wpwl-container-card-label' class='text-light-gray d-flex align-items-center gap-2'><input type='radio' name='paymentOptions' value='.wpwl-container-card'>+ Add a new card</label>");
              $('div.wpwl-container-card').css("display","none")
              $('input[value="#wpwl-registrations"').change(function(){
                $('div.wpwl-container-card').css("display","none")
                $('div#wpwl-registrations').css("display","block")
                $('#wpwl-container-card-label').addClass("text-light-gray")
                $('#wpwl-container-card-label').removeClass("text-light-red")
                $('#wpwl-registrations-label').addClass("text-light-red")
                $('#wpwl-registrations-label').removeClass("text-light-gray")
              })
              $('input[value=".wpwl-container-card"').change(function(){
                $('div.wpwl-container-card').css("display","block")
                $('div#wpwl-registrations').css("display","none")
                $('#wpwl-container-card-label').addClass("text-light-red")
                $('#wpwl-container-card-label').removeClass("text-light-gray")
                $('#wpwl-registrations-label').addClass("text-light-gray")
                $('#wpwl-registrations-label').removeClass("text-light-red")
              })
            }
            $('.wpwl-wrapper-registration-registrationId').css("display","none");
            ${cards.length?'let createRegistrationHtml = \'<div class="d-flex gap-2 mb-4"><div class="customInput"><input type="checkbox" name="createRegistration" value="true" /></div><div class="customLabel">Save card info.</div></div>\';$("form.wpwl-form-card").find(".wpwl-wrapper.wpwl-wrapper-submit").before(createRegistrationHtml);':""}
            $('.wpwl-wrapper-registration-holder').css("display","none");
            $('.wpwl-wrapper-registration-cvv').css("display","none");
            $('.wpwl-group-registration label').addClass('payment-card');
            $('.wpwl-wrapper-registration-brand').css("align-self","center")
            $('.wpwl-wrapper-registration-expiry').html("Valid until "+ $('.wpwl-wrapper-registration-expiry').html());
            $('.wpwl-wrapper-registration-expiry').addClass("text-gray-650");
            $('.wpwl-control-cardNumber').after($(".wpwl-brand-card"));
            $('.wpwl-group.wpwl-group-cardNumber').before($('.wpwl-group.wpwl-group-cardHolder'))
            $('.wpwl-control-iframe.wpwl-control-cardNumber').attr('placeholder','1234 1234 1234 1234')
            $('.wpwl-control-iframe.wpwl-control-cvv').attr('placeholder','3 digits')
            $('.wpwl-control-iframe.wpwl-control-cvv').attr('data-maxlength','3');
            $('.wpwl-control-cardHolder').attr('placeholder','Name on the card')
            $('.wpwl-wrapper-brand').css("display","none");
            $('.wpwl-label-brand').css("display","none");
            $('.wpwl-control-cardHolder').attr('required', 'required');
            $('input[type="radio"]').addClass("form-check-input");
            let number=$('.wpwl-wrapper-registration-number')
            if(number.length!=0){
            $('.wpwl-wrapper-registration-number').each(function(){$(this).html($(this).html().replace('**', '*****'))});
            }
            $('.wpwl-control-cardNumber').on('input', function() {
              var cardNumber = $(this).val().replace(/s/g, ''); // Remove whitespace
              if (cardNumber.length !== 16) {
                $(this).addClass('is-invalid');
              } else {
                $(this).removeClass('is-invalid');
              }
            });
            
            $('.wpwl-control-cardHolder').attr("required",true)
            $('.wpwl-control-cardHolder').on('input', function(e) {
              var cardHolderName = $('.wpwl-control-cardHolder').val();
              var errorMessage = "Invalid name"; // Set the error message
              if (cardHolderName.trim() === '') {
                e.preventDefault(); // Prevent the form from submitting
                $('.wpwl-control-cardHolder').addClass('is-invalid');
                // Create a new element for the error message
                var errorElement = $("<div class='invalid-feedback'>" + errorMessage + "</div>");
                // Append the error message to the form control
                $('.wpwl-control-cardHolder').parent().append(errorElement);
              } else {
                // If the name is valid, remove the error message and class
                $('.wpwl-control-cardHolder').removeClass('is-invalid');
                $('.wpwl-control-cardHolder').parent().find('.invalid-feedback').remove();
              }
            });
                                    
            },
        registrations: {
            hideInitialPaymentForms: false
        }
      }
      
                                
        `}
        </script>
        <script src={scriptURL} async></script>
      </Helmet>

      <form
        action={baseURL}
        className="mt-4 paymentWidgets"
        data-brands="MADA VISA MASTER"
      ></form>
    </>
  );
};

export default Checkout;
