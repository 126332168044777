import TableScrollbar from "react-table-scrollbar";
import "antd/dist/antd.css";
import { Card, CardBody, Col, Row, Collapse } from "reactstrap";
import { Link } from "react-router-dom";
import noImage from "../../../assets/images/no_image.jpg";
import noProducts from "../../../assets/images/no_products_icon.svg";
import { DatePicker } from "antd";
import { TextField, InputAdornment } from "@material-ui/core";
import moment from "moment";
import { config } from "../../../config";

const { RangePicker } = DatePicker;

const CollapseOne = ({
  collapseOne,
  flagSearch,
  setProductName,
  products,
  setProductDetails,
  handleStepper,
  handleValidatePPC,
  title,
  productDetails,
  setTitle,
  pageNumber,
  setPageNumber,
  allProductsData,
  handleProductSearch,
  setDealDuration,
  setStartDate,
  setEndDate,
  budget,
  setbudget,
  startDate,
  endDate
}) => {
  const handleKeypress = (e) => {
    setProductName(e.target.value)
    handleProductSearch()

  }

  const { currency } = config;
  const handleDealDateChange = (e) => {
    if (!e) {
      return;
    } else {
      setDealDuration(e[1].diff(e[0], "days"));
      setStartDate(e[0].format("YYYY-MM-DD"));
      setEndDate(e[1].format("YYYY-MM-DD"));
    }
  };
  const lengthBudget = parseInt(budget).toString().length;
  const numBudget = parseFloat(budget);
  const decimalPlaces = numBudget.toString().split('.')[1]?.length ?? 0;
  return (
    <Collapse in isOpen={collapseOne}>
      <Card className="bg-alice-blue h-100%">
        <CardBody className="border-0 h-100% ">
          <h4 className="card-body mb-0">Set pay per click details</h4>

          <p className="card-body color  text-sm  text-muted mb-0">
            Please set pay per click campaign details by entering title,
            estimated budget, and scheduling its date.
          </p>
          <Row>
            <Col xl={12} className="p-5 colOnePPC">
              <Card className="mb-20 p-0 h-100 border-0">
                <div className="mb-3 mt-2">
                  <span className="badge badge-soft-danger align-middle badge-span rounded-pill mx-2">
                    <p className="badge-icon">{1}</p>
                  </span>
                  Pay per click details<br />
                  <p className="card-body color  text-sm  text-muted mb-0">
                    Title, bugget and scheduling pay per click campaign
                  </p>
                </div>
                <div className="mb-20 p-0 d-flex flex-row border-0 CardBodyPPC">

                  <CardBody className=" border-0 " >
                    <h4 className="card-title mb-3">Pay per click Title</h4>

                    <TextField
                      className="text-field-sd"
                      type="text"
                      size="medium"
                      variant="outlined"
                      placeholder="Please enter title for pay per click campaign"
                      value={title}
                      autoFocus={true}
                      onChange={(event) => setTitle(event.target.value)}
                      error={title.length > 60}
                      helperText={title.length > 60 ? "Too Long!" : " "}
                    />
                  </CardBody>
                  <CardBody className=" border-0 " >
                    <h4 className="card-title mb-3">Pay per click budget</h4>
                    <TextField
                      className="text-field-sd"
                      type="number"
                      size="medium"
                      variant="outlined"
                      placeholder="Please enter pay per click budget"
                      value={budget}
                      autoFocus={true}
                      onChange={(event) => setbudget(event.target.value)}
                      error={parseInt(budget) <= 0 || parseInt(budget) > 999999 || lengthBudget > 7 || decimalPlaces > 2}
                      helperText={(parseInt(budget) <= 0 || parseInt(budget) > 999999) || (lengthBudget > 7 || decimalPlaces > 2) ? 'Minimum budget is 1 and max 9999.99' : " "}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {currency}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </CardBody>
                </div>
                <CardBody>
                  <h4 className="card-title mb-3">Schedule Date</h4>

                  <RangePicker
                    className="RangePickerPPC"
                    allowClear={false}
                    min={new Date()}
                    disabledDate={(current) => current && current <= moment().endOf("day")}
                    onChange={handleDealDateChange}
                  />

                </CardBody>
              </Card>
            </Col>

            <Col xl={12} className="px-5 productPPC">
              <Card className="mb-20 p-3 h-100 border-0">
                <div className="mb-3 ">
                  <span className="badge badge-soft-danger align-middle rounded-pill badge-span m-2">
                    <p className="badge-icon">{2}</p>
                  </span>
                  Choose a product
                </div>

                <div className="search-box w-50 mb-5 RangePickerPPC">
                  <input
                    type="text"
                    className="form-control search "
                    placeholder="Search for products..."
                    onChange={handleKeypress}
                    onKeyUpCapture={handleKeypress}
                  />
                  <i className="ri-search-line search-icon"></i>
                  <button
                    className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                    type="button"
                    id="password-addon"
                    onClick={(e) => handleProductSearch()}
                  >
                    <i className="ri-send-plane-2-line align-middle"></i>
                  </button>
                </div>
                <CardBody className=" border-0">
                  <div className="table-responsive table-card table-scroll">
                    {parseInt(allProductsData.count) !== 0 ? (
                      <TableScrollbar rows={6} height="300px">
                        <table
                          style={{ width: "97%" }}
                          className="table  table-hover table-centered align-middle table-nowrap  "
                        >
                          <thead
                            className={"data-sticky-header-offset-y bg-white"}
                          ></thead>
                          <tbody>
                            <td></td>
                            {products?.map((item, key) => (
                              <tr
                                key={key}
                                onClick={(e) =>
                                  setProductDetails({
                                    id: item.id,
                                    name: item.product_name,
                                    image: item.product_image,
                                    price: item.price,
                                  })
                                }
                              >
                                <td>
                                  <div className="d-flex align-items-center">
                                    <input
                                      className="form-check-input"
                                      id="product_name"
                                      name="product_name"
                                      type="radio"
                                      checked={productDetails.id === item.id}
                                      readOnly
                                    />
                                    <div className="avatar-sm bg-light rounded p-1 me-2">
                                      <img
                                        src={
                                          item.product_image === ""
                                            ? noImage
                                            : item.product_image
                                        }
                                        alt=""
                                        className="img-fluid d-block"
                                      />
                                    </div>
                                    <div>
                                      <h5 className="fs-14 my-1">
                                        {item.Product}{item.product_name}
                                      </h5>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        {allProductsData.next && !flagSearch && (
                          <div className="d-flex justify-content-center">
                            <button
                              className="btn text-white bg-light-red"
                              onClick={() => setPageNumber(pageNumber + 1)}
                            >
                              Load More
                            </button>
                          </div>
                        )}
                      </TableScrollbar>
                    ) : (
                      <div className="text-center m-4">

                        <img width={"150px"} src={noProducts} alt="" />
                        <p>

                          There are no products, please go to products page and
                          add a new product.
                        </p>
                        <div className="col-sm-auto">
                          <Link
                            to="/apps-ecommerce-add-product"
                            className="btn bg-light-pink text-light-red"
                          >
                            <i className="ri-add-line align-bottom me-1"></i> Go
                            to products page
                          </Link>
                        </div>
                      </div>
                    )}
                  </div>
                </CardBody>
              </Card>
              <div
                className="d-flex justify-content-center mt-5"
              >
                <button
                  className="btn bg-light-red text-white button-width-sd ContinuebtnPPC"
                  onClick={() => handleValidatePPC()}
                  disabled={
                    !((title.trim() !== "" && title.length < 60) && (budget > 0 && endDate !== '') && (startDate !== '' && productDetails !== '') && (budget < 999999 && lengthBudget < 7) && decimalPlaces < 3)
                  }
                >
                  Continue
                </button>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Collapse>
  );
};
export default CollapseOne;
