import React, { useEffect, useState } from "react";
import {
    Container,
    Row,

} from "reactstrap";
import { config } from '../../../config'
import { useHistory ,useParams} from "react-router-dom";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { Helmet } from "react-helmet";

//Import actions
import {
    preChangeCard, changeCard, resetEcomFlag
} from "../../../store/ecommerce/action";
//redux
import { useSelector, useDispatch } from "react-redux";

const ChangeCardPopup = () => {
    const dispatch = useDispatch();
    const dispatchPreChangeCard = useDispatch();
    const navigate = useHistory();
    
    const { preChangeCardRes, preChangeCardFlag} = useSelector((state) => ({
        preChangeCardRes: state.Ecommerce.preChangeCard,
        changeCardRes: state.Ecommerce.changeCard,
        preChangeCardFlag :state.Ecommerce.preChangeCardFlag

    }));

    const [baseURL, setBaseUrl] = useState();
    const [scriptURL, setScriptUrl] = useState();
    let { id } = useParams();
    useEffect(() => {
        if(id){
            dispatchPreChangeCard(preChangeCard({ "token": id, "callback_url": config.PUBLIC_URL + `cards/${id}/` }));

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatchPreChangeCard,id]);

    useEffect(() => {
        if(preChangeCardFlag){
        setBaseUrl(preChangeCardRes.baseURL)
        setScriptUrl(preChangeCardRes.scriptURL)
        
    }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [preChangeCardFlag,preChangeCardRes]);
    
    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const checkout_id = queryParams.get('id');
        if (checkout_id) {
            dispatch(changeCard({ "token": checkout_id }));
            navigate.push("/cards/")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);
    useEffect(() => {
        setTimeout(() => {
            dispatch(resetEcomFlag());
        }, 3000);
    }, [
        dispatch, preChangeCardFlag
    ]);
    return (
        <div className="page-content">
            <Container fluid>
                <BreadCrumb title="Change card" pageTitle="Ecommerce" />
                <Row>
                    <div className="col-xl-9 col-lg-8">
                        <Helmet>
                            <script src="https://code.jquery.com/jquery-1.11.3.min.js"></script>
                            <script>
                                {`
                               var wpwlOptions = {
                                style: "plain",
                                onReady: function(){ 
                                    $('.wpwl-label-brand').html('Card Brand');
                                    $('.wpwl-label-cardHolder').html('Card holder name');
                                    $('.wpwl-control-iframe.wpwl-control-cardNumber').attr('placeholder','1234 1234 1234 1234')
                                    $('.wpwl-control-iframe.wpwl-control-cvv').attr('placeholder','3 digits')
                                    $('.wpwl-control-iframe.wpwl-control-cvv').attr('data-maxlength','3');
                                    $('.wpwl-control-cardHolder').attr('placeholder','Name on the card')
                                    $('button[aria-label="Register now"]').html('Save')
                                    $('button[aria-label="Pay now"]').css("display","none");
                                    let val=$('input[name="registrationId"]').val();
                                    var RegistrationHtml = '<div class="customInput"><input type="hidden" name="customParameters[SHOPPER_registrationId]" value="'+val+'" /></div>';
                                    $('button[aria-label="Register now"]').before(RegistrationHtml);
                                    $('.wpwl-wrapper-registration-registrationId').css("display","none");
                                    $('.wpwl-wrapper-brand').css("display","none");
                                    $('.wpwl-label-brand').css("display","none");
                                    $('.wpwl-wrapper-registration-cvv').css("display","none");
                                    $('.wpwl-wrapper-registration-holder').css("display","none");
                                    $('.wpwl-group-registration label').addClass('payment-card');
                                    let number=$('.wpwl-wrapper-registration-number').html().replace('**', '*****');
                                    $('.wpwl-wrapper-registration-number').html(number);
                                    $('.wpwl-wrapper-registration-brand').css("align-self","center")
                                    $('.wpwl-wrapper-registration-expiry').html("Valid until "+ $('.wpwl-wrapper-registration-expiry').html());
                                    $('.wpwl-wrapper-registration-expiry').addClass("text-gray-650");
                                    $('.wpwl-control-cardNumber').after($(".wpwl-brand-card"));
                                    $('.wpwl-group.wpwl-group-cardNumber').before($('.wpwl-group.wpwl-group-cardHolder'))
                                    $('.wpwl-control-cardHolder').attr("required",true)
                                    $('.wpwl-control-cardHolder').on('input', function(e) {
                                    var cardHolderName = $('.wpwl-control-cardHolder').val();
                                    var errorMessage = "Invalid name"; // Set the error message
                                    if (cardHolderName.trim() === '') {
                                        e.preventDefault(); // Prevent the form from submitting
                                        $('.wpwl-control-cardHolder').addClass('is-invalid');
                                        // Create a new element for the error message
                                        var errorElement = $("<div class='invalid-feedback'>" + errorMessage + "</div>");
                                        // Append the error message to the form control
                                        $('.wpwl-control-cardHolder').parent().append(errorElement);
                                    } else {
                                        // If the name is valid, remove the error message and class
                                        $('.wpwl-control-cardHolder').removeClass('is-invalid');
                                        $('.wpwl-control-cardHolder').parent().find('.invalid-feedback').remove();
                                    }
                                    });
                                },
                                registrations: {
                                    hideInitialPaymentForms: false
                                }
                              }
                                
                            `}

                            </script>
                            <script src={scriptURL} async></script>

                        </Helmet>

                        
                        <form
                            action={baseURL}
                            className="mt-4 paymentWidgets"
                            data-brands="MADA VISA MASTER"
                        ></form>
                        

                    </div>
                </Row>
            </Container>
        </div>
    );
};

export default ChangeCardPopup;