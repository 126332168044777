import {
  ADD_NEW_MERCHANT,
  ADD_NEW_MERCHANT_SUCCESSFUL,
  ADD_NEW_MERCHANT_FAILED,
  RESET_REGISTER_FLAG,
} from "./actionTypes";

export const addMerchantSuccessful = (user) => {
  return {
    type: ADD_NEW_MERCHANT_SUCCESSFUL,
    payload: user,
  };
};

export const addMerchantFailed = (user) => {
  return {
    type: ADD_NEW_MERCHANT_FAILED,
    payload: user,
  };
};

export const addMerchant = (merchant) => {
  return {
    type: ADD_NEW_MERCHANT,
    payload: merchant,
  };
};


export const resetRegisterFlag = () => {
  return {
    type: RESET_REGISTER_FLAG,
  };
};


