import React, { useState, useEffect } from "react";
import { Container } from "reactstrap";


import DeleteModal from "../../../Components/Common/DeleteModal";
import WebhookList from "./WebhookList";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { useFormik } from "formik";
import * as Yup from "yup";
import validator from "../../../helpers/Validator";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { config } from "../../../config";
import ConfirmationModal from "../../../Components/Common/ConfirmationModal";
import {
  addWebhook,
  getWebhook,
  deleteWebhook,
  resetEcomFlag,
} from "../../../store/actions";
import WebhookModal from "./WebhookModal";
import WebhookForm from "./WebhookForm";
const { TOAST_AUTOCLOSE_TIMER } = config;

const validationSchema = Yup.object().shape({
  url: Yup.string()
    .required("Required")
    .matches(
      validator.urlValidation,
      "Input a valid url that starts with http or https"
    ),
});

const Webhook = () => {
  const dispatch = useDispatch();

  const [selected, setSelected] = useState([]);
  const [events, setEvents] = useState([]);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [webhookModal, setWebhookModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [webhookId, setwebhookId] = useState(false);

  const { addWebhookFlag, weebhooks, deleteWebhookFlag } = useSelector(
    (state) => ({
      addWebhookFlag: state.Ecommerce.addWebhookFlag,
      weebhooks: state.Ecommerce.weebhooks,
      deleteWebhookFlag: state.Ecommerce.deleteWebhookFlag,
    })
  );

  const formik = useFormik({
    initialValues: {
      url: "",
    },
    validationSchema,
    onSubmit: (values) => {
      setConfirmationModal(true);
    },
  });
  const handleAddWebhook = () => {
    setSelected([]);
    formik.resetForm();
    setConfirmationModal(false);

    const webhook = {
      url: formik.values.url,
      events: events,
    };
    dispatch(addWebhook(webhook));
  };
  useEffect(() => {
    setEvents(   selected
    );
  }, [selected]);

  useEffect(() => {
    dispatch(getWebhook());
  }, [dispatch, addWebhookFlag, deleteWebhookFlag]);

  useEffect(() => {
    if (addWebhookFlag) {
      toast(addWebhookFlag, {
        position: "top-right",
        hideProgressBar: true,
        className: addWebhookFlag.includes("successfully")
        ? "bg-success text-white"
        : "bg-danger text-white",
        progress: undefined,
        toastId: "",
      });
    }

    if (deleteWebhookFlag) {
      toast(deleteWebhookFlag, {
        position: "top-right",
        hideProgressBar: true,
        className: deleteWebhookFlag.includes("failed")
          ? "bg-danger text-white"
          : "bg-success text-white",
        progress: undefined,
        toastId: "",
      });
    }
    dispatch(resetEcomFlag());
  }, [dispatch, addWebhookFlag, deleteWebhookFlag]);

  const handleDeleteWebhook = () => {
    dispatch(deleteWebhook(webhookId));
    setDeleteModal(false);
  };
  document.title = "Webhook | Salis";
  return (
    <div className="page-content ">
      <Container fluid>
        <BreadCrumb title={"Webhook"} pageTitle="Dashboard" />
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDeleteWebhook}
          onCloseClick={() => setDeleteModal(false)}
        />
        <ConfirmationModal
          show={confirmationModal}
          onCloseClick={() => setConfirmationModal(false)}
          message={"You are going to add a new service! "}
          action={handleAddWebhook}
        />
        <WebhookModal
          show={webhookModal}
          onCloseClick={() => setWebhookModal(false)}
          message={
            "The webhook helps you to receive notifications from Salis accrording to the events you are interested in. Only 5 webhooks are allowed to be registered"
          }
        />

        <ToastContainer
          autoClose={TOAST_AUTOCLOSE_TIMER}
          style={{ width: "auto" }}
        />
        <WebhookForm
          setConfirmationModal={setConfirmationModal}
          setWebhookModal={setWebhookModal}
          selected={selected}
          setSelected={setSelected}
          formik={formik}
        />

        <WebhookList
          weebhooks={weebhooks}
          formik={formik}
          setDeleteModal={setDeleteModal}
          setwebhookId={setwebhookId}
        />
      </Container>
    </div>
  );
};

export default Webhook;
