import React, { useEffect, useState } from "react";
import { Container, Row, Button } from "reactstrap";
import { config } from "../../../config";
import DataTable from "react-data-table-component";
import { useHistory } from "react-router-dom";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import AddCardModal from "./addCardPopup";
import AddBankModal from "./addBankPopup";
import BankAccountsTable from "./BankAccounts";
import AddButton from "./AddButton";
import DeleteCardModal from "../../../Components/Common/DeleteModal";
import WarningAlert from "../../../Components/Common/WarningAlert";
import CardIcon from "../../../assets/images/svg/card";
import BankIcon from "../../../assets/images/svg/bank";
import Moment from "moment";
import SuccessAlert from "../../../Components/Common/SuccessAlert ";
import Balance from "../../../Components/Common/Balance";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

//Import actions
import {
  addCard,
  getCards,
  resetEcomFlag,
  preChangeCard,
  deleteCard,
  getBanks,
} from "../../../store/ecommerce/action";

//redux
import { useSelector, useDispatch } from "react-redux";
const EcommerceCards = () => {
  const navigate = useHistory();
  const [deleteCardModal, setDeleteCardModal] = useState(false);
  const [addCardModal, setAddCardModal] = useState(false);
  const [addBankModal, setAddBankModal] = useState(false);
  const [DeleteToken, setDeleteToken] = useState("");
  const [Token, setToken] = useState("");
  const dispatch = useDispatch();
  const dispatchPreChangeCard = useDispatch();

  const {
    cards,
    isCardAdd,
    isCardChange,
    isCardDelete,
    hasErrors,
    isBankAdded,
    banks,
    isBankChanged,
    isBankDelete,
    AddCardError,
  } = useSelector((state) => ({
    cards: state.Ecommerce.get_CardsData,
    addCardStatus: state.Ecommerce.addCard,
    isCardAdd: state.Ecommerce.isCardAdd,
    isCardChange: state.Ecommerce.isCardChange,
    isCardDelete: state.Ecommerce.isCardDelete,
    deleteCardres: state.Ecommerce.deleteCard,
    hasErrors: state.Ecommerce.hasErrors,
    isBankAdded: state.Ecommerce.isBankAdded,
    isBankChanged: state.Ecommerce.isBankChanged,
    isBankDelete: state.Ecommerce.isBankDelete,
    banks: state.Ecommerce.banksData,
    AddCardError: state.Ecommerce.AddCardError,
  }));
  // eslint-disable-next-line no-unused-vars
  const [pageNumber, setPageNumber] = useState(1);
  const handleGetCards = () => {
    const filterData = {};

    filterData["page"] = pageNumber;
  };
  useEffect(() => {
    dispatch(getBanks());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const checkout_id = queryParams.get("id");
    if (checkout_id) {
      dispatch(addCard({ checkout_id: checkout_id }));
      navigate.push("/cards/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    dispatch(getCards());
    handleGetCards();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isCardAdd, isCardChange, isCardDelete]);
  useEffect(() => {
    if (isBankAdded) {
      setAddBankModal(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch,isBankAdded]);
  useEffect(() => {
    setTimeout(() => {
      dispatch(resetEcomFlag());
    }, 100000);
  }, [
    dispatch,
    isCardAdd,
    isCardChange,
    isCardDelete,
    hasErrors,
    isBankAdded,
    isBankChanged,
    AddCardError
  ]);

  const handleToken = (token) => {
    setToken(token);
  };
  useEffect(() => {
    if (Token) {
      dispatchPreChangeCard(
        preChangeCard({
          token: Token,
          callback_url: config.PUBLIC_URL + `cards/${Token}/`,
        })
      );
      navigate.push(`/cards/${Token}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatchPreChangeCard, Token]);
  const handleTokenDelete = (token) => {
    setDeleteToken(token);
    setDeleteCardModal(true);
  };
  const onDeleteClick = () => {
    setDeleteCardModal(false);
    dispatch(deleteCard({ token: DeleteToken }));
  };
  document.title = "Wallet | Salis- Merchants Dashboard";

  const columns = [
    {
      name: <span className="fw-normal text-gray-650 fs-6">Card info.</span>,
      selector: (cell) => (
        <div className="d-flex gap-2 gap-lg-4 flex-wrap">
          <p className="fw-normal p-0 m-0 fs-6">{cell.brand}</p>
          <p className="fw-normal fs-6 d-flex gap-1 align-items-center p-0 m-0">
            <sub className="d-block fs-4"> ***** </sub>
            {cell.last_four_digits}
          </p>
        </div>
      ),
    },
    {
      name: <span className="fw-normal text-gray-650 fs-6">EXP. date</span>,
      selector: (cell) => {
        let expiry_date = Moment(cell.expiry_date).format("MM-YY");

        return (
          <p className="fw-normal p-0 m-0 fs-6 d-flex gap-2 flex-wrap">
            <span>Valid until</span> <span>{expiry_date}</span>
          </p>
        );
      },
    },
    {
      name: <span className="fw-normal fs-6 text-gray-650">Edit</span>,
      selector: (cell) => (
        <button
          type="button"
          className="btn border-none fs-5 fw-normal p-0"
          onClick={() => handleToken(cell.token)}
          data-testid="edit"
        >
          <i className="ri-edit-line"></i>
        </button>
      ),
    },
    {
      name: <span className="fw-normal text-gray-650 fs-6">Delete</span>,
      selector: (cell) => (
        <Button
          className="btn btn-soft-danger"
          onClick={() => handleTokenDelete(cell.token)}
          data-testid="delete"
        >
          <i className="ri-delete-bin-2-line"></i>
        </Button>
      ),
    },
  ];
  const TabTitle = ({ icon, text }) => {
    return (
      <div className="d-flex align-items-center mb-1 gap-4">
        {icon}
        <p className="text-gray-650 fw-normal m-0 fs-6">{text}</p>
      </div>
    );
  };
  return (
    <div className="page-content bg-alice-blue">
      <Container fluid>
        <BreadCrumb title="wallet" pageTitle="Ecommerce" />
        <DeleteCardModal
          show={deleteCardModal}
          onCloseClick={() => setDeleteCardModal(false)}
          onDeleteClick={onDeleteClick}
        />
        <Row>
          {hasErrors === true ? (
            <WarningAlert message="Oops! You have only 1 card" />
          ) : null}
          {isBankAdded && (
            <SuccessAlert
              className="mt-5 w-100 pt-5"
              message={"Bank account added successfully"}
            />
          )}
          {isBankChanged && (
            <SuccessAlert
              className="mt-5 w-100 pt-5"
              message={"Bank account Changed successfully"}
            />
          )}
          {isBankDelete && (
            <SuccessAlert
              className="mt-5 w-100 pt-5"
              message={"Bank account Deleted successfully"}
            />
          )}
          {isCardAdd && (
            <SuccessAlert
              className="mt-5 w-100 pt-5"
              message={"Credit card added successfully"}
            />
          )}
          
          {AddCardError === true ? (
            <WarningAlert message="Failed to add the card" />
          ) : null}
          <div className="col-xl-9 col-lg-8">
            <div className="wallet-cards d-flex flex-column flex-md-row gap-2 gap-md-4">
              <div className="w-md-33">
                <Balance />
              </div>
              <div className="d-flex gap-2 gap-md-4 w-md-66">
                <AddButton
                  onClickFunction={() => setAddCardModal(true)}
                  img={<CardIcon />}
                  text={"Add credit card"}
                />
                <AddButton
                  onClickFunction={() => setAddBankModal(true)}
                  img={<BankIcon />}
                  text={"Add bank account"}
                />
              </div>
            </div>
          </div>
          <AddCardModal
            show={addCardModal}
            onCloseClick={() => setAddCardModal(false)}
          />
          <AddBankModal
            banks={banks}
            show={addBankModal}
            onCloseClick={() => setAddBankModal(false)}
          />
          <Tabs className={"mt-4"}>
            <TabList>
              <Tab>
                <TabTitle
                  icon={<CardIcon width="20" height="18" />}
                  text={"Cards"}
                />
              </Tab>
              <Tab>
                <TabTitle
                  icon={<BankIcon width="20" height="20" />}
                  text={"Bank accounts"}
                />
              </Tab>
            </TabList>

            <TabPanel>
              <div className="common-table-rdt w-100 w-lg-50 mt-4">
                <DataTable
                  columns={columns}
                  data={cards}
                  pagination
                  responsive
                  paginationRowsPerPageOptions={[5, 10, 25, 50]}
                />
              </div>
            </TabPanel>
            <TabPanel>
              <BankAccountsTable banks={banks} />
            </TabPanel>
          </Tabs>
        </Row>
      </Container>
    </div>
  );
};

export default EcommerceCards;
