import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import DashboardCards from "../../Components/Common/DashboardCards";
import { getROI } from "../../store/charts/action";
import { useDispatch, useSelector } from "react-redux";
import marketingSvg from "../../assets/images/dashboardIcons/marketingBudgetSvg";
import ROISvg from "../../assets/images/dashboardIcons/ROISvg";
import salesSvg from "../../assets/images/dashboardIcons/salesSvg";
import { useTranslation } from "react-i18next";
import { config } from "../../config";
import MonthsFilterCard from "./MonthsFilterCard";
import moment from "moment";
import ROILineChart from "./returnOnInvestment/ROILineChart";
const { currency } = config;
const ROITab = () => {
    const { t } = useTranslation();

    const dispatch = useDispatch();
    const [totalSales, settotalSales] = useState(0);
    const [totalSpending, setTotalSpending] = useState(0);
    const [callApi, setCallApi] = useState(false);
    const [totalROI, setTotalROI] = useState(0);
    const { ROICard } = useSelector((state) => ({
        ROICard: state.Charts.ROIDataCard,
    }));
    const [month, setMonth] = useState(moment(new Date()).format("YYYY-MM"));
    useEffect(() => {
        const params = {
            month: month,
        };
        dispatch(getROI(params));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, callApi]);
    useEffect(() => {
        if (ROICard.length !== 0) {
            let totalSales = ROICard.results[0].total_sales;
            let totalSpending = ROICard.results[0].total_marketing_spending;
            let totalROI = ROICard.results[0].total_return_on_investment;
            totalROI = totalROI.toFixed(2);
            settotalSales(totalSales);
            setTotalSpending(totalSpending);
            setTotalROI(totalROI);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ROICard]);

    return (
        <React.Fragment>
            <Row className="bg-alice-blue d-flex flex-column-reverse">
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    <div className="d-md-flex gap-4 justify-content-center justify-content-sm-between py-4  ">

                        <DashboardCards
                            iconComponent={ROISvg("#EE856F")}
                            text={t("ROICard")}
                            amount={totalROI + " " + currency}
                            title={`${t("ROICard")}  / Month`}
                            fullWidth="cards-full-width"
                        />

                        <DashboardCards
                            iconComponent={salesSvg("#367868")}
                            text={t("totalSales")}
                            amount={totalSales + " " + currency}
                            title={`${t("sales")}  / Month`}
                            fullWidth="cards-full-width"
                        />
                        <DashboardCards
                            iconComponent={marketingSvg("#4E5AA8")}
                            text={t("marketingBudget")}
                            amount={totalSpending + " " + currency}
                            title={`${t("budget")} / Month`}
                            fullWidth="cards-full-width"
                        />
                    </div>
                </Col>
                <div className="dashboard_picker d-flex gap-2 justify-content-start justify-content-xxl-end">
                    <p className="my-auto">{t("Return On Investment")} : </p>
                    <MonthsFilterCard
                        setMonth={setMonth}
                        month={month}
                        setCallApi={setCallApi}
                        callApi={callApi}
                    />
                </div>
            </Row>
            <Row className="bg-alice-blue pt-5">
                <Col xl={12}>
                    <ROILineChart />
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default ROITab;
