import {
  GET_FEATURES_FLAGS,
  GET_FEATURES_FLAGS_SUCCESS,
  GET_FEATURES_FLAGS_FAILED,
} from "./actionType";


export const getFeaturesFlags = (data) => ({
  type: GET_FEATURES_FLAGS,
  payload:data
});
export const getFeaturesFlagsSuccess = (data) => ({
  type: GET_FEATURES_FLAGS_SUCCESS,
  payload:data
});
export const getFeaturesFlagsFailed = (data) => ({
  type: GET_FEATURES_FLAGS_FAILED,
  payload:data
});

