import React from "react";
import "antd/dist/antd.css";
import { Card, CardBody, Col, Collapse } from "reactstrap";
import { TextField } from "@material-ui/core";
import { useEffect } from "react";
import { resetEcomFlag } from "../../../store/ecommerce/action";
import { useSelector, useDispatch } from "react-redux";
import OrderTotalAmountImage from "../../../assets/images/OrderTotalAmount.png";

const CollapseThree = ({
  collapseThree,
  handleStepper,
  setTotalAmount,
  totalAmount,
  maxAmount,
  minAmount,
}) => {
  const dispatch = useDispatch();

  const { specialDealError, specialDealErrorLoading } = useSelector(
    (state) => ({
      specialDealError: state.Ecommerce.specialDealError,
      specialDealErrorLoading: state.Ecommerce.specialDealErrorLoading,
    })
  );

  useEffect(() => {
    dispatch(resetEcomFlag());
  }, [dispatch, specialDealError, specialDealErrorLoading]);

  return (
    <Collapse in isOpen={collapseThree} >
      <Card className=" h-100% d-flex justify-content-center flex-column  align-items-center bg-light w-100 p-5 phoneCollapseOne">
        <CardBody className="border-0 h-100% bg-light d-flex justify-content-center flex-column  align-items-center w-100 ">
          <h4 className="card-body mb-3">
            <img
              className="p-2"
              src={OrderTotalAmountImage}
              alt="OrderTotalAmount logo"
            />
            Order total amount</h4>
          <p className="card-body color  text-sm  text-muted mb-0 w-100 text-center phoneCollapseOneP">
            Please enter Order total amount to continue payment process.
          </p>
          <Col className="p-5 w-100 phoneCollapseOneCard">
            <Card className="mb-20 h-100 border-0 w-100" >
              <CardBody className="p-5 phoneCollapseOneCard">
                <h4 className="card-title mb-3">
                  Total amount</h4>
                <TextField
                  id="totalAmount"
                  name="totalAmount"
                  type="text"
                  className="form-control padding_input"
                  placeholder="Please enter total amount"
                  value={totalAmount}
                  onChange={(event) => setTotalAmount(event.target.value)}
                  error={totalAmount > maxAmount || totalAmount < minAmount}
                  helperText={(totalAmount > maxAmount || totalAmount < minAmount)&& totalAmount !=='' ? `Total Amount must be between ${minAmount} and ${maxAmount}` : ""}
                  min={minAmount}
                  max={maxAmount}
                />
              </CardBody>
            </Card>
          </Col>
          <div
            className="d-flex justify-content-center w-50 phoneCollapseOnebutton"
          >
            <button
              className="btn bg-light-red text-white w-50 my-5 phoneCollapseOnebutton"
              onClick={() => handleStepper(3)}
              disabled={
                (totalAmount > maxAmount || totalAmount < minAmount || totalAmount === "")
              }
            >
              Continue
            </button>
          </div>
        </CardBody>
      </Card>


    </Collapse>
  );
};
export default CollapseThree;