import React from "react";
import { DatePicker } from "antd";
import moment from "moment";
import { MonthsFinder } from "../../helpers/charts_helper";
import { ToastContainer } from "react-toastify";
import { config } from "../../config";

const { TOAST_AUTOCLOSE_TIMER } = config;
const YearsFilter = ({
    setAction,
    setxLabel,
    setStartDate,
    setEndDate,
    setCallApi, callApi
}) => {
    function disabledDate(current) {
        // Can not select days before today and today

        return current && current >= moment().endOf("year");

    }
    function getLastDayOfMonth(year, month) {
        return new Date(year, month, 0).getDate();
    }
    const handleOnClick = (e, action) => {
        setAction(action);
        setCallApi(!callApi)

        const startDate = moment(e).startOf('year').format("YYYY-MM-DD");
        const endDate = moment(e).endOf('year').format('YYYY-MM-DD');
        let lastDayInMonth;

        lastDayInMonth = getLastDayOfMonth(Number(e.format("YYYY")), 12);

        setxLabel(
            MonthsFinder(
                new Date(startDate).toLocaleDateString("sv"),
                new Date(endDate).toLocaleDateString("sv")
            )
        );

        setStartDate(e.format(`YYYY-01-01`));
        setEndDate(e.format(`YYYY-12-${lastDayInMonth}`));

    };

    return (
        <div className="d-flex gap-1 dashboard_picker">
            <ToastContainer autoClose={TOAST_AUTOCLOSE_TIMER} />
            <DatePicker
                bordered={false}
                allowClear={false}
                min={new Date()}
                mode={"year"}
                suffixIcon={
                    <button
                        type="button"
                        className="btn text-light-red bg-light-pink btn-sm"
                    >
                        <span className="ant-picker-suffix">
                            <span role="img" aria-label="calendar" className="anticon anticon-calendar">
                                <svg viewBox="64 64 896 896" focusable="false" data-icon="calendar" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                                    <path d="M880 184H712v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H384v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H144c-17.7 0-32 14.3-32 32v664c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V216c0-17.7-14.3-32-32-32zm-40 656H184V460h656v380zM184 392V256h128v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h256v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h128v136H184z"></path>
                                </svg>
                            </span>
                        </span>
                    </button>
                }
                disabledDate={disabledDate}
                onChange={(e) => {
                    handleOnClick(e, "month");
                }}
                size="small"
                picker={"year"}
            />
        </div>
    );
};
export default YearsFilter;