import { Card, CardBody, CardHeader } from "reactstrap";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DateFilter from "../DateFilter";
import moment from "moment";
import { timeFilter, MonthsFinder } from "../../../helpers/charts_helper";
import NoDataFound from "../NoDataFound";
import {
  BarChart,
} from "../../../Components/Common/ChartsJs";
import { getTotalSpent } from "../../../store/charts/action";
import { useTranslation } from "react-i18next";
import { config } from "../../../config";

const { currency } = config;
const TotalBudgetSpecialDeals = () => {
const { t } = useTranslation();

  const now = new Date();
  const firstDay = moment(now).startOf('year').format("YYYY-MM-DD");
  const lastDay = moment(now).endOf('year').format('YYYY-MM-DD');
  const [startDate, setStartDate] = useState(firstDay);
  const [action, setAction] = useState("month");
  const [xLabel, setxLabel] = useState(MonthsFinder(firstDay, lastDay));
  const [endDate, setEndDate] = useState(lastDay);
  const [callApi, setCallApi] = useState(false);
  const dispatch = useDispatch();
  const [sale, setSale] = useState("");
  const { totalSpent } = useSelector((state) => ({
    totalSpent: state.Charts.totalSpent,
  }));

  useEffect(() => {

    const params = {
      start_date: startDate,
      end_date: endDate,
      action: action,
    };

    dispatch(getTotalSpent(params));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, callApi]);

  useEffect(() => {

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, callApi]);


  useEffect(() => {
    if (totalSpent?.length !== 0) {
      let a = [];

      xLabel.forEach((date) => {
        const item = totalSpent?.find((item) => {
          return item.date === date;
        });

        if (item) {
          a.push(item.total);
        } else {
          a.push(0);
        }
      });
      setSale(a);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalSpent, callApi]);

  return (
    <Card className="">

      <CardHeader className="py-3 d-flex justify-content-between">
        <h4 className="card-title mb-0">
        {t("totalBudgetSpecialDeal") + " / " + timeFilter[action]}
        </h4>
        <div className="dashboard_picker">
          <DateFilter
              action={action}
              setAction={setAction}
              setxLabel={setxLabel}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              setCallApi={setCallApi}
              startDate={startDate}
              endDate={endDate}
              callApi={callApi}
          />
        </div>
      </CardHeader>
      <CardBody className="position-relative">
      {totalSpent?.length === 0 && (
            <NoDataFound />
          )}
        <BarChart
          xLabel={timeFilter[action]}
          yLabel={"Amount"}
          Month={xLabel}
          Sales={sale}
          dataFlag={totalSpent?.length !== 0}
          currency={currency}
          label={`Amount Of Budget Spent in month`}
          dataColors='["--vz-primary-rgb, 0.8", "--vz-primary-rgb, 0.9"]'
          colorFrom={"#EE856F"}
          colorTo={"#FFB797"}
        />
      </CardBody>
    </Card>
  );
};
export default TotalBudgetSpecialDeals;