export const GET_TOTAL_SPENT = "GET_TOTAL_SPENT";
export const GET_TOTAL_SPENT_SUCCESS = "GET_TOTAL_SPENT_SUCCESS";
export const GET_TOTAL_SPENT_FAIL = "GET_TOTAL_SPENT_FAIL";

export const RESET_LOADER_FLAG = "RESET_LOADER_FLAG";


export const GET_ACTUAL_CUSTOMERS = "GET_ACTUAL_CUSTOMERS";
export const GET_ACTUAL_CUSTOMERS_SUCCESS = "GET_ACTUAL_CUSTOMERS_SUCCESS";
export const GET_ACTUAL_CUSTOMERS_FAIL = "GET_ACTUAL_CUSTOMERS_FAIL";

  
export const GET_VISITORS = "GET_VISITORS";
export const GET_VISITORS_SUCCESS = "GET_VISITORS_SUCCESS";
export const GET_VISITORS_FAIL = "GET_VISITORS_FAIL";
export const GET_DAILY_VISITORS = "GET_DAILY_VISITORS";
export const GET_DAILY_VISITORS_SUCCESS = "GET_DAILY_VISITORS_SUCCESS";
export const GET_DAILY_VISITORS_FAIL = "GET_DAILY_VISITORS_FAIL";
export const GET_MONTHLY_VISITORS = "GET_MONTHLY_VISITORS";
export const GET_MONTHLY_VISITORS_SUCCESS = "GET_MONTHLY_VISITORS_SUCCESS";
export const GET_MONTHLY_VISITORS_FAIL = "GET_MONTHLY_VISITORS_FAIL";

export const GET_DAILY_CUSTOMERS = "GET_DAILY_CUSTOMERS";
export const GET_DAILY_CUSTOMERS_SUCCESS = "GET_DAILY_CUSTOMERS_SUCCESS";
export const GET_DAILY_CUSTOMERS_FAIL = "GET_DAILY_CUSTOMERS_FAIL";

export const GET_3D_CUSTOMERS = "GET_3D_CUSTOMERS";
export const GET_3D_CUSTOMERS_SUCCESS = "GET_3D_CUSTOMERS_SUCCESS";
export const GET_3D_CUSTOMERS_FAIL = "GET_3D_CUSTOMERS_FAIL";

export const GET_3D_COMMISSIONS = "GET_3D_COMMISSIONS";
export const GET_3D_COMMISSIONS_SUCCESS = "GET_3D_COMMISSIONS_SUCCESS";
export const GET_3D_COMMISSIONS_FAIL = "GET_3D_COMMISSIONS_FAIL";

export const GET_TOTAL_CONVERSION_RATE = "GET_TOTAL_CONVERSION_RATE";
export const GET_TOTAL_CONVERSION_RATE_SUCCESS = "GET_TOTAL_CONVERSION_RATE_SUCCESS";
export const GET_TOTAL_CONVERSION_RATE_FAIL = "GET_TOTAL_CONVERSION_RATE_FAIL";

export const GET_PRODUCTS_VISITORS_DATA = "GET_PRODUCTS_VISITORS_DATA";
export const GET_PRODUCTS_VISITORS_DATA_SUCCESS = "GET_PRODUCTS_VISITORS_DATA_SUCCESS";
export const GET_PRODUCTS_VISITORS_DATA_FAIL = "GET_PRODUCTS_VISITORS_DATA_FAIL";


export const GET_WALLET_STATEMENT_DATA = "GET_WALLET_STATEMENT_DATA";
export const GET_WALLET_STATEMENT_DATA_SUCCESS = "GET_WALLET_STATEMENT_DATA_SUCCESS";
export const GET_WALLET_STATEMENT_DATA_FAIL = "GET_WALLET_STATEMENT_DATA_FAIL";


export const GET_ITEMS_CONVERSION_RATE = "GET_ITEMS_CONVERSION_RATE";
export const GET_ITEMS_CONVERSION_RATE_SUCCESS = "GET_ITEMS_CONVERSION_RATE_SUCCESS";
export const GET_ITEMS_CONVERSION_RATE_FAIL = "GET_ITEMS_CONVERSION_RATE_FAIL";


export const GET_TOTAL_SALES = "GET_TOTAL_SALES";
export const GET_TOTAL_SALES_SUCCESS = "GET_TOTAL_SALES_SUCCESS";
export const GET_TOTAL_SALES_FAIL = "GET_TOTAL_SALES_FAIL";


export const GET_TOP_ITEMS = "GET_TOP_ITEMS";
export const GET_TOP_ITEMS_SUCCESS = "GET_TOP_ITEMS_SUCCESS";
export const GET_TOP_ITEMS_FAIL = "GET_TOP_ITEMS_FAIL";


export const GET_ITEMS_SALES = "GET_ITEMS_SALES";
export const GET_ITEMS_SALES_SUCCESS = "GET_ITEMS_SALES_SUCCESS";
export const GET_ITEMS_SALES_FAIL = "GET_ITEMS_SALES_FAIL";


//PPC
export const GET_PPC_DATA_SPENDING = "GET_PPC_DATA_SPENDING";
export const GET_PPC_DATA_SUCCESS = "GET_PPC_DATA_SUCCESS";
export const GET_PPC_DATA_FAIL = "GET_PPC_DATA_FAIL";
export const GET_PPC_DATA_TABLE = "GET_PPC_DATA_TABLE";
export const GET_PPC_DATA_TABLE_SUCCESS = "GET_PPC_DATA_TABLE_SUCCESS";
export const GET_PPC_DATA_TABLE_FAIL = "GET_PPC_DATA_TABLE_FAIL";


//Total marketing spending
export const GET_TOTAL_MARKETING_SPENDING = "GET_TOTAL_MARKETING_SPENDING";
export const GET_TOTAL_MARKETING_SPENDING_SUCCESS = "GET_TOTAL_MARKETING_SPENDING_SUCCESS";
export const GET_TOTAL_MARKETING_SPENDING_FAIL = "GET_TOTAL_MARKETING_SPENDING_FAIL";

//Total Receivables Chart
export const GET_TOTAL_RECEIVABLES_CHART = "GET_TOTAL_RECEIVABLES_CHART";
export const GET_TOTAL_RECEIVABLES_CHART_SUCCESS = "GET_TOTAL_RECEIVABLES_CHART_SUCCESS";
export const GET_TOTAL_RECEIVABLES_CHART_FAIL = "GET_TOTAL_RECEIVABLES_CHART_FAIL";

//Total Recievables
export const GET_TOTAL_RECIEVABLES = "GET_TOTAL_RECIEVABLES";
export const GET_TOTAL_RECIEVABLES_SUCCESS = "GET_TOTAL_RECIEVABLES_SUCCESS";
export const GET_TOTAL_RECIEVABLES_FAIL = "GET_TOTAL_RECIEVABLES_FAIL";

//ROI
export const GET_ROI = "GET_ROI";
export const GET_ROI_SUCCESS = "GET_ROI_SUCCESS";
export const GET_ROI_FAIL = "GET_ROI_FAIL";
export const GET_ROI_CHART = "GET_ROI_CHART";
export const GET_ROI_CHART_SUCCESS = "GET_ROI_CHART_SUCCESS";
export const GET_ROI_CHART_FAIL = "GET_ROI_CHART_FAIL";

//Marketing Spending Cards
export const GET_MARKETING_SPENDING_CARDS="GET_MARKETING_SPENDING_CARDS";
export const GET_MARKETING_SPENDING_CARDS_SUCCESS="GET_MARKETING_SPENDING_CARDS_SUCCESS";
export const GET_MARKETING_SPENDING_CARDS_FAIL="GET_MARKETING_SPENDING_CARDS_FAIL";
//Receivables Due
export const GET_RECEIVABLES_DUE = "GET_RECEIVABLES_DUE";
export const GET_RECEIVABLES_DUE_SUCCESS = "GET_RECEIVABLES_DUE_SUCCESS";
export const GET_RECEIVABLES_DUE_FAIL = "GET_RECEIVABLES_DUE_FAIL";
