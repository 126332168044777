import React, { useMemo } from "react";
import DataTable from "react-data-table-component";
import Loader from "../../Ecommerce/Loader";
import { timeFilter } from "../../../helpers/charts_helper";
const ItemsConversionRate = ({ dataProducts, action }) => {

  const columns = useMemo(
    () => [
      {
        name: <span className="fw-normal text-gray-650">Item</span>,
        selector: (row) => (
          <>

            <p className="m-0 p-0 fw-bold">{row.product.product_name}</p>
            <p className="m-0 p-0 text-gray-650">#{row.product.reference_id}</p>
          </>
        ),
      },
      {
        name: (
          <span className="fw-normal text-gray-650">Number of visitors</span>
        ),
        selector: (row) => row.visitors,
        sortable: false,
      },
      {
        name: (
          <span className="fw-normal text-gray-650">Number of buyers</span>
        ),
        selector: (row) => row.customers,
        sortable: false,
      },
      {
        name: (
          <span className="fw-normal text-gray-650">Conversion rate</span>
        ),
        selector: (row) => <p className="m-0 p-0 fw-bold">{row.conversion_rate}</p>
        ,
        sortable: false,
      },
      // {
      //   name: (
      //     <span className="fw-normal text-gray-650">Date</span>
      //   ),
      //   selector: (row) => <p className="m-0 p-0 fw-normal">{row.date}</p>
      //   ,
      //   sortable: false,
      // },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const columnNames = columns.map((col) => col.name.props.children);

  return (
    <div className="py-4 mt-2 ">
      <div className="bg-white rounded d-flex justify-content-between align-items-center py-3 p-2">
        <h4 className="card-title mb-0">Conversion rate per item / {timeFilter[action]}</h4>

      </div>

      <div className="product-visitors-table-rdt">
        {dataProducts && dataProducts.length > 0 ? (
          <DataTable
            columns={columns}
            data={dataProducts}
            pagination
            responsive
            paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
            paginationPerPage={5}
            paginationComponentOptions={{
              rowsPerPageText: "",
              rangeSeparatorText: "of",
            }}
          />
        ) : (
          <Loader columnNames={columnNames} />
        )}
      </div>
    </div>
  );
};
export default ItemsConversionRate;
