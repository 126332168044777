import { TextField } from "@material-ui/core";
import TableScrollbar from "react-table-scrollbar";
import "antd/dist/antd.css";
import { Card, CardBody, Col, Row, Collapse } from "reactstrap";
import { Link } from "react-router-dom";
import noImage from "../../../assets/images/no_image.jpg";
import noProducts from "../../../assets/images/no_products_icon.svg";
import { config } from "../../../config";
const { currency } = config;
const CollapseOne = ({
  collapseOne,
  flagSearch,
  setProductName,
  products,
  setProductDetails,
  handleStepper,
  title,
  productDetails,
  setTitle,
  pageNumber,
  setPageNumber,
  allProductsData,
  handleProductSearch,
}) => {
  const handleKeypress = (e) => {
    setProductName(e.target.value)
    handleProductSearch()

  }
 
  return (
    <Collapse in isOpen={collapseOne}>
      <Card className=" h-100%">
        <CardBody className="border-0 h-100%">
          <h4 className="card-body mb-0">Product Settings</h4>

          <p className="card-body color  text-sm  text-muted mb-0">
            Please select a product for special deal, set its budget, and put
            title for this special deal.
          </p>
          <Row>
            <Col xl={6}>
              <Card className="mb-20 p-0 h-100 border-0 ">
                <div className="mb-3 mt-2">
                  <span className="badge badge-soft-danger align-middle badge-span rounded-pill mx-2">
                    <p className="badge-icon">{1}</p>
                  </span>
                  Set special deal title
                </div>
                <CardBody className=" border-0">
                  <h4 className="card-title mb-3">Special Deal Title</h4>

                  <TextField
                    className="text-field-sd"
                    type="text"
                    size="medium"
                    variant="outlined"
                    placeholder="Please enter title for your special deal"
                    value={title}
                    autoFocus={true}
                    onChange={(event) => setTitle(event.target.value)}
                    error={title.length > 60}
                    helperText={title.length > 60 ? "Too Long!" : " "}
                  />
                </CardBody>
              </Card>
            </Col>
            <Col xl={6}>
              <Card className="mb-20 p-0 h-100 border-0">
                <div className="mb-3">
                  <span className="badge badge-soft-danger align-middle rounded-pill badge-span m-2">
                    <p className="badge-icon">{2}</p>
                  </span>
                  Select product For the special deal
                </div>

                <div className="search-box">
                  <input
                    type="text"
                    className="form-control search /"
                    placeholder="Search for products..."
                    onChange={handleKeypress}
                    onKeyUpCapture={handleKeypress}

                  />
                  <i className="ri-search-line search-icon"></i>
                  <button
                    className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                    type="button"
                    id="password-addon"
                    onClick={(e) => handleProductSearch()}
                  >
                    <i className="ri-send-plane-2-line align-middle"></i>
                  </button>
                </div>
                <CardBody className=" border-0">
                  <div className="table-responsive table-card table-scroll">
                    {parseInt(allProductsData.count) !== 0 ? (
                      <TableScrollbar rows={10} height="300px">
                        <table
                          style={{ width: "97%" }}
                          className="table  table-hover table-centered align-middle table-nowrap  "
                        >
                          <thead
                            className={"data-sticky-header-offset-y bg-white"}
                          ></thead>
                          <tbody>
                            {products?.map((item, key) => (
                              <tr
                                key={key}
                                onClick={(e) =>
                                  setProductDetails({
                                    id: item.id,
                                    name: item.product_name,
                                    image: item.product_image,
                                    price: item.price,
                                  })
                                }
                              >
                                <td>
                                  <input
                                    className="form-check-input"
                                    id="product_name"
                                    name="product_name"
                                    type="radio"
                                    checked={productDetails.id === item.id}
                                    readOnly
                                  />
                                </td>
                                <td>
                                  <div className="d-flex align-items-center">
                                    <div className="avatar-sm bg-light rounded p-1 me-2">
                                      <img
                                        src={
                                          item.product_image === ""
                                            ? noImage
                                            : item.product_image
                                        }
                                        alt=""
                                        className="img-fluid d-block"
                                      />
                                    </div>
                                    <div>
                                      <h5 className="fs-14 my-1">
                                        <Link
                                          to="/product-details"
                                          className="text-reset"
                                        >
                                          {item.Product}
                                        </Link>
                                      </h5>
                                      {/*<span className="text-muted">{item.date}</span>*/}
                                    </div>
                                  </div>
                                </td>
                                <td>{item.product_name}</td>
                                <td>
                                  {item.price} {currency}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        {allProductsData.next && !flagSearch && (
                          <div className="d-flex justify-content-center">
                            <button
                              className="btn text-white bg-light-red"
                              onClick={() => setPageNumber(pageNumber + 1)}
                            >
                              Load More
                            </button>
                          </div>
                        )}
                      </TableScrollbar>
                    ) : (
                      <div className="text-center m-4">

                        <img width={"150px"} src={noProducts} alt="" />
                        <p>

                          There are no products, please go to products page and
                          add a new product.
                        </p>
                        <div className="col-sm-auto">
                          <Link
                            to="/products-add"
                            className="btn bg-light-pink text-light-red"

                          >
                            <i className="ri-add-line align-bottom me-1"></i> Go
                            to products page
                          </Link>
                        </div>
                      </div>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
            <div
              className="d-flex justify-content-center mt-5"
            >
              <button
                className="btn bg-light-red text-white button-width-sd ContinuebtnPPC"
                onClick={() => handleStepper(1)}
                disabled={
                  !(title !== "" && title.length < 30 && productDetails !== "")
                }
              >
                Continue
              </button>
            </div>
          </Row>
        </CardBody>
      </Card>
    </Collapse>
  );
};
export default CollapseOne;
