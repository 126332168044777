import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

const Navdata = () => {
  const history = useHistory();
  //state data
  const [isDashboard, setIsDashboard] = useState(false);
  const [isMarketing, setIsMarketing] = useState(true);
  const [isAuth, setIsAuth] = useState(false);
  const [isPages, setIsPages] = useState(false);
  const [isBaseUi, setIsBaseUi] = useState(false);
  const [isAdvanceUi, setIsAdvanceUi] = useState(false);
  const [isForms, setIsForms] = useState(false);
  const [isTables, setIsTables] = useState(false);
  const [isCharts, setIsCharts] = useState(false);
  const [isIcons, setIsIcons] = useState(false);
  const [isMaps, setIsMaps] = useState(false);
  const [isMultiLevel, setIsMultiLevel] = useState(false);



  // Pages

  // eslint-disable-next-line no-unused-vars
  const [isLanding, setIsLanding] = useState(false);



  const [iscurrentState, setIscurrentState] = useState("Dashboard");

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("subitems");
        if (document.getElementById(id))
          document.getElementById(id).classList.remove("show");
      });
    }
  }

  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");
    if (iscurrentState !== "Dashboard") {
      setIsDashboard(false);
    }
    if (iscurrentState !== "Marketing") {
      setIsMarketing(true);
    }
    if (iscurrentState !== "Auth") {
      setIsAuth(false);
    }
    if (iscurrentState !== "Pages") {
      setIsPages(false);
    }
    if (iscurrentState !== "BaseUi") {
      setIsBaseUi(false);
    }
    if (iscurrentState !== "AdvanceUi") {
      setIsAdvanceUi(false);
    }
    if (iscurrentState !== "Forms") {
      setIsForms(false);
    }
    if (iscurrentState !== "Tables") {
      setIsTables(false);
    }
    if (iscurrentState !== "Charts") {
      setIsCharts(false);
    }
    if (iscurrentState !== "Icons") {
      setIsIcons(false);
    }
    if (iscurrentState !== "Maps") {
      setIsMaps(false);
    }
    if (iscurrentState !== "MuliLevel") {
      setIsMultiLevel(false);
    }
    if (iscurrentState === "Widgets") {
      history.push("/widgets");
      document.body.classList.add("twocolumn-panel");
    }
    if (iscurrentState !== "Landing") {
      setIsLanding(false);
    }
  }, [
    history,
    iscurrentState,
    isDashboard,
    isMarketing,
    isAuth,
    isPages,
    isBaseUi,
    isAdvanceUi,
    isForms,
    isTables,
    isCharts,
    isIcons,
    isMaps,
    isMultiLevel,
  ]);

  const menuItems = [
    {
      label: "Menu",
      isHeader: true,
    },
    {
      id: "dashboard",
      label: "Dashboard",
      icon: "ri-dashboard-line",
      link: "/#",
      stateVariables: isDashboard,
    },
    {
      id: "marketing",
      label: "Marketing",
      icon: "ri-mail-volume-line",
      link: "/#",
      click: function (e) {
        e.preventDefault();
        setIsMarketing(!isMarketing);
        setIscurrentState("Marketing");
        updateIconSidebar(e);
      },
      stateVariables: isMarketing,
      subItems: [
        {
          id: 1,
          label: "Products",
          link: "/products",
          parentId: "apps",
        },

        {
          id: 2,
          label: "Special Deals",
          link: "/special-deals",
          parentId: "apps",
        },
        {
          id: 3,
          label: "Pay Per Click",
          link: "/pay-per-click",
          parentId: "apps",
        },
       

      ],
    },
    {
      id: "Orders",
      label: "Orders",
      link: "/orders",
      icon: "ri-shopping-bag-line",
    },
    {
      id: "settlements",
      label: "Settlements",
      link: "/settlements",
      icon: "ri-pantone-line",
    },
    {
      id: "transactions",
      label: "Transactions",
      link: "/transactions",
      icon: "ri-arrow-up-down-line",
    },
    {
      id: "wallet",
      label: "Wallet",
      icon: "ri-wallet-2-line",
      link: "/cards",
    },
    {
      id: "webhook",
      label: "Webhooks",
      icon: "ri-links-line",
      link: "/webhook",
    },
    {
      id: "logout",
      label: "Logout",
      icon: "ri-logout-box-r-line",
      link: "/logout",
    },
    

  ];
  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
