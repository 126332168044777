import React, { useMemo ,useState,useEffect} from "react";
import DataTable from "react-data-table-component";
import Loader from "../../Ecommerce/Loader";
import { DatePicker } from "antd";
import moment from "moment";
import { useDispatch } from "react-redux";
import { getTotalReceivablesChart } from "../../../store/charts/action";
import { TableToPDF } from "../../../helpers/tableToPdf";
import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown,
} from "reactstrap";
import { useTranslation } from "react-i18next";

const ReceivablePaymentsTable = ({ totalReceivables, setStartDay, setEndDay }) => {
    const { t } = useTranslation();
    const { RangePicker } = DatePicker;
    const now = new Date();
    const dispatch = useDispatch();
    const [selectedDateRange, setSelectedDateRange] = useState(null);
    const firstDay = moment(now).startOf('month').format("YYYY-MM-DD");
    const lastDay = moment(now).endOf('month').format('YYYY-MM-DD');
    const [startDate, setStartDate] = useState(firstDay);
    const [endDate, setEndDate] = useState(lastDay);
    const [action, setAction] = useState("day");
    const columns = useMemo(
        () => [
            {
                name: <span className="fw-normal text-gray-650">{t("invoiceDetails")}</span>,
                selector: (row) => row.order_id,
            },
            {
                name: <span className="fw-normal text-gray-650">{t("clientAppId")}</span>,
                selector: (row) => row.client_id,
            },
            {
                name: <span className="fw-normal text-gray-650">{t("settlementAmount")}</span>,
                selector: (row) => row.settlement_amount,

            },
            {
                name: (
                    <span className="fw-normal text-gray-650">{t("startDate")}</span>
                ),
                selector: (row) => row.start_date,
            },
            {
                name: (
                    <span className="fw-normal text-gray-650">{t("dueDate")}</span>
                ),

                selector: (row) => row.due_date.substring(0, 10),
            },
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );
    const handleDayPickerChange = (dates) => {
        const [start, end] = dates;
        const formattedStart = start.format("YYYY-MM-DD");
        const formattedEnd = end.format("YYYY-MM-DD");
        
        setStartDate(formattedStart);
        setEndDate(formattedEnd);
        setSelectedDateRange([moment(formattedStart), moment(formattedEnd)]);
      };
      
      useEffect(() => {
        const params = {
            start_date: startDate,
            end_date: endDate,
            action: action,
        };

        dispatch(getTotalReceivablesChart(params));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, startDate]);  
    const printTableToPDF = () => {
        TableToPDF(totalReceivables, "Total Receivables");
    };
    const columnNames = columns.map((col) => col.name.props.children);

    return (
        <div className="py-4 mt-2">
            <div className="bg-white rounded d-flex justify-content-between align-items-center p-2">
                <h4 className="card-title mb-0">{t("receivablesDuePayments")}</h4>
                <div className="dashboard_picker">
                    <button className="btn text-light-red bg-light-pink btn-sm cursor-pointer p-0">
                        <RangePicker
                            bordered={false}
                            allowClear={false}
                            min={new Date()}
                            onChange={handleDayPickerChange}
                            size="small"
                            value={selectedDateRange} 
                        />
                    </button>
                    <UncontrolledDropdown className="dropdown d-inline-block">
                        <DropdownToggle className="btn border-0 btn-sm" tag="button">
                            <i className="ri-more-2-fill align-middle"></i>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end">
                            <DropdownItem href="#!" onClick={printTableToPDF}>
                                <i className="ri-printer-line align-bottom me-2 text-muted"></i>
                                Print as PDF
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </div>
            </div>

            <div className="product-visitors-table-rdt">
                {totalReceivables && totalReceivables.length > 0 ? (
                    <DataTable
                        columns={columns}
                        data={totalReceivables}
                        setAction={setAction}
                        pagination
                        responsive
                        paginationRowsPerPageOptions={[6, 10, 15, 20, 25, 30]}
                        paginationPerPage={6}
                        paginationComponentOptions={{
                            rowsPerPageText: "",
                            rangeSeparatorText: "of",
                        }}
                    />
                ) : (
                    <Loader columnNames={columnNames} />
                )}
            </div>
        </div>
    );
};
export default ReceivablePaymentsTable;
