import PropTypes from "prop-types";
import React from "react";
import { Modal, ModalBody } from "reactstrap";


const WebhookModal = ({ message, show, onCloseClick }) => {
  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalBody className="py-3 px-5">
        <div className="mt-2 text-center">
          <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
            <p className="text-muted mx-4 mb-0">{message}</p>
          </div>
        </div>
        <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
          <button
            type="button"
            className="btn w-sm bg-light-red text-white "
            data-bs-dismiss="modal"
            onClick={onCloseClick}
          >
            Close
          </button>
      
        </div>
      </ModalBody>
    </Modal>
  );
};

WebhookModal.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any,
  message: PropTypes.any,
};

export default WebhookModal;
