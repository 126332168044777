import React, { useMemo ,useState,useEffect} from "react";
import DataTable from "react-data-table-component";
import Loader from "../Ecommerce/Loader";
import { DatePicker } from "antd";
import { TableToPDF } from "../../helpers/tableToPdf";
import moment from "moment";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { useDispatch } from "react-redux";

import { getWalletStatementData } from "../../store/charts/action";

const StatementTable = ({ WalletStatement, setStartDay, setEndDay }) => {
  const dispatch = useDispatch();
  const { RangePicker } = DatePicker;
  const now = new Date();
  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const firstDay = moment(now).startOf('month').format("YYYY-MM-DD");
  const lastDay = moment(now).endOf('month').format('YYYY-MM-DD');
  const [startDate, setStartDate] = useState(firstDay);
  const [endDate, setEndDate] = useState(lastDay);
  const columns = useMemo(
    () => [
      {
        name: <span className="fw-normal text-gray-650">Date</span>,
        selector: (row) => row.created_at,
        sortable: true,
      },
      {
        name: <span className="fw-normal text-gray-650">Amount</span>,
        selector: (row) => row.amount,
        sortable: true,
        sortFunction: (rowA, rowB) => {
          const a = Number(rowA.amount);
          const b = Number(rowB.amount);

          if (a > b) {
            return 1;
          }

          if (b > a) {
            return -1;
          }

          return 0;
        },
      },
      {
        name: <span className="fw-normal text-gray-650">Reference Number</span>,
        selector: (row) =>
          row.transaction_reference_number === "NOT_PROVIDED"
            ? ""
            : row.transaction_reference_number,
      },
      {
        name: <span className="fw-normal text-gray-650">Narrative</span>,
        selector: (row) => row.narrative,
      },
      {
        name: (
          <span className="fw-normal text-gray-650">Purpose Of Transfer</span>
        ),
        selector: (row) => row.purpose_of_transfer,
        sortable: true,
      },
      {
        name: <span className="fw-normal text-gray-650">Status</span>,
        selector: (row) => row.status,
      },

      {
        name: (
          <span className="fw-normal text-gray-650">Reason Of Failure</span>
        ),
        selector: (row) =>
          row.reason_of_failure === "NOT_PROVIDED" ? "" : row.reason_of_failure,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  function disabledDay(current) {
    // Can not select days before today and today
    return current && current >= moment().endOf("day");
  }
  const handleDayPickerChange = (dates) => {
    const [start, end] = dates;
    const formattedStart = start.format("YYYY-MM-DD");
    const formattedEnd = end.format("YYYY-MM-DD");
    
    setStartDate(formattedStart);
    setEndDate(formattedEnd);
    setSelectedDateRange([moment(formattedStart), moment(formattedEnd)]);
  };
  const printTableToPDF = () => {
    TableToPDF(WalletStatement, "Wallet Statement");
  };
  useEffect(() => {
    const params = {
        start_date: startDate,
        end_date: endDate,
        action: "day",
    };

    dispatch(getWalletStatementData(params));
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [dispatch, startDate]);  
  const columnNames = columns.map((col) => col.name.props.children);
  return (
    <div className="py-4 mt-2">
      <div className="bg-white rounded d-flex justify-content-between align-items-center p-2">
        <h4 className="card-title mb-0">Transfers by salis</h4>
        <div className="dashboard_picker">
          <button className="btn text-light-red bg-light-pink btn-sm cursor-pointer p-0">
            <RangePicker
              bordered={false}
              allowClear={false}
              min={new Date()}
              disabledDate={disabledDay}
              onChange={handleDayPickerChange}
              size="small"
              value={selectedDateRange}
            />
          </button>
          <UncontrolledDropdown className="dropdown d-inline-block">
            <DropdownToggle className="btn border-0 btn-sm" tag="button">
              <i className="ri-more-2-fill align-middle"></i>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              <DropdownItem href="#!" onClick={printTableToPDF}>
                <i className="ri-printer-line align-bottom me-2 text-muted"></i>
                Print as PDF
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </div>

      <div className="product-visitors-table-rdt">
        {WalletStatement && WalletStatement.length > 0 ? (
          <DataTable
            columns={columns}
            data={WalletStatement}
            pagination
            responsive
            paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
            paginationPerPage={5}
            paginationComponentOptions={{
              rowsPerPageText: "",
              rangeSeparatorText: "of",
            }}
          />
        ) : (
          <Loader columnNames={columnNames}/>
        )}
      </div>
    </div>
  );
};
export default StatementTable;
