import PropTypes from "prop-types";
import React, { useState, useEffect, useCallback } from "react";
import { Modal, ModalBody } from "reactstrap";
import { useFormik } from "formik";
import { Input, Label } from "reactstrap";
import validator from "../../../helpers/Validator";
import { isValidIBANNumber } from "../../../helpers/iban_validation";
import { config } from "../../../config";

import * as Yup from "yup";
import { addBankAccount, resetEcomFlag } from "../../../store/ecommerce/action";
// redux
import { useSelector, useDispatch } from "react-redux";
const { TOAST_AUTOCLOSE_TIMER } = config;
const validationSchema = Yup.object().shape({
    iban: Yup.string()
      .required("Required")
      .test("Required", "Field should have a valid IBAN", (value) =>
        isValidIBANNumber(value)
      ),
    holder_name: Yup.string()
      .required("Required")
      .matches(validator.textOnly, "Field should have a valid holder name"),
    bank: Yup.string().required("Required"),
  });
const AddBankPopup = ({ banks, show, onCloseClick }) => {
  const { isBankAddFailed, isBankAdded } = useSelector((state) => ({
    isBankAddFailed: state.Ecommerce.isBankAddFailed,
    isBankAdded: state.Ecommerce.isBankAdded,
  }));
  
  const formik = useFormik({
    initialValues: {
      iban: "",
      holder_name: "",
      bank: "",
    },
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      let formData = new FormData();

      formData.append("iban", values.iban);
      formData.append("holder_name", values.holder_name);
      formData.append("bank", values.bank);

      dispatch(addBankAccount(formData));
    },
  });
  const handleNotValidForm = (e) => {
    e.preventDefault();
    setInValidCreate(true);
    formik.touched.iban = true;
    formik.touched.holder_name = true;
    formik.touched.bank = true;
  };
  const [inValidCreate, setInValidCreate] = useState(false);
  const [validCreate, setValidCreate] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      setInValidCreate(false);
      setValidCreate(false);
    }, 3000);
  }, [validCreate, inValidCreate]);

  useEffect(() => {
    setTimeout(() => {
      dispatch(resetEcomFlag());
    }, TOAST_AUTOCLOSE_TIMER);
  }, [dispatch, isBankAddFailed]);
  
  const resetFormik = useCallback(() => {
    
    formik.resetForm({ values: "" });
    /* eslint-disable */
  }, []);
  useEffect(() => {
    
    if (isBankAdded) {
      resetFormik();
    }
  }, [resetFormik,isBankAdded]);
  return (
    <>
      <Modal isOpen={show} toggle={onCloseClick} centered={true}>
        <ModalBody className="p-3 rounded">
          <div className="d-flex gap-2 justify-content-end">
            <button
              type="button"
              className="btn w-fit border rounded btn-light"
              data-bs-dismiss="modal"
              onClick={onCloseClick}
              data-testid="close"
            >
              <i className="ri-close-line align-bottom"></i>
            </button>
          </div>
          <div className="text-center pt-2 fs-15 ">
            <h5>Add new bank account</h5>
            <p className="text-muted mx-4 mb-0 fs-6">
              Please add bank account information
            </p>
          </div>
          <form
            method="post"
            onSubmit={(e) =>
              formik.isValid ? formik.handleSubmit(e) : handleNotValidForm(e)
            }
          >
            <div className="mb-3">
              <Label>IBAN</Label>

              <Input
                id="iban"
                type="text"
                name="iban"
                placeholder="Enter your iban"
                value={formik.values.iban || ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={
                  (formik.errors.iban && formik.touched.iban) || isBankAddFailed
                    ? "form-control is-invalid"
                    : "form-control"
                }
              />
              {formik.errors.iban && formik.touched.iban ? (
                <div className="text-danger">{formik.errors.iban}</div>
              ) : isBankAddFailed ? (
                <div className="text-danger">{isBankAddFailed}</div>
              ) : null}
            </div>
            <div className="mb-3">
              <Label>Holder name</Label>

              <Input
                id="holder_name"
                type="text"
                placeholder="Enter your name"
                name="holder_name"
                value={formik.values.holder_name || ""}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={
                  formik.errors.holder_name && formik.touched.holder_name
                    ? "form-control is-invalid"
                    : "form-control"
                }
                
              />
              {formik.errors.holder_name && formik.touched.holder_name ? (
                <div className="text-danger">{formik.errors.holder_name}</div>
              ) : null}
            </div>
            <div className="mb-3">
              <Label>Bank</Label>
              <Input
                type="select"
                name="bank"
                id="bank"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={
                  formik.errors.bank && formik.touched.bank
                    ? "form-control is-invalid"
                    : "form-control"
                }
                defaultValue="selected"
                data-testid="select-bank"
              >
                <option value="selected" disabled>
                  Select your bank
                </option>
                {Object.keys(banks).length !== 0 &&
                  banks.map(function (item, index) {
                    return (
                      <option key={index} value={item.id}>
                        {item.en_name}
                      </option>
                    );
                  })}
              </Input>
              {formik.errors.bank && formik.touched.bank ? (
                <div className="text-danger">{formik.errors.bank}</div>
              ) : null}
            </div>
            <div className="text-center mb-3">
              <button
                type="submit"
                className="btn btn bg-light-red text-white w-sm"
              >
                Submit
              </button>
            </div>
          </form>
        </ModalBody>
      </Modal>
    </>
  );
};

AddBankPopup.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any,
};

export default AddBankPopup;
