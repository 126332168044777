import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import DashboardCards from "../../Components/Common/DashboardCards";
import { config } from "../../config";
import profitIcon from "../../assets/images/dashboardIcons/profit.svg";
import ppcIcon from "../../assets/images/dashboardIcons/ppc.svg";
import budget2Icon from "../../assets/images/dashboardIcons/budget2.svg";
import TotalBudgetSpecialDeals from "./totalBudget/TotalBudgetSpecialDeals";
import TotalSpendingBudget from "./totalBudget/TotalSpendingBudget";
// import Comission3DChart from "./totalBudget/Comission3DChart";
import PayPerClick3DChart from "./totalBudget/PayPerClick3DChart";
import { useTranslation } from "react-i18next";
import PayPerClickTable from "./totalBudget/PayPerClickTable";
import moment from "moment";
import { getMarketingSpendingCards } from "../../store/charts/action";
import { useDispatch, useSelector } from "react-redux";
// import { getPPCDataChart } from "../../store/charts/action";
// import { useDispatch, useSelector } from "react-redux";
const totalMonth = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
const monthShortNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
  "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
];
const year = new Date().getFullYear();
const { currency } = config;
const TotalBudget = ({ totalSpents, tspent }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [totalBudget, settotalBudget] = useState(0);
  const [PPCCard, setPPCCard] = useState(0);
  const [specialDealCard, setSpecialDealCard] = useState(0);
  const { marketingCards } = useSelector((state) => ({
    marketingCards: state.Charts.marketingSpendingCards,
  }));
  const month = moment(new Date()).format("YYYY-MM");
  useEffect(() => {
    const params = {
      month: month,
    };
    dispatch(getMarketingSpendingCards(params));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
  useEffect(() => {
    if (marketingCards.length !== 0) {
      let totalBudget = marketingCards.results[0].total_spending;
      let PPCCard = marketingCards.results[0].pay_per_click_spending;
      let specialDealCard = marketingCards.results[0].special_deals_spending;
      settotalBudget(totalBudget);
      setPPCCard(PPCCard);
      setSpecialDealCard(specialDealCard);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marketingCards]);

  function disabledDate(current) {
    return current && current >= moment().endOf("year");
  }
  return (
    <React.Fragment>
      <Row className="bg-alice-blue">
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <div className="d-md-flex gap-4 justify-content-center justify-content-sm-between py-4  ">
            <DashboardCards
              icon={profitIcon}
              text={t("totalBudget")}
              amount={totalBudget + " " + currency}
              link={"/"}
              title={t("amountMonth")}
              fullWidth="cards-full-width"
            />
            <DashboardCards
              icon={ppcIcon}
              text={"PPC"}
              amount={PPCCard + " " + currency}
              link={"/"}
              title={t("amountMonth")}
              fullWidth="cards-full-width"
            />
            <DashboardCards
              icon={budget2Icon}
              text={t("specialDeal")}
              amount={specialDealCard + " " + currency}
              link={"/"}
              title={t("amountMonth")}
              fullWidth="cards-full-width"
            />
          </div>
        </Col>
      </Row>
      <Row className="bg-alice-blue pt-5" >
        <Col sm={12} xxl={5} >
          <PayPerClick3DChart
            disabledDate={disabledDate}
          />
          <TotalSpendingBudget
            totalMonth={totalMonth}
            totalSpents={totalSpents}
            tspent={tspent}
            currency={currency}
            year={year}
          />
        </Col>
        <Col sm={12} xxl={7}>
          <PayPerClickTable />
        </Col>
      </Row>
      <Row className="bg-alice-blue">
        <Col sm={12} xxl={5}>
          {/* <Comission3DChart
            totalMonth={totalMonth}
            totalSpents={totalSpents}
            tspent={tspent}
            currency={currency}
            year={year}
          /> */}
          <TotalBudgetSpecialDeals
            totalMonth={monthShortNames}
            totalSpents={totalSpents}
            tspent={tspent}
            currency={currency}
            year={year}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default TotalBudget;
