import React, { useEffect } from "react";
import { Col, Row } from "reactstrap";
import DashboardCards from "../../Components/Common/DashboardCards";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { getTopItems } from "../../store/charts/action";

import { useTranslation } from "react-i18next";
import commissionIcon from "../../assets/images/dashboardIcons/commission.svg";
import profitSvg from "../../assets/images/dashboardIcons/profitSvg";
import salesSvg from "../../assets/images/dashboardIcons/salesSvg";
import TotalSales from "./sales/TotalSalesChart";
import TopTenItems from "./sales/TopTenItems";
import ItemsSales from "./sales/ItemsSales";
import Comission3DChart from "./sales/Comission3DChart";

import { config } from "../../config";
const { currency } = config;
const Sales = () => {
  const { t } = useTranslation();
  // const [salisCommissionCard, setSalisCommissionCard] = useState(0);

  const dispatch = useDispatch()
  const { totalSales, topTenItems } = useSelector((state) => ({
    totalSales: state.Charts.totalSales,
    topTenItems: state.Charts.topTenItems,

  }));
  const { marketingCards } = useSelector((state) => ({
    marketingCards: state.Charts.marketingSpendingCards,
  }));
  useEffect(() => {
    if (marketingCards.length !== 0) {
      // let salisCommissionCard = marketingCards.results[0].commission_spending;
      // setSalisCommissionCard(salisCommissionCard);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marketingCards]);
  useEffect(() => {

    dispatch(getTopItems());


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  function disabledDate(current) {
    // Can not select days before today and today
    return current && current >= moment().endOf("year");
  }

  return (
    <React.Fragment>
      <Row className="bg-alice-blue">
        <Col xl={12}>
          <div className="d-md-flex gap-4 justify-content-center justify-content-sm-between py-4  ">
            <DashboardCards
              iconComponent={salesSvg("#EE856F")}
              text={t("totalRevenue")}
              amount={totalSales.total_sales + " " + currency || 0 + " " + currency}
              title={t("salesMonth")}
              fullWidth="cards-full-width"
            />
            <DashboardCards
              iconComponent={profitSvg("#367868")}
              text={t("netSales")}
              amount={totalSales.total_revenue + " " + currency || 0+ " " + currency}
              title={t("revenueMonth")}
              fullWidth="cards-full-width"
            />
          <DashboardCards
            icon={commissionIcon}
            text={t("salisCommission")}
            amount={totalSales.commission + " " + currency || 0 + " " + currency}
            link={"/"}
            title={t("amountMonth")}
            fullWidth="cards-full-width"

            />
          </div>
        </Col>

        <Col xl={6}>
          <TotalSales disabledDate={disabledDate} />
        </Col>
        <Col xl={6}>
          <Comission3DChart />
        </Col>
        <Col xl={12}>


          <ItemsSales topTenItems={topTenItems.data} />

        </Col>
      </Row>
      <Row className="bg-alice-blue">
        <Col xl={12}>
          <TopTenItems topTenItems={topTenItems.data} />

        </Col>
      </Row>

    </React.Fragment>
  );
};

export default Sales;
