import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { EDIT_PROFILE, GET_PROFILE, UPDATE_PROFILE } from "./actionTypes";
import { profileSuccess, profileError, getUserDataSuccess, getUserDataError, updateUserDataSuccess, updateUserDataError } from "./actions";
//Include Both Helper File with needed methods

import {
  postFakeProfile,
  postJwtProfile,
  getUserData as getUserDataApi,
  updateUserData as updateUserDataApi,
} from "../../../helpers/salis_api";
import { config } from "../../../config";




function* editProfile({ payload: { user } }) {
  try {
    if (config.DEFAULTAUTH === "jwt") {
      const response = yield call(postJwtProfile, "/post-jwt-profile", {
        username: user.username,
        idx: user.idx,
      });
      yield put(profileSuccess(response));
    } else if (config.API_URL) {
      const response = yield call(postFakeProfile, user);
      yield put(profileSuccess(response));
    }
  } catch (error) {
    yield put(profileError(error));
  }
}
export function* watchProfile() {
  yield takeEvery(EDIT_PROFILE, editProfile);
}



function* getUserData({ payload: data }) {
  try {
    const response = yield call(getUserDataApi, data);
    yield put(getUserDataSuccess(response));
  } catch (error) {
    yield put(getUserDataError(error));
  }
}
export function* watchGetUserData() {
  yield takeEvery(GET_PROFILE, getUserData);
}

function* updateUserData({ payload: data }) {
  try {
    const response = yield call(updateUserDataApi, data);
    yield put(updateUserDataSuccess(response));
  } catch (error) {
    yield put(updateUserDataError(error));
  }
}
export function* watchUpdateUserData() {
  yield takeEvery(UPDATE_PROFILE, updateUserData);
}



function* ProfileSaga() {
  yield all([
    fork(watchProfile),
    fork(watchGetUserData),
    fork(watchUpdateUserData),
  ]);
}

export default ProfileSaga;
