import React, { useEffect, useState, useMemo } from "react";
import DataTable from "react-data-table-component";
import { useSelector, useDispatch } from "react-redux";
import { getProductsVisitorsData } from "../../store/charts/action";
import Loader from "../Ecommerce/Loader";
import DateFilterCards from "./DateFilterCards";
import moment from "moment";
import { useTranslation } from "react-i18next";

const ProductsVisitors = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [callApi, setCallApi] = useState(false);
  const now = new Date();
  const firstDay = moment(now).startOf('month').format("YYYY-MM-DD");
  const lastDay = moment(now).endOf('month').format('YYYY-MM-DD');
  const [action, setAction] = useState("month");
  const [startDate, setStartDate] = useState(firstDay);
  const [endDate, setEndDate] = useState(lastDay);

  const { dataProducts } = useSelector((state) => ({
    dataProducts: state.Charts.ProductsVisitorsData,
  }));
  useEffect(() => {
    const params = {
      start_date: startDate,
      end_date: endDate,
      action_type: "click_on_product_page",
      action: action,
    };
    dispatch(getProductsVisitorsData(params));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, callApi]);

  const columns = useMemo(
    () => [
      {
        name: <span className="fw-normal text-gray-650">Product</span>,
        selector: (row) => (
          <div className="d-flex align-items-center">
            <div className="flex-shrink-0 me-3">
              <div className="avatar-sm bg-light rounded p-1">
                <img
                  src={row.product.product_image}
                  alt=""
                  className="img-fluid d-block"
                />
              </div>
            </div>
            <p className="m-0 text-wrap p-0">{row.product.product_name}</p>
          </div>
        ),
      },
      {
        name: (
          <span className="fw-normal text-gray-650">Number of visitors</span>
        ),
        selector: (row) => row.visitors,
        sortable: false,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const columnNames = columns.map((col) => col.name.props.children);
  return (
    <div className="py-4 mt-2">
      <div className="bg-white rounded d-flex justify-content-between align-items-center p-2">
        <h4 className="card-title mb-0">{t(`visitorsOnProductsPage${action}`)}</h4>
        <div className="dashboard_picker">
          <button className="btn text-light-red bg-light-pink btn-sm cursor-pointer p-0">
            <DateFilterCards
              action={action}
              setAction={setAction}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              startDate={startDate}
              endDate={endDate}
              setCallApi={setCallApi}
              callApi={callApi}
          />
          </button>
        </div>
      </div>

      <div className="product-visitors-table-rdt">
        {dataProducts && dataProducts?.data?.length > 0 ? (
          <DataTable
            columns={columns}
            data={dataProducts?.data}
            pagination
            responsive
            paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
            paginationPerPage={5}
            paginationComponentOptions={{
              rowsPerPageText: "",
              rangeSeparatorText: "of",
            }}
          />
        ) : (
          <Loader columnNames={columnNames} />
        )}
      </div>
    </div>
  );
};
export default ProductsVisitors;
