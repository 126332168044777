import React from "react";
import done from "../../assets/images/done.svg";
const SuccessAlert = ({ message }) => {
  return (
    <div className="alert alert-success d-flex align-items-center" role="alert">
   <img src={done} alt="done"/>
    <div className="px-3 w-100  d-flex justify-content-between">
     {message}
    <i className="ri-close-line align-bottom me-1 border border-2 px-1"></i> 

    </div>
  </div>
      
  );
};

export default SuccessAlert;
