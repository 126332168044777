const ROISvg = ( fill ) => {
    return (
<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="48" height="48" rx="4" fill={fill} fillOpacity="0.1"/>
<g clipPath="url(#clip0_4514_3311)">
<path d="M17.6708 16.2572C21.5988 13.0382 27.4038 13.2622 31.0708 16.9292C34.9758 20.8342 34.9758 27.1662 31.0708 31.0712C27.1658 34.9762 20.8338 34.9762 16.9288 31.0712C15.7304 29.8744 14.8558 28.3928 14.3872 26.7653C13.9186 25.1378 13.8713 23.4179 14.2498 21.7672L14.3268 21.4542L16.2608 21.9642C15.7813 23.7864 15.9605 25.7195 16.7666 27.4226C17.5728 29.1257 18.9543 30.4896 20.6676 31.2739C22.3809 32.0582 24.3161 32.2125 26.132 31.7098C27.948 31.207 29.5282 30.0794 30.5942 28.5256C31.6602 26.9718 32.1435 25.0917 31.959 23.2164C31.7745 21.3412 30.934 19.5913 29.5856 18.2751C28.2373 16.9589 26.4676 16.1608 24.5884 16.0216C22.7093 15.8824 20.8414 16.411 19.3138 17.5142L19.0928 17.6802L20.1098 18.6972L15.5138 19.7572L16.5738 15.1612L17.6698 16.2572H17.6708ZM24.9998 18.0002V20.0002H27.4998V22.0002H21.9998C21.8748 22 21.7543 22.0465 21.662 22.1307C21.5697 22.2149 21.5122 22.3306 21.5009 22.455C21.4896 22.5794 21.5253 22.7036 21.601 22.803C21.6767 22.9024 21.7868 22.9699 21.9098 22.9922L21.9998 23.0002H25.9998C26.6628 23.0002 27.2987 23.2636 27.7675 23.7324C28.2364 24.2013 28.4998 24.8371 28.4998 25.5002C28.4998 26.1632 28.2364 26.7991 27.7675 27.2679C27.2987 27.7368 26.6628 28.0002 25.9998 28.0002H24.9998V30.0002H22.9998V28.0002H20.4998V26.0002H25.9998C26.1247 26.0004 26.2452 25.9539 26.3376 25.8697C26.4299 25.7855 26.4874 25.6698 26.4986 25.5454C26.5099 25.4209 26.4742 25.2968 26.3986 25.1973C26.3229 25.0979 26.2127 25.0304 26.0898 25.0082L25.9998 25.0002H21.9998C21.3367 25.0002 20.7008 24.7368 20.232 24.2679C19.7632 23.7991 19.4998 23.1632 19.4998 22.5002C19.4998 21.8371 19.7632 21.2013 20.232 20.7324C20.7008 20.2636 21.3367 20.0002 21.9998 20.0002H22.9998V18.0002H24.9998Z" fill="#EE907B"/>
</g>
<defs>
<clipPath id="clip0_4514_3311">
<rect width="24" height="24" fill="white" transform="translate(12 12)"/>
</clipPath>
</defs>
</svg>
    );
  };

  export default ROISvg;