//REGISTER
export const POST_FAKE_REGISTER = "/auth/signup";

//LOGIN
export const POST_FAKE_LOGIN = "/auth/signin";
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login";
export const POST_FAKE_PASSWORD_FORGET = "/auth/forgot-password";
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd";
export const SOCIAL_LOGIN = "/social-login";

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile";
export const POST_EDIT_PROFILE = "/user";

// Calendar
export const GET_EVENTS = "/events";
export const GET_CATEGORIES = "/categories";
export const GET_UPCOMMINGEVENT = "/upcommingevents";
export const ADD_NEW_EVENT = "/add/event";
export const UPDATE_EVENT = "/update/event";
export const DELETE_EVENT = "/delete/event";

// Chat
export const GET_DIRECT_CONTACT = "/chat";
export const GET_MESSAGES = "/messages";
export const ADD_MESSAGE = "add/message";
export const GET_CHANNELS = "/channels";

//Mailbox
export const GET_MAIL_DETAILS = "/mail";
export const DELETE_MAIL = "/delete/mail";

// Ecommerce
// Product
export const GET_PRODUCTS = "/apps/product";

// Orders
export const GET_ORDERS = "/apps/order";
export const ADD_NEW_ORDER = "/apps/order";
export const UPDATE_ORDER = "/apps/order";
export const DELETE_ORDER = "/apps/order";

// Customers
export const GET_CUSTOMERS = "/reports/merchants/customers";
export const ADD_NEW_CUSTOMER = "/apps/customer";
export const UPDATE_CUSTOMER = "/apps/customer";
export const DELETE_CUSTOMER = "/apps/customer";

// Sellers
export const GET_SELLERS = "/sellers";

// Project list
export const GET_PROJECT_LIST = "/project/list";

export const GET_CATEGORY = "/categories/";
export const GET_PRODUCTS_DATA = "/products/";
export const GET_ONE_PRODUCTS_DATA = "/products/";
export const UPDATE_ONE_PRODUCTS_DATA = "/products/";
export const DELETE_PRODUCT = "/products/";

export const ADD_NEW_PRODUCT = "/products/";

export const GET_ORDERS_DATA = "/orders/";
export const GET_ONE_ORDERS_DATA = "/orders/";


export const UPDATE_ORDER_DATA = "/merchants/sub-orders/";

export const LOGIN_USER = "/token/";

export const USER_DATA = "/merchants/";
export const ADD_NEW_MERCHANT ="/merchants/";

// report
export const GET_ACTUAL_CUSTOMERS = "/report/actual-customers/";
export const GET_COMMISSIONS = "/report/commissions/";
export const GET_TOP_ITEMS = "/report/top-ten-selling-items/";
export const GET_ITEMS_SALES = "/report/items-sales/";
export const GET_TOTAL_SPENT = "/special-deals/report/";
export const GET_TOTAL_CONVERSION_RATE = "/report/conversion-rate/";
export const GET_TOTAL_MARKETING_SPENDING = "/report/total-marketing-spending/";
export const GET_TOTAL_RECIEVABLES = "/report/total_recievables/";


// Special Deals

export const GET_SPECIAL_DEALS_DATA = "/special-deals/";
export const ADD_SPECIAL_DEALS = "/special-deals/";
export const SPECIAL_DEALS_SETTINGS = "/main/project-settings/";
export const SPECIAL_AVG_MAX_SETTINGS = "/special-deals/settings/";

// PPC

export const GET_PPC_DATA_TABLE = "/ppc/spending/";
export const GET_PPC_DATA_SPENDING = "ppc/total-spending/";

//Test total receivables
export const GET_TOTAL_RECEIVABLES_CHART = "/report/receivables-due-statement/"
// OTP
export const SEND_OTP = "otp/send/";
export const VERIFY_OTP = "otp/check/";

// Task
export const GET_TASK_LIST = "/apps/task";
export const ADD_NEW_TASK = "/apps/task";
export const UPDATE_TASK = "/apps/task";
export const DELETE_TASK = "/apps/task";

// CRM
// Conatct
export const GET_CONTACTS = "/apps/contact";
export const ADD_NEW_CONTACT = "/apps/contact";
export const UPDATE_CONTACT = "/apps/contact";
export const DELETE_CONTACT = "/apps/contact";

// Companies
export const GET_COMPANIES = "/apps/company";
export const ADD_NEW_COMPANIES = "/apps/company";
export const UPDATE_COMPANIES = "/apps/company";
export const DELETE_COMPANIES = "/apps/company";

// Lead
export const GET_LEADS = "/apps/lead";
export const ADD_NEW_LEAD = "/apps/lead";
export const UPDATE_LEAD = "/apps/lead";
export const DELETE_LEAD = "/apps/lead";

// Deals
export const GET_DEALS = "/deals";

// Crypto
export const GET_TRANSACTION_LIST = "/transaction-list";
export const GET_ORDRER_LIST = "/order-list";

// Invoice
export const GET_INVOICES = "/apps/invoice";
export const ADD_NEW_INVOICE = "/apps/invoice";
export const UPDATE_INVOICE = "/apps/invoice";
export const DELETE_INVOICE = "/apps/invoice";

// TicketsList
export const GET_TICKETS_LIST = "/apps/ticket";
export const ADD_NEW_TICKET = "/apps/ticket";
export const UPDATE_TICKET = "/apps/ticket";
export const DELETE_TICKET = "/apps/ticket";

//Cards
export const GET_CARDS = "payment-management/cards/";
export const PRE_ADD_CARD = "payment-management/cards/pre-create/";
export const ADD_CARD = "payment-management/cards/";
export const PRE_CHANGE_CARD = "payment-management/cards/pre-change/";
export const CHANGE_CARD = "payment-management/cards/";
export const DELETE_CARD = "payment-management/cards/";

//Checkout

export const PRE_CHECKOUT = "/thirdparty-management/payment-gateway/checkout/";
export const CHECKOUT = "/thirdparty-management/payment-gateway/checkout/";


//in-store-order

export const IN_STORE_ORDER = "/in-store-checkout/";
export const ORDER_RATING = "/rating/";
export const CANCEL_ORDER = "/cancel-order/";

//Banks
export const GET_BANK_ACCOUNTS = "payment-management/banks/";
export const GET_BANKS = "payment-management/banks/list/";

//wallet

export const GET_WALLET="wallet-management/wallets/"
export const GET_ITEMS = "/orders/";
export const GET_CAPTURES = "/orders/";
export const REFUND_PAYMENT = "/refund-payment/";
export const CAPTURE_PAYMENT = "/capture-payment/";

// password
export const FORGET_PASSWORD="/reset-password/"


// webhook
export const REGISTER_WEBHOOK="/merchant-webhooks/"
export const GET_WEBHOOK="merchant-webhooks/"
export const DELETE_WEBHOOK="/merchant-webhooks/"


//visitors

export const GET_VISITORS = "/track/"
export const GET_ITEMS_CONVERSION_RATE = "/report/items-conversion-rate/"
export const GET_SETTLEMENTS = "/settlements/"

//visitors

export const GET_TRANSACTION = "/thirdparty-management/payment-gateway/transactions/"


export const PRE_UPLOAD_IMAGE = "/file-upload/"

export const WITHDRAW ="wallet-management/wallets/withdraw/"

export const GET_FEATURES_FLAGS ="/flags/"

export const GET_WALLET_STATEMENT ="/wallet-management/wallets/statement/"


export const GET_TOTAL_SALES ="/report/total-sales/"

// Pay Per Click 
export const POST_PayPer_Click ="/ppc/"
export const GET_PPC_DATA = "/ppc/";
export const CANCEL_PPC = "/ppc/";

//ROI
export const GET_ROI="/report/return-on-investment/"
export const GET_ROI_CHART="/report/return-on-investment-chart/"

//Marketing Spending Cards
export const GET_MARKETING_SPENDING_CARDS="/report/marketing-spending/"
//receivables due
export const GET_RECEIVABLES_DUE="/report/total-receivables-due"
export const GET_PAYMENT_TYPES ="merchants/payment-types/"
