import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";

// Import Images
import error from "../../../assets/images/404_page.svg";

const Basic404 = () => {
  document.title =
    "404 Error Basic | Salis- Merchants Dashboard";
  return (
    <React.Fragment>
      <div className="auth-page-wrapper">
          <div className="auth-page-content">
            <Container>
              <Row>
                <Col lg={12}>
                  <div className="text-center pt-4">
                    <div className="">
                      <img
                        src={error}
                        alt=""
                        className="error-basic-img move-animation"
                      />
                    </div>
                    <div className="mt-n4">
                      <h3 className="text-uppercase">
                      Page not found
                      </h3>
                      <p className="text-muted mb-4">
                      This page you requested couldn’t be found.
                      </p>
                      <Link to="/dashboard" className="btn bg-light-red text-white">
                        <i className="mdi mdi-home me-1"></i>Back to home
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
      </div>
    </React.Fragment>
  );
};

export default Basic404;
