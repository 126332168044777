import {
  GET_TOTAL_SPENT,
  GET_TOTAL_SPENT_SUCCESS,
  GET_TOTAL_SPENT_FAIL,
  GET_ACTUAL_CUSTOMERS,
  GET_ACTUAL_CUSTOMERS_SUCCESS,
  GET_ACTUAL_CUSTOMERS_FAIL,
  GET_VISITORS,
  GET_VISITORS_SUCCESS,
  GET_VISITORS_FAIL,
  GET_DAILY_VISITORS,
  GET_DAILY_VISITORS_SUCCESS,
  GET_DAILY_VISITORS_FAIL,
  GET_MONTHLY_VISITORS,
  GET_MONTHLY_VISITORS_SUCCESS,
  GET_MONTHLY_VISITORS_FAIL,
  GET_DAILY_CUSTOMERS,
  GET_DAILY_CUSTOMERS_SUCCESS,
  GET_DAILY_CUSTOMERS_FAIL,
  GET_3D_CUSTOMERS,
  GET_3D_CUSTOMERS_SUCCESS,
  GET_3D_CUSTOMERS_FAIL,
  GET_3D_COMMISSIONS,
  GET_3D_COMMISSIONS_SUCCESS,
  GET_3D_COMMISSIONS_FAIL,
  GET_TOTAL_CONVERSION_RATE,
  GET_TOTAL_CONVERSION_RATE_SUCCESS,
  GET_TOTAL_CONVERSION_RATE_FAIL,
  GET_PRODUCTS_VISITORS_DATA,
  GET_PRODUCTS_VISITORS_DATA_SUCCESS,
  GET_PRODUCTS_VISITORS_DATA_FAIL,
  GET_WALLET_STATEMENT_DATA,
  GET_WALLET_STATEMENT_DATA_SUCCESS,
  GET_WALLET_STATEMENT_DATA_FAIL,
  RESET_LOADER_FLAG,
  GET_ITEMS_CONVERSION_RATE,
  GET_ITEMS_CONVERSION_RATE_SUCCESS,
  GET_ITEMS_CONVERSION_RATE_FAIL,
  GET_TOTAL_SALES,
  GET_TOTAL_SALES_SUCCESS,
  GET_TOTAL_SALES_FAIL,
  GET_TOP_ITEMS,
  GET_TOP_ITEMS_SUCCESS,
  GET_TOP_ITEMS_FAIL,
  GET_ITEMS_SALES,
  GET_ITEMS_SALES_SUCCESS,
  GET_ITEMS_SALES_FAIL,
  GET_PPC_DATA_SPENDING,
  GET_PPC_DATA_SUCCESS,
  GET_PPC_DATA_FAIL,
  GET_PPC_DATA_TABLE,
  GET_PPC_DATA_TABLE_SUCCESS,
  GET_PPC_DATA_TABLE_FAIL,
  GET_TOTAL_MARKETING_SPENDING,
  GET_TOTAL_MARKETING_SPENDING_SUCCESS,
  GET_TOTAL_MARKETING_SPENDING_FAIL,
  GET_TOTAL_RECEIVABLES_CHART,
  GET_TOTAL_RECEIVABLES_CHART_SUCCESS,
  GET_TOTAL_RECEIVABLES_CHART_FAIL,
  GET_TOTAL_RECIEVABLES,
  GET_TOTAL_RECIEVABLES_SUCCESS,
  GET_TOTAL_RECIEVABLES_FAIL,
  GET_ROI,
  GET_ROI_SUCCESS,
  GET_ROI_FAIL,
  GET_ROI_CHART,
  GET_ROI_CHART_SUCCESS,
  GET_ROI_CHART_FAIL,
  GET_MARKETING_SPENDING_CARDS,
  GET_MARKETING_SPENDING_CARDS_SUCCESS,
  GET_MARKETING_SPENDING_CARDS_FAIL,
  GET_RECEIVABLES_DUE,
  GET_RECEIVABLES_DUE_SUCCESS,
  GET_RECEIVABLES_DUE_FAIL
} from "./actionTypes";




export const resetChartsLoaderFlag = () => {
  return {
    type: RESET_LOADER_FLAG,
  };
};

export const getTotalSpent = (data) => {
  return {
    type: GET_TOTAL_SPENT,
    payload: data,
  };
};

export const getTotalSpentSuccess = (data) => {
  return {
    type: GET_TOTAL_SPENT_SUCCESS,
    payload: data,
  };
};

export const getTotalSpentFail = (error) => {
  return {
    type: GET_TOTAL_SPENT_FAIL,
    payload: error,
  };
};

export const getDailyCustomers = (data) => ({
  type: GET_DAILY_CUSTOMERS,
  payload: data,
});

export const getDailyCustomersSuccess = (data) => ({
  type: GET_DAILY_CUSTOMERS_SUCCESS,
  payload: data,
});

export const getDailyCustomersFail = (data) => ({
  type: GET_DAILY_CUSTOMERS_FAIL,
  payload: data,
});

export const get3DCustomers = (data) => ({
  type: GET_3D_CUSTOMERS,
  payload: data,
});

export const get3DCustomersSuccess = (data) => ({
  type: GET_3D_CUSTOMERS_SUCCESS,
  payload: data,
});

export const get3DCustomersFail = (data) => ({
  type: GET_3D_CUSTOMERS_FAIL,
  payload: data,
});

export const getActualCustomers = (data) => ({
  type: GET_ACTUAL_CUSTOMERS,
  payload: data,
});

export const getActualCustomersSuccess = (data) => ({
  type: GET_ACTUAL_CUSTOMERS_SUCCESS,
  payload: data,
});

export const getActualCustomersFail = (data) => ({
  type: GET_ACTUAL_CUSTOMERS_FAIL,
  payload: data,
});

export const getVisitors = (data) => ({
  type: GET_VISITORS,
  payload: data,
});

export const getVisitorsSuccess = (data) => ({
  type: GET_VISITORS_SUCCESS,
  payload: data,
});

export const getVisitorsFail = (data) => ({
  type: GET_VISITORS_FAIL,
  payload: data,
});

export const getDailyVisitors = (data) => ({
  type: GET_DAILY_VISITORS,
  payload: data,
});

export const getDailyVisitorsSuccess = (data) => ({
  type: GET_DAILY_VISITORS_SUCCESS,
  payload: data,
});

export const getDailyVisitorsFail = (data) => ({
  type: GET_DAILY_VISITORS_FAIL,
  payload: data,
});

export const getMonthlyVisitors = (data) => ({
  type: GET_MONTHLY_VISITORS,
  payload: data,
});

export const getMonthlyVisitorsSuccess = (data) => ({
  type: GET_MONTHLY_VISITORS_SUCCESS,
  payload: data,
});

export const getMonthlyVisitorsFail = (data) => ({
  type: GET_MONTHLY_VISITORS_FAIL,
  payload: data,
});

export const getCommission = (data) => ({
  type: GET_3D_COMMISSIONS,
  payload: data,
});

export const getCommissionSuccess = (data) => ({
  type: GET_3D_COMMISSIONS_SUCCESS,
  payload: data,
});
export const getCommissionFail = (data) => ({
  type: GET_3D_COMMISSIONS_FAIL,
  payload: data,
});

export const getTotalConversionRate = (data) => ({
  type: GET_TOTAL_CONVERSION_RATE,
  payload: data,
});

export const getTotalConversionRateSuccess = (data) => ({
  type: GET_TOTAL_CONVERSION_RATE_SUCCESS,
  payload: data,
});

export const getTotalConversionRateFail = (data) => ({
  type: GET_TOTAL_CONVERSION_RATE_FAIL,
  payload: data,
});

export const getProductsVisitorsData = (data) => ({
  type: GET_PRODUCTS_VISITORS_DATA,
  payload: data,
});

export const getProductsVisitorsDataSuccess = (data) => ({
  type: GET_PRODUCTS_VISITORS_DATA_SUCCESS,
  payload: data,
});

export const getProductsVisitorsDataFail = (data) => ({
  type: GET_PRODUCTS_VISITORS_DATA_FAIL,
  payload: data,
});

export const getWalletStatementData = (data) => ({
  type: GET_WALLET_STATEMENT_DATA,
  payload: data,
});

export const getWalletStatementDataSuccess = (data) => ({
  type: GET_WALLET_STATEMENT_DATA_SUCCESS,
  payload: data,
});

export const getWalletStatementDataFail = (data) => ({
  type: GET_WALLET_STATEMENT_DATA_FAIL,
  payload: data,
});

export const getItemConversionRate = (data) => ({
  type: GET_ITEMS_CONVERSION_RATE,
  payload: data,
});

export const getItemConversionRateSuccess = (data) => ({
  type: GET_ITEMS_CONVERSION_RATE_SUCCESS,
  payload: data,
});

export const getItemConversionRateFail = (data) => ({
  type: GET_ITEMS_CONVERSION_RATE_FAIL,
  payload: data,
});

export const getTotalSales = (data) => ({
  type: GET_TOTAL_SALES,
  payload: data,
});

export const getTotalSalesSuccess = (data) => ({
  type: GET_TOTAL_SALES_SUCCESS,
  payload: data,
});


export const getTotalSalesFail = (data) => ({
  type: GET_TOTAL_SALES_FAIL,
  payload: data,
});


export const getTopItems = (data) => ({
  type: GET_TOP_ITEMS,
  payload: data,
});

export const getTopItemsSuccess = (data) => ({
  type: GET_TOP_ITEMS_SUCCESS,
  payload: data,
});


export const getTopItemsFail = (data) => ({
  type: GET_TOP_ITEMS_FAIL,
  payload: data,
});


export const getItemsSales = (data) => ({
  type: GET_ITEMS_SALES,
  payload: data,
});

export const getItemsSalesSuccess = (data) => ({
  type: GET_ITEMS_SALES_SUCCESS,
  payload: data,
});


export const getItemsSalesFail = (data) => ({
  type: GET_ITEMS_SALES_FAIL,
  payload: data,
});

export const getPPCDataChart = (data) => ({
  type: GET_PPC_DATA_SPENDING,
  payload: data,
});

export const getPPCDataChartSuccess = (data) => ({
  type: GET_PPC_DATA_SUCCESS,
  payload: data,
});


export const getPPCDataChartFail = (data) => ({
  type: GET_PPC_DATA_FAIL,
  payload: data,
});
export const getPPCDataTable = (data) => ({
  type: GET_PPC_DATA_TABLE,
  payload: data,
});

export const getPPCDataTableSuccess = (data) => ({
  type: GET_PPC_DATA_TABLE_SUCCESS,
  payload: data,
});


export const getPPCDataTableFail = (data) => ({
  type: GET_PPC_DATA_TABLE_FAIL,
  payload: data,
});
export const getTotalMarketingSpending = (data) => ({
  type: GET_TOTAL_MARKETING_SPENDING,
  payload: data,
});

export const getTotalMarketingSpendingSuccess = (data) => ({
  type: GET_TOTAL_MARKETING_SPENDING_SUCCESS,
  payload: data,
});


export const getTotalMarketingSpendingFail = (data) => ({
  type: GET_TOTAL_MARKETING_SPENDING_FAIL,
  payload: data,
});


export const getTotalReceivablesChart = (data) => ({
  type: GET_TOTAL_RECEIVABLES_CHART,
  payload: data,
});

export const getTotalReceivablesChartSuccess = (data) => ({
  type: GET_TOTAL_RECEIVABLES_CHART_SUCCESS,
  payload: data,
});


export const getTotalReceivablesChartFail = (data) => ({
  type: GET_TOTAL_RECEIVABLES_CHART_FAIL,
  payload: data,
});
export const getTotalRecievables = (data) => ({
  type: GET_TOTAL_RECIEVABLES,
  payload: data,
});

export const getTotalRecievablesSuccess = (data) => ({
  type: GET_TOTAL_RECIEVABLES_SUCCESS,
  payload: data,
});


export const getTotalRecievablesFail = (data) => ({
  type: GET_TOTAL_RECIEVABLES_FAIL,
  payload: data,
});
export const getROI = (data) => ({
  type: GET_ROI,
  payload: data,
});

export const getROISuccess = (data) => ({
  type: GET_ROI_SUCCESS,
  payload: data,
});
export const getReceivablesDue = (data) => ({
  type: GET_RECEIVABLES_DUE,
  payload: data,
});

export const getReceivablesDueSuccess = (data) => ({
  type: GET_RECEIVABLES_DUE_SUCCESS,
  payload: data,
});


export const getROIFail = (data) => ({
  type: GET_ROI_FAIL,
  payload: data,
});
export const getROIChart = (data) => ({
  type: GET_ROI_CHART,
  payload: data,
});

export const getROIChartSuccess = (data) => ({
  type: GET_ROI_CHART_SUCCESS,
  payload: data,
});


export const getROIChartFail = (data) => ({
  type: GET_ROI_CHART_FAIL,
  payload: data,
});
export const getMarketingSpendingCards = (data) => ({
  type: GET_MARKETING_SPENDING_CARDS,
  payload: data,
});

export const getMarketingSpendingCardsSuccess = (data) => ({
  type: GET_MARKETING_SPENDING_CARDS_SUCCESS,
  payload: data,
});


export const getMarketingSpendingCardsFail = (data) => ({
  type: GET_MARKETING_SPENDING_CARDS_FAIL,
  payload: data,
});
export const getReceivablesDueFail = (data) => ({
  type: GET_RECEIVABLES_DUE_FAIL,
  payload: data,
});


