import "react-toastify/dist/ReactToastify.css";

// Formik Validation

import Otp from "./OtpValidation";
import { Col, Collapse, Row, Container } from "reactstrap";
import { useHistory } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import "aos/dist/aos.css";
import AOS from "aos";
import CollapseOne from "./RegisterCollapses/collapseOne";
import CollapseThree from "./RegisterCollapses/collapseThree";
import CollapseFour from "./RegisterCollapses/collapseFour";
import CollapseFive from "./RegisterCollapses/collapseFive";
// action
import {
  addMerchant,
  loginUser,
  sendOtp,
  verifyOtp,
  resetOtpFlag,
  resetRegisterFlag,
  resetLoginFlag,
  uploadImage,
  resetEcomFlag,
} from "../../store/actions";
//redux
import { useDispatch, useSelector } from "react-redux";
import { Stepper, Step } from "react-form-stepper";
import {
  MdAccountCircle,
  MdVpnKey,
  MdOutlineLockOpen,
  MdOutlineLocationOn,
  MdOutlineAddToPhotos,
} from "react-icons/md";
import { config } from "../../config";

//import images
import { useFormik } from "formik";
import * as schema from "./RegisterSchema";
const { TOAST_AUTOCLOSE_TIMER } = config;
const { LOADER_RESET_TIMER } = config;

const Register = () => {
  const ref_image_trade_license = useRef();
  const ref_image_tax_registration_certificate = useRef();

  const history = useHistory();
  const dispatch = useDispatch();

  const {
    registrationError,
    success,
    otpSuccess,
    otpError,
    imageUploadLink,
    imageUpload,
    imageUploadError,
  } = useSelector((state) => ({
    success: state.Account.success,
    registrationError: state.Account.registrationError,
    otpSuccess: state.Otp.otpSuccess,
    otpError: state.Otp.otpError,
    imageUploadLink: state.Ecommerce.imageUploadLink,
    imageUpload: state.Ecommerce.imageUpload,
    imageUploadError: state.Ecommerce.imageUploadError,
  }));

  const [currentPage, setCurrentPage] = useState(0);
  const [photoClass, setPhotoClass] = useState("auth-sign-up1-image");
  const [otp, setOTP] = useState("");
  const [finisnedSteps, setFinisnedSteps] = useState(false);
  const [image, setImage] = useState([]);

  const [validationSchema, setValidationSchema] = useState(
    schema.validationSchemaOne
  );

  useEffect(() => {
    setTimeout(() => {
      AOS.init();
    }, 500);
  }, [currentPage]);

  useEffect(() => {
    setTimeout(() => {
      dispatch(resetEcomFlag());
    }, LOADER_RESET_TIMER);
  }, [dispatch, imageUpload, imageUploadError]);
  useEffect(() => {
    if (imageUpload) {
      let imageTradeLicense;
      let imageTaxRegistrationCertificate;
      imageUploadLink.forEach((item, index) => {
        if (item.type === "image_trade_license") {
          imageTradeLicense = item["link"].substring(
            0,
            item["link"].indexOf("?")
          );
        }
        if (item.type === "image_tax_registration_certificate") {
          imageTaxRegistrationCertificate = item["link"].substring(
            0,
            item["link"].indexOf("?")
          );
        }
      });
      const user_data = {
        email: validation.values.email,
        username: validation.values.username,
        password: validation.values.password,
      };
      const data = {
        user_data: user_data,
        mobile_number: validation.values.mobile_number,
        business_registration_number:
          validation.values.business_registration_number,
        company_name: validation.values.company_name,
        store_name: validation.values.store_name,
        tax_registration_number: validation.values.tax_registration_number,
        latitude: validation.values.latitude,
        longitude: validation.values.longitude,
        category: validation.values.category,
        image_trade_license: imageTradeLicense,
        image_tax_registration_certificate: imageTaxRegistrationCertificate,
      };
      // JSON.stringify({'email':values.email,'username':values.username,'password':values.password} ))
      dispatch(addMerchant(data));
      setFinisnedSteps(true);
    }

    if (imageUploadError) {
      toast("An error accord while uploading image", {
        position: "top-right",
        hideProgressBar: true,
        className: "bg-danger text-white",
        progress: undefined,
        toastId: "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageUpload, imageUploadError]);
  const handleUpdateProfile = () => {
    let data;

    imageUploadLink.forEach((item, index) => {
      data = {
        url: item.link,
        image: image[index][item.type]["file"],
        imageType: image[index][item.type]["fileType"],
      };
      dispatch(uploadImage(data));
    });
  };
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      username: "",
      category: "",
      password: "",
      confirm_password: "",
      mobile_number: "+966",
      company_name: "",
      store_name: "",
      latitude: "",
      longitude: "",
      headquarters_location: "",
      tax_registration_number: "",
      business_registration_number: "",
      image_trade_license: "",
      image_tax_registration_certificate: "",
    },
    validationSchema,
    onSubmit: (values) => {
      handleNextPage(currentPage + 1);
      if (currentPage === 4) {
        handleUpdateProfile();
      }
    },
  });
  const handleStepper = (value) => {
    if (value !== currentPage) handleNextPage(value);
  };

  const handleNextPage = (value) => {
    setPhotoClass(`auth-sign-up${value + 1}-image `);
    if (value === 0) {
      setCurrentPage(value);
      setValidationSchema(schema.validationSchemaOne);
    } else if (value === 1) {
      handleSendOtp();

      setValidationSchema(schema.validationSchemaTwo);
    } else if (value === 2) {
      handleVerifyOtp();
      if (value === 2 && !otpSuccess) {
        return;
      }
      setValidationSchema(schema.validationSchemaThree);
    } else if (value === 3) {
      setValidationSchema(schema.validationSchemaFour);
    } else if (value === 4) {
      setValidationSchema(schema.validationSchemaFive);
    }
    setCurrentPage(value);
  };
  const handleSendOtp = () => {
    dispatch(sendOtp({ phone_number: validation.values.mobile_number }));
  };

  const handleVerifyOtp = () => {
    dispatch(
      verifyOtp({ phone_number: validation.values.mobile_number, otp: otp })
    );
  };

  useEffect(() => {
    if (success) {
      setFinisnedSteps(true);

      toast("Registered successfully. Redirecting To Dashboard...", {
        position: "top-right",
        hideProgressBar: true,
        className: "bg-success text-white",
        progress: undefined,
        toastId: "",
      });
      dispatch(
        loginUser({
          username: validation.values.username,
          password: validation.values.password,
        })
      );
      dispatch(resetRegisterFlag());
      dispatch(resetLoginFlag());

      setTimeout(() => history.push("dashboard"), TOAST_AUTOCLOSE_TIMER);
    }
    if (registrationError) {
      toast(registrationError, {
        position: "top-right",
        hideProgressBar: true,
        className: "bg-danger text-white",
        progress: undefined,
        toastId: "",
      });
      ref_image_trade_license.current.value = "";
      ref_image_tax_registration_certificate.current.value = "";

      validation.setFieldValue("image_trade_license", "");
      validation.setFieldValue("image_tax_registration_certificate", "");
      setValidationSchema(schema.validationSchemaOne);
      setCurrentPage(0);
      setPhotoClass(`auth-sign-up${1}-image `);
      dispatch(resetRegisterFlag());
      setFinisnedSteps(false);

    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, success, registrationError, history]);

  useEffect(() => {
    if (otpSuccess) {
      setCurrentPage(2);

      setValidationSchema(schema.validationSchemaThree);
    }
  }, [otpSuccess]);
  useEffect(() => {
    if (otpError) {
      toast(otpError, {
        position: "top-right",
        hideProgressBar: true,
        className: "bg-danger text-white",
        progress: undefined,
        toastId: "",
      });
    }
    dispatch(resetOtpFlag());
  }, [dispatch, otpError, otpSuccess]);

  document.title = "Sign Up | Salis";

  return (
    <React.Fragment>
      <ToastContainer
        autoClose={TOAST_AUTOCLOSE_TIMER}
        style={{ width: "auto" }}
      />
      <Container fluid className="h-100">
        <Row className="h-100">
          <Col className="d-none px-0 d-lg-block h-100" lg={6} md={6}>
            <div data-aos="fade-right" className={photoClass}></div>
          </Col>
          <Col className="px-0" md={12} lg={6} xl={6} xxl={6}>
            {!finisnedSteps && (
              <Stepper activeStep={currentPage}>
                <Step onClick={() => handleStepper(0)} label="Contact Info">
                  {" "}
                  <MdAccountCircle />
                </Step>
                <Step onClick={() => handleStepper(1)} label="OTP">
                  {" "}
                  <MdOutlineLockOpen />
                </Step>
                <Step onClick={() => handleStepper(2)} label="Password">
                  {" "}
                  <MdVpnKey />
                </Step>
                <Step onClick={() => handleStepper(3)} label="Location">
                  {" "}
                  <MdOutlineLocationOn />
                </Step>
                <Step onClick={() => handleStepper(4)} label="Attach Files">
                  {" "}
                  <MdOutlineAddToPhotos />
                </Step>
              </Stepper>
            )}

            <CollapseOne currentPage={currentPage} validation={validation} />
            <Collapse in isOpen={currentPage === 1}>
              <Otp
                otp={otp}
                setOTP={setOTP}
                handleAction={() => handleNextPage(2)}
                phoneNumber={validation.values.mobile_number}
              />
            </Collapse>
            <CollapseThree currentPage={currentPage} validation={validation} />
            <CollapseFour currentPage={currentPage} validation={validation} />
            <CollapseFive
              currentPage={currentPage}
              validation={validation}
              success={success}
              setImage={setImage}
              image={image}
              ref_image_trade_license={ref_image_trade_license}
              ref_image_tax_registration_certificate={
                ref_image_tax_registration_certificate
              }
            />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default Register;
