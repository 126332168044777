import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Modal, ModalBody } from "reactstrap";
import { useFormik } from "formik";
import { Input, Label } from "reactstrap";
import { isValidIBANNumber } from "../../../helpers/iban_validation";
import validator from "../../../helpers/Validator";

import * as Yup from "yup";
import {
  changeBankAccount,
  resetEcomFlag,
} from "../../../store/ecommerce/action";
// redux
import { useSelector, useDispatch } from "react-redux";
import { config } from "../../../config";
const { TOAST_AUTOCLOSE_TIMER } = config;

const ChangeBankPopup = ({
  banks,
  bankAccount,
  show,
  onCloseClick,
  bankID,
}) => {
  const dispatch = useDispatch();
  const { isBankChangeFailed } = useSelector((state) => ({
    isBankChangeFailed: state.Ecommerce.isBankChangeFailed,
  }));
  useEffect(() => {
    setTimeout(() => {
      dispatch(resetEcomFlag());
    }, TOAST_AUTOCLOSE_TIMER);
  }, [dispatch, isBankChangeFailed]);
  const validationSchema = Yup.object().shape({
    iban: Yup.string()
      .required("Required")
      .test("Required", "Field should have a valid IBAN", (value) =>
        isValidIBANNumber(value)
      ),
    holder_name: Yup.string()
      .required("Required")
      .matches(validator.textOnly, "Field should have a valid holder name"),
    bank: Yup.string().required("Required"),
  });
  const formik = useFormik({
    initialValues: {
      id: bankAccount.id,
      iban: bankAccount.iban,
      holder_name: bankAccount.holder_name,
      bank: bankID,
    },
    validationSchema,
    onSubmit: (values) => {
      dispatch(changeBankAccount(values));
    },
    enableReinitialize: true,
  });
  const handleNotValidForm = (e) => {
    e.preventDefault();
    setInValidCreate(true);
    formik.touched.iban = true;
    formik.touched.holder_name = true;
    formik.touched.bank = true;
  };
  const [inValidCreate, setInValidCreate] = useState(false);
  const [validCreate, setValidCreate] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setInValidCreate(false);
      setValidCreate(false);
    }, 3000);
  }, [validCreate, inValidCreate]);

  return (
    <>
      <Modal isOpen={show} toggle={onCloseClick} centered={true}>
        <ModalBody className="p-3 rounded">
          <div className="d-flex gap-2 justify-content-end">
            <button
              type="button"
              className="btn w-fit border rounded btn-light"
              data-bs-dismiss="modal"
              onClick={onCloseClick}
              data-testid="close-update-bank"
            >
              <i className="ri-close-line align-bottom"></i>
            </button>
          </div>
          <div className="text-center pt-2 fs-15 ">
            <h5>Update bank account</h5>
          </div>
          <form
            method="post"
            onSubmit={(e) =>
              formik.isValid ? formik.handleSubmit(e) : handleNotValidForm(e)
            }
          >
            <div className="mb-3">
              <Label>IBAN</Label>

              <Input
                id="iban"
                type="text"
                name="iban"
                placeholder="Enter your iban"
                value={formik.values.iban}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={
                  (formik.errors.iban && formik.touched.iban) ||
                  isBankChangeFailed
                    ? "form-control is-invalid"
                    : "form-control"
                }
              />
              {formik.errors.iban && formik.touched.iban ? (
                <div className="text-danger">{formik.errors.iban}</div>
              ) : isBankChangeFailed ? (
                <div className="text-danger">{isBankChangeFailed}</div>
              ) : null}
            </div>
            <div className="mb-3">
              <Label>Holder name</Label>

              <Input
                id="holder_name"
                type="text"
                placeholder="Enter your name"
                name="holder_name"
                value={formik.values.holder_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={
                  formik.errors.holder_name && formik.touched.holder_name
                    ? "form-control is-invalid"
                    : "form-control"
                }
              />
              {formik.errors.holder_name && formik.touched.holder_name ? (
                <div className="text-danger">{formik.errors.holder_name}</div>
              ) : null}
            </div>
            <div className="mb-3">
              <Label>Bank</Label>
              <Input
                type="select"
                name="bank"
                id="bank"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={
                  formik.errors.bank && formik.touched.bank
                    ? "form-control is-invalid"
                    : "form-control"
                }
                defaultValue={bankID}
                data-testid="select-bank"
              >
                {/* <option value="selected" disabled>Select your bank</option> */}
                {Object.keys(banks).length !== 0 &&
                  banks.map(function (item, index) {
                    return (
                      <option key={index} value={item.id}>
                        {item.en_name}
                      </option>
                    );
                  })}
              </Input>
              {formik.errors.bank && formik.touched.bank ? (
                <div className="text-danger">{formik.errors.bank}</div>
              ) : null}
            </div>
            <div className="text-center mb-3">
              <button
                type="submit"
                className="btn btn bg-light-red text-white w-sm"
              >
                Submit
              </button>
            </div>
          </form>
        </ModalBody>
      </Modal>
    </>
  );
};

ChangeBankPopup.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any,
  bankAccount: PropTypes.any,
};

export default ChangeBankPopup;
