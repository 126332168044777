import PropTypes from "prop-types";
import React, { useState } from "react";

import { Modal, ModalBody } from "reactstrap";

import Loader from "./Loader";
const ConfirmationModal = ({
  message,
  show,
  onCloseClick,
  action,
  image = null,
  title = "Are you sure?",
}) => {
  const [loading, setLoading] = useState(false);

  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalBody className="py-3 px-3">
        {!loading ? (
          <>
            {" "}
            <div className="mt-2 text-center">
              <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-3">
              {image!= null ? 
              <img
                src={image}
                alt="Confirmation"
                height="151"
                className="m-2 mb-5"
              /> :''}
                <h4>{title}</h4>
                <p className="text-muted mx-4 mb-0">{message}</p>
              </div>
            </div>
            <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
              <button
                type="button"
                className="btn w-sm btn-light"
                data-bs-dismiss="modal"
                onClick={onCloseClick}
              >
                Close
              </button>
              <button
                type="button"
                className="btn w-sm bg-light-red text-white "
                id="delete-record"
                onClick={() => {
                  action();
                  setLoading(true);
                }}
              >
                Confirm
              </button>
            </div>{" "}
          </>
        ) : (
          <Loader />
        )}
      </ModalBody>
    </Modal>
  );
};

ConfirmationModal.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any,
  message: PropTypes.any,
};

export default ConfirmationModal;
