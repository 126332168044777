import React from "react";
import { TextField, InputAdornment } from "@material-ui/core";

import "antd/dist/antd.css";
import { Card, CardBody, Col, Row, Collapse } from "reactstrap";
import { useHistory } from "react-router-dom";
import minBidLogo from "../../../assets/images/min_bid.svg";
import avgBidLogo from "../../../assets/images/avg_bid.svg";
import maxBidLogo from "../../../assets/images/max_bid.svg";
import { useEffect } from "react";
import { resetEcomFlag, addSpecialDeals } from "../../../store/ecommerce/action";
import { useSelector, useDispatch } from "react-redux";
import { config } from "../../../config";

const { currency } = config;

const CollapseThree = ({
  collapseThree,
  handleStepper,
  setMyBid,
  myBid,
  minBid,
  specialDealsAvgMaxSettings,
  dealCategory,
  startDate,
  endDate,
  productDetails,
  title,
}) => {
  const History = useHistory();
  const dispatch = useDispatch();
const addSpecialDeal=()=>{
  const newSpecialDeal = {
    product: productDetails.id,
    start_date: startDate,
    end_date: endDate,
    category: dealCategory,
    bid: 1,
    title: title,
  };
  dispatch(addSpecialDeals(newSpecialDeal));
  History.push("/special-deals/?issa=5456");
}
  const { specialDealError, specialDealErrorLoading } = useSelector(
    (state) => ({
      specialDealError: state.Ecommerce.specialDealError,
      specialDealErrorLoading: state.Ecommerce.specialDealErrorLoading,
    })
  );
  useEffect(() => {
    dispatch(resetEcomFlag());
  }, [dispatch, specialDealError, specialDealErrorLoading]);
  const lengthMyBid = parseInt(myBid).toString().length;

  return (
    <Collapse in isOpen={collapseThree}>
      {specialDealErrorLoading && (
        <>
          {!specialDealError ? (
            <>
              {specialDealsAvgMaxSettings?.results ? 
              <Card className=" h-100%">
               <CardBody className=" h-100%">
                 <h4 className="card-body mb-0">Bid Settings</h4>

                 <p className="card-body  text-sm  color text-muted mb-0">
                 As one of the privileged merchants who qualify for the initial four exclusive offers in this category and on this specific date, you won't need to bid.
                  Instead, you have the freedom to create your own special deal at no cost!
                 </p>
        
                 <div
                   style={{ marginBottom: "100px",marginTop:"150px" }}
                   className="d-flex justify-content-center"
                 >
                   <button

                     className="btn button-width-sd text-white bg-light-red ContinuebtnPPC"
                     onClick={() => addSpecialDeal()}
                   >
                     Create Special Deal
                   </button>
                 </div>
               </CardBody>
             </Card>
              :
               <Card className=" h-100%">
               <CardBody className=" h-100%">
                 <h4 className="card-body mb-0">Bid Settings</h4>

                 <p className="card-body  text-sm  color text-muted mb-0">
                   Please offer your bid to be able to reserve place in our
                   special deal sections
                 </p>
                 <Row>
                   <Col
                     xl={6}
                     style={{ width: "100%", backgroundColor: "white" }}
                   >
                     <div className="d-flex text-sm justify-content-around bidCollapse">
                       <span
                         className="my-4 px-4  py-4 h-100 rounded d-flex cursor-pointer	"
                         style={{ backgroundColor: "#F0F0F0" }}
                         onClick={() => {
                           setMyBid(minBid || 0);
                         }}
                       >
                         <img
                           src={minBidLogo}
                           className="img-fluid d-block px-3"
                           alt=""
                         />
                         <div className="flex ">
                           <p className="fw-bold m-0"> {minBid || 0} {currency}</p>
                           <p className="m-0">Min Bid</p>
                         </div>
                       </span>
                       <span
                         className="my-4 px-4  py-4 h-100 rounded d-flex cursor-pointer	"
                         style={{ backgroundColor: "#F0F0F0" }}
                         onClick={() => {
                           setMyBid(specialDealsAvgMaxSettings.avg_bid);
                         }}
                       >
                         <img
                           src={avgBidLogo}
                           className="img-fluid d-block px-3"
                           alt=""
                         />
                         <div className="flex 	">
                           <p className="fw-bold m-0">

                             {specialDealsAvgMaxSettings.avg_bid} {currency}
                           </p>
                           <p className="m-0">Avg Bid</p>
                         </div>
                       </span>
                       <span
                         className="my-4 px-4  py-4 h-100 rounded d-flex cursor-pointer	"
                         style={{ backgroundColor: "#F0F0F0" }}
                         onClick={() => {
                           setMyBid(specialDealsAvgMaxSettings.max_bid);
                         }}
                       >
                         <img
                           src={maxBidLogo}
                           className="img-fluid d-block px-3"
                           alt=""
                         />
                         <div className="flex 	">
                           <p className="fw-bold m-0">

                             {specialDealsAvgMaxSettings.max_bid} {currency}
                           </p>
                           <p className="m-0">Max Bid</p>
                         </div>
                       </span>
                     </div>

                     <Card className="border-0">
                       <div className="mb-3">
                         <span className="badge badge-soft-danger align-middle badge-span rounded-pill  mx-2">
                           <p className="badge-icon">{1}</p>
                         </span>
                         Set your bid price
                       </div>
                       <CardBody className="border-0">
                         <TextField
                           style={{
                             width: "100%",
                             height: "70px",
                             fontSize: "14pt",
                           }}
                           type="number"
                           size="medium"
                           variant="outlined"
                           placeholder="Enter New Price"
                           value={myBid}
                           autoFocus={true}
                           onChange={(event) => setMyBid(parseInt(event.target.value))}
                           error={(myBid < Number(minBid) && myBid !== 0) || lengthMyBid > 7}
                           helperText={
                             (myBid < Number(minBid) && myBid !== 0) || lengthMyBid > 7
                               ? `Minimum bid is  ${minBid} and max 9999999`
                               : " "
                           }
                           InputProps={{
                             endAdornment: (
                               <InputAdornment position="end">
                                 {currency}
                               </InputAdornment>
                             ),
                           }}
                         />
                       </CardBody>
                     </Card>
                   </Col>
                 </Row>
                 <div
                   style={{ marginBottom: "100px" }}
                   className="d-flex justify-content-center"
                 >
                   <button

                     className="btn button-width-sd text-white bg-light-red ContinuebtnPPC"
                     onClick={() => handleStepper(3)}
                     disabled={!((myBid < 9999999) && (myBid >= Number(minBid)))}
                   >
                     Continue
                   </button>
                 </div>
               </CardBody>
             </Card> }

             
            </>
          ) : (
            <div className="py-4 text-center">
              <div>
                <lord-icon
                  src="https://cdn.lordicon.com/nmgjlgnk.json"
                  trigger="loop"
                  colors="primary:#ee856f,secondary:#ee856f"
                  style={{ width: "104px", height: "auto" }}
                ></lord-icon>
              </div>

              <div className="mt-4">
                <h5>
                  You already have a special deal for this product in the  {dealCategory} category.
                </h5>
              </div>
            </div>
          )}
        </>
      )}
    </Collapse>
  );
};
export default CollapseThree;