import validator from "../../helpers/Validator";
import * as Yup from "yup";

export const validationSchemaOne = Yup.object({
  company_name: Yup.string()
    .required("Please Enter Your Company Name")
    .min(2, "Too Short!")
    .max(40, "Too Long!")
    .matches(validator.companyNameValidation, "Input a valid company name"),
  email: Yup.string()
    .email()
    .required("Please Enter Your Email")
    .max(40, "Too Long!"),
  username: Yup.string()
    .required("Please Enter Your Username")
    .min(5, "Too Short!")
    .max(40, "Too Long!")
    .matches(validator.usernameValidation, "Input a valid username"),
  mobile_number: Yup.string()
    .required("Please Enter Your Phone Number")
    .min(8, "Too Short!")
    .max(14, "Too Long!")
    .matches(validator.mobileNumberValidation, "Input a valid phone number"),
});

export const validationSchemaTwo = Yup.object({});

export const validationSchemaThree = Yup.object({
  password: Yup.string()
    .required("Please Enter Your Password")
    .matches(
      validator.passwordValidation,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Character"
    ),
  confirm_password: Yup.string()
    .required("Please Enter Your Password")
    .when("password", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "Confirm Password Isn't Match"
      ),
    }),
});
export const validationSchemaFour = Yup.object({
  store_name: Yup.string()
    .required("Please Enter Your Store Name")
    .min(2, "Too Short!")
    .max(40, "Too Long!")
    .matches(validator.companyNameValidation, "Input a valid store name"),
  headquarters_location: Yup.string()
    .required("Please Enter Your Headquarters Location"),

  tax_registration_number: Yup.string()
    .required("Please Enter Your Tax Registration Number")
    .min(1, "Too Short!")
    .max(30, "Too Long!"),
  business_registration_number: Yup.string()
    .required("Please Enter Your Business Registration Number")
    .min(1, "Too Short!")
    .max(30, "Too Long!"),
  category: Yup.string().required("Please Select category"),
});

const FILE_SIZE = 5000000;

export const SUPPORTED_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/gif",
  "image/png",
];
export const validationSchemaFive = Yup.object({
  image_trade_license: Yup.mixed()
    .required("Required")

    .test(
      "fileSize",
      "Maximum image sizw is 5 mb",
      (value) => value && value.size <= FILE_SIZE
    )
    .test(
      "fileFormat",
      "Unsupported Format",
      (value) => value && SUPPORTED_FORMATS.includes(value.type)
    ),
  image_tax_registration_certificate: Yup.mixed()
    .required("Required")

    .test(
      "fileSize",
      "Maximum image sizw is 5 mb",
      (value) => value && value.size <= FILE_SIZE
    )
    .test(
      "fileFormat",
      "Unsupported Format",
      (value) => value && SUPPORTED_FORMATS.includes(value.type)
    ),
});
