import React, { useState } from "react";
import { DatePicker } from "antd";
import moment from "moment";
import {
  MonthsFinder,
  YearsFinder,
  QuartersFinder,
  DaysNumber,
  DaysFinder,
} from "../../helpers/charts_helper";
import { ToastContainer, toast } from "react-toastify";
import { config } from "../../config";

const { TOAST_AUTOCLOSE_TIMER } = config;
const DateFilter = ({
  action,
  setAction,
  setxLabel,
  setStartDate,
  setEndDate, startDate, endDate
  , setCallApi, callApi ,xLabel
}) => {
  const { RangePicker } = DatePicker;
  const [prevAction, setPrevAction] = useState("");
  const [dateHolder, setDateHolder] = useState("");
  const dateFormat = "YYYY-MM-DD";

  function disabledDate(current) {
    // Can not select days before today and today
    return current && current >= moment().endOf("day");
  }
  function getLastDayOfMonth(year, month) {
    return new Date(year, month, 0).getDate();
  }
  function getLastDayOfQuarter(year, month) {
    return new Date(year, month + 2, 0);
  }



  const handleOnClick = (e, action) => {
    setAction(action);
    setCallApi(!callApi)
    setPrevAction(action)

    const startDate = e[0].format("YYYY-MM-DD");
    const endDate = e[1].format("YYYY-MM-DD");
    let lastDayInMonth;
    let quarterDate;
    if (action === "year") {
      lastDayInMonth = getLastDayOfMonth(Number(e[1].format("YYYY")), 12);
    } else if (action === "quarter") {
      quarterDate = getLastDayOfQuarter(Number(e[1].format("YYYY")), Number(e[1].format("MM"))).toLocaleDateString("sv");
    }

    else {
      lastDayInMonth = getLastDayOfMonth(
        e[1].format("YYYY"),
        e[1].format("MM")
      );
    }
    if (action === "day") {
      if (
        DaysNumber(
          new Date(new Date(endDate)),
          new Date(new Date(startDate))
        ) <= 30
      ) {
        setxLabel(DaysFinder(new Date(startDate), new Date(endDate)));
      }
    } else if (action === "month") {
      setxLabel(
        MonthsFinder(
          new Date(startDate).toLocaleDateString("sv"),
          new Date(endDate).toLocaleDateString("sv")
        )
      );
    } else if (action === "year") {
      setxLabel(
        YearsFinder(
          new Date(startDate).toLocaleDateString("sv"),
          new Date(endDate).toLocaleDateString("sv")
        )
      );
    } else if (action === "quarter") {
      setxLabel(
        QuartersFinder(
          new Date(startDate).toLocaleDateString("sv"),
          new Date(endDate).toLocaleDateString("sv")
        )
      );
    }
    if (action === "day") {
      if (
        DaysNumber(
          new Date(e[1].format("YYYY-MM-DD")),
          new Date(e[0].format("YYYY-MM-DD"))
        ) > 30
      ) {
        toast("A maximum of 30 days is allowed. ", {
          position: "top-right",
          hideProgressBar: true,
          className: "bg-danger text-white",
          progress: undefined,
          toastId: "",
        });
      } else {
        setStartDate(e[0].format("YYYY-MM-DD"));
        setEndDate(e[1].format("YYYY-MM-DD"));
      }
    } else if (action === "year") {
      setStartDate(e[0].format(`YYYY-01-01`));
      setEndDate(e[1].format(`YYYY-12-${lastDayInMonth}`));
    } else if (action === "month") {
      setStartDate(e[0].format(`YYYY-MM-01`));
      setEndDate(e[1].format(`YYYY-MM-${lastDayInMonth}`));
    } else if (action === "quarter") {
      setStartDate(e[0].format(`YYYY-MM-DD`));
      setEndDate(e[1].format(quarterDate));
    }
  };
  const handleResetDates = (date) => {

    if (date !== prevAction) {
      setDateHolder(null)
    } else {
      setDateHolder([
        moment(startDate, dateFormat),
        moment(endDate, dateFormat)
      ])
    }

  }

  return (
    <div className="d-flex gap-1 dashboard_picker">
      <ToastContainer autoClose={TOAST_AUTOCLOSE_TIMER} />

      <RangePicker
        bordered={false}
        allowClear={false}
        min={new Date()}
        suffixIcon={
          <button
            type="button"
            className="btn text-light-red bg-light-pink btn-sm"
          >
            D
          </button>
        }
        disabledDate={disabledDate}
        onClick={() => handleResetDates("day")}
        onChange={(e) => {
          handleOnClick(e, "day");
        }}
        size="small"
        value={dateHolder}

      />
      <RangePicker
        bordered={false}
        allowClear={false}
        min={new Date()}
        suffixIcon={
          <button
            type="button"
            className="btn text-light-red bg-light-pink btn-sm"
          >
            M
          </button>
        }
        disabledDate={disabledDate}
        onClick={() => handleResetDates("month")}
        onChange={(e) => {
          handleOnClick(e, "month");
        }}
        size="small"
        month
        value={dateHolder}

        picker={"month"}
      />
      <RangePicker
        bordered={false}
        allowClear={false}
        min={new Date()}
        suffixIcon={
          <button
            type="button"
            className="btn text-light-red bg-light-pink btn-sm"
          >
            Q
          </button>
        }
        disabledDate={disabledDate}
        onClick={() => handleResetDates("quarter")}
        onChange={(e) => {
          handleOnClick(e, "quarter");
        }}
        size="small"
        value={dateHolder}

        picker={"quarter"}
      />
      <RangePicker
        bordered={false}
        allowClear={false}
        min={new Date()}
        suffixIcon={
          <button
            type="button"
            className="btn text-light-red bg-light-pink btn-sm"
          >
            Y
          </button>
        }
        disabledDate={disabledDate}
        onClick={() => handleResetDates("year")}
        onChange={(e) => {
          handleOnClick(e, "year");
        }}
        size="small"
        value={dateHolder}

        picker={"year"}
      />
    </div>
  );
};
export default DateFilter;
