const salesSvg = (fill) => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48" height="48" rx="4" fill={fill} fillOpacity="0.1" />
      <g clipPath="url(#clip0_3556_2205)">
        <path
          d="M19 20V18C19 16.6739 19.5268 15.4021 20.4645 14.4645C21.4021 13.5268 22.6739 13 24 13C25.3261 13 26.5979 13.5268 27.5355 14.4645C28.4732 15.4021 29 16.6739 29 18V20H32C32.2652 20 32.5196 20.1054 32.7071 20.2929C32.8946 20.4804 33 20.7348 33 21V33C33 33.2652 32.8946 33.5196 32.7071 33.7071C32.5196 33.8946 32.2652 34 32 34H16C15.7348 34 15.4804 33.8946 15.2929 33.7071C15.1054 33.5196 15 33.2652 15 33V21C15 20.7348 15.1054 20.4804 15.2929 20.2929C15.4804 20.1054 15.7348 20 16 20H19ZM19 22H17V32H31V22H29V24H27V22H21V24H19V22ZM21 20H27V18C27 17.2044 26.6839 16.4413 26.1213 15.8787C25.5587 15.3161 24.7956 15 24 15C23.2044 15 22.4413 15.3161 21.8787 15.8787C21.3161 16.4413 21 17.2044 21 18V20Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_3556_2205">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(12 12)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default salesSvg;
