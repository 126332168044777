import PropTypes from "prop-types";
import React, {useState} from "react";
import { Modal, ModalBody, Row, Col } from "reactstrap";

import rateOne from "../../../assets/images/rate/rate_1.svg"
import rateTwo from "../../../assets/images/rate/rate_2.svg"
import rateThree from "../../../assets/images/rate/rate_3.svg"
import rateFour from "../../../assets/images/rate/rate_4.svg"
import rateFive from "../../../assets/images/rate/rate_5.svg"

const emoji = [
  { "picture": rateOne, "text": "Very Dissatisfied", "rate": 1 },
  { "picture": rateTwo, "text": "Dissatisfied", "rate": 2 },
  { "picture": rateThree, "text": "Neutral", "rate": 3 },
  { "picture": rateFour, "text": " Satisfied", "rate": 4 },
  { "picture": rateFive, "text": "Very Satisfied", "rate": 5 }
]
const OrderRatingModal = ({ show, setShow, handleOrderRate }) => {
  const [askFeedback,setAskFeedback]=useState('');
  const [showTextArea,setShowTextArea]=useState(false);
  const [rate,setRate]=useState(0);
  const [feedback, setFeedback] = useState('');
  
  
  
  const handlerClickRate =(r)=>{
    setRate(r)
    if (r<=3){
      setAskFeedback('How can we improve ?');
    }else{
      setAskFeedback('What are we doing right ?');
    }
    setShowTextArea(true);
  }

  const onCloseClick =()=>{
    setShow(false)
    setRate(0)
    setShowTextArea(false)
    setFeedback('')

  }

  const onSaveClick =()=>{
    const data ={
      rate,
      feedback
    }
    onCloseClick();
    handleOrderRate(data);
    setFeedback('')
  }

  return (
    <Modal
      size="lg"
      isOpen={show} toggle={onCloseClick} backdrop="static" centered={true} className="custom-modal-style">

      <ModalBody className="py-3 px-2 ">
        <div className="mt-2">
          <h5 className="text-center fw-bold">Rate your satisfaction level</h5>
        </div>
        <div className="mt-4 mx-5">
          <h6 className="text-center"> Rate your satisfaction level on the transaction by clicking on the emoji you want.</h6>
        </div>

        <Row className="justify-content-center mt-4 mb-2">
          {emoji.map((item, key) => (
            <Col xs="6" sm="4" md="2" key={key} className="mb-4">
              <div
                onClick={(e) => {
                  handlerClickRate(item.rate)
                }}
                className="cursor-pointer text-center"
              >
                <img
                  className="py-2 hover-zoom"
                  src={item.picture}
                  alt="success logo"
                />
                <p className={ rate===item.rate? 'text-light-red text-muted':'text-muted'}>{item.text}</p>
              </div>
            </Col>
          ))}
        </Row>
        

        {showTextArea && (
          <div className="d-flex flex-column align-items-center">
          <h6>{askFeedback}</h6>
          <textarea className=" textarea-feedback w-50" onChange={(e)=>{setFeedback(e.target.value)}} />
        </div>
        )}
        

        <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
          <button
            type="button"
            className="btn btn-light w-25"
            data-bs-dismiss="modal"
            onClick={()=>onCloseClick()}
          >
            Close
          </button>
          <button
            type="button"
            className="btn bg-light-red text-white w-25"
            data-bs-dismiss="modal"
            onClick={()=>onSaveClick()}
            disabled={rate !== 0?false:true}
          > Submit</button>
        </div>
      </ModalBody>
    </Modal>
  );
};

OrderRatingModal.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any,
  message: PropTypes.any,
};

export default OrderRatingModal;
