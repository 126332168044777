import React, { useEffect, useState } from "react";

import { Card, CardBody, CardHeader } from "reactstrap";
import { LineChart } from "../../Components/Common/ChartsJs";
import { useDispatch, useSelector } from "react-redux";
import NoDataFound from "./NoDataFound";
import YearsFilter from "./YearsFilter";
import { getMonthlyVisitors } from "../../store/charts/action";
import { MonthsFinder, timeFilter } from "../../helpers/charts_helper";
import { useTranslation } from "react-i18next";

const monthShortNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
  "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
];
const VisitorsLineChart = () => {
  const { t } = useTranslation();
  const [totalVisitors, setTotalVisitors] = useState([]);
  const { monthlyVisitors } = useSelector((state) => ({
    monthlyVisitors: state.Charts.monthlyVisitors,
  }));
  const year = new Date().getFullYear();
  const firstDay = `${year}-01-01`;
  const lastDay = `${year}-12-31`;
  const [action, setAction] = useState("month");
  const [xLabel, setxLabel] = useState(MonthsFinder(firstDay, lastDay));
  const [startDate, setStartDate] = useState(firstDay);
  const [endDate, setEndDate] = useState(lastDay);
  const [callApi, setCallApi] = useState(false);
  const dispatchChart = useDispatch();
  useEffect(() => {
    const params = {
      action_type: "visit_on_merchant_website",
      start_date: startDate,
      end_date: endDate,
      action: action,
    };

    dispatchChart(getMonthlyVisitors(params));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatchChart, callApi]);
  useEffect(() => {
    if (monthlyVisitors?.data) {
      let visitorsNum = [];
      xLabel.forEach((month) => {
        const item = monthlyVisitors?.data?.find((item) => {
          return item.date === month;
        });

        if (item) {
          visitorsNum.push(item.evolution);
        } else {
          visitorsNum.push(0);
        }
      });
      setTotalVisitors(visitorsNum);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [monthlyVisitors]);
  return (
    <Card>
      <CardHeader className="py-3 d-flex justify-content-between">
        <h4 className="card-title mb-0">{t("evolutionOfVisitors") +  " / " + timeFilter[action]}</h4>
        <div className="dashboard_picker">
          <YearsFilter
            action={action}
            setAction={setAction}
            setxLabel={setxLabel}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            setCallApi={setCallApi}
            startDate={startDate}
            endDate={endDate}
            callApi={callApi}
          />
        </div>
      </CardHeader>
      <CardBody className="position-relative">
        {monthlyVisitors?.data?.length === 0 && (
          <NoDataFound />
        )}
        <LineChart
          xLabel={timeFilter[action]}
          yLabel={"Visitors"}
          Month={monthShortNames}
          Sales={totalVisitors}
          dataFlag={monthlyVisitors?.data?.length !== 0}

          label={"Evolution of visitors"}
          dataColors='["--vz-primary-rgb, 0.8", "--vz-primary-rgb, 0.9"]'
          colorFrom={"#4E5AA8"}
          colorTo={"#EE856F"}
        />
      </CardBody>
    </Card>
  );
};
export default VisitorsLineChart;
