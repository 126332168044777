import { takeEvery, fork, put, all, call } from "redux-saga/effects";

import { FORGET_PASSWORD } from "./actionTypes";
import { forgetPasswordSuccess, forgetPasswordFail } from "./actions";

import { forgetPassword as forgetPasswordApi } from "../../../helpers/salis_api";


function* forgetPassword({ payload: data }) {
  try {
    const response = yield call(forgetPasswordApi, data);
    yield put(forgetPasswordSuccess(response));
  } catch (error) {
    yield put(forgetPasswordFail(error));
  }
}
export function* watchForgetPassword() {
  yield takeEvery(FORGET_PASSWORD, forgetPassword);
}

function* forgetPasswordSaga() {
  yield all([fork(watchForgetPassword)]);
}

export default forgetPasswordSaga;
