import PropTypes from "prop-types";
import React from "react";
import { Modal, ModalBody } from "reactstrap";


const CaptureModal = ({ message, show, onCloseClick, action, setShippingCompany, shippingCompany }) => {
  return (

    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalBody className="py-3 px-5">
        <div className="mt-2 text-center">
          <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
            <h4>Are you sure? </h4>
            <p className="text-muted mx-4 mb-0">{message}</p>
          </div>
        </div>

        <div className="mb-3 pt-3">
          <label className="form-label" htmlFor="product-price-input">
            Shipping company *
          </label>
          <div className="input-group mb-3">

            <input
              id="total_amount"
              type="text"
              placeholder="Please enter shipping company name"
              onChange={(e) => setShippingCompany(e.target.value)}
              className={
                shippingCompany.length > 50
                  ? "form-control is-invalid"
                  : "form-control"
              }
            />
          </div>

        </div>
        <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
          <button
            type="button"
            className="btn w-sm btn-light"
            data-bs-dismiss="modal"
            onClick={onCloseClick}
          >
            Close
          </button>
          <button
            type="button"
            className="btn w-sm bg-light-red text-white "
            id="delete-record"
            onClick={action}
            disabled={shippingCompany === "" || shippingCompany.length > 50}
          >
            Yes
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};

CaptureModal.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any,
  message: PropTypes.any,
};

export default CaptureModal;
