import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Modal, ModalBody } from "reactstrap";
//Import actions
import {
    preAddCard,
} from "../../../store/ecommerce/action";
//redux
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";

const AddCardPopup = ({ show, onCloseClick }) => {
    const dispatch = useDispatch();

    const { preAddCardRes } = useSelector((state) => ({
        preAddCardRes: state.Ecommerce.preAddCard,
    }));
    const baseURL = preAddCardRes.baseURL
    const scriptURL = preAddCardRes.scriptURL

    useEffect(() => {
        if(show){
        dispatch(preAddCard());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch,show]);

    return (
        <>

            <Modal isOpen={show} toggle={onCloseClick} centered={true}>
                <ModalBody className="p-3 rounded">
                    <Helmet>
                        <script src="https://code.jquery.com/jquery-1.11.3.min.js"></script>
                        <script>
                            {`
                               var wpwlOptions = {
                                style: "plain",
                                onReady: function(){ 
                                    $('.wpwl-label-brand').html('Card Brand');
                                    $('.wpwl-label-cardHolder').html('Card holder name');
                                    $('.wpwl-control-iframe.wpwl-control-cardNumber').attr('placeholder','1234 1234 1234 1234');
                                    $('.wpwl-control-iframe.wpwl-control-cvv').attr('placeholder','3 digits');
                                    $('.wpwl-control-iframe.wpwl-control-cvv').attr('data-maxlength','3');
                                    $('.wpwl-control-cardHolder').attr('placeholder','Name on the card');
                                    $('button[aria-label="Register now"]').html('Save');
                                    $('.wpwl-wrapper-brand').css("display","none");
                                    $('.wpwl-label-brand').css("display","none");
                                    $('.wpwl-control-cardNumber').after($(".wpwl-brand-card"));
                                    $('.wpwl-group.wpwl-group-cardNumber').before($('.wpwl-group.wpwl-group-cardHolder'))
                                    $('.wpwl-control-cardHolder').attr("required",true)
                                    $('.wpwl-control-cardHolder').on('input', function(e) {
                                        var cardHolderName = $('.wpwl-control-cardHolder').val();
                                        var errorMessage = "Invalid name"; // Set the error message
                                        if (cardHolderName.trim() === '') {
                                          e.preventDefault(); // Prevent the form from submitting
                                          $('.wpwl-control-cardHolder').addClass('is-invalid');
                                          // Create a new element for the error message
                                          var errorElement = $("<div class='invalid-feedback'>" + errorMessage + "</div>");
                                          // Append the error message to the form control
                                          $('.wpwl-control-cardHolder').parent().append(errorElement);
                                        } else {
                                          // If the name is valid, remove the error message and class
                                          $('.wpwl-control-cardHolder').removeClass('is-invalid');
                                          $('.wpwl-control-cardHolder').parent().find('.invalid-feedback').remove();
                                        }
                                      });
                                },
                                
                              }
                                
                            `}

                        </script>
                        <script src={scriptURL} async></script>

                    </Helmet>
                    <div className="d-flex gap-2 justify-content-end">
                        <button
                            type="button"
                            className="btn w-fit border rounded btn-light"
                            data-bs-dismiss="modal"
                            onClick={onCloseClick}
                            data-testid="close"
                        >
                            <i className="ri-close-line align-bottom"></i>
                        </button>
                    </div>
                    <div className="text-center pt-2 fs-15 ">
                        <h5>Add new card</h5>
                        <p className="text-muted mx-4 mb-0 fs-6">
                            Please add card information
                        </p>
                    </div>
                    <form
                        action={baseURL}
                        className="mt-4 paymentWidgets"
                        data-brands="MADA VISA MASTER"
                    ></form>

                </ModalBody>
            </Modal>
        </>
    );
};

AddCardPopup.propTypes = {
    onCloseClick: PropTypes.func,
    onDeleteClick: PropTypes.func,
    show: PropTypes.any,
};

export default AddCardPopup;