import React from "react";
import * as moment from "moment";
import { config } from "../../../config";
const handleValidDate = (date) => {
  const date1 = moment(new Date(date)).format("DD MMM Y");
  return date1;
};
const { currency } = config;
const handleValidTime = (time) => {
  const time1 = new Date(time);
  const getHour = time1.getUTCHours();
  const getMin = time1.getUTCMinutes();
  const getTime = `${getHour}:${getMin}`;
  var meridiem = "";
  if (getHour >= 12) {
    meridiem = "PM";
  } else {
    meridiem = "AM";
  }
  const updateTime = moment(getTime, "hh:mm").format("hh:mm") + " " + meridiem;
  return updateTime;
};

const Status = (cell) => {
  let statusBgColor = "white";
  let statusColor = "black";
  if (cell.value === "Active") {
    statusBgColor = "#E5FFE2";
    statusColor = "#22A717";
  } else if (cell.value === "Pending") {
    statusBgColor = "#FFF3DB";
    statusColor = "#F6A300";
  } else if (cell.value === "Expired") {
    statusBgColor = "#FFDCDC";
    statusColor = "#BF1D1D";
  }

  return (
    <React.Fragment>
      <span>
        <span
          className="badge  fs-12 fw-medium "
          style={{ backgroundColor: statusBgColor, color: statusColor }}
        >
          {cell.value}
        </span>
      </span>
    </React.Fragment>
  );
};

const Published = (cell) => {
  return (
    <React.Fragment>
      <span>
        {handleValidDate(cell.value)}
        <small className="text-muted ms-1">{handleValidTime(cell.value)}</small>
      </span>
    </React.Fragment>
  );
};

const Price = (cell) => {
  return (
    <React.Fragment>
      {cell.row.original.product.product_price} {currency}
    </React.Fragment>
  );
};
const Bid = (cell) => {
  return (
    <React.Fragment>
      {cell.row.original.bid} {currency}
    </React.Fragment>
  );
};
const TotalBid = (cell) => {
  return (
    <React.Fragment>
      {cell.row.original.total_bid} {currency}
    </React.Fragment>
  );
};

export { Status, Published, Price, handleValidTime, Bid, TotalBid };
