import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  DropdownItem,
} from "reactstrap";
import { handleValidDate } from "./EcommerceProductCol";
//Simple bar

import BreadCrumb from "../../../Components/Common/BreadCrumb";
import noImage from "../../../assets/images/no_image.jpg"

import { ProductDetails } from "./EcommerceProductCol";

import { Swiper, SwiperSlide } from "swiper/react";
import classnames from "classnames";
import { config } from "../../../config";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

import SwiperCore, { FreeMode, Navigation, Thumbs } from "swiper";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";

import { getOneProductData } from "../../../store/ecommerce/action";

SwiperCore.use([FreeMode, Navigation, Thumbs]);

const PricingWidgetList = (props) => {

  const { currency } = config;
  return (
    <React.Fragment>
      <Col lg={4} xs={10} sm={8} md={6}>
        <div className="p-1 border border-dashed rounded">
          <div className="d-flex align-items-center">
            <div className="avatar-sm me-2">
              <div className="avatar-title rounded bg-transparent text-success fs-24">
                <i className={props.prodDetail?.icon}></i>
              </div>
            </div>
            <div className="flex-grow-1">
              <p className="text-muted mb-1">{props.prodDetail?.label} :</p>
              <h5 className="mb-0">{props.prodDetail?.labelDetail} {currency}</h5>
            </div>
          </div>
        </div>
      </Col>
    </React.Fragment>
  );
};

function EcommerceProductDetail(props) {
  const dispatch = useDispatch();

  const { oneProduct } = useSelector((state) => ({
    oneProduct: state.Ecommerce.oneProduct,
  }));

  let { id } = useParams();

  useEffect(() => {
    dispatch(getOneProductData(id));
  }, [dispatch, id]);

  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  const [customActiveTab, setCustomActiveTab] = useState("1");
  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setCustomActiveTab(tab);
    }
  };

  const prodDetail = ProductDetails(oneProduct);
  document.title =
    "Product Details | Salis- Merchants Dashboard";
  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Product Details" pageTitle="Ecommerce" />

        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <Row className="gx-lg-5">
                  <Col xl={4} md={8} className="mx-auto">
                    <div className="product-img-slider sticky-side-div">
                      <Swiper
                        navigation={true}
                        thumbs={{ swiper: thumbsSwiper }}
                        className="swiper product-thumbnail-slider p-2 rounded bg-light"
                      >
                        <div className="swiper-wrapper">
                          <SwiperSlide>
                            <img

                              src={oneProduct.product_image === "" ? noImage : oneProduct.product_image}
                              alt=""
                              className="img-fluid d-block"
                            />
                          </SwiperSlide>
                        </div>
                      </Swiper>

                      <div className="product-nav-slider mt-2">
                        <Swiper
                          onSwiper={setThumbsSwiper}
                          slidesPerView={4}
                          freeMode={true}
                          watchSlidesProgress={true}
                          spaceBetween={10}
                          className="swiper product-nav-slider mt-2 overflow-hidden"
                        ></Swiper>
                      </div>
                    </div>
                  </Col>

                  <Col xl={8}>
                    <div className="mt-xl-0 mt-5">
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <h4>{oneProduct.product_name}</h4>
                          <div className="hstack gap-3 flex-wrap">


                            <div className="vr"></div>
                            <div className="text-muted">
                              Published :{" "}
                              <span className="text-body fw-medium">
                                {handleValidDate(oneProduct.created_at)}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="flex-shrink-0">
                          <div>
                            <DropdownItem>
                              <Link
                                to={`/products-update/${id}`}
                                className="text-decoration"
                              >
                                <i className="ri-pencil-fill align-bottom me-2 text-muted"></i>{" "}
                              </Link>
                            </DropdownItem>
                          </div>
                        </div>
                      </div>

                      <Row className="mt-4">
                        {prodDetail.map((prodDetail, key) => (
                          <PricingWidgetList
                          prodDetail={prodDetail}
                            key={key}
                          />
                        ))}
                      </Row>

                      <div className="mt-4 text-muted">
                        <h5 className="fs-14">Description :</h5>
                        <p>{oneProduct.short_description}</p>
                      </div>
                      <div className="mt-4 text-muted">
                        <h5 className="fs-14">Product url :</h5>
                        <p>{oneProduct.product_url}</p>
                      </div>
                      <div className="mt-4 text-muted">
                        <h5 className="fs-14">Reference ID :</h5>
                        <p>{oneProduct.reference_id}</p>
                      </div>

                      <div className="product-content mt-5">
                        <Nav tabs className="nav-tabs-custom nav-success">
                          <NavItem>
                            <NavLink
                              style={{ cursor: "pointer" }}
                              className={classnames({
                                active: customActiveTab === "1",
                              })}
                              onClick={() => {
                                toggleCustom("1");
                              }}
                            >
                              Details
                            </NavLink>
                          </NavItem>
                        </Nav>

                        <TabContent
                          activeTab={customActiveTab}
                          className="border border-top-0 p-4"
                          id="nav-tabContent"
                        >
                          <TabPane id="nav-detail" tabId="1">
                            <div>
                              <h5 className="font-size-16 mb-3">
                                Product Description:
                              </h5>
                              <p>{oneProduct.description}</p>
                            </div>
                          </TabPane>
                        </TabContent>
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default EcommerceProductDetail;
