import React, { useState, useEffect } from "react";
import moment from "moment";
import { Card, CardBody, CardHeader } from "reactstrap";
import { BarChart } from "../../../Components/Common/ChartsJs";
import { timeFilter, MonthsFinder } from "../../../helpers/charts_helper";
import { useDispatch, useSelector } from "react-redux";
import { getReceivablesDue } from "../../../store/charts/action";
import NoDataFound from "../NoDataFound";
import { useTranslation } from "react-i18next";
import YearsFilter from "../YearsFilter";

import { config } from "../../../config";

const { currency } = config;
const monthShortNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
];
const ReceivablesDue3D = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch();
    const now = new Date();
    const firstDay = moment(now).startOf('year').format("YYYY-MM-DD");
    const lastDay = moment(now).endOf('year').format('YYYY-MM-DD');
    const [action, setAction] = useState("month");
    const [startDate, setStartDate] = useState(firstDay);
    const [endDate, setEndDate] = useState(lastDay);
    const [xLabel, setxLabel] = useState(MonthsFinder(firstDay, lastDay));
    const [callApi, setCallApi] = useState(false);
    const [receivablesDueData, setreceivablesDueData] = useState("");

    const { receivablesDue } = useSelector((state) => ({
        receivablesDue: state.Charts.receivablesDue,
    }));
    useEffect(() => {
        const params = {
            start_date: startDate,
            end_date: endDate,
            action: 'month'
        };
        dispatch(getReceivablesDue(params));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, callApi]);
    useEffect(() => {
        if (receivablesDue) {
            let amount = [];

            xLabel.forEach((date) => {
                const item = receivablesDue?.results?.[0]?.data?.find((item) => {
                    return item.due_date === date;
                });

                if (item) {
                    amount.push(item.due_amount);
                } else {
                    amount.push(0);
                }
            });
            setreceivablesDueData(amount);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [receivablesDue, xLabel]);
    return (
        < >
            <Card>
                <CardHeader className="border-0 align-items-center d-flex pb-2">
                    <h4 className="card-title mb-0 flex-grow-1">
                        {t('receivablesDueMonth') }
                    </h4>
                    <div className="dashboard_picker">
                        <YearsFilter
                            action={action}
                            setAction={setAction}
                            setxLabel={setxLabel}
                            setStartDate={setStartDate}
                            setEndDate={setEndDate}
                            setCallApi={setCallApi}
                            startDate={startDate}
                            endDate={endDate}
                            callApi={callApi}
                        />
                    </div>
                </CardHeader>

                <CardBody className="position-relative">
                    {receivablesDue?.results?.[0]?.data?.length === 0 && (
                        <NoDataFound />
                    )}
                    <BarChart
                        xLabel={timeFilter['day']}
                        yLabel={"Amount"}
                        Month={monthShortNames}
                        Sales={receivablesDueData}
                        dataFlag={receivablesDue?.results?.[0]?.data?.length !== 0}
                        currency={currency}
                        label={"Receivables Due"}
                        dataColors='["--vz-primary-rgb, 0.8", "--vz-primary-rgb, 0.9"]'
                        colorFrom={"#EE856F"}
                        colorTo={"#4E5AA8"}
                    />
                </CardBody>
            </Card>
        </>
    );
};
export default ReceivablesDue3D;
