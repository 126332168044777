import React, { useMemo } from "react";
import DataTable from "react-data-table-component";
import Loader from "../../Ecommerce/Loader";
import { DatePicker } from "antd";
import { TableToPDF } from "../../../helpers/tableToPdf";
import moment from "moment";
import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown,
} from "reactstrap";
import { useTranslation } from "react-i18next";

const InstallmentsTable = ({ totalReceivables, setStartDay, setEndDay }) => {
    const { t } = useTranslation();
    const { RangePicker } = DatePicker;

    const columns = useMemo(
        () => [
            {
                name: <span className="fw-normal text-gray-650">{t("invoiceDetails")}</span>,
                selector: (row) => row.invoice_details,
            },
            {
                name: <span className="fw-normal text-gray-650">{t("clientAppId")}</span>,
                selector: (row) => row.clinet_app_id,
            },
            {
                name: <span className="fw-normal text-gray-650">{t("purchaseValue")}</span>,
                selector: (row) => row.purchase_value,

            },
            {
                name: <span className="fw-normal text-gray-650">{t("receiveAmount")}</span>,
                selector: (row) => row.receive_amount,
            },
            {
                name: (
                    <span className="fw-normal text-gray-650">{t("dueAmount")}</span>
                ),
                selector: (row) => row.due_amount === "" ? "--" : row.due_amount,
            },
            {
                name: <span className="fw-normal text-gray-650">{t("overdueAmount")}</span>,
                selector: (row) => row.overdue_amount === "" ? "--" : row.overdue_amount,
            },

            {
                name: (
                    <span className="fw-normal text-gray-650">{t("startDate")}</span>
                ),
                selector: (row) => row.start_date,
            },
            {
                name: (
                    <span className="fw-normal text-gray-650">{t("dueDate")}</span>
                ),
                selector: (row) => row.end_date,
            },
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );
    function disabledDay(current) {
        // Can not select days before today and today
        return current && current >= moment().endOf("day");
    }
    const handleDayPickerChange = (e) => {
        const startDate = e[0].format("YYYY-MM-DD");
        setStartDay(startDate);
        const endDate = e[1].format("YYYY-MM-DD");
        setEndDay(endDate);
    };
    const printTableToPDF = () => {
        TableToPDF(totalReceivables, "Total Receivables");
    };
    return (
        <div className="py-4 mt-2">
            <div className="bg-white rounded d-flex justify-content-between align-items-center p-2">
                <h4 className="card-title mb-0">{t("totalReceivablesOutStanding")}</h4>
                <div className="dashboard_picker">
                    <button className="btn text-light-red bg-light-pink btn-sm cursor-pointer p-0">
                        <RangePicker
                            bordered={false}
                            allowClear={false}
                            min={new Date()}
                            disabledDate={disabledDay}
                            onChange={handleDayPickerChange}
                            size="small"
                            value={null}
                        />
                    </button>
                    <UncontrolledDropdown className="dropdown d-inline-block">
                        <DropdownToggle className="btn border-0 btn-sm" tag="button">
                            <i className="ri-more-2-fill align-middle"></i>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end">
                            <DropdownItem href="#!" onClick={printTableToPDF}>
                                <i className="ri-printer-line align-bottom me-2 text-muted"></i>
                                Print as PDF
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </div>
            </div>

            <div className="product-visitors-table-rdt">
                {totalReceivables && totalReceivables.length > 0 ? (
                    <DataTable
                        columns={columns}
                        data={totalReceivables}
                        pagination
                        responsive
                        paginationRowsPerPageOptions={[6, 10, 15, 20, 25, 30]}
                        paginationPerPage={6}
                        paginationComponentOptions={{
                            rowsPerPageText: "",
                            rangeSeparatorText: "of",
                        }}
                    />
                ) : (
                    <Loader />
                )}
            </div>
        </div>
    );
};
export default InstallmentsTable;
