import React, { useState } from "react";
import { Card, CardBody, Col, CardHeader, Button } from "reactstrap";
import Loader from "../Loader";
import { handleDate } from "../../../helpers/orderColHelper";
import { handleLetter } from "../../../helpers/orderColHelper";
import { useTranslation } from "react-i18next";
const WebhookList = ({ weebhooks, formik, setDeleteModal, setwebhookId }) => {
  const { t } = useTranslation();

  const [urlIndex, setUrlIndex] = useState(0);
  return (
    <Card className="my-5">
      <CardHeader>
        <Col className="col-sm">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex gap-4  ">
              <h5 className="card-title flex-grow-1 mb-0 ">
                {t("webhookListHeader")}
              </h5>
            </div>
          </div>
        </Col>
      </CardHeader>
      <CardBody>
        {weebhooks.results?.length === 0 ? (
          <Loader />
        ) : (
          <div className="table-responsive table-card mb-1">
            <table
              className="table align-middle table-nowrap table-borderless"
              id="customerTable"
            >
              <thead className="table-light">
                <tr>
                  <th width="350px" scope="col">{t("url")}</th>
                  <th scope="col">{t("events")}</th>
                  <th scope="col">{t("createdAt")}</th>
                  <th scope="col">{t("action")}</th>
                </tr>
              </thead>

              <tbody className="list form-check-all">
                {weebhooks.results?.map((item, index) => (
                
                  <tr
                    className="border-top "
                    key={item.webhook_id}
                  >
                    <td data-index={index}
                      onMouseEnter={(e) =>{setUrlIndex(Number(e.target.dataset.index))}}
                      onMouseLeave={() => setUrlIndex(-1)}
                    >
                      <button type="button" className={index  === urlIndex? "webhook-link-full" : "webhook-link"}>
                        <a
                          style={{ display: "contents" }}
                          href={item.url}
                          target={"_blank"}
                          rel="noreferrer"
                        >
                          {item.url}
                        </a>
                      </button>
                    </td>
                    {item.events.map((element, index) => (
                      <ul key={element.event_id}>
                        <li>{handleLetter(element.name)}</li>
                      </ul>
                    ))}
                    <td>
                      <p className=" align-center m-0">
                        {handleDate(item.created_at)}
                      </p>
                    </td>
                    <td>
                      <Button
                        className="btn btn-soft-danger"
                        id="product_image"
                        onBlur={formik.handleBlur}
                        onClick={() => {
                          setDeleteModal(true);
                          setwebhookId(item.webhook_id);
                        }}
                      >
                        <i className="ri-delete-bin-2-line"></i>
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </CardBody>
    </Card>
  );
};

export default WebhookList;
