const validator = {
   titleValidation : "^[\u0621-\u064Aa-zA-Z 0-9_.-]*$",
   descValidation : "^[\u0621-\u064Aa-zA-Z 0-9_.-]*$",
   priceValidation : "^\\d+(\\.\\d+)*$",
   urlValidation: "((http|https)://)(www.)?[a-zA-Z0-9@:%.\\-_\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)",
   FILE_SIZE : 5000000,
   SUPPORTED_FORMATS : ["image/jpg", "image/jpeg", "image/gif", "image/png"],
   mobileNumberValidation:"^[0-9+]*$",
   textOnly:"^[\u0621-\u064Aa-zA-Z ]*$",
   passwordValidation :/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{8,})/,
   usernameValidation:"^[a-zA-Z0-9_-]*$",
   companyNameValidation:"^[a-zA-Z 0-9_-]*$",
}

export default  validator ;
