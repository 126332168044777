import { jsPDF } from "jspdf";
import 'jspdf-autotable';
const TableToPDF = (data,file="data") => {
    let doc = new jsPDF({unit:"px",format:"a3"});
    let table=[];
    if(data.length){
      data.forEach(element => {
      table.push(Object.values(element))
    })
    let tableheader=Object.keys(data[0])
    tableheader.forEach((element,index) => {
        let header=element.replaceAll("_"," ")
        tableheader[index]=header.charAt(0).toUpperCase() + header.slice(1)
          
    })
    doc.autoTable({
        head: [tableheader],
        body: table,
        styles:{
            cellPadding:3,
            minCellWidth:'auto',
            lineWidth:1,
        },
    });
  
    doc.save(file+'.pdf');
    }else{
        doc.text(250, 50, 'Sorry! No Result Found.');
        doc.save(file+'.pdf');
    }

};

export { TableToPDF };