import {
  PROFILE_ERROR,
  PROFILE_SUCCESS,
  EDIT_PROFILE,
  RESET_PROFILE_FLAG,
  GET_PROFILE,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAIl,
  UPDATE_PROFILE,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAIl,
} from "./actionTypes";




export const editProfile = (user) => {
  return {
    type: EDIT_PROFILE,
    payload: { user },
  };
};

export const profileSuccess = (msg) => {
  return {
    type: PROFILE_SUCCESS,
    payload: msg,
  };
};

export const profileError = (error) => {
  return {
    type: PROFILE_ERROR,
    payload: error,
  };
};

export const resetProfileFlag = (error) => {
  return {
    type: RESET_PROFILE_FLAG,
  };
};


export const getUserData = (user) => {
  return {
    type: GET_PROFILE,
    payload: user,
  };
};

export const getUserDataSuccess = (user) => {
  return {
    type: GET_PROFILE_SUCCESS,
    payload: user,
  };
};

export const getUserDataError = (error) => {
  return {
    type: GET_PROFILE_FAIl,
    payload: error,
  };
};

export const updateUserData = (user) => {
  return {
    type: UPDATE_PROFILE,
    payload: user,
  };
};

export const updateUserDataSuccess = (user) => {
  return {
    type: UPDATE_PROFILE_SUCCESS,
    payload: user,
  };
};

export const updateUserDataError = (error) => {
  return {
    type: UPDATE_PROFILE_FAIl,
    payload: error,
  };
};


