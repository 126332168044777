import React, { useEffect} from "react";

//import Scss
import "./assets/scss/themes.scss";

//imoprt Route
import Route from "./Routes";

import { loadAnimation } from "lottie-web";
import { defineLordIconElement } from "lord-icon-element";
import { useDispatch } from "react-redux";
import {
  getFeaturesFlags,
} from "./store/featuresFlags/action";
defineLordIconElement(loadAnimation);
function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getFeaturesFlags());
  }, [dispatch]);
  return (
    <React.Fragment>
      <Route />
    </React.Fragment>
  );
}

export default App;
