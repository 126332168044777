import React from "react";
import { Redirect } from "react-router-dom";

//Dashboard
import DashboardEcommerce from "../pages/DashboardEcommerce";

// //Ecommerce Pages
import EcommerceProducts from "../pages/Ecommerce/EcommerceProducts/index";
import EcommerceProductDetail from "../pages/Ecommerce/EcommerceProducts/EcommerceProductDetail";
import EcommerceAddProduct from "../pages/Ecommerce/EcommerceProducts/EcommerceAddProduct";
import EcommerceUpdateProduct from "../pages/Ecommerce/EcommerceProducts/EcommerceUpdateProduct";
import EcommerceOrders from "../pages/Ecommerce/EcommerceOrders/index";
import EcommerceSettlements from "../pages/Ecommerce/EcommerceSettlements/index";
import EcommerceTransactions from "../pages/Ecommerce/EcommerceTransactions/index";

import EcommerceSettlementsDetail from "../pages/Ecommerce/EcommerceSettlements/EcommerceSettlementsDetail";

import EcommerceOrderDetail from "../pages/Ecommerce/EcommerceOrders/EcommerceOrderDetail";
import EcommerceInStoreOrder from "../pages/Ecommerce/EcommerceOrders/EcommerceInStoreOrderAdd";
import EcommerceCheckout from "../pages/Ecommerce/EcommerceCheckout";
import EcommerceInvoice from "../pages/Ecommerce/EcommerceInvoice/index";
import Webhook from "../pages/Ecommerce/EcommerceWebhook/index";

import EcommerceChartsJs from "../pages/Ecommerce/EcommerceCharts";
import EcommerceSpecialDeals from "../pages/Ecommerce/EcommerceSpecialDeals/index";
import EcommerceAddSpecialDeal from "../pages/Ecommerce/EcommerceSpecialDeals/EcommerceAddSpecialDeal";

//Cards
import EcommerceCards from "../pages/Ecommerce/EcommerceCards/index";
import EcommerceChangeCard from "../pages/Ecommerce/EcommerceCards/changeCard";
import Checkout from "../pages/Ecommerce/EcommerceCheckout/index";
import paymentStatusFlag from "../pages/Ecommerce/EcommerceCheckout/result";
import EcommerceWithdraw from "../pages/Ecommerce/EcommerceWallet/index";

// pay per click
import EcommercePayperClick from "../pages/Ecommerce/Ecommercepayperclick/index";
import EcommerceAddPayperclick from "../pages/Ecommerce/Ecommercepayperclick/EcommerceAddPayperclick";


//login
import Login from "../pages/Authentication/Login";
import Otp from "../pages/Authentication/OtpValidation";
import ForgetPasswordPage from "../pages/Authentication/ForgetPassword";
import ChangepasswordPage from "../pages/Authentication/Changepassword";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";

// User Profile
import Profile from "../pages/Authentication/Profile";
import EditProfile from "../pages/Authentication/EditProfile";
import Basic404 from "../pages/AuthenticationInner/Errors/Basic404";

const authProtectedRoutes = [
  { path: "/dashboard", component: DashboardEcommerce },
  { path: "/index", component: DashboardEcommerce },
  { path: "/products", component: EcommerceProducts },
  {
    path: "/product-details/:id",
    component: EcommerceProductDetail,
  },
  { path: "/products-add", component: EcommerceAddProduct },
  {
    path: "/products-update/:id",
    component: EcommerceUpdateProduct,
  },
  { path: "/orders", component: EcommerceOrders },
  { path: "/settlements", component: EcommerceSettlements },

  { path: "/transactions", component: EcommerceTransactions },


  { path: "/pay-per-click", component: EcommercePayperClick },
  {
    path: "/add-pay-per-click",
    component: EcommerceAddPayperclick,
  },


  { path: "/in-store-order", component: EcommerceInStoreOrder },

  {
    path: "/order-details/:id",
    component: EcommerceOrderDetail,
  },

  {
    path: "/settlements-details/:id",
    component: EcommerceSettlementsDetail,
  },

  { path: "/charts", component: EcommerceChartsJs },
  { path: "/special-deals", component: EcommerceSpecialDeals },
  {
    path: "/add-special-deals",
    component: EcommerceAddSpecialDeal,
  },
  {
    path: "/webhook",
    component: Webhook,
  },

  { path: "/invoice/:order_id", component: EcommerceInvoice },
  { path: "/apps-ecommerce-checkout", component: EcommerceCheckout },

  //User Profile
  { path: "/profile", component: Profile },
  { path: "/profile-edit", component: EditProfile },
  
  
  //cards
  { path: "/cards", component: EcommerceCards },
  { path: "/cards/:id", component: EcommerceChangeCard },
  { path: "/wallet/withdraw/", component: EcommerceWithdraw },

  //checkout
  { path: "/checkout/", component: Checkout },
  { path: "/result/", component: paymentStatusFlag },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/dashboard" />,
  },
  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "*",
    exact: true,
    component: Basic404,
  },
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/Otp", component: Otp },
  { path: "/forgot-password", component: ForgetPasswordPage },
  { path: "/change-password", component: ChangepasswordPage },

  { path: "/register", component: Register },
];

export { authProtectedRoutes, publicRoutes };
