const conversionRateSvg = ( fill ) => {
    return (
<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" role="img">
<rect width="48" height="48" rx="4" fill={fill} fillOpacity="0.1"/>
<g clipPath="url(#clip0_3194_2161)">
<path d="M28 28C29.657 28 31 29.343 31 31C31 32.657 29.657 34 28 34C26.343 34 25 32.657 25 31C25 29.343 26.343 28 28 28ZM18 24C20.21 24 22 25.79 22 28C22 30.21 20.21 32 18 32C15.79 32 14 30.21 14 28C14 25.79 15.79 24 18 24ZM28 30C27.448 30 27 30.448 27 31C27 31.552 27.448 32 28 32C28.552 32 29 31.552 29 31C29 30.448 28.552 30 28 30ZM18 26C16.895 26 16 26.895 16 28C16 29.105 16.895 30 18 30C19.105 30 20 29.105 20 28C20 26.895 19.105 26 18 26ZM26.5 14C29.538 14 32 16.462 32 19.5C32 22.538 29.538 25 26.5 25C23.462 25 21 22.538 21 19.5C21 16.462 23.462 14 26.5 14ZM26.5 16C24.567 16 23 17.567 23 19.5C23 21.433 24.567 23 26.5 23C28.433 23 30 21.433 30 19.5C30 17.567 28.433 16 26.5 16Z" fill={fill}/>
</g>
<defs>
<clipPath id="clip0_3194_2161">
<rect width="24" height="24" fill="white" transform="translate(12 12)"/>
</clipPath>
</defs>
</svg>
    );
  };

  export default conversionRateSvg;