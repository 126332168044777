import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import refundLogo from "../../../assets/images/refund.svg";
import ConfirmationModal from "../../../Components/Common/ConfirmationModal";
import { useDispatch, useSelector } from "react-redux";
import { addRefundPayment, resetEcomFlag } from "../../../store/actions";
import { ToastContainer, toast } from "react-toastify";
import { config } from "../../../config";
const { TOAST_AUTOCLOSE_TIMER } = config;

const OrderRefundModal = ({ show, onCloseClick, orderCaptures, oneOrder }) => {
  const dispatch = useDispatch();
  const [orderCapturedAmount, setOrderCapturedAmount] = useState("");
  const [confirmationModal, setConfirmationModal] = useState(false);

  const validationSchema = Yup.object().shape({
    capture_id: Yup.string().required("Required"),
    total_amount: Yup.number()
      .max(orderCapturedAmount, "Too Long!")
      .required("Required"),
    refund_reference_id: Yup.string()
      .min(2, "Too Short!")
      .max(30, "Too Long!")
      .required("Required"),
  });
  const { refundFlag } = useSelector((state) => ({
    inStoreErrorFlag: state.Ecommerce.inStoreErrorFlag,
    refundFlag: state.Ecommerce.refundFlag,
  }));
  useEffect(() => {
    if (refundFlag) {
      toast(refundFlag, {
        position: "top-right",
        hideProgressBar: true,
        className: refundFlag.includes("failed")
          ? "bg-danger text-white"
          : "bg-success text-white",
        progress: undefined,
        toastId: "",
      });
    }
    dispatch(resetEcomFlag());
  }, [dispatch, refundFlag]);

  const formik = useFormik({
    initialValues: {
      capture_id: "",
      refund_reference_id: "",
      total_amount: "",
    },
    validationSchema,
    onSubmit: (values) => {
      onCloseClick();

      setConfirmationModal(true);
    },
  });
  const handleOrderRefund = () => {
    setConfirmationModal(false);
    formik.resetForm();
    const data = {
      capture_id: formik.values.capture_id,
      refund_reference_id: formik.values.refund_reference_id,
      total_amount: formik.values.total_amount,
      order_id: oneOrder.order_id,
    };
    dispatch(addRefundPayment(data));
  };
  const handleNotValidForm = (e) => {
    e.preventDefault();
    formik.touched.capture_id = true;
    formik.touched.refund_reference_id = true;
    formik.touched.total_amount = true;
  };
  useEffect(() => {
    const index = orderCaptures.findIndex((object) => {
      return object.capture_id === formik.values.capture_id;
    });
    if (orderCaptures.length !== 0 && formik.values.capture_id) {
      setOrderCapturedAmount(orderCaptures[index].total_amount);
    }
  }, [formik, orderCapturedAmount, orderCaptures]);

  return (
    <>
      <ToastContainer
        autoClose={TOAST_AUTOCLOSE_TIMER}
        style={{ width: "auto" }}
      />

      <ConfirmationModal
        show={confirmationModal}
        onCloseClick={() => {
          setConfirmationModal(false);
          formik.resetForm();
        }}
        message={`You are going to refund with ${formik.values.total_amount} SAR!`}
        action={handleOrderRefund}
      />
      <Modal
        size="lg"
        isOpen={show}
        toggle={onCloseClick}
        backdrop="static"
        centered={true}
        className="custom-modal-style"
      >
        <ModalBody className="py-3 px-2 ">
          <div className="mt-2 text-center">
            <img src={refundLogo} alt="done" />
            <h5 className="pt-4 fw-bold">Refund amount</h5>
          </div>
          <div className="mt-4 mx-5 text-center">
            <span className="my-4">
              {" "}
              Order number:{" "}
              <p className="text-muted d-inline"> {oneOrder.order_id} </p>
            </span>
            {orderCaptures.length === 0 ? (
              <h6 className="text-center my-4">
                You don't have any captures! Please make a capture first.
              </h6>
            ) : (
              <h6 className="text-center my-4">
                You have {orderCaptures.length} captures please enter the refund
                amount you need
              </h6>
            )}
          </div>
          <form
            method="post"
            onSubmit={(e) =>
              formik.isValid ? formik.handleSubmit(e) : handleNotValidForm(e)
            }
          >
            <div className="mx-5">
              <div className="mb-3">
                <label className="form-label" htmlFor="inputGroupSelect01">
                  Capture ID *
                </label>
                <div className="input-group mb-3">
                  <select
                    id="capture_id"
                    value={formik.values.capture_id || ""}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className={
                      formik.errors.capture_id && formik.touched.capture_id
                        ? "form-select is-invalid"
                        : "form-select"
                    }
                  >
                    <option hidden value="default">
                      Select Capture ID
                    </option>
                    {orderCaptures.map((item, index) => {
                      return <option key={index}>{item.capture_id}</option>;
                    })}
                  </select>
                </div>
              </div>
              {formik.values.capture_id ? (
                <div className="text-success pb-4 ">
                  Captured amount is {orderCapturedAmount}
                </div>
              ) : null}

              <div className="mb-3">
                <label className="form-label" htmlFor="product-price-input">
                  Refund amount *
                </label>
                <div className="input-group mb-3">
                  <span className="input-group-text">SAR</span>
                  <input
                    id="total_amount"
                    type="number"
                    disabled={!formik.values.capture_id}
                    placeholder="Please enter refund amount"
                    value={formik.values.total_amount || ""}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    className={
                      formik.errors.total_amount && formik.touched.total_amount
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                  />
                </div>
                {formik.errors.total_amount && formik.touched.total_amount ? (
                  <div className="text-danger">
                    Refund amount should be less than or equal captured amount
                  </div>
                ) : null}
              </div>
              <div className="mb-3">
                <label htmlFor="title">Refund Reference ID *</label>
                <input
                  id="refund_reference_id"
                  type="text"
                  placeholder="Enter your refund reference id"
                  value={formik.values.refund_reference_id || ""}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={
                    formik.errors.refund_reference_id &&
                    formik.touched.refund_reference_id
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                />
                {formik.errors.refund_reference_id &&
                formik.touched.refund_reference_id ? (
                  <div className="text-danger">
                    {formik.errors.refund_reference_id}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
              <button
                type="button"
                className="btn  bg-light-red text-white w-25"
                data-bs-dismiss="modal"
                onClick={(e) => {
                  onCloseClick();
                  formik.resetForm();
                }}
              >
                Close
              </button>
              <button
                type="submit"
                className="btn  bg-light-red text-white w-25"
                data-bs-dismiss="modal"
              >
                Refund
              </button>
            </div>
          </form>
        </ModalBody>
      </Modal>
    </>
  );
};

OrderRefundModal.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any,
  message: PropTypes.any,
};

export default OrderRefundModal;
