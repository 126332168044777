const visitorsSvg = (fill ) => {
    return (
        <svg
        width="48"
        height="49"
        viewBox="0 0 48 49"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
      >
        <rect
          y="0.5"
          width="48"
          height="48"
          rx="4"
          fill={fill}
          fillOpacity="0.1"
        />
        <g clipPath="url(#clip0_2052_8098)">
          <path
            d="M23.141 16.5C21.559 16.5 20.754 16.669 20.013 17.065C19.395 17.3901 18.8901 17.895 18.565 18.513C18.169 19.253 18 20.059 18 21.64V27.358C18 28.94 18.169 29.745 18.565 30.486C18.902 31.116 19.383 31.597 20.013 31.934C20.753 32.33 21.559 32.499 23.141 32.499H24.859C26.441 32.499 27.246 32.33 27.987 31.934C28.605 31.6089 29.1098 31.104 29.435 30.486C29.831 29.746 30 28.94 30 27.358V21.64C30 20.058 29.831 19.253 29.435 18.512C29.1098 17.894 28.605 17.3891 27.987 17.064C27.247 16.669 26.441 16.5 24.86 16.5H23.14H23.141ZM23.141 14.5H24.859C26.873 14.5 27.953 14.778 28.931 15.301C29.8973 15.8125 30.6875 16.6027 31.199 17.569C31.722 18.547 32 19.627 32 21.641V27.359C32 29.373 31.722 30.453 31.199 31.431C30.6875 32.3973 29.8973 33.1875 28.931 33.699C27.953 34.222 26.873 34.5 24.859 34.5H23.14C21.126 34.5 20.046 34.222 19.068 33.699C18.1017 33.1875 17.3115 32.3973 16.8 31.431C16.278 30.453 16 29.373 16 27.359V21.64C16 19.626 16.278 18.546 16.801 17.568C17.3129 16.6019 18.1035 15.8121 19.07 15.301C20.047 14.778 21.127 14.5 23.141 14.5ZM23 18.5H25V23.5H23V18.5Z"
            fill={fill}
          />
        </g>
        <defs>
          <clipPath id="clip0_2052_8098">
            <rect
              width="24"
              height="24"
              fill="white"
              transform="translate(12 12.5)"
            />
          </clipPath>
        </defs>
      </svg>
    );
  };

  export default visitorsSvg;