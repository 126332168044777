import React, { useRef, useEffect, useState } from "react";

import WalletIcon from "../../assets/images/svg/wallet.svg";
import { Link } from "react-router-dom";

//Import actions
import { getWallet } from "../../store/ecommerce/action";

//redux
import { useSelector, useDispatch } from "react-redux";
const Balance = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getWallet());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
  const { wallet, getWalletFailed } = useSelector((state) => ({
    wallet: state.Ecommerce.wallet,
    getWalletFailed: state.Ecommerce.getWalletFailed,
  }));
  const [copySuccess, setCopySuccess] = useState(' text-light-red');
  const textAreaRef = useRef(null);
  function copyToClipboard(e) {
    textAreaRef.current.select();
    document.execCommand('copy');
    e.target.focus();
    setCopySuccess('');
  }
  return (
    <div className="p-4 rounded wallet">
      <img className="mb-2" src={WalletIcon} alt="wallet" />
      {!getWalletFailed && wallet ? (
        <>
          <h6 className="fw-normal">My balance</h6>
          <h5 className="fw-bold">{wallet.balance} SAR</h5>
          <div className="d-flex align-items-center gap-2">
            <input
              ref={textAreaRef}
              value={wallet.iban || ""}
              className="bg-none w-100 p-0 border-0"
              readOnly
            />

            <button className={`btn m-0 p-0 ${copySuccess}`} onClick={copyToClipboard}><i className="ri-clipboard-line"></i></button>
          </div>


          <Link to={"/wallet/withdraw/"}>
            <button
              type="button"
              className="btn text-light-red fw-normal fs-6 p-0 text-decoration-underline"
            >
              Withdraw
            </button>
          </Link>

        </>
      ) : (
        <>
          <h6 className="fw-normal">My balance</h6>
          <h5 className="fw-bold">0.00 SAR</h5>
          <p className="fw-normal">Your Account is not activated yet</p>
        </>
      )}
    </div>
  );
};
export default Balance;
