import React, { useState, useEffect } from "react";

import { Card, CardBody, CardHeader } from "reactstrap";
import { BarChart } from "../../../Components/Common/ChartsJs";
import DateFilter from "../DateFilter";
import { useDispatch, useSelector } from "react-redux";
import { timeFilter, MonthsFinder } from "../../../helpers/charts_helper";
import { getCommission } from "../../../store/charts/action";
import { useTranslation } from "react-i18next";
import NoDataFound from "../NoDataFound";
import moment from "moment";
import { config } from "../../../config";

const { currency } = config;
const Comission3DChart = () => {
  const { t } = useTranslation();
  const now = new Date();
  const firstDay = moment(now).startOf('year').format("YYYY-MM-DD");
  const lastDay = moment(now).endOf('year').format('YYYY-MM-DD');
  const [action, setAction] = useState("month");
  const [startDate, setStartDate] = useState(firstDay);
  const [endDate, setEndDate] = useState(lastDay);
  const [xLabel, setxLabel] = useState(MonthsFinder(firstDay, lastDay));
  const dispatch = useDispatch();
  const [callApi, setCallApi] = useState(false);

  const [commission3D, setCommission3D] = useState([]);

  const { commission } = useSelector((state) => ({
    commission: state.Charts.commission,
  }));
  useEffect(() => {

    const params = {
      start_date: startDate,
      end_date: endDate,
      action: action,
    };
    dispatch(getCommission(params));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, callApi]);

  useEffect(() => {
    if (commission) {
      if (commission.data.length !== 0) {
        let customers = [];

        xLabel.forEach((date) => {
          const item = commission.data.find((item) => {
            return item.date === date;
          });

          if (item) {
            customers.push(item.commissions);
          } else {
            customers.push(0);
          }
        });
        setCommission3D(customers);
      } else {
        setCommission3D([]);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commission, xLabel]);
  return (
    <>

      <Card className="">
        <CardHeader className="py-3 d-flex justify-content-between">
          <h4 className="card-title mb-0 flex-grow-1">{t("salisCommission")  + " / " +  timeFilter[action]}  </h4>
          <DateFilter
            action={action}
            setAction={setAction}
            setxLabel={setxLabel}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            setCallApi={setCallApi}
            startDate={startDate}
            endDate={endDate}
            callApi={callApi}
          />
        </CardHeader>

        <CardBody className="position-relative">
          {commission?.data?.length === 0 && (
            <NoDataFound />
          )}
          <BarChart
            xLabel={timeFilter[action]}
            yLabel={t("commission")}
            Month={xLabel}
            Sales={commission3D}
            dataFlag={commission?.data?.length !== 0}
            currency={currency}
            label={t("commission")}
            dataColors='["--vz-primary-rgb, 0.8", "--vz-primary-rgb, 0.9"]'
            colorFrom={"#4E5AA8"}
            colorTo={"#EE856F"}
          />
        </CardBody>
      </Card>
    </>
  );
};
export default Comission3DChart;
