import React, { useState } from "react";

import "antd/dist/antd.css";
import { Col, Container, Row } from "reactstrap";
import {
  getCards,
  getProductsData,
  payperclick,
  resetEcomFlag
} from "../../../store/ecommerce/action";
import { useDispatch, useSelector } from "react-redux";
import CollapseOne from "./collapseOne";
import CollapseTwo from "./collapseTwo";
import { config } from "../../../config";

import { useEffect } from "react";
import { Stepper, Step } from "react-form-stepper";
import { BsMegaphone } from "react-icons/bs";
import { HiNewspaper } from "react-icons/hi2";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import ResultModal from "../../../Components/Common/ResultModal";






const EcommerceAddPayperclick = () => {
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [products, setProducts] = useState([]);

  const [productDetails, setProductDetails] = useState("");
  const [title, setTitle] = useState("");
  const [budget, setbudget] = useState(1);

  const [dealDuration, setDealDuration] = useState("");

  const [dealCategory, setDealCategory] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [collapseOne, setCollapseOne] = useState(true);
  const [collapseTwo, setCollapseTwo] = useState(false);
  const [productName, setProductName] = useState("");
  const [flagSearch, setFlagSearch] = useState(false);
  const [Email, setEmail] = useState(false);
  const [notification, setnotification] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const { LOADER_RESET_TIMER } = config;

  const [resultModal, setResultModal] = useState(false);
  const [resultModalMsg, setResultModalMsg] = useState("");
  const [resultModalStatus, setResultModalStatus] = useState("");
  const [resultModaltitle, setResultModaltitle] = useState("");
  const [resultModalcloseBtn, setResultModalcloseBtn] = useState("");
  const {
    isCardAdd,
    payperclickFail,
    payperclickSuccess,
    allProductsData,
  } = useSelector((state) => ({
    payperclickSuccess: state.Ecommerce.payperclickSuccess,
    payperclickFail: state.Ecommerce.payperclickFail,
    allProductsData: state.Ecommerce.allProductsData,
  }));
  useEffect(() => {
    dispatch(getCards());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isCardAdd]);
  
  useEffect(() => {
    const productsFilter = {
      page: pageNumber,
    };

    dispatch(getProductsData(productsFilter));
  }, [dispatch,pageNumber]);

  const handleProductSearch = () => {
    setFlagSearch(true);

    if (productName !== "") {
      const productsFilter = {
        page: 1,
        product_name: productName,
      };
      dispatch(getProductsData(productsFilter));
    } else {
      setPageNumber(1);
      dispatch(getProductsData());
    }
  };

  useEffect(() => {
    if (allProductsData.results !== undefined) {

      if (!flagSearch) {
        if (products[0]?.id !== allProductsData.results[0]?.id) {
          setProducts((products) => [...products, ...allProductsData.results]);
        } else if (products.length === 0) {
          setProducts([...allProductsData.results]);
        }
      } else {
        setProducts([...allProductsData.results]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allProductsData]);

  useEffect(() => {
    setTimeout(() => {
      dispatch(resetEcomFlag());
    }, LOADER_RESET_TIMER);
  }, [
    dispatch,
    payperclickSuccess,
    payperclickFail,
    LOADER_RESET_TIMER
  ]);
  const handleStepper = (value) => {
    if (value === 0) {
      setCurrentPage(value);
      setCollapseOne(true);
      setCollapseTwo(false);
    } else if (
      value === 1 &&
      title.length < 60 &&
      title !== "" &&
      productDetails !== ""&&
      startDate !== "" &&
      endDate !== "" &&
      budget > 0 
    ) {
      setCurrentPage(value);
      setCollapseOne(false);
      setCollapseTwo(true);
    }
  };

  const handleValidatePPC = () => {
    const data = {
      product: productDetails.id,
      budget: budget,
      end_date:endDate,
      start_date:startDate,
      title:title,
      email_marketing:Email,
      push_notification:notification,
    };
    dispatch(payperclick(data));
  };

  useEffect(() => {
    if (payperclickSuccess) {
      handleStepper(1)
    }else if (payperclickFail) {
      setResultModaltitle("Oops")
      setResultModalStatus('warning');
      setResultModalMsg("It appears that You either have a duplicate campaign, have up to 5 campaigns, or have overdue payments for previous campaigns.");
      setResultModalcloseBtn("Ok")
      setResultModal(true)


    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    payperclickFail,
    payperclickSuccess
  ]);

  return (
    <>
      <div className="page-content">
      <ResultModal
        show={resultModal}
        onCloseClick={() => setResultModal(false)}
        message={resultModalMsg}
        status={resultModalStatus}
        title={resultModaltitle}
        closeBtn={resultModalcloseBtn}
      />
        <Container fluid>
          <BreadCrumb
            subTitle="Add Pay per click"
            title="Pay per click"
            pageTitle="Marketing"
          />
          <Row className="row">
            <Col lg={4} xl={2}>
              <div className="special-deals-stepper stepperPPC" style={{ marginTop: 80 }}>
                <Stepper hideConnectors={true} activeStep={currentPage}>
                  <Step
                    onClick={() => handleStepper(0)}
                    label="Set pay per click details"
                  >
                    {" "}
                    <BsMegaphone />
                  </Step>
                  <Step onClick={() => handleStepper(3)} label="Billing and payment" >
                    {" "}
                    <HiNewspaper />
                  </Step>
                </Stepper>
              </div>
            </Col>
            <Col lg={8} xl={10}>
              <CollapseOne
                collapseOne={collapseOne}
                setProductDetails={setProductDetails}
                products={products}
                productDetails={productDetails}
                allProductsData={allProductsData}
                handleProductSearch={handleProductSearch}
                setProductName={setProductName}
                flagSearch={flagSearch}
                setPageNumber={setPageNumber}
                pageNumber={pageNumber}
                title={title}
                setTitle={setTitle}
                budget={budget}
                setbudget={setbudget}
                handleStepper={handleStepper}
                handleValidatePPC={handleValidatePPC}
                setEndDate={setEndDate}
                setStartDate={setStartDate}
                endDate={endDate}
                startDate={startDate}
                setDealDuration={setDealDuration}
                setnotification={setnotification}
                notification={notification}
                Email={Email}
                setEmail={setEmail}
                dealDuration={dealDuration}
              />

              <CollapseTwo
                collapseTwo={collapseTwo}
                handleStepper={handleStepper}
                setDealCategory={setDealCategory}
                setEndDate={setEndDate}
                setStartDate={setStartDate}
                startDate={startDate}
                endDate={endDate}
                title={title}
                budget={budget}
                dealCategory={dealCategory}
                productDetails={productDetails}
                confirmationModal={confirmationModal}
                setConfirmationModal={setConfirmationModal}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default EcommerceAddPayperclick;
