import React from "react";
 const BankIcon=({width=40,height=40})=> {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2052_7640)">
        <path
          d="M4 40H44V44H4V40ZM8 24H12V38H8V24ZM18 24H22V38H18V24ZM26 24H30V38H26V24ZM36 24H40V38H36V24ZM4 14L24 4L44 14V22H4V14ZM8 16.472V18H40V16.472L24 8.472L8 16.472ZM24 16C23.4696 16 22.9609 15.7893 22.5858 15.4142C22.2107 15.0391 22 14.5304 22 14C22 13.4696 22.2107 12.9609 22.5858 12.5858C22.9609 12.2107 23.4696 12 24 12C24.5304 12 25.0391 12.2107 25.4142 12.5858C25.7893 12.9609 26 13.4696 26 14C26 14.5304 25.7893 15.0391 25.4142 15.4142C25.0391 15.7893 24.5304 16 24 16Z"
          fill="#B3B3B3"
        />
      </g>
      <defs>
        <clipPath id="clip0_2052_7640">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
export default BankIcon;