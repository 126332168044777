import React from "react";
import { Container } from "reactstrap";
import TotalBudget from "./TotalBudget";
import ConversionRate from "./ConversionRate";
import Customers from "./Customers";
import Sales from "./Sales";


import { resetLoginFlag } from "../../store/actions";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import { useProfile } from "../../Hooks/UserHooks";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import TopBar from "./TopBar";
import VisitorsTab from "./VisitorsTab";
import AllCards from "./AllCards";
import StatementTab from "./StatementTab";
import InstallmentTab from "./InstallmentTab";
import ROITab from "./ReturnOnInvestmentTab";

const EcommerceChartsJs = () => {
  const { userProfile } = useProfile();
  const dispatch = useDispatch();

  const [tab, setTab] = useState("visitors");
  const handleTap = (value) => {
    setTab(value);
  };

  useEffect(() => {
    dispatch(resetLoginFlag());

 
  }, [dispatch, userProfile]);


  document.title = "Dashboard| Salis";
  return (
    <div className="page-content bg-alice-blue h-100">
      <Container className="h-100" fluid>
        <BreadCrumb title={tab} pageTitle="Dashboard" />
        <TopBar handleTap={handleTap} tab={tab} />
        {tab === "all" && <AllCards />}

        {tab === "budget" && (
          <TotalBudget />
        )}

        {tab === "conversion" && <ConversionRate />}

        {tab === "visitors" && <VisitorsTab />}
        {tab === "customers" && <Customers />}
        {tab === "sales" && <Sales />}
        {tab === "installments" && <InstallmentTab  />}
        {tab === "statement" && <StatementTab />}
        {tab === "investment" && <ROITab />}
      </Container>
    </div>
  );
};

export default EcommerceChartsJs;
