import { takeEvery, fork, put, all, call } from "redux-saga/effects";

//Account Redux states
import { SEND_OTP,VERIFY_OTP } from "./actionTypes";
import { sendOtpSuccess, sendOtpFail,verifyOtpSuccess,verifyOtpFail } from "./actions";

//Include Both Helper File with needed methods
import { sendOtp as sendOtpApi,verifyOtp as verifyOtpApi} from "../../../helpers/salis_api";

function* sendOtp({ payload: otp }) {
  try {
    const response = yield call(sendOtpApi, otp);
    yield put(sendOtpSuccess(response));
  } catch (error) {
    yield put(sendOtpFail(error));
  }
}

export function* watchSendOtp() {
  yield takeEvery(SEND_OTP, sendOtp);
}



function* verifyOtp({ payload: otp }) {
  try {
    const response = yield call(verifyOtpApi, otp);
    yield put(verifyOtpSuccess(response));
  } catch (error) {
    yield put(verifyOtpFail(error));
  }
}

export function* watchVerifyOtp() {
  yield takeEvery(VERIFY_OTP, verifyOtp);
}




function* otpSaga() {
  yield all([fork(watchSendOtp)]);
  yield all([fork(watchVerifyOtp)]);
}

export default otpSaga;
